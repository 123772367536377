import React from 'react';
import { PieChart } from '@mui/x-charts';
import { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHookReportes } from '../../../libs/hookReportes';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { BiCalendar } from 'react-icons/bi';
import { useHookUsuarios } from '../../../libs/hookUsuarios';
import { FaConciergeBell } from 'react-icons/fa';

// import { addDays } from 'date-fns';
// import { useState } from 'react';

const ReporteServicios = () => {
	const isFirstRender = useRef(true);
	const [isLoading, setIsLoading] = useState(true);
	const [totalCitas, setTotalCitas] = useState(0);

	const columns = [
		{ field: 'col1', headerName: 'Id', width: 50 },
		{ field: 'col2', headerName: 'Centro', width: 150 },
		{ field: 'col3', headerName: 'Fecha', width: 120 },
		{ field: 'col4', headerName: 'Paciente', width: 280 },
		{ field: 'col5', headerName: 'Especialista', width: 280 },
		{ field: 'col6', headerName: 'Servicio', width: 250 },
	];

	const { getReport, filtroDate2, filtros2 } = useHookReportes();
	const { getUsuarios } = useHookUsuarios();
	const [rows, setRows] = useState([]);
	const [grafCitas, setGrafCitas] = useState([]);

	function capitalizeString(str) {
		if (typeof str === 'string' && str.length > 0) {
			const words = str.split(' ');
			const palabras = words.map(word => {
				return word[0].toUpperCase() + word.slice(1);
			});
			return palabras.join(' ');
		} else {
			return str;
		}
	}

	function formatDate(fecha) {
		// Obtenemos el día, el mes y el año de la fecha
		if(fecha !== undefined)
		{
		  const dia = fecha.getDate();
		const mes = fecha.getMonth() + 1;
		const anyo = fecha.getFullYear();
			// Formateamos la fecha
			return `${dia}/${mes}/${anyo}`;
		} else {
		  return "";
		}
	}

	const fechaFormateada = unixTimestamp => {
		const fecha = new Date(unixTimestamp * 1000);
		const fechaFormateada = fecha.toLocaleDateString();
		return fechaFormateada;
	};

	function generarObjeto(data, usuarios) {
		const objeto = [];
		let index = 0;
		const sortedData = data.sort((a, b) => a.inicio - b.inicio, {
			reverse: true,
		});
		for (const cita of sortedData) {
			const especialista = usuarios.filter(
				item => item.id === cita.especialistas
			);
			index++;
			objeto.push({
				id: index,
				col1: index,
				col2: capitalizeString(cita.nombreCentro),
				col3: fechaFormateada(cita.inicio),
				col4: cita.pacientes,
				col5:
					especialista.length > 0 ? especialista[0].nombre : cita.especialistas,
				col6: capitalizeString(cita.servicios),
			});
		}

		return objeto;
	}

	const objectFormater = objeto => {
		const data = Object.keys(objeto).map((valor, index) => {
			if (valor !== 'total') {
				return {
					id: index,
					value: objeto[valor],
					label: valor,
				};
			}
		});
		const filteredArr = data.filter(element => element !== undefined);
		return filteredArr;
	};

	const handlerGetData = async () => {
		let arrayCitas;
		let usuarios;
		const dateInicio = Math.floor(filtroDate2[0].getTime() / 1000);
		const dateFin = Math.floor(filtroDate2[1].getTime() / 1000);
		await getReport(dateInicio, dateFin, 1, true, filtros2)
			.then(res => {
				if(res.summary) {
					setGrafCitas(objectFormater(res.summary));
				} else {
					setGrafCitas({});
				}
				// setTotalCitas(res.data.summary.total)
				arrayCitas = res.data;
			})
			.catch(error => {
				console.log(error);
			});

		await getReport(dateInicio, dateFin, 2, false, filtros2)
			.then(res => {
				if(res.summary.total) {
					setTotalCitas(res.summary.total);
				} else {
					setTotalCitas({});
				}
				
			})
			.catch(error => {
				console.log(error);
			});

		await getUsuarios().then(res => {
			const listaEspecialistas = res.filter(usuario => {
				return usuario.rol === 'especialista';
			});
			const arrayDeObjetos = listaEspecialistas.map(objeto => ({
				id: objeto.correo,
				nombre: `${capitalizeString(objeto.nombre)} ${capitalizeString(
					objeto.apellido
				)}`,
			}));

			usuarios = arrayDeObjetos;
		});
		if(arrayCitas) {
			setRows(generarObjeto(arrayCitas, usuarios));
		} else {
			setRows([]);
		}
		
		setIsLoading(false);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handlerGetData();
		}
	}, [handlerGetData]);

	return (
		<div
			className="w-full h-full bg-azure  overflow-y-auto relative mt-3 "
			key={nanoid()}
		>
			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-50 z-40">
					<div className="absolute inset-0 flex items-center justify-center z-50">
						<div className="animate-spin rounded-full h-16 w-16 border-t-[6px] border-b-[6px] border-[#29CEBE]"></div>
					</div>
				</div>
			)}
			<div className="flex mx-auto rounded-2xl h-20 w-[93%] my-2 bg-darkblue ">
				<p className="text-2xl font-semibold text-center mx-auto my-auto text-white">
					{`Reportes centro Skinner ${formatDate(
						filtroDate2[0]
					)} hasta ${formatDate(filtroDate2[1])}`}
				</p>
			</div>
			{/* inicio graficos */}
			{rows.length > 0 && (
				<section className="text-gray-600 body-font w-full mb-3 mx-auto">
					<div className="container px-5 pt-3 mx-auto w-full">
						<div className="flex -m-4 text-center mx-auto w-full justify-center">
							<div className="p-4 md:w-2/5 sm:w-1/2 w-full">
								<div className="flex-wrap  px-3 py-4 bg-lightgrey rounded-xl  text-darkblue mx-auto">
									<p className="text-2xl font-medium text-center mx-auto mb-1 ">
										Servicios agendados
									</p>
									<div className="flex justify-center">
										{grafCitas.length > 0 && (
											<PieChart
												series={[
													{
														data: grafCitas,
														innerRadius: 50,
														cx: 95,
													},
												]}
												sx={{
													'--ChartsLegend-rootOffsetX': ' -80px',
													'--ChartsLegend-rootOffsetY': ' 0px',
												}}
												width={400}
												height={200}
											/>
										)}
									</div>
								</div>
							</div>

							{/* card 2 */}
							<div className="hidden p-4 md:w-2/5 sm:w-1/1 w-full  ">
								<div className="flex-col bg-lightgrey rounded-xl  text-darkblue justify-center items-center  h-[100%]">
									{/* card  */}
									<div className="p-2 w-full h-1/2  flex">
										<div className=" flex-col w-[100%] border-2 border-gray-200 px-3  bg-[#38b000] rounded-xl  text-white h-[90%] justify-between">
											<div className="my-1">
												<div className="mt-2 flex justify-center items-center align-middle">
													<BiCalendar className="text-2xl" />
													<h2 className=" title-font font-medium text-xl text-gray-900 px-2 ">{`${formatDate(
														filtroDate2[0]
													)}`}</h2>
												</div>
												<p className="leading-relaxed">Hasta</p>
												<div className="flex justify-center items-center align-middle">
													<BiCalendar className="text-2xl" />
													<h2 className=" title-font font-medium text-xl text-gray-900 px-2 ">{`${formatDate(
														filtroDate2[1]
													)}`}</h2>
												</div>
											</div>
										</div>
									</div>
									{/* fin car  */}
									{/* inicio  */}
									<div className="p-2 w-full h-1/2  ">
										<div className="flex-wrap border-2 border-gray-200 px-3 bg-[#4cc9f0] rounded-xl  text-white h-[90%] my-auto">
											<div className="flex justify-center mt-5 ">
												<FaConciergeBell className="text-4xl" />
												<h2 className=" title-font font-medium text-3xl text-gray-900 px-2 ">{`${totalCitas}`}</h2>
											</div>
											<p className="leading-relaxed">Servicios</p>
										</div>
									</div>
									{/* fin car  */}
								</div>
							</div>
							{/* fin car 2 */}
						</div>
					</div>
				</section>
			)}
			{/* fin graficos */}
			<div className=" mx-auto bg-lightgrey  rounded-2xl p-5 w-[95%]">
				<Box sx={{ height: 400, width: 1 }} className="">
					<DataGrid
						showCellVerticalBorder={true}
						showColumnVerticalBorder={true}
						// unstable_ignoreValueFormatterDuringExport={true}
						rows={rows}
						// disableColumnFilter
						// disableColumnSelector
						// disableDensitySelector
						columns={columns}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
					/>
				</Box>
			</div>
		</div>
	);
};

export default ReporteServicios;