import React from 'react'
import { FaGear } from 'react-icons/fa6';

const GearConfig = ({handlerShowFilter, data}) => {
    const sizeConfig = 30;

    const handleClick = () => {
      handlerShowFilter(data);
    }

  return (
    <div className="absolute top-3 right-5 rounded-lg bg-transparent p-2 hover:bg-darkblue hover:text-white text-darkblue">
	<FaGear 
  size={sizeConfig} 
  onClick={() => handleClick()} />
	</div>
  )
}

export default GearConfig