
import config from "../config";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { getDataFromApi  } from "./functions";
//atoms de recoil
import { userDataState, citaSelectedState} from "../atoms/utilsAtom";
import { useRecoilState } from "recoil";



////////////////////////////////////////HOOK USUARIOS////////////////////////////////////////
const fb = initializeApp(config.firebaseConfig);
const auth = getAuth(fb);

const useHookCentros = () => {
  const [usuario, setUsuario] = useRecoilState(userDataState);
  const handleSetUsuario = (data) => {
    setUsuario(data)
  }

 

  const getCentros = async () => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = 'getCentros';

 		const data = {
  			uid: usuario.uid,
	  		clienteId: usuario.clienteId,
		};

    const res = await getDataFromApi(endPoint, token, data)
    if (res && res.status === 200) {
      if (Array.isArray(res.data)) {
        return res.data
      } else {
        return []
      }
    } else {
      console.error('Error al obtener centros:', res);
			return false;
    }
  };

  const getCentro = async (centroId) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getCentro";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      centroId: centroId,
    };

    const res = await getDataFromApi(endPoint, token, data)
    if(res && res.status === 200){
      if (res && res.status === 200) {
        return res.data
      } else {
        console.log("error get centro",res) 
        return false
      }
    } else {
      console.log("error get centro",res) 
      return false
    }
  };

  const createCentro = async (nombre, direccion, telefono, salas) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "createCentro";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      centroData: {
        clienteId: usuario.clienteId,
        nombre: nombre,
        direccion: direccion,
        telefono: telefono,
        salas: salas,
      },
    };

    const res = await getDataFromApi(endPoint, token, data)
    if(res && res.status === 200){
      if (res && res.data === "centro creado correctamente") {
        toast.success(`${res.data}`, {
          position: "top-center",
          theme: "colored",
        })
      } else {
        console.log("Crear Centro error: ",res.data)
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
      }
    } else {
      console.log("error creando cita",res)
      return false
    }
  };

  const updateCentro = async ( parametro, valor, uid,
  ) => {
    const token = await auth.currentUser.getIdToken();

    const endPoint = "editCentro";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      centroId: uid,
      parametro: parametro,
      valor: valor,
    };

    const res = await getDataFromApi(endPoint, token, data)
    if(res &&res.status === 200){
    if (res && res.data === "centro actualizado correctamente") {
      return false
    } else {
      console.log("edit Centro error: ",res.data)
      toast.error("ha ocurrido un error", {
        position: "top-center",
        theme: "colored",
      })
    }} else{
      console.log("error editando centro",res)
      return false
    }

  };

  const deleteCentro = async (centroId) => {
    const endPoint = "delCentro";
    const token = await auth.currentUser.getIdToken();

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      centroId: centroId,
    };

    const res = await getDataFromApi(endPoint, token, data)
    if (res && res.status === 200) {
      if (res && res.data === "centro eliminado correctamente") {
        toast.success(`${res.data}`, {
          position: "top-center",
          theme: "colored",
        })
      } else {
        console.log("delete Centro error: ",res.data)
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
      }
    } else {
      console.log("error eliminando centro",res)
      return false
    }
    
  };

 
  
  const [citaSelected, setCitaSelected] = useRecoilState(citaSelectedState);


  const handleSetCitaSelected = (data) => {
    setCitaSelected(data)
  }

  return {
    getCentros,
    getCentro,
    createCentro,
    deleteCentro,
    updateCentro,
    citaSelected,
    handleSetCitaSelected,
    handleSetUsuario,
  };
};

export { useHookCentros };