import React, { useEffect, useState, useRef } from 'react';
import { useHookCentros } from '../../../libs/hookCentros';
import { nanoid } from "nanoid";
import UpdateCentro from './UpdateCentro';
import EliminarCentro from './EliminarCentro';
import CrearCentro from './CrearCentro';
import VerCentro from './VerCentro'
import Paginacion from "../../../components/paginacion";
import PopUp from "../../../components/PopUp";
import { BsBuildingAdd } from "react-icons/bs";
import { FaTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";

let Centros = () => {

    const { getCentros } = useHookCentros();
    const [centros, setCentros] = useState(null);
    const centroSelectedId = useRef(null)
    const isFirstRender = useRef(true);
    const [nombreCentroEliminar, setNombreCentroEliminar] = useState('');
    const [showModalEditar, setShowModalEditar] = useState(false)
    const [showModalCrear, setShowModalCrear] = useState(false)
    const [showModalEliminar, setShowModalEliminar] = useState(false)
    const [showModalVer, setShowModalVer] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    /**
    * Fetches centers: obtiene los centros.
    *
    * @return {Promise<void>} Promise that resolves when the centers data is fetched and the state is updated.
    */
    const fetchCenters = async () => {
        try {
            const centersData = await getCentros();
            setCentros(centersData);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            fetchCenters();
        }

    }, [fetchCenters])

     /**
     * Maneja el menu de editar.
     *
     * @param {type} centroId - The ID of the centro to be edited.
     * @return {type} No return value.
     */
    const handleEdit = (centroId) => {
        centroSelectedId.current = centroId;
        setShowModalEditar(true)
    }
    const handleOnEliminar = (centroId, nombre) => {
        setNombreCentroEliminar(nombre)
        centroSelectedId.current = centroId;
        setShowModalEliminar(true)
    }

    const handleOnVer = (centroId) => {
        centroSelectedId.current = centroId;
        setShowModalVer(true)
    }

    //PAGINACION:
    const [paginaActual, setPaginaActual] = useState(1);
    const centrosPorPagina = 10;
    const numeroTotalPaginas = Array.isArray(centros) ? Math.ceil(centros.length / centrosPorPagina) : 1;

    const handlePaginaCambio = (numeroPagina) => {
        setPaginaActual(numeroPagina);
    };

    const handlePaginaAnterior = () => {
        if (paginaActual > 1) {
            setPaginaActual(paginaActual - 1);
        }
    };

    const handlePaginaSiguiente = () => {
        if (paginaActual < numeroTotalPaginas) {
            setPaginaActual(paginaActual + 1);
        }
    };

    const closeModalEditar = () => {
        setShowModalEditar(false)
        setIsLoading(true)
        fetchCenters();
    }

    const closeModalVer = () => {
        setShowModalVer(false)
    }

    const closeModalEliminar = () => {
        setShowModalEliminar(false)
    }

    const closeModalCrear = () => {
        setShowModalCrear(false)
    }


    return (
        <div className="w-full h-full bg-azure overflow-x-auto" key={nanoid()}>
            {/* Popup Editarcentro */}
            {showModalEditar && ( <PopUp closeFunction={closeModalEditar}>
                            <UpdateCentro centroId={centroSelectedId.current} getCentros={fetchCenters} />
                            </PopUp>)}
            {/* Popup Ver Centro */}
            {showModalVer && (<PopUp closeFunction={closeModalVer}>
                            <VerCentro centroId={centroSelectedId.current} />
                            </PopUp>)}
            {/* Popup Eliminar Centro */}
            {showModalEliminar && (<PopUp closeFunction={closeModalEliminar}> 
                            <EliminarCentro nombre={nombreCentroEliminar} centroId={centroSelectedId.current} setShowModal={setShowModalEliminar} getCentros={fetchCenters} setIsLoading={setIsLoading} />
                            </PopUp>)}
            {/* Popup Crear Centro */}
            {showModalCrear && (<PopUp closeFunction={closeModalCrear}> 
                            <CrearCentro setShowModal={setShowModalCrear} getCentros={fetchCenters} setIsLoading={setIsLoading} />
                            </PopUp>)}

            {/* inicio de la lista de centros */}
            <div id="last-users" className="w[98%] m-4  bg-lightgrey rounded-md  p-4" key={nanoid()}>
                <div className="flex justify-between">
                    <h1 className="text-3xl  text-center font-semibold text-gray-700 m-4">
                        Lista de centros
                    </h1>

                    <button
                         onClick={()=>setShowModalCrear(true)}
                        className="items-center m-4 flex sm:text-xs lg:text-xl md:text-md px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
                    >
                        <BsBuildingAdd className="mr-2 w-8" /> <span> Añadir Centro</span>
                    </button>
                </div>
                {/* inicio tabla */}
                <div className="overflow-x-auto">
                    <table className="m-auto whitespace-nowrap w-[98%] rounded-md">
                        <thead className=" ">
                            <tr>
                                <th className="text-left py-3 px-2">Nombres</th>
                                <th className="text-left py-3 px-2">Teléfono</th>
                                <th className="text-left py-3 px-2">Dirección</th>
                                <th className="text-center py-3 px-2">Nº Salas</th>
                                <th className="text-left py-3 px-2"></th>
                            </tr>
                        </thead>
                        {/* inicio cuerpo tabla */}
                        <tbody>

                            {!Array.isArray(centros) || isLoading ? (

                                Array.from({ length: (centrosPorPagina / 2) }, (_, index) => (
                                    <tr key={index} className="bg-azure border-lightgrey border-b-8">
                                        <td colSpan={5} className="py-3 px-2">
                                            <div role="status" className="w-full animate-pulse">
                                                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                                            </div>
                                        </td>
                                    </tr>
                                ))

                            ) : (


                                Array.isArray(centros) &&  !isLoading &&
                                centros.slice((paginaActual - 1) * centrosPorPagina, paginaActual * centrosPorPagina).map((centro, index) => (
                                    <tr key={index} className="bg-azure border-lightgrey border-b-8 hover:bg-aqua hover:text-white cursor-pointer">
                                        <td className='py-3 px-2 capitalize rounded-l-[10px]' onClick={() => handleOnVer(centro.uid)}>
                                            {centro.nombre}
                                        </td>
                                        <td className="py-3 px-2"  onClick={() => handleOnVer(centro.uid)}>
                                            {centro.telefono}
                                        </td>
                                        <td className="py-3 px-2"  onClick={() => handleOnVer(centro.uid)}>
                                            {centro.direccion}
                                        </td>
                                        <td className="py-3 px-2 text-center"  onClick={() => handleOnVer(centro.uid)}>
                                            {centro.salas.length !== undefined ? centro.salas.length : '0'}
                                        </td>
                                        <td className="py-3 px-2 rounded-r-md">
                                            <div className='flex justify-around space-x-2' key={nanoid()}>
                                                <button type="button" title="Edit" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => handleEdit(centro.uid)}><FiEdit /></button>
                                                <button type="button" className="text-red hover:text-darkred" onClick={() => handleOnEliminar(centro.uid, centro.nombre)}><FaTrashCan /></button>
                                            </div>
                                        </td>
                                    </tr>

                                )))}

                        </tbody>
                    </table>
                </div>
                {/* fin tabla */}
                <Paginacion
                    currentPage={paginaActual}
                    totalPages={numeroTotalPaginas}
                    onPageChange={handlePaginaCambio}
                    onPrevPage={handlePaginaAnterior}
                    onNextPage={handlePaginaSiguiente}
                />

            </div>
        </div>

    );
};

export default Centros;