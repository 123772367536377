import React from "react";

import { BiTimeFive } from "react-icons/bi";
import {  BsCalendarDateFill } from "react-icons/bs";
import {
    FaHandHoldingMedical,
    FaConciergeBell,
    FaBuilding,
    FaUser
} from "react-icons/fa";
import "../../styles/calendarioCita.css";
import { format } from "date-fns";
import { useHookCalendario } from "../../../libs/hookCalendario";

const DatosSesion = ({handleShowVerCitas}) => {
const { sesionSelected } = useHookCalendario();
const inicioTimestamp = sesionSelected.inicio;
const finTimestamp = sesionSelected.fin;
let inicioDate;
let finDate;
if(sesionSelected.nombreCentro !== 'strapi_migracion' ) {
    inicioDate = new Date(inicioTimestamp * 1000);
    finDate = new Date(finTimestamp * 1000);
} else {
    inicioDate = new Date((inicioTimestamp * 1000) - (5 * 3600000));
    finDate = new Date((finTimestamp * 1000) - (5 * 3600000));
}

const horaInicio = inicioDate.getHours();
const minutoInicio = inicioDate.getMinutes();
const horaFin = finDate.getHours();
const minutoFin = finDate.getMinutes();
const horaInicioFormateada = (horaInicio < 10 ? '0' : '') + horaInicio;
const minutoInicioFormateado = (minutoInicio < 10 ? '0' : '') + minutoInicio;
const horaFinFormateada = (horaFin < 10 ? '0' : '') + horaFin;
const minutoFinFormateado = (minutoFin < 10 ? '0' : '') + minutoFin;

    return (
        <div className="flex-col relative h-fit my-12 mx-auto max-w-7xl">
            <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">
                DATOS SESION
            </h3>

            <form className="w-full" >
                <div className="flex flex-col lg:flex-row w-[50vw]">
                    <div className="lg:w-1/2 p-3 w-full">


                        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="paciente"> Paciente:
                            <div className="relative" >
                                <FaUser className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                                <input
                                    type="text"
                                    value={`${sesionSelected.nombrePaciente} ${sesionSelected.apellidoPaciente}`}
                                    name="paciente"
                                    id="paciente"
                                    placeholder="Nombre del paciente"
                                    disabled
                                    className="capitalize w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                />
                            </div>
                        </label>


                        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="especialista"> Especialista:
                            <div className="relative" >
                                <FaHandHoldingMedical className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                                <input
                                    type="text"
                                    name="especialista"
                                    value={`${sesionSelected.nombreEspecialista} ${sesionSelected.apellidoEspecialista}`}
                                    id="especialista"
                                    placeholder="Especialista..."
                                    disabled
                                    className="capitalize w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                />
                            </div>
                        </label>

                        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="nombreServicio"> Servicio:
                            <div className="relative" >
                                <FaConciergeBell className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                                <input
                                    type="text"
                                    value={sesionSelected.nombreServicio}
                                    name="nombreServicio"
                                    id="nombreServicio"
                                    placeholder="Servicio..."
                                    disabled
                                    className="capitalize w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                />
                            </div>
                        </label>

                       

                        <div>
                            <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
                                Sesion:
                            </label>
                            <ul className="flex flex-wrap">

                                <li className={'bg-aqua text-white cursor-pointer ring-1 ring-aqua px-2 py-2 w-auto m-4 tracking-wide rounded-md flex justify-center'}
                                >
                                    {sesionSelected.nombre}
                                </li>
                            </ul>
                        </div>


                        <label
                            className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
                            htmlFor="centro"> Centro:
                            <div className="relative">
                                <FaBuilding className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                                <input
                                value={sesionSelected.nombreCentro}
                                    id="centro"
                                    name="centro"
                                    className="block pl-10 w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                    disabled
                                    placeholder="Centro..."
                                />
                            </div>
                        </label>

                       
                        <div>
                            <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
                                Sala:
                            </label>
                            <ul className="flex flex-wrap">

                                <li className={'bg-aqua text-white cursor-pointer ring-1 ring-aqua px-2 py-2 w-auto m-4 tracking-wide rounded-md flex justify-center'}
                                >
                                    {sesionSelected.sala}
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div className="lg:w-1/2 w-full p-3 lg:border-l lg:border-grey sm:w-full ">

                    <label
                            className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
                            htmlFor="fecha"
                        >
                            Fecha
                           {sesionSelected.inicio && <div className="relative">
                                <BsCalendarDateFill className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                                <input
                                    disabled
                                 
                                    id="fecha"
                                    name="fecha"
                                    className=" w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                    type="text"
                                    value={format(inicioDate, "dd/MM/yyyy")}
                                />
                            </div>}
                        </label>

                        <label
                            className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
                            htmlFor="hora"
                        >
                            Hora inicio
                            <div className="relative">
                                <BiTimeFive className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                                <input
                                    disabled
                                    
                                    value={`${horaInicioFormateada}:${minutoInicioFormateado}`}
                                    id="horaInicio"
                                    name="horaInicio"
                                    className=" w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                    type="time"
                                />
                            </div>
                        </label>

                        <label
                            className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
                            htmlFor="hora"
                        >
                            Hora fin:
                            <div className="relative">
                                <BiTimeFive className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                                <input
                                    disabled
                                   
                                    value={`${horaFinFormateada}:${minutoFinFormateado}`}
                                    id="horaFin"
                                    name="horaFin"
                                    className=" w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                    type="time"
                                />
                            </div>
                        </label>
                    </div>
                </div>

                <div className="flex justify-around w-3/4 m-auto mt-8 mb-4">
                    <button
                        className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
                    >Guardar</button>
                    <button
                        type="button"
                        onClick={handleShowVerCitas}
                        className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white"
                    >
                        Cancelar
                    </button>
                </div>
            </form>
        </div>
    );


};

export default DatosSesion;