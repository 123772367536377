import config from "../config";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import {
  getFirestore,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { useMemo } from "react";
import { getDataFromApi } from "./functions";

//atoms de recoil
import {
  loguedState,
  showModalState,
  clienteIdState,
  userDataState,
  rutaState,
  rolState,
 showMenuFullState
} from "../atoms/utilsAtom";
import { useRecoilState, useRecoilValue } from "recoil";
import { toast } from "react-toastify";

//firebase
const fb = initializeApp(config.firebaseConfig);
const auth = getAuth(fb);
const db = getFirestore(fb);
const storage = getStorage(fb);
const googleProvider = new GoogleAuthProvider();

////////////////////////////////////////HOOK AUTH////////////////////////////////////////

const useHookAuth = () => {
  const [logued, setLogued] = useRecoilState(loguedState);
  const [usuario, setUsuario] = useRecoilState(userDataState);
  const [showModal, setShowModal] = useRecoilState(showModalState);
  const [ menuFull, setMenuFull] = useRecoilState(showMenuFullState);
  const clienteId = useRecoilValue(clienteIdState);
  const [rol, setRol] = useRecoilState(rolState);

  // const toggleLogued = useMemo(() => (logued) => {
  //   setLogued(!logued);
  // }, []);

  const handleMenuFull = () => {
    setMenuFull(!menuFull);
  }
  // Memoiza la función login para evitar recreaciones innecesarias
  const login = useMemo(() => (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  }, []);

  const handlerModal = () => {
    if (showModal) {
      setShowModal(!showModal);
    } else {
      setShowModal(!showModal);
    }
  }

  const handlerLogOut = () => {
    setTimeout(() => {
      logout();
    }, 2000);

  }


  const getUserLogin = async (uid, correoUser) => {
  
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getUserLogged";
    const data = {
      uid: uid,
      correo: correoUser,
    };

    const res = await getDataFromApi(endPoint, token, data);
    if (res && res.status === 200) {
      if (res.data.rol === "administrador" || res.data.rol === "coordinador" || res.data.rol === "especialista") { 
                if (res.data.activo === true) {
                  setRol(res.data.rol)
                 
                  setUsuario({
                    correo: res.data.correo,
                    uid: uid,
                    apellido: res.data.apellido,
                    nombre: res.data.nombre,
                    rol: res.data.rol,
                    clienteId: res.data.clienteId,
                    activo: res.data.activo,
                    proveedor: res.data.proveedor,
                    servicios: res.data.servicios,
                    especialidad: res.data.especialidad
                  });
                  setLogued(true);
                } else {
                  toast.error("Usuario se encuentra desactivado contacte al administrador", {
                    position: 'top-center',
                    theme: 'colored',
                  })
    
                  handlerLogOut();
                }
              } else {
                toast.error("Usuario no tiene permisos para acceder", {
                  position: 'top-center',
                  theme: 'colored',
                })
                handlerLogOut();
              }
    }
  };

  const validateUserLogin = async () => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getUserLogged";
    const data = {
      uid: usuario.uid,
      correo: usuario.correo,
    };

    const res = await getDataFromApi(endPoint, token, data);
    if(res.status === 200){
      if (res.data.rol !== usuario.rol) {
        setLogued(false);
       }
    } else {
      setLogued(false);
    } 
  };

  const handleUserLogued = async () => {
    await onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const usercredential = {
          uid: currentUser.uid,
          correo: currentUser.email,
        };
        getUserLogin(usercredential.uid, usercredential.correo);
        return false;
      } else {
        return false;
      }
    });
  }

  //logOut
  const logout = () => {
    signOut(auth);
    setLogued(false);
  };

  const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    return user
  } catch (error) {
    toast.error({error}, {
      position: 'top-center',
      theme: 'colored',
    })
  }
};



  return {handlerModal, menuFull, handleMenuFull, logued, handleUserLogued, usuario, login, clienteId, logout, getUserLogin, rol, validateUserLogin, signInWithGoogle };
};


////////////////////////////////////////HOOK MENU////////////////////////////////////////

const useHookMenu = () => {

  const [showModal, setShowModal] = useRecoilState(showModalState);
  const [ruta, setRuta] = useRecoilState(rutaState);




  const handlerRuta = (data) => {
    setRuta(data);
  };

  const handlerModal = () => {
    if (showModal) {
      setShowModal(!showModal);
    } else {
      setShowModal(!showModal);
    }
  }



  return {
    ruta,
    handlerRuta,
    showModal,
    handlerModal,

  };
}


//////////////////////////////////////////////////////////////



export {
  auth,
  db,
  fb,
  storage,
  useHookAuth,
  useHookMenu,
};
