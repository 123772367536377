import React from 'react';
import Editar from './Editar';
import { AiOutlinePlusCircle } from 'react-icons/ai';

const CardPago = ({ pago, onBorrar, onEditar, pagoSelected  }) => {
	return (
		<div
			className={`${pagoSelected && pagoSelected.index === pago.index
                ? 'bg-aqua text-white '
                : 'bg-lightgrey text-[#000] '
            } min-w-[7rem] relative    px-1 py-1 w-[40%] m-4 tracking-wide h-[80%] rounded-md `}
			
		>
			{pago.recibo === 'Añadir recibo' ? (
				<AiOutlinePlusCircle className="w-2/5 h-auto m-auto " onClick={onEditar} />
			) : (
				<>
				<div onClick={onEditar} >
					<p className="font-semibold">Recibo:</p>
					<p className='text-center'>{pago.recibo}</p>
					<p className="font-semibold">Valor:</p>
					<p className='text-center' >S/. {pago.valor}</p>
					</div>
					<div className="absolute top-2 right-2">
						 <Editar onBorrar={onBorrar} onEditar={onEditar} />
					</div>
				
				</>
			)}
		</div>
	);
};

export default CardPago;


