import { Routes, Route } from 'react-router-dom';
import Login from './pages/login';
import HomeAdmin from './pages/home/indexAdmin';
import HomeEspecialista from './pages/home/indexEspecialista';
import HomeCoordinador from './pages/home/indexCoordinador';
import { ProtectedRouteRol } from './components/ProtectedRouteRol'

const AppRoutes = () => {
	return (
		<Routes>
			<Route
				index
				element={
					<ProtectedRouteRol>
						<Login />
					</ProtectedRouteRol>
				}
			/>

			<Route
				index
				path="/home/admin/*"
				element={
					<ProtectedRouteRol>
						<HomeAdmin />
					</ProtectedRouteRol>
				}
			/>

			<Route
				index
				path="/home/especialista/*"
				element={
					<ProtectedRouteRol>
						<HomeEspecialista />
					</ProtectedRouteRol>
				}
			/>

			<Route
				index
				path="/home/coordinador/*"
				element={
					<ProtectedRouteRol>
						<HomeCoordinador />
					</ProtectedRouteRol>
				}
			/>

			<Route path="/login" element={<Login />} />

			<Route path="*" element={<Login />} />
			
		</Routes>
	);
};

export default AppRoutes;
