import React from 'react'
import { useHookPacientes } from '../../../libs/hookPacientes';

const EliminarPaciente = (props) => {
  const { deletePaciente } = useHookPacientes();

    let handleEliminar = async () => {
      props.setShowModal(false);
      props.setIsLoading(true)
      await deletePaciente(props.pacienteUid);
      props.handlerResetPacientes();
    }

    return (
  
      <div className="w-[35vw] justify-center">
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">ELIMINAR PACIENTE</h3>
      
      <div className='justify-center py-10 text-center'>
              <span className='inline-block' >¿Desea eliminar el paciente:
                <p className='inline-block capitalize ml-1'>{`${props.nombre} ${props.apellido}`}</p>
                ?</span>
          </div>
          <div className='flex justify-around w-3/4 m-auto mt-8 mb-4'>
          <button type="submit"
           onClick={handleEliminar} 
           className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white">Eliminar</button>
        </div>
    </div>
    );
}

export default EliminarPaciente