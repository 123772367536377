import React, { useEffect, useState, useRef } from 'react';
import { useHookUsuarios } from '../../../libs/hookUsuarios';
import { useHookCentros } from '../../../libs/hookCentros';
import { nanoid } from "nanoid";
import EliminarCentro from './EliminarCentro';
import CrearFecha from './CrearFecha';
import PopUp from "../../../components/PopUp";
import { BsBuildingAdd } from "react-icons/bs";
import { FaBuilding, FaTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import 'moment/locale/es';
import moment from 'moment';
moment.locale('es');

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import UpdateFecha from './UpdateFecha';

let Disponiblididad = () => {
    let viewHight = window.innerHeight
       if(viewHight < 800){
        viewHight = 'mediana'
    } else {
        viewHight = 'larga'
    }
    const [valueHora, setValueHora] = useState(new Date());

    const {  userSelect, getUser,
		getAgendaCompletaEspecialista,
	} = useHookUsuarios();
    const { getCentros } = useHookCentros();
    const [centros, setCentros] = useState([]);
    const agendaSelectedId = useRef(null)
    const isFirstRender = useRef(true);
    const [showModalEditar, setShowModalEditar] = useState(false)
    const [showModalCrear, setShowModalCrear] = useState(false)
    const [showModalEliminar, setShowModalEliminar] = useState(false)
    const [usuarioSel, setUsuarioSel] = useState(null);
    const [disponibilidad, setDisponibilidad] = useState(null);

        function convertirUnixAHora(unix) {
            const fecha = new Date(unix * 1000);
            const hora = fecha.getHours().toString().padStart(2, '0');
            const minutos = fecha.getMinutes().toString().padStart(2, '0');
          
            return `${hora}:${minutos}`;
          }

          function getDiaSemana(unix) {
            const fecha = new Date(unix * 1000);
            const dia = fecha.getDate().toString().padStart(2, '0');
            const diaSemana = fecha.getDay();
          
            const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
          
            return` ${diasSemana[diaSemana]} ${dia}`;
          }


    /**
    * Fetches centers: obtiene los centros.
    *
    * @return {Promise<void>} Promise that resolves when the centers data is fetched and the state is updated.
    */
    const fetchUsuario = async () => {
        try {
          
            const usuarioData = await getUser(userSelect);

            
            if (usuarioData.rol === 'especialista') {
                setUsuarioSel(usuarioData);
            } else {
                setUsuarioSel(usuarioData);
            }

        } catch (error) {
            console.error(error);
        }
        
    }

    const agendaUsuario = async (date, centro) => {
        setDisponibilidad(null)
        let start = moment(date).startOf('month');
		let end = moment(date).endOf('month');
        const uniInicio = Math.floor(start._d.getTime() / 1000);
        const uniFin = Math.floor(end._d.getTime() / 1000);
        try {
            if(centro !== null && centro !== '0'){
                const agenda = await getAgendaCompletaEspecialista(userSelect, uniInicio, uniFin, centro);
                setDisponibilidad(agenda)
            } else {
            const agenda = await getAgendaCompletaEspecialista(userSelect, uniInicio, uniFin);
            setDisponibilidad(agenda)                
            }
        } catch (error) {
            console.error(error);
        }
        
    }

    const handlerGetCentros = async () => {
        try {
            const centros = await getCentros();
            if(centros){
                setCentros(centros);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const convertirUidACentro = (uid) => {
        if(uid !== null && uid !== '' && uid !== undefined) {
        const centro = centros.find((centro) => centro.uid === uid);
        if(centro){
            return centro.nombre;
        } else {
            return '';
        }
        } else {
            return '';
        }
    }

    useEffect(() => {

        if (isFirstRender.current) {
            isFirstRender.current = false;
            fetchUsuario();
            handlerGetCentros();
            agendaUsuario(valueHora);
        }

    }, [fetchUsuario, handlerGetCentros])

  

    const closeModalCrear = () => {
        setShowModalCrear(!showModalCrear);
        agendaUsuario(valueHora);
    }

    const changeFecha = (date) => {
        setDisponibilidad(null)
        setValueHora(date)
        agendaUsuario(date)    
    }

    const closeModalEditar = () => {
        setShowModalEditar(!showModalEditar);
        agendaUsuario(valueHora);
    }

    const handleEdit = (AgendaId) => {
        agendaSelectedId.current = AgendaId;
        setShowModalEditar(true)
    }
    const handleOnEliminar = (AgendaId) => {
        agendaSelectedId.current = AgendaId;
        setShowModalEliminar(true)
    }

    const closeModalEliminar = () => {
        setShowModalEliminar(!showModalEliminar);
        agendaUsuario(valueHora);
    }

    const [centroValue, setCentroValue] = useState(null);

    const seleccionarCentro = event => {
        event.preventDefault();
        const centro = event.target.value;
		setCentroValue(event.target.value);
        agendaUsuario(valueHora, centro);
	};
   


    return (
        <div className="w-full h-full bg-azure overflow-x-auto" key={nanoid()}>
            {/* Popup Editarcentro */}
            {showModalEditar && ( <PopUp closeFunction={closeModalEditar}>
                            <UpdateFecha 
                                closeModalEditar={closeModalEditar} 
                                agendaSelectedId={agendaSelectedId.current} 
                                centros={centros}
                                />
                            </PopUp>)}
         
            {/* Popup Eliminar Centro */}
            {showModalEliminar && (<PopUp closeFunction={closeModalEliminar}> 
                            <EliminarCentro closeModalEliminar={closeModalEliminar} agendaSelectedId={agendaSelectedId.current}/>
                            </PopUp>)}
            {/* Popup Crear Fecha */}
            {showModalCrear && (<PopUp closeFunction={closeModalCrear}> 
                            <CrearFecha closeModalCrear={closeModalCrear} userSelect={userSelect}  valueHora={valueHora} centros={centros} />
                            </PopUp>)}

            {/* inicio de la lista de centros */}
            <div id="last-users" className="w[98%] m-4  bg-lightgrey rounded-md  p-4" key={nanoid()}>
                <div className="flex flex-col justify-between mb-3">
                    <div className='flex mx-auto '>
                    {usuarioSel ? (<h1 className="text-3xl  text-center font-semibold text-gray-700 m-4 capitalize mx-auto">
                    {`Disponibilidad de especialista ${usuarioSel.nombre} ${usuarioSel.apellido}`}
                        </h1>) : (<div className='mb-4 h-10 bg-white text-white rounded-md animate-pulse animate-infinite animate-duration-1000 animate-ease-in'><div className='mx-auto w-[600px] h-10 bg-white text-white rounded-md'></div></div>) }
                    </div>
                    <div className='flex justify-between'>
                        <div className='my-auto'>
                        <LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={es}
							className=" text-[#000000] border-none border rounded-md "
							>
							<DatePicker
							value={valueHora}
							views={['month', 'year']}
							className=" w-fit  text-[#000000] border-none bg-white border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2 sm:text-md lg:text-xl md:text-lg"
							onChange={newValue => changeFecha(newValue)}
							/>
							</LocalizationProvider>
                        </div>

                        <div className='flex justify-items-start my-auto'>
                        <div className="relative w-full">
									<FaBuilding className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
									<select
										id="centro"
										name="centro"
										value={centroValue}
										onChange={event => seleccionarCentro(event)}
										className="block pl-10 w-full px-4 py-3 mx-2 text-[#000000] border-none bg-white rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2 h-[56px]"
									>
										<option value="0">Todos los centros</option>
										{centros !== undefined &&
											centros !== null &&
											centros.length > 0 &&
											centros.map(centro => (
												<option key={centro.uid} value={centro.uid}>
													{centro.nombre}
												</option>
											))}
									</select>
							</div>
                        </div>
                  
                        <button
                         onClick={()=>setShowModalCrear(true)}
                        className="h-[56px] items-center m-4 flex sm:text-md lg:text-xl md:text-lg px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white my-auto"
                        >
                        <BsBuildingAdd className="mr-2 w-8 " /> <span> Añadir</span>
                        </button>
                    </div>
                </div>
                {/* inicio tabla */}
                <div className={`${viewHight === "mediana" ?'h-[65vh]':'h-[76vh]'} overflow-x-auto`}>
                    <table className="m-auto whitespace-nowrap w-[98%] rounded-md">
                        <thead className=" ">
                            <tr>
                                <th className="text-left py-3 px-2">Dia</th>
                                <th className="text-left py-3 px-2">Inicio</th>
                                <th className="text-center py-3 px-2">Fin</th>
                                <th className="text-center py-3 px-2">Centro</th>
                                <th className="text-left py-3 px-2"></th>
                            </tr>
                        </thead>
                        {/* inicio cuerpo tabla */}
                        <tbody>
                               {disponibilidad === null  && (
                                Array.from({ length: 5 }, (_, index) => (
                                    <tr key={index} className="bg-azure border-lightgrey border-b-8">
                                      <td colSpan={5} className="py-3 px-2">
                                        <div role="status" className="w-full animate-pulse">
                                          <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                )} 

                                {Array.isArray(disponibilidad) && disponibilidad && disponibilidad.map((fecha, index) => (
                                    <tr key={index} className="bg-azure border-lightgrey border-b-8 hover:bg-aqua hover:text-white cursor-pointer">
                                        <td className='py-3 px-2 capitalize rounded-l-[10px]' >
                                          {getDiaSemana(fecha.inicio)}
                                        </td>
                                        <td className="py-3 px-2">
                                           {convertirUnixAHora(fecha.inicio)}
                                        </td>
                                        <td className="py-3 px-2 text-center">
                                            {convertirUnixAHora(fecha.fin)}
                                        </td>
                                        <td className="py-3 px-2 text-center capitalize">
                                            {convertirUidACentro(fecha.nombreCentro)}
                                        </td>
                                        <td className="py-3 px-2 rounded-r-md">
                                            <div className='flex justify-end space-x-2' key={nanoid()}>
                                                <button type="button" title="Edit" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => handleEdit(fecha.uid)}><FiEdit /></button>
                                                <button type="button" className="text-red hover:text-darkred" onClick={() => handleOnEliminar(fecha.uid)}><FaTrashCan /></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {Array.isArray(disponibilidad) && disponibilidad.length === 0 && 
                                     <tr className="bg-azure border-lightgrey border-b-8">
                                     <td colSpan={5} className="py-3 px-2 text-center">
                                     Sin registros
                                     </td>
                                   </tr>

                                }
                        </tbody>
                    </table>
                </div>
                {/* fin tabla */}
            </div>
        </div>

    );
};

export default Disponiblididad;