import { Routes, Route } from "react-router-dom";
import Usuarios from "./adminUsuarios";
import Centros from "./adminCentros/index";
import Servicios from "./adminServicios";
import Pacientes from "./pacientes";
import Reportes from "./adminReportes/index";
import NucleoFam from "./pacientes/nucleoFam";
import UpdatePaciente from "./pacientes/UpdatePaciente";
//import CitasHoy from "./calendario/CitasHoy";
import Calendario from "./calendario/index";
import ReporteIngresosEspecialista from "./adminReportes/reportesIngresosEspecialista";
import Disponibilidad from "./adminDisponibilidad";
import ReporteIngresosPagos from "./adminReportes/reportesIngresosPagos";

const RutasContainerEspecialista = () => {
  return (
    <Routes>

      <Route index element={<Calendario />} />

      <Route index path="/Calendario" element={<Calendario />} />

      <Route index path="/Pacientes" element={<Pacientes />} />

      <Route index path="/Centros" element={<Centros />} />
      
      <Route index path="/Servicios" element={<Servicios />} />

      <Route index path="/Usuarios" element={<Usuarios />} />

      <Route index path="/Reportes" element={<Reportes />} />

      <Route index path="/Disponibilidad" element={<Disponibilidad />} />

      <Route path="/Pacientes/Nucleo" element={<NucleoFam />} />

      <Route path="/Pacientes/UpdatePaciente" element={<UpdatePaciente />} />

      {/* <Route path="/Citashoy" element={<CitasHoy />} />

      <Route path="/Calendario/Citashoy" element={<CitasHoy />} /> */}

      <Route path="/Reportes/ReporteIngresosEspecialista" element={<ReporteIngresosEspecialista />} />

      <Route path="/Reportes/ReporteIngresosPagos" element={<ReporteIngresosPagos />} />

      <Route path="*" element={<Calendario />} />

    </Routes>
  );
};

export default RutasContainerEspecialista;
