import React, { useEffect, useState } from 'react';
import { BsFillCalendarCheckFill } from 'react-icons/bs';
import { BiNote } from 'react-icons/bi';
import {
	FaHandHoldingMedical,
	FaConciergeBell,
	FaFileInvoiceDollar,
	FaUser,
	FaDownload,
	FaUpload,
	FaMoneyCheckAlt,
} from 'react-icons/fa';
import { useHookStorage } from '../../../libs/hookUseStorage';
import { useHookCalendario } from '../../../libs/hookCalendario';
import { useHookAuth } from '../../../libs/firebase';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import CardPago from './CardPago';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import solesImg from '../../../img/simbolo-de-moneda-nuevo-sol-de-peru.png';

const RegistrarPago = ({ handleShowVerCitas, cita }) => {
	const [file, setFile] = useState({
		file: null,
		extension: null,
	});
	const { handlerDelete, imagenes, handlerGetUrl, handleResetImagenes } =
		useHookStorage(file);
	const { usuario } = useHookAuth();
	const [disabled, setDisabled] = useState(false);
	const [pagoSelected, setPagoSelected] = useState(null);
	const [form, setForm] = useState({
		comprobante: '',
		fecha: new Date(),
		recibo: 'Añadir recibo',
		adjuntos: [],
		comentario: '',
		valor: 0,
		tipo: '',
		index: 0,
		usuario: usuario.correo,
		timeStamp: Date.now(),
	});
	const [pagos, setPagos] = useState([
		{
			comprobante: '',
			fecha: new Date(),
			recibo: 'Añadir recibo',
			adjuntos: [],
			comentario: '',
			valor: 0,
			tipo: '',
			index:0,
			usuario: usuario.correo,
			timeStamp: Date.now(),
		},
	]);

	const { updateCita, getCita } = useHookCalendario();
	const [valueHora, setValueHora] = useState(new Date());
	const tipos = [
		{
			nombre: 'Efectivo',
			codigo: 0,
		},
		{
			nombre: 'Yape',
			codigo: 1,
		},
		{
			nombre: 'Pago Link',
			codigo: 2,
		},
		{
			nombre: 'Transferencia / Deposito',
			codigo: 3,
		},
		{
			nombre: 'POS',
			codigo: 4,
		},
	];
	// const now = Date.now();
	const [isLoading, setIsLoading] = useState(true);

	const handleChange = event => {
		if (event.target.files[0]) {
			const reader = new FileReader();
			if (event.target.files[0]) {
				reader.readAsDataURL(event.target.files[0]);
			}
			reader.onload = readerEvent => {
				setFile({
					file: readerEvent.target.result,
					extension: event.target.files[0].name.split('.')[1],
				});
			};
		}
	};

	  const handleChangeForm = ({ target: { value, name } }) => {
		setForm({ ...form, [name]: value })
		const newData = pagos.map(objeto => {
			if (objeto.index === pagoSelected.index) {
				return {
					...objeto,
					[name]: value
				};
			}
			return objeto;
		});
		setPagos(newData);
	  };

	  function addIndex(pago) {
		pago.index = cita.pagos.indexOf(pago)+1;
		return pago;
	  }

	useEffect(() => {
		if (cita !== undefined && cita !== null) {
			if(cita.pagos){
				//SetPagos([ ...pagos, ...cita.pagos]);
				const citasPagos = cita.pagos.map(addIndex)
				setPagos([ ...pagos, ...citasPagos]);
			}
			setIsLoading(false);	
		}
	}, [cita]);

	const cargarImagen = e => {
		e.preventDefault();
		document.getElementById('fileInput').click();
	};

	const handlerDeleteImages = async index => {
		const res = await handlerDelete(index)

		return res
	};

	function getMissingObject(list1, list2) {
		const missingObjects = [];

		for (const object of list2) {
			if (!list1.some(item => item.recibo === object.recibo)) {
				missingObjects.push(object);
			}
		}

		if (missingObjects.length === 0) {
			return null;
		}

		return missingObjects;
	}

	// function removeObjectFromArrayByIndex(array, index) {
	// 	return array.filter((cita, i) => i !== index);
	//   }

	const generarString = (pagos, saldo) => {
		let string = "Cita actualizada correctamente con los pagos: ";
		pagos.forEach((pago) => {
		  string += `recibo: ${pago.recibo} valor: S/.${pago.valor}, `;
		});
		string += `total: S/. ${pagos.reduce((a, b) => a + parseInt(b.valor), 0)} saldo actual: S/. ${saldo}`;
		return string;
	  };

	const handleSubmit = async event => {
		event.preventDefault();
		const toastId = toast.loading('Actualizando Pago...');
		//funcion cierra modal
		function closeModal() {
			setTimeout(() => {
				handleShowVerCitas();
			}, 1000);
		}
		setDisabled(true);

		// let pagosGuardar;


		//si se eliminaron pagos se quitaron del array pero no se eliminan imagenes hasta esta linea
		if (cita.pagos !== undefined) {
			//se llama funcion para comparar lista de pagos original con lista de pagos actual para eliminar fotos
			const missingObjects = getMissingObject(pagos, cita.pagos);
			if (missingObjects !== null) {
				for (const object of missingObjects) {
					for (const adjunto of object.adjuntos) {
						await handlerDeleteImages(adjunto)
							.then(() => {})
							.catch(() => {});
					}
				}
			}
		}
		

		// se filtra si esta algun campo vacio y se elemina del array de pagos
		const arrayFiltrado = pagos.filter(
			obj =>
				obj.recibo !== 'Añadir recibo' &&
				obj.recibo !== '' &&
				obj.valor > 0 &&
				obj.fecha !== ''
		);

		let correcto;
		
		//se guarda el pago como una sola lista y un objeto por cada recibo
		await updateCita(cita.uid, 'pagos', arrayFiltrado)
			.then((res) => {
				correcto = res;
				if(typeof res !== 'string') {
					console.log("error actualizar pago", res);
					toast.update(toastId, {
						render: 'Algo salio mal no se actualizo pago',
						type: 'success',
						isLoading: false,
						autoClose: 500,
					});
				}
				
				//closeModal();
			})
			.catch(() => {
				toast.error('Error al actualizar el pago', {
					position: 'top-center',
					theme: 'colored',
				});
				closeModal();
			});
		let verificacion;
		if(correcto === 'cita actualizada correctamente') {
			verificacion = await getCita(cita.uid);
			if(verificacion.pagos && verificacion.pagos.length > 0) {
			const stringGenerado = generarString(verificacion.pagos, verificacion.saldo);
			toast.update(toastId, {
				render: stringGenerado,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
			})
			closeModal();	
			} else {
				const stringGenerado = "Cita actualizada correctamente sin pagos";
			toast.update(toastId, {
				render: stringGenerado,
				type: 'success',
				isLoading: false,
				autoClose: 5000,
			})
			}
		}else {
			console.log("error actualizar pago", correcto);
				toast.update(toastId, {
					render: 'Algo salio mal no se actualizo pago',
					type: 'success',
					isLoading: false,
					autoClose: 1000,
				});
		}
		
	};

	//CARRUSEL

	const [currentIndex, setCurrentIndex] = useState(0);

	const prevSlide = () => {
		if (currentIndex > 0) {
			setCurrentIndex(prevIndex => prevIndex - 1);
		}
	};

	const nextSlide = () => {
		if (currentIndex < pagos?.length - 2) {
			setCurrentIndex(prevIndex => prevIndex + 1);
		}
	};

	const handleBorrarPago = async pago => {
		setDisabled(true);
		const updatedPago = pagos.filter(item => item.index !== pago.index);
		setPagos(updatedPago);
		setForm({
			...form,
			valor: 0,
			tipo: '',
			comentario: '',
			recibo: '',
			comprobante: '',
			fecha: new Date(),
			index: 0,
		});
		setValueHora(new Date());
		handleResetImagenes();
	};

	useEffect(() => {
		if (disabled) {
			setDisabled(false);
		}
	}, [pagos]);

	const seleccionarPago = async (data, boton) => {
			setPagoSelected(data);
			await handleResetImagenes();
			
			if (boton === 'borrar') {
				handleBorrarPago(data);
			}
	
			if (boton === 'editar') {
					let fecha;
					setForm({
						...form,
						valor: data.valor,
						tipo: data.tipo,
						comentario: data.comentario,
						recibo: data.recibo,
						comprobante: data.comprobante,
						fecha: new Date(),
						index: data.index
					});
					if(typeof data.fecha === 'number'){
						fecha = new Date(data.fecha * 1000);
					} else {
						fecha = new Date()
					}
					
					handlerFecha(fecha, data);
					
					await handlerGetUrl(data.adjuntos);
			}
		

		
	};

	const handlerFecha = (date, data) => {
		setValueHora(date);
		const timestampUnix = Math.floor(date.getTime() / 1000);
		let newData;
		if(data) {
			newData = pagos.map(objeto => {
				if (objeto.index === data.index) {
					return {
						...objeto,
						fecha: timestampUnix
					};
				}
				return objeto;
			});
		} else {
			newData = pagos.map(objeto => {
				if (objeto.index === pagoSelected.index) {
					return {
						...objeto,
						fecha: timestampUnix
					};
				}
				return objeto;
			});
		}
		
		setPagos(newData);
	}

	useEffect(() => {
		const newArray = imagenes.map(image => image.name);
		if (pagoSelected !== null && pagoSelected.adjuntos !== newArray) {
			const newData = pagos.map(objeto => {
				if (objeto.index === pagoSelected.index) {
					return {
						...objeto,
						adjuntos: newArray
					};
				}
				return objeto;
			});
			setPagos(newData);
		}
	},[imagenes])

	const regex = new RegExp(/pdf/);

	return (
		<div className="flex-col relative h-fit my-12 mx-auto max-w-7xl">
			{cita && (
				<>
					<h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">
						REGISTRAR PAGO
					</h3>

					<form className="w-full" onSubmit={handleSubmit}>
						<div className="flex flex-col lg:flex-row w-[50vw]">
							<div className="lg:w-1/2 p-3 w-full">
								{/* paciente */}
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="paciente"
								>
									Paciente
									{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
										<FaUser className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
										<input
											id="paciente"
											name="paciente"
											required
											value={
												cita !== undefined &&
												cita !== null &&
												`${cita?.paciente[0].nombre} ${cita?.paciente[0].apellido}`
											}
											disabled
											className="capitalize block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
										></input>
									</div>)}
								</label>
								{/* servicio */}
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="servicio"
								>
									Servicio
									{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
										<FaConciergeBell className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
										<input
											id="servicio"
											name="servicio"
											required
											value={cita?.servicio[0].nombre}
											disabled
											className="capitalize block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
										></input>
									</div>)}
								</label>
								{/* especialista */}
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="especialista"
								>
									Especialista
									{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
										<FaHandHoldingMedical className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
										<input
											id="especialista"
											name="especialista"
											required
											value={`${cita?.especialista[0].nombre} ${cita?.especialista[0].apellido}`}
											disabled
											className="capitalize block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
										></input>
									</div>)}
								</label>

								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="precio"
								>
									Precio
									{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
										<img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />
										<input
											required
											id="precio"
											name="precio"
											value={cita?.precio}
											disabled
											className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
										/>
									</div>)}
								</label>
								{/* saldo */}
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="precio"
								>
									Saldo
									{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
										<img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />
										<input
											required
											id="precio"
											name="precio"
											value={cita.saldo ?? cita.precio}
											disabled
											className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
										/>
									</div>)}
								</label>

								{/* selector de pagos */}
								{pagos?.length > 0 ? (
									<div className="mt-4 h-[10rem] my-8 relative">
										<div
											key={nanoid()}
											className="flex  justify-center items-center h-full w-full"
										>
											{pagos
												.slice(currentIndex, currentIndex + 2)
												.map((s, index) => (
													<CardPago
														pago={s}
														key={index}
														onBorrar={() => seleccionarPago(s, 'borrar')}
														onEditar={() => seleccionarPago(s, 'editar')}
														pagoSelected={pagoSelected}
													/>
												))}
										</div>

										<button
											type="button"
											className="  absolute top-[-1rem] left-0 z-30 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
											onClick={prevSlide}
										>
											<span className="inline-flex items-center justify-center w-7 h-7 rounded-full dark:bg-gray-800/30 group-hover:bg-darkblue group-hover:text-white dark:group-hover:bg-gray-800/60 ring-darkblue ring-2 dark:group-focus:ring-gray-800/70 group-focus:outline-none">
												<svg
													className="w-4 h-4 dark:text-gray-800"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 6 10"
												>
													<path
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="M5 1 1 5l4 4"
													/>
												</svg>

												<span className="sr-only">Previous</span>
											</span>
										</button>

										<button
											type="button"
											className="  absolute top-[-1rem] right-0 z-30 flex items-center justify-center h-full  cursor-pointer group focus:outline-none"
											onClick={nextSlide}
										>
											<span className="inline-flex items-center justify-center w-7 h-7 rounded-full dark:bg-gray-800/30 group-hover:bg-darkblue group-hover:text-white dark:group-hover:bg-gray-800/60 ring-darkblue ring-2 dark:group-focus:ring-gray-800/70 group-focus:outline-none">
												<svg
													className="w-4 h-4 dark:text-gray-800"
													aria-hidden="true"
													xmlns="http://www.w3.org/2000/svg"
													fill="none"
													viewBox="0 0 6 10"
												>
													<path
														stroke="currentColor"
														strokeLinecap="round"
														strokeLinejoin="round"
														strokeWidth="2"
														d="m1 9 4-4-4-4"
													/>
												</svg>

												<span className="sr-only">Next</span>
											</span>
										</button>
									</div>
								) : (
									''
								)}
							</div>
							{/* fin container izquierda */}
							{/* inicio container derecha */}

							{pagoSelected && (
								<div className="lg:w-1/2 w-full p-3 lg:border-l lg:border-grey sm:w-full ">
									{/* inicio comprobante */}
									<label
										className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
										htmlFor="comprobante"
									>
										Nº de comprobante:
										<div className="relative">
											<FaFileInvoiceDollar className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
											<input
												onChange={handleChangeForm}
												value={form.comprobante}
												id="comprobante"
												name="comprobante"
												className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
											></input>
										</div>
									</label>
									{/* fin comprobante */}
									{/* inicio recibo */}
									<label
										className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
										htmlFor="recibo"
									>
										Nº de recibo interno:
										<div className="relative">
											<FaFileInvoiceDollar className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
											<input
												onChange={handleChangeForm}
												id="recibo"
												name="recibo"
												value={form.recibo}
												className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
											></input>
										</div>
										{form.recibo === "" && <p className='text-[red] italic mb-2'>Se debe ingresar el numero del recibo </p>}
									</label>
									{/* fin recibo */}
									{/* inicio fecha */}
									<label
										className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
										htmlFor="hora"
									>
										Fecha y hora:
										<div className="relative">
											<BsFillCalendarCheckFill
												size={23}
												className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl"
											/>
											<LocalizationProvider
												dateAdapter={AdapterDateFns}
												adapterLocale={es}
											>
												<MobileDateTimePicker
													value={valueHora}
													required={true}
													className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
													onChange={newValue => handlerFecha(newValue)}
													
												/>
											</LocalizationProvider>
										</div>
									</label>
									{/* fin fecha */}
									{/* valor inicio */}
									<label
										className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
										htmlFor="valor"
									>
										Valor:
										<div className="relative">
										<img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />
											<input
												onChange={handleChangeForm}
												value={form.valor}
												id="valor"
												name="valor"
												className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
											></input>
										</div>
										{form.valor === 0 && <p className='text-[red] italic mb-2'>El valor debe ser mayor a 0 </p>}
									</label>
									{/* valor fin */}
									{/*inicio tipo de pago */}
									<label
										className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
										htmlFor="tipo"
									>
										Tipo de pago:
										<div className="relative">
											<FaMoneyCheckAlt className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
											<select 
											onChange={handleChangeForm}
											id="tipo" 
											name="tipo" 
											value={form.tipo}
											className='block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 capitalize'
											>
												{tipos.map(pais => (
													<option key={pais.codigo} value={pais.nombre}>
														{pais.nombre}
													</option>
												))}
											</select>
										</div>
									</label>
									{/* fin tipo pago */}
									<label
										className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
										htmlFor="comentario"
									>
										Comentario:
										<div className="relative">
											<BiNote className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
											<input
												onChange={handleChangeForm}
												id="comentario"
												value={form.comentario}
												name="comentario"
												className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
											></input>
										</div>
									</label>

									{/* input imagenes */}
									<label
										htmlFor="fileInput"
										className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									>
										Comprobante:
										<div className="flex justify-between w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2">
											{imagenes !== null && imagenes.length > 0 ? (
												<span className="max-w-[80%]">
													Agregar otro archivo
												</span>
											) : (
												<span className="max-w-[80%]">Selecciona archivos</span>
											)}
											<button
												onClick={cargarImagen}
												className="transition-colors duration-200 transform rounded-md hover:text-aqua relative"
											>
												<FaUpload size={30} />
											</button>

											<input
												type="file"
												accept="image/*"
												id="fileInput"
												onChange={handleChange}
												className="hidden"
											/>
										</div>
									</label>
									{/* container imagenes cargadas */}
									<div className="flex flex-col justify-around items-center mt-4 w-full">
										{imagenes !== null &&
											imagenes.length > 0 &&
											imagenes.map((image, index) => (
												<div
													key={index}
													className="flex items-center justify-around w-full"
												>
													<div className="max-w-[60%] ">
														{!regex.test(image.name)? (	<img
																src={image.url}
																alt={image.name}
																className="max-w-[100%]"
															/>):<iframe
															src={image.url}
															className="max-w-[100%] overflow: auto"
														/>	}
																	
														<p key={index} className="max-w-[100%] break-words	mb-4" >
															{image.name}
														</p>
													</div>
													<div className="flex flex-col justify-between items-center">
														<a
															href={image.url}
															download={image.name}
															target="_blank"
															rel="noopener noreferrer"
															className="mb-10 transition-colors duration-200 transform rounded-md hover:text-aqua relative"
														>
															<FaDownload size={30} />
														</a>
													</div>
												</div>
											))}
									</div>
									{/* fin container imagenes cargadas */}
								</div>
							)}
							{/* fin container derecha */}
						</div>

						<div className="flex justify-around w-3/4 m-auto mt-8 mb-4">
							<button
								type="submit"
								// onClick={handleSubmit}
								disabled={disabled}
								className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white "
							>
								Guardar
							</button>
							<button
								type="button"
								onClick={handleShowVerCitas}
								className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white"
							>
								Cancelar
							</button>
						</div>
					</form>
				</>
			)}
		</div>
	);
};

export default RegistrarPago;


	