import React, { useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";

const Busqueda = ({ handlerSearchPaciente }) => {
  const [inputValue, setInputValue] = useState("");

  const manejarSubmit = (event) => {
    event.preventDefault();
    setInputValue(inputValue.toLocaleLowerCase());
    handlerSearchPaciente(inputValue);
  };

  const manejarInputChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <form className="relative m-4 flex" onSubmit={manejarSubmit}>
      <BiSearchAlt2 className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
      <input
        required
        type="search"
        name="search"
        value={inputValue}
        onChange={manejarInputChange}
        id="search"
        placeholder="Buscar por nombre, apellido o DNI"
        className="h-100% block w-[70%] lg:w-[40%] px-4 pl-10 py-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
      ></input>
      <input
        type="submit"
        value="Buscar"
        className="ml-4 sm:text-xs lg:text-xl md:text-md w-auto px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
      />
    </form>
  );
};

export default Busqueda;
