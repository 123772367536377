import config from '../config';
import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { toast } from 'react-toastify';
import { getDataFromApi } from './functions';

//atoms de recoil
import { userDataState } from '../atoms/utilsAtom';
import {userSelectState} from '../atoms/utilsAtom';
import { useRecoilState } from 'recoil';

//firebase
const fb = initializeApp(config.firebaseConfig);
const auth = getAuth(fb);

////////////////////////////////////////HOOK USUARIOS////////////////////////////////////////

const useHookUsuarios = () => {
	const [usuario, setUsuario] = useRecoilState(userDataState);
	const [userSelect, setUserSelect] = useRecoilState(userSelectState);
	const handleSetUserSelect = data => {
		setUserSelect(data);
	}

	const handleSetUsuario = data => {
		setUsuario(data);
	};

	const getUsuarios = async () => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'getUsers';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
		};

		const res = await getDataFromApi(endPoint, token, data);
		
		if (res && res.status === 200) {
			if (Array.isArray(res.data)) {
				return res.data
			} else {
				return []
			}
			
		} else {
			console.error('Error al obtener usuarios:', res);
			return false;
		}
	};

	const getUser = async correo => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'getUser';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			correo: correo,
		};
		
		const res = await getDataFromApi(endPoint, token, data);
		
		if (res && res.status === 200) {
			if (typeof res.data === 'object') {
				return res.data
			} else {
				return {}
			}
		}
	};

	const updateUser = async (correo, parametro, valor) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'editUser';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			correo: correo,
			parametro: parametro,
			valor: valor,
		};

		const res = await getDataFromApi(endPoint, token, data);
		if (res && res.status === 200) {
			if (res.data === 'usuario actualizado correctamente') {
				return res.data
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false;
			}
		} else {
			console.error('Error al actualizar usuario:', res);
			return false;
		}
	};

	const createUser = async (
		clave,
		nombre,
		apellido,
		correo,
		proveedor,
		rol,
		activo
	) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'createUser';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			userData: {
				clienteId: usuario.clienteId,
				clave: clave,
				nombre: nombre,
				apellido: apellido,
				correo: correo,
				proveedor: proveedor,
				rol: rol,
				activo: activo,
			},
		};
		const res = await getDataFromApi(endPoint, token, data);
		if (res && res.status === 200) {
			if ( res.data === 'usuario creado correctamente') {
				toast.success(`${res.data}`, {
					position: 'top-center',
					theme: 'colored',
				});
				return res.data
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false
			}
		} else {
			console.error('Error al crear usuario:', res);
			return false;
		}

	};


	const createUserEspecialista = async (
		clave,
		nombre,
		apellido,
		correo,
		proveedor,
		rol,
		especialidad,
		activo,
		servicios
	) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'createUser';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			userData: {
				clienteId: usuario.clienteId,
				clave: clave,
				nombre: nombre,
				apellido: apellido,
				correo: correo,
				proveedor: proveedor,
				especialidad: especialidad,
				rol: rol,
				activo: activo,
				servicios: servicios,
			},
		};
		const res = await getDataFromApi(endPoint, token, data);
		
		if(res.status === 200) {
			if (res.data === 'usuario creado correctamente') {
				toast.success(`${res.data}`, {
					position: 'top-center',
					theme: 'colored',
				});
				return res.data;
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false
			}
		}
	};

	const deleteUser = async correo => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'delUser';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			correo: correo,
		};
		const res = await getDataFromApi(endPoint, token, data);
		if (res && res.status === 200) {
			if (res.data === 'usuario eliminado correctamente') {
				toast.success(`${res.data}`, {
					position: 'top-center',
					theme: 'colored',
				});
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
			}

		} else {
			console.error('Error al borrar usuario:', res);
			return false;
		}
		
	};


	////////////////////////////////////////////////////////////AGENDA///////////////////////////////////////////////////

	const createAgendaEspecialista = async (
		correo,
		inicio,
		fin,
		centro
	) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'createAgenda';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			agendaData: {
				clienteId: usuario.clienteId,
				usuario: correo,
				inicio: parseInt(inicio),
				fin: parseInt(fin),
				nombreCentro: centro,
			},
		};
		
		const res = await getDataFromApi(endPoint, token, data);
		
		if(res.status === 200) {
			if (res.data === 'agenda creada correctamente') {
				return res.data;
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false
			}
		}
	};

	const getAgendaEspecialista = async (
		agendaId,
	) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'getAgenda';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			agendaId: agendaId,
	
		};
		const res = await getDataFromApi(endPoint, token, data);

		if(res.status === 200) {
			if (typeof res.data === 'object') {
				return res.data;
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false
			}
		}
	};

	const getAgendaCompletaEspecialista = async (
		correo,
		inicio,
		fin,
		centro
	) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'findAgendas';
		let data;
		if(centro){
			data = {
				uid: usuario.uid,
				clienteId: usuario.clienteId,
				usuario:correo,
				inicio: parseInt(inicio),
				fin: parseInt(fin),
				nombreCentro: centro,
			};
		} else {
			data = {
				uid: usuario.uid,
				clienteId: usuario.clienteId,
				usuario:correo,
				inicio: parseInt(inicio),
				fin: parseInt(fin)
			};
		}

		const res = await getDataFromApi(endPoint, token, data);

		if(res.status === 200) {
			if (Array.isArray(res.data)) {
				return res.data;
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false
			}
		}
	};

	const updateAgendaEspecialista = async (
		agendaId,
		parametro,
		valor,
	) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'editAgenda';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			agendaId: agendaId,
			parametro: parametro,
			valor: valor,
		};

		const res = await getDataFromApi(endPoint, token, data);

		if(res.status === 200) {
				return res.data;
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false
			}
		}


	const deleteAgendaEspecialista = async (
		agendaId
	) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'delAgenda';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			agendaId: agendaId,
		};
		const res = await getDataFromApi(endPoint, token, data);

		if(res.status === 200) {
				toast.success(`Disponibilidad eliminada correctamente`, {
					position: 'top-center',
					theme: 'colored',
				});
				return true;
			} else {
				toast.error('ha ocurrido un error', {
					position: 'top-center',
					theme: 'colored',
				});
				return false
			}
		
	};

	return {
		getUsuarios,
		getUser,
		updateUser,
		createUser,
		createUserEspecialista,
		deleteUser,
		handleSetUsuario,
		handleSetUserSelect,
		userSelect,
		createAgendaEspecialista,
		getAgendaEspecialista,
		getAgendaCompletaEspecialista,
		updateAgendaEspecialista,
		deleteAgendaEspecialista
	};
};

export { useHookUsuarios };
