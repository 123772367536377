import React from 'react'
import { useHookServicios } from "../../../libs/hookServicios";


const EliminarServicio = (props) => {
  const { deleteServicio } = useHookServicios();

  /**
 * Handles the process of deleting a service.
 *
 * @async
 * @function
 * @param {Object} props - The properties object.
 * @param {Function} props.setIsLoading - Function to set isLoading state.
 * @param {Function} props.setShowModal - Function to set showModal state.
 * @param {string} props.servicioId - The ID of the service to delete.
 * @param {Function} props.getServicios - Function to get the list of services.
 * @return {Promise} A Promise that resolves when the service is deleted.
 */
  let handleEliminar = async () => {
    props.setIsLoading(true);
    props.setShowModal(false)
    await deleteServicio(props.servicioId)
    props.getServicios();
  }

  return (

    <div className="w-[35vw] justify-center">
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">ELIMINAR SERVICIO</h3>

      <div className='justify-center py-10 text-center'>
              <span className='inline-block' >¿Desea eliminar el servicio:
                <p className='inline-block capitalize ml-1'>{props.nombre}</p>
                ?</span>
          </div>

        <div className='flex justify-around w-3/4 m-auto mt-8 mb-4'>
          <button type="submit" onClick={handleEliminar} className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white">Eliminar</button>
        </div>

    </div>
  );
}

export default EliminarServicio;