
const dev = {
    firebaseConfig : {
      apiKey: "AIzaSyCn-2z1SINOqaJnb8O5F8e_ZF2eP0eJobY",
      authDomain: "api-skinner-test.firebaseapp.com",
      projectId: "api-skinner-test",
      storageBucket: "api-skinner-test.appspot.com",
      messagingSenderId: "410163454247",
      appId: "1:410163454247:web:d44e665c52210838bfc902",
      },
    endPoint: "https://us-central1-api-skinner-test.cloudfunctions.net/"
}

const prod = {
  firebaseConfig : {
  apiKey: "AIzaSyBHP4AIf08HsXxCGP4P3RUxSpjXhOUxCzk",
  authDomain: "api-skinner-prod.firebaseapp.com",
  projectId: "api-skinner-prod",
  storageBucket: "api-skinner-prod.appspot.com",
  messagingSenderId: "940144182990",
 appId: "1:940144182990:web:a152ded84a3afaedc7b29f"
      },
  endPoint: "https://us-central1-api-skinner-prod.cloudfunctions.net/"
};

const state = process.env.REACT_APP_STAGE === "prod" ? prod : dev;
console.log(process.env.REACT_APP_STAGE)
const config = {
  ...state
}

 
export default config