import React, { useEffect, useState, useRef } from "react";
import { useHookPacientes } from "../../../libs/hookPacientes";
import { format } from "date-fns";

import Calendar from "react-calendar";
import "../../styles/calendar.css";
import imagenUsuario from "../../../img/user-circle.svg";

const UpdatePersona = () => {
  const { updatePaciente, getPaciente, pacienteSelectedUid, handlerSetPacienteSelectedUid } = useHookPacientes();
  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState(false);
  const [nombre, setNombre] = useState(null)
  const [apellido, setApellido] = useState(null)
  const [documento, setDocumento] = useState(null)
  const [tipo, setTipo] = useState(null)
  const [direccion, setDireccion] = useState(null)
  const [correo, setCorreo] = useState(null)
  const [telefono, setTelefono] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [uid, setUid] = useState(null);

  const HandlerGetPaciente = async (pacienteUid) => {
    try {
      const pacienteData = await getPaciente(pacienteUid);
      console.log(pacienteData)
      setUid(pacienteData.uid)
      setNombre(pacienteData.nombre)
      setApellido(pacienteData.apellido)
      setDocumento(pacienteData.documento)
      setTipo(pacienteData.tipo)
      setDireccion(pacienteData.direccion)
      setCorreo(pacienteData.correo)
      setTelefono(pacienteData.telefono)
      
      if (pacienteData.nacimiento && !isNaN(new Date(pacienteData.nacimiento))) {
        setSelectedDate(new Date(pacienteData.nacimiento));
      }

      setIsLoading(false)
      handlerSetPacienteSelectedUid(null)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      HandlerGetPaciente(pacienteSelectedUid);
    }
  }, [pacienteSelectedUid, HandlerGetPaciente]);

  const handleChangeNombre = (nombre) => {
    setNombre(nombre)
    let newErrors = {};

    const nombreValido = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ' ]+$/;

    if (!nombreValido.test(nombre.trim())) {
      newErrors.nombre = "Nombre inválido"

    }
    else {
      updatePaciente(
        uid,
        "nombre",
        nombre.trim().toLowerCase(),
      );

    }
    setErrors(newErrors);
  };

  const handleChangeApellido = (apellido) => {
    setApellido(apellido)
    let newErrors = {};

    const apellidoValido = /^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ' ]+$/;

    if (!apellidoValido.test(apellido)) {
      newErrors.apellido = "Apellidos inválidos"
    }
    else {
      updatePaciente(
        uid,
        "apellido",
        apellido.trim().toLowerCase(),
      );

    }
    setErrors(newErrors);
  }

  const handleChangeTipo = (tipo) => {
    setTipo(tipo)
    updatePaciente(
      uid,
      "tipo",
      tipo,
    );
  }

  const handleChangeDocumento = (documento) => {
    setDocumento(documento)
    let newErrors = {};

    const documentoValido = /^[a-zA-Z0-9]{3,}$/;

    if (!documentoValido.test(documento.trim())) {
      newErrors.documento = "Documento inválido"

    }
    else {
      updatePaciente(
        uid,
        "documento",
        documento.trim().toLowerCase(),
      );

    }
    setErrors(newErrors);
  };

  const handleChangeDireccion = (direccion) => {
    setDireccion(direccion)
    let newErrors = {};

    const direccionValida = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9# -]{3,}$/;

    if (!direccionValida.test(direccion.trim())) {
      newErrors.direccion = "Dirección inválida"

    }
    else {
      updatePaciente(
        uid,
        "direccion",
        direccion.trim().toLowerCase(),
      );

    }
    setErrors(newErrors);
  };

  const handleChangeCorreo = (correo) => {
    setCorreo(correo)
    let newErrors = {};

    const correoValido = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;

    if (!correoValido.test(correo.trim())) {
      newErrors.correo = "Correo inválido"

    }
    else {
      updatePaciente(
        uid,
        "correo",
        correo.trim().toLowerCase(),
      );

    }
    setErrors(newErrors);
  };

  const handleChangeTelefono = (telefono) => {
    setTelefono(telefono)
    let newErrors = {};

    const telefonoValido = /^\+?\d+$/;

    if (!telefonoValido.test(telefono.trim())) {
      newErrors.telefono = "Telefono inválido"

    }
    else {
      updatePaciente(
        uid,
        "telefono",
        telefono.trim(),
      );

    }
    setErrors(newErrors);

  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const fechaActual = new Date();
    let newErrors = {};

    if (date > fechaActual) {
      newErrors.nacimiento = "Fecha inválida"
    } else {
      const unixTimestamp = date.getTime();

      updatePaciente(
        uid,
        "nacimiento",
        unixTimestamp
      );

    }
    setErrors(newErrors);
  };

  return (
    <div className='w-full h-full bg-azure overflow-x-auto'>
      <div className="bg-darkblue p-4 m-4 rounded-md text-white items-center">
        <form>
          <div className="flex flex-row w-full">
            <img
              className="m-auto my-8 w-auto h-auto"
              src={imagenUsuario}
              alt="imagen del usuario"
            />

          </div>
          <div className="flex flex-row w-full rounded-md p-4 pb-0">
            <div className="w-1/2 p-3">
              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="nombre"
              >
                Nombres
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  id="nombre"
                  name="nombre"
                  className="capitalize w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Nombres..."
                  value={nombre}
                  onChange={(e) => handleChangeNombre(e.target.value)}
                />)}
              </label>

              {errors.nombre && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.nombre}</span>
                  <br />
                </div>
              )}

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="nacimiento"
              >
                Fecha de Nacimiento
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  disabled
                  id="nacimiento"
                  name="nacimiento"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  value={format(selectedDate, "dd/MM/yyyy")}
                />)}
              </label>
              {errors.nacimiento && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.nacimiento}</span>
                  <br />
                </div>
              )}

              <div className="p-3 w-4/5 m-auto">
                <Calendar value={selectedDate} onChange={handleDateChange} />
              </div>
            </div>

            <div className="w-1/2 p-3 border-l border-grey">
              <label
                className=" block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="apellido"
              >
                Apellidos
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  id="apellido"
                  name="apellido"
                  className="capitalize w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Apellidos..."
                  value={apellido}
                  onChange={(e) => handleChangeApellido(e.target.value)}
                />)}
              </label>

              {errors.apellido && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.apellido}</span>
                  <br />
                </div>
              )}

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="tipo"
              >
                Tipo
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<select
                  required
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  id="tipo"
                  name="tipo"
                  value={tipo}
                  onChange={(e) => handleChangeTipo(e.target.value)}
                >
                  <option value="dni">DNI</option>
                  <option value="ce">CE</option>
                  <option value="pasaporte">Pasaporte</option>
                  <option value="otro">Otro</option>
                </select>)}
              </label>

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="documento"
              >
                Documento
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  id="documento"
                  name="documento"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Numero documento..."
                  value={documento}
                  onChange={(e) => handleChangeDocumento(e.target.value)}
                />)}
              </label>

              {errors.documento && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.documento}</span>
                  <br />
                </div>
              )}

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="direccion"
              >
                Dirección
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input

                  id="direccion"
                  name="direccion"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Dirección..."
                  value={direccion}
                  onChange={(e) => handleChangeDireccion(e.target.value)}
                />)}
              </label>

              {errors.direccion && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.direccion}</span>
                  <br />
                </div>
              )}

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="correo"
              >
                Correo electrónico
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div 
                  className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  required
                  id="correo"
                  name="correo"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="email"
                  placeholder="ejemplo@dominio.com"
                  value={correo}
                  onChange={(e) => handleChangeCorreo(e.target.value)}
                />)}
              </label>

              {errors.correo && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.correo}</span>
                  <br />
                </div>
              )}

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="telefono"
              >
                Teléfono
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  required
                  pattern="[+]?[0-9]+"
                  id="telefono"
                  name="telefono"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="phone"
                  placeholder="+51 9## ### ###"
                  value={telefono}
                  onChange={(e) => handleChangeTelefono(e.target.value)}
                />)}
              </label>

              {errors.telefono && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.telefono}</span>
                  <br />
                </div>
              )}
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default UpdatePersona;
