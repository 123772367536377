import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import es from 'date-fns/locale/es';
import { useHookServicios } from '../../../libs/hookServicios';
import { useHookUsuarios } from '../../../libs/hookUsuarios';
import { useHookCentros } from '../../../libs/hookCentros';
import { useHookPacientes } from '../../../libs/hookPacientes';
import { useHookAuth } from '../../../libs/firebase';
import { useHookCalendario } from '../../../libs/hookCalendario';
import Searchable from '../../../components/searchable';
import SearchableUser from '../../../components/searchableUser';

function FiltroSideBarReporte({
	handlerShowFilter,
	loading,
	cancelFilter										}) {	
	const anchoPantalla = window.innerWidth;
	

	const { getPaciente } = useHookPacientes();
	const { usuario } = useHookAuth();
	const {
		filtros,
		filtrosDate } = useHookCalendario();

	const [skeleton, setSkeleton] = useState(true);
	const isFirstRender = useRef(true);
	const [centros, setCentros] = useState(null);
	const [valueCentros, setValueCentros] = useState(null);
	const [arrayCentros, setArrayCentros] = useState([]);
	const [servicios, setServicios] = useState(null);
	const [valueServicios, setValueServicios] = useState(null);
	const [arrayServicios, setArrayServicios] = useState([]);
	const [usuarios, setUsuarios] = useState(null);
	const [valueUsuarios, setValueUsuarios] = useState(null);
	const [arrayUsuarios, setArrayUsuarios] = useState([]);
	const [valuePacientes, setValuePacientes] = useState(null);
	const [arrayPacientes, setArrayPacientes] = useState([]);
	const { getCentros } = useHookCentros();
	const { getServicios } = useHookServicios();
	const { getUsuarios } = useHookUsuarios();
	const [filter, setFilter] = useState([]);
	const today = new Date();
			const dayOfWeek = today.getDay();
			const monday = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() - dayOfWeek + 1
			);
			const sunday = new Date(
				monday.getFullYear(),
				monday.getMonth(),
				monday.getDate() + 6
			);
			const [valueFecha1, setValueFecha1] = useState(monday);
			const [valueFecha2, setValueFecha2] = useState(sunday);

	useEffect(() => {
		if (!skeleton) {
			const arrayCentrosId = arrayCentros?.map(object => object.nombre);
			const arrayPacientesId = arrayPacientes?.map(object => object.id);
			const arrayServiciosId = arrayServicios?.map(object => object.id);
			let arrayEspecialistasId;
			if (usuario.rol === 'especialista') {
				arrayEspecialistasId = [usuario.correo];
			} else {
				arrayEspecialistasId = arrayUsuarios?.map(object => object.id);
			}

			const filters = [
				{ filtro: 'centros', centros: arrayCentrosId },
				{ filtro: 'pacientes', pacientes: arrayPacientesId },
				{ filtro: 'especialistas', especialistas: arrayEspecialistasId },
				{ filtro: 'servicios', servicios: arrayServiciosId },
			];
			setFilter(filters);
		}
	}, [arrayCentros, arrayPacientes, arrayServicios, arrayUsuarios]);

	const handleDateChange1 = value => {
		const fechaInicio = value;
		fechaInicio.setHours(0);
		fechaInicio.setMinutes(0);
		fechaInicio.setSeconds(0);
		setValueFecha1(fechaInicio);
		if (fechaInicio > valueFecha2) {
			toast.error('La fecha de inicio debe ser menor a la fecha de fin', {
				position: 'top-center',
				theme: 'colored',
			});
		}
	};

	const handleDateChange2 = value => {
		const fechaFin = value;
		fechaFin.setHours(23);
		fechaFin.setMinutes(59);
		fechaFin.setSeconds(59);
		setValueFecha2(fechaFin);
		if (fechaFin < valueFecha1) {
			toast.error('La fecha de fin debe ser mayor a la fecha de inicio', {
				position: 'top-center',
				theme: 'colored',
			});
		}
	};

	const llamarAPI = async id => {
		const pacienteData = await getPaciente(id);
		const datos = {
			id: pacienteData.uid,
			nombre: `${pacienteData.nombre} ${pacienteData.apellido}`,
		};
		return datos;
	};

	const seleccionarFiltrosPaciente = async pacientesIds => {
		const pacientes = [];
		for (const pacienteId of pacientesIds) {
			const paciente = await llamarAPI(pacienteId);
			if (paciente) {
				pacientes.push(paciente);
			}
		}
		return pacientes;
	};

	const handleValueCentros = value => {
		let existingArray = null;
		existingArray = arrayCentros.find(array => array === value);
		if (existingArray === undefined) {
			setArrayCentros([...arrayCentros, value]);
			setValueCentros(null);
		}
	};

	const handleValueUsuarios = value => {
		let existingArray = null;
		existingArray = arrayUsuarios.find(array => array === value);
		if (existingArray === undefined) {
			setArrayUsuarios([...arrayUsuarios, value]);
			setValueUsuarios(null);
		}
	};

	const handleValuePacientes = value => {
		let existingArray = null;
		existingArray = arrayPacientes.find(array => array === value);
		if (existingArray === undefined) {
			setArrayPacientes([...arrayPacientes, value]);
			setValuePacientes(null);
		}
	};

	const handleValueServicios = value => {
		let existingArray = null;
		existingArray = arrayServicios.find(array => array === value);
		if (existingArray === undefined) {
			setArrayServicios([...arrayServicios, value]);
			setValueServicios(null);
		}
	};

	const handleRemovePaciente = value => {
		const usuariosFiltrados = arrayPacientes.filter(paciente => {
			return paciente.nombre !== value;
		});
		setArrayPacientes([...usuariosFiltrados]);
	};

	const handleRemoveCentro = value => {
		const centrosFiltrados = arrayCentros.filter(centro => {
			return centro.nombre !== value;
		});
		setArrayCentros([...centrosFiltrados]);
	};

	const handleRemoveUsuario = value => {
		const usuariosFiltrados = arrayUsuarios.filter(usuario => {
			return usuario.nombre !== value;
		});
		setArrayUsuarios([...usuariosFiltrados]);
	};

	const handleRemoveServicio = value => {
		const serviciosFiltrados = arrayServicios.filter(servicio => {
			return servicio.nombre !== value;
		});
		setArrayServicios([...serviciosFiltrados]);
	};

	const seleccionarFiltrosCentro = (objetos, filtros) => {
		if (filtros.length > 0) {
			return objetos.filter(objeto => filtros.includes(objeto.nombre));
		} else {
			[];
		}
	};

	const seleccionarFiltrosServicios = (objetos, filtros) => {
		if (filtros && filtros.length > 0) {
			return objetos.filter(objeto => filtros.includes(objeto.id));
		} else {
			return [];
		}
	};

	const seleccionarFiltrosEspecialistas = (objetos, filtros) => {
		if (filtros !== null && filtros.length > 0) {
			return objetos.filter(objeto => filtros.includes(objeto.id));
		} else {
			return [];
		}
	};

	const restoreFilters = async (
		filtros,
		centrosVariables,
		serviciosVariables,
		usuariosVariables
	) => {
		const centrosSeleccionados = filtros[0].centros;
		if (centrosSeleccionados) {
			const objetosFiltrados = seleccionarFiltrosCentro(
				centrosVariables,
				centrosSeleccionados
			);

			if (objetosFiltrados) {
				setArrayCentros(objetosFiltrados);
			} else {
				setArrayCentros([]);
			}
		}
		const pacientesSeleccionados = filtros[1].pacientes;
		if (pacientesSeleccionados) {
			const pacientesFiltrados = await seleccionarFiltrosPaciente(
				pacientesSeleccionados
			);
			setArrayPacientes(pacientesFiltrados);
		}
		const especialistasSeleccionados = filtros[2].especialistas;
		if (especialistasSeleccionados) {
			const especialistasFiltrados = seleccionarFiltrosEspecialistas(
				usuariosVariables,
				especialistasSeleccionados
			);
			if (especialistasFiltrados) {
				setArrayUsuarios(especialistasFiltrados);
			} else {
				setArrayUsuarios([]);
			}
		}
		const serviciosSeleccionados = filtros[3].servicios;
		if (serviciosSeleccionados) {
			const serviciosFiltrados = seleccionarFiltrosServicios(
				serviciosVariables,
				serviciosSeleccionados
			);
			setArrayServicios(serviciosFiltrados);
			if (serviciosFiltrados) {
				setArrayServicios(serviciosFiltrados);
			} else {
				setArrayServicios([]);
			}
		}
	};

	//setea los filtros si existen datos
	const handlerShowFilterInit = async ()  => {
		setSkeleton(true);
		let centrosVariables = [];
		let serviciosVariables = [];
		let usuariosVariables = [];

			const respuesta = await datosFiltro();

			centrosVariables = respuesta[0].map(objeto => ({
				id: objeto.uid,
				nombre: objeto.nombre,
			}));

			setCentros(centrosVariables);
			serviciosVariables = respuesta[1].map(objeto => ({
				id: objeto.uid,
				nombre: objeto.nombre,
			}));
			setServicios(serviciosVariables);

			const listaEspecialistas = respuesta[2].filter(usuario => {
				return usuario.rol === 'especialista';
			});

			usuariosVariables = listaEspecialistas.map(objeto => ({
				id: objeto.correo,
				nombre: `${objeto.nombre} ${objeto.apellido}`,
			}));
			setUsuarios(usuariosVariables);
	

		//validacion datofs galery card que llamo
		//existen datos en los filtros?
		if (filtros.length > 0) {
			setValueFecha1(filtrosDate[0]);
			setValueFecha2(filtrosDate[1]);
			if (Array.isArray(filtros)) {
				await restoreFilters(
					filtros,
					centrosVariables,
					serviciosVariables,
					usuariosVariables
				);
			}
		} 
		 else {
			setArrayCentros([]);
			setArrayPacientes([]);
			setArrayServicios([]);
			setArrayUsuarios([]);
		}
		setSkeleton(false);
	
	};


	async function datosFiltro() {
		const promises = [getCentros(), getServicios(), getUsuarios()];
		try {
			const result = await Promise.all(promises);
			return result;
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handlerShowFilterInit();
		}
	});

	return (
		<div className="relative">
			<div className={`overflow-y-auto pl-4 rounded-l-[18px] fixed top-0.1 right-0 min-w-[300px] h-[100vh] flex-col  justify-between bg-white z-50 ${anchoPantalla < 1367 ? 'w-[35vw]' : 'w-[28vw]'}`}>
				<div className="rounded-tl-[18px]   w-full flex justify-content-center ">
					<span className=" text-darkblue items-center flex-col sm:text-md lg:text-xl md:text-md text-md font-medium text-center align-items-center flex-grow h-fit pt-6  ">
						Filtrar Citas
					</span>
				</div>
				{/* inicio fechas */}
				<div className="m-4 mb-4 justify-center ">
					<label
						className="text-md text-gray-800  justify-center"
						htmlFor="centrosFilter"
					>
						Rango de fechas
						{skeleton === true || loading === true ? (
							<div className="flex align-items-center bg-greymedium h-12 w-[90%] rounded-md animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
						) : (
							<div className="flex align-items-center">
								<LocalizationProvider
									dateAdapter={AdapterDateFns}
									adapterLocale={es}
								>
									<DatePicker
										value={valueFecha1}
										className="block pl-10 w-[36%] py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										onChange={newValue => handleDateChange1(newValue)}
										// disabled={
										// 	filtroSelected === 'deudas' &&
										// 	checkedAcumulado === 'acumulado'
										// 		? true
										// 		: false
										// }
									/>
									<span className=" w-fit px-2 py-2 text-[#000000] ">
										hasta
									</span>
									<DatePicker
										value={valueFecha2}
										className="block pl-10 w-[36%] py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										onChange={newValue => handleDateChange2(newValue)}
										// disabled={
										// 	filtroSelected === 'deudas' &&
										// 	checkedAcumulado === 'acumulado'
										// 		? true
										// 		: false
										// }
									/>
								</LocalizationProvider>
							</div>
						)}
					</label>
					
				</div>
				{/* fin fechas */}
				{/* inicio centros */}

				{true && (
						<div>
							<div className="m-4 mb-4 justify-center " key={nanoid()}>
								<label
									className="text-md text-gray-800  justify-center"
									htmlFor="centrosFilter"
								>
									Centros
									<div>
										{centros === null && skeleton === true || loading === true ? (
											<div className="flex align-items-center bg-greymedium h-12 w-[90%] rounded-md animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
										) : (
											<Searchable
												options={centros}
												className="relative w-full"
												label="nameCentro"
												id="idCentro"
												selectedVal={valueCentros}
												handleChange={val => handleValueCentros(val)}
												arrayCentros={arrayCentros}
												key={nanoid()}
											/>
										)}
									</div>
								</label>
							</div>
							<div className="mr-4 ml-4 flex flex-wrap my-2" key={nanoid()}>
								{arrayCentros &&
									arrayCentros.length > 0 &&
									arrayCentros.map(centro => (
										<>
											{skeleton === true || loading === true ? (
												<div
													role="status"
													className="w-full animate-pulse"
													key={nanoid()}
												>
													<div className="h-3 bg-darkgrey rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>
												</div>
											) : (
												<a className="inline-flex mr-4 my-2 " key={nanoid()}>
													<span className="rounded-l-xl h-8 flex items-center justify-center font-medium pl-4 pr-1 bg-darkaqua sm:text-xs lg:text-lg md:text-md text-md cursor-pointer overflow-hidden  truncate capitalize w-[80%]">
														{centro.nombre}
													</span>
													<span
														className="rounded-r-xl h-8 w-8 flex-shrink-0 flex align-items-center justify-center border-l-0 bg-darkaqua  hover:bg-black hover:text-white transition duration-500 ease-in-out cursor-pointer"
														onClick={() => handleRemoveCentro(centro.nombre)}
													>
														x
													</span>
												</a>
											)}
										</>
									))}
							</div>
						</div>
					)}
				<hr className="mr-14 ml-4 bg-grey h-1" />
				<br />
				{/* fin centros */}
				{/* inicio servicios */}
				{true && (
						<div>
							{' '}
							<div className="m-4 mb-4 justify-center " key={nanoid()}>
								<label
									className="text-md text-gray-800  justify-center"
									htmlFor="centrosFilter"
								>
									Servicios
									<div>
										{servicios === null && skeleton === true || loading === true ? (
											<div className="flex align-items-center bg-greymedium h-12 w-[90%] rounded-md animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
										) : (
											<Searchable
												options={servicios}
												className="relative w-full"
												label="nameServicios"
												id="idServicios"
												selectedVal={valueServicios}
												handleChange={val => handleValueServicios(val)}
												arrayCentros={arrayServicios}
												key={nanoid()}
											/>
										)}
									</div>
								</label>
							</div>
							<div className="mr-4 ml-4 flex flex-wrap my-2" key={nanoid()}>
								{arrayServicios.length > 0 &&
									arrayServicios.map(servicio => (
										<>
											{skeleton === true || loading === true ? (
												<div
													role="status"
													className="w-full animate-pulse"
													key={nanoid()}
												>
													<div className="h-3 bg-darkgrey rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>
												</div>
											) : (
												<a className="inline-flex mr-4 my-2 " key={nanoid()}>
													<span className="rounded-l-xl h-8 flex items-center justify-center font-medium pl-4 pr-1 bg-darkaqua sm:text-xs lg:text-lg md:text-md text-md cursor-pointer overflow-hidden truncate capitalize w-[80%]">
														{servicio.nombre}
													</span>
													<span
														className="rounded-r-xl h-8 w-8 flex-shrink-0 flex align-items-center justify-center border-l-0 bg-darkaqua  hover:bg-black hover:text-white transition duration-500 ease-in-out cursor-pointer"
														onClick={() =>
															handleRemoveServicio(servicio.nombre)
														}
													>
														x
													</span>
												</a>
											)}
										</>
									))}
							</div>
							<hr className="mr-14 ml-4 bg-grey h-1" />
							<br />
						</div>
					)}
				{usuario.rol !== 'especialista' && (
						<div>
							<div className="m-4 mb-4 justify-center " key={nanoid()}>
								<label
									className="text-md text-gray-800  justify-center"
									htmlFor="centrosFilter"
								>
									Especialista
									<div>
										{usuarios === null && skeleton === true || loading === true ? (
											<div className="flex align-items-center bg-greymedium h-12 w-[90%] rounded-md animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
										) : (
											<Searchable
												options={usuarios}
												className="relative w-full"
												label="nameUsuarios"
												id="idUsuarios"
												selectedVal={valueUsuarios}
												handleChange={val => handleValueUsuarios(val)}
												arrayCentros={arrayUsuarios}
												key={nanoid()}
											/>
										)}
									</div>
								</label>
							</div>
							<div className="mr-4 ml-4 flex flex-wrap my-2" key={nanoid()}>
								{arrayUsuarios.length > 0 &&
									arrayUsuarios.map(usuario => (
										<>
											{skeleton === true || loading === true ? (
												<div
													role="status"
													className="w-full animate-pulse"
													key={nanoid()}
												>
													<div className="h-3 bg-darkgrey rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>
												</div>
											) : (
												<a className="inline-flex mr-4 my-2 " key={nanoid()}>
													<span className="rounded-l-xl h-8 flex items-center justify-center font-medium pl-4 pr-1 bg-darkaqua sm:text-xs lg:text-lg md:text-md text-md cursor-pointer overflow-hidden truncate capitalize w-[80%]">
														{usuario.nombre}
													</span>
													<span
														className="rounded-r-xl h-8 w-8 flex-shrink-0 flex align-items-center justify-center border-l-0 bg-darkaqua  hover:bg-black hover:text-white transition duration-500 ease-in-out cursor-pointer"
														onClick={() => handleRemoveUsuario(usuario.nombre)}
													>
														x
													</span>
												</a>
											)}
										</>
									))}
							</div>
							<hr className="mr-14 ml-4 bg-grey h-1" />
							<br />
						</div>
					)}
				{true && (
						<div>
							<div className="m-4 mb-4 justify-center " key={nanoid()}>
								<label
									className="text-md text-gray-800  justify-center"
									htmlFor="centrosFilter"
								>
									Pacientes
									<div>
										{skeleton === true || loading === true ? (
											<div className="flex align-items-center bg-greymedium h-12 w-[90%] rounded-md animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
										) : (
											<SearchableUser
												className="relative w-full"
												label="namePacientes"
												id="idPacientes"
												selectedVal={valuePacientes}
												handleChange={val => handleValuePacientes(val)}
												arrayCentros={arrayPacientes}
												key={nanoid()}
											/>
										)}
									</div>
								</label>
							</div>
							<div className="mr-4 ml-4 flex flex-wrap my-2" key={nanoid()}>
								{arrayPacientes.length > 0 &&
									arrayPacientes.map(paciente => (
										<>
											{skeleton === true || loading === true ? (
												<div
													role="status"
													className="w-full animate-pulse"
													key={nanoid()}
												>
													<div className="h-3 bg-darkgrey rounded-full dark:bg-gray-700 w-4/5 mb-4"></div>
												</div>
											) : (
												<a className="inline-flex mr-4 my-2 " key={nanoid()}>
													<span className="rounded-l-xl h-8 flex items-center justify-center font-medium pl-4 pr-1 bg-darkaqua sm:text-xs lg:text-lg md:text-md text-md cursor-pointer overflow-hidden  truncate capitalize w-[80%]">
														{paciente.nombre}
													</span>
													<span
														className="rounded-r-xl h-8 w-8 flex-shrink-0 flex align-items-center justify-center border-l-0 bg-darkaqua  hover:bg-black hover:text-white transition duration-500 ease-in-out cursor-pointer"
														onClick={() =>
															handleRemovePaciente(paciente.nombre)
														}
													>
														x
													</span>
												</a>
											)}
										</>
									))}
							</div>
							<hr className="mr-14 ml-4 bg-grey h-1" />
							<br />
						</div>
					)}
				<div className="flex justify-around w-3/4 m-auto mt-8 mb-4">
					<button
						className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white "
						onClick={() => handlerShowFilter(filter, valueFecha1, valueFecha2)}
					>
						Buscar
					</button>
				</div>
			</div>
			<div
				className="fixed top-0 left-0 w-[74vw] h-screen flex items-center justify-center bg-black bg-opacity-50 z-40 "
				onClick={() => {
					cancelFilter();
				}}
				key={nanoid()}
			></div>
		</div>
	);
}

export default FiltroSideBarReporte;