import React, { useState, useEffect, useRef } from "react";
import imagenUsuario from "../../../img/user-circle1.svg";
import { useHookUsuarios } from "../../../libs/hookUsuarios";
import { useHookServicios } from "../../../libs/hookServicios";
import { BsCurrencyDollar, BsPercent } from "react-icons/bs";
import CarouselSe from "../../../components/CarouselSe";


const UpdateUsuario = (props) => {
  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const { updateUser, getUser } = useHookUsuarios();
  const { getServicios } = useHookServicios();
  const [servicios, setServicios] = useState([])
  const [errors, setErrors] = useState({});

  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [correo, setCorreo] = useState('')
  const [rol, setRol] = useState('');
  const [especialidad, setEspecialidad] = useState('');
  const [tarjetas, setTarjetas] = useState([]);

  const [tarjetaServicio, setTarjetaServicio] = useState({
    nombre: "",
    porcentaje: "",
    monto: "",
    servicioId: "0",
  });
  const [errorsTarjeta, setErrorsTarjeta] = useState({});
  const [tipoSeleccionado, setTipoSeleccionado] = useState('');

  const handleGetServicios = async () => {

    await getServicios().then((data) => {
      setServicios(data)
    })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleGetUser = async (usuarioCorreo) => {

    try {
      const usuarioData = await getUser(usuarioCorreo);
      setNombre(usuarioData.nombre)
      setApellido(usuarioData.apellido)
      setCorreo(usuarioData.correo)
      setRol(usuarioData.rol)


      if (usuarioData.rol === 'especialista') {
        setEspecialidad(usuarioData.especialidad)
        setTarjetas(usuarioData.servicios);
        handleGetServicios();
      }

      setIsLoading(false)

    }
    catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleGetUser(props.usuarioCorreo);
    }
  }, [props.usuarioCorreo, handleGetUser]);

  const handleEliminarServicio = async (nombre) => {
    const updatedTarjetas = tarjetas.filter(servicio => servicio.nombre !== nombre);
    setTarjetas(updatedTarjetas)
    await updateUser(correo, "servicios", updatedTarjetas);
  }

  const handleAddServicioTarjeta = async (e) => {
    e.preventDefault();
    let errors = {};

    const montoValido = /^[0-9]+(?:\.[0-9]+)?$/;
    const porcentajeValido = /^\d{1,2}$/;

    if (tipoSeleccionado === 'porcentaje') {
      if (!porcentajeValido.test(tarjetaServicio.porcentaje)) {
        errors.porcentaje = "Porcentaje inválido"
      }
    }

    if (tipoSeleccionado === 'monto') {
      if (!montoValido.test(tarjetaServicio.monto)) {
        errors.monto = "Monto inválido"
      }
    }
    setErrorsTarjeta(errors);


    if (porcentajeValido.test(tarjetaServicio.porcentaje) || montoValido.test(tarjetaServicio.monto)) {
      const servicio = servicios.find(item => item.uid === tarjetaServicio.servicioId);

      if (servicio) {
        const nuevaTarjeta = {
          servicioId: servicio.uid,
          nombre: servicio.nombre,
          porcentaje: tarjetaServicio.porcentaje.trim(),
          monto: tarjetaServicio.monto.trim(),
        };

        setTarjetas([nuevaTarjeta, ...tarjetas]);
        await updateUser(correo, "servicios", [...tarjetas, nuevaTarjeta]);
        setTarjetaServicio({ nombre: "", porcentaje: "", monto: "", servicioId: "0" });
        setTipoSeleccionado("");
      }
    }
  };

  const handleChangeTarjeta = ({ target: { value, name } }) => {
    setTarjetaServicio({ ...tarjetaServicio, [name]: value });
  };

  const handleTipoChange = (event) => {
    setErrors({})
    setTipoSeleccionado(event.target.value);
    setTarjetaServicio(prevState => ({
      ...prevState,
      porcentaje: "",
      monto: "",
    }));

  };

  const handleChangeNombre = (nombre) => {
    setNombre(nombre)
    let newErrors = {};

    const nombreValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$/;

    if (!nombreValido.test(nombre)) {
      newErrors.nombre = "Nombre inválido"
    }
    else {

      updateUser(
        correo,
        "nombre",
        nombre.trim().toLowerCase()
      );

    }
    setErrors(newErrors);
  }

  const handleChangeApellido = (apellido) => {
    setApellido(apellido)
    let newErrors = {};

    const apellidoValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$/;

    if (!apellidoValido.test(apellido)) {
      newErrors.apellido = "Apellidos inválidos"
    }
    else {

      updateUser(
        correo,
        "apellido",
        apellido.trim().toLowerCase()
      );

    }
    setErrors(newErrors);
  }

  const handleChangeRol = (rol) => {
    setRol(rol)
    updateUser(
      correo,
      "rol",
      rol
    );
    if (rol === 'especialista') {
      handleGetServicios();
    }
  }

  const handleChangeEspecialidad = (especialidad) => {
    setEspecialidad(especialidad)
    let newErrors = {};

    const especialidadValida = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$/;

    if (!especialidadValida.test(especialidad)) {
      newErrors.especialidad = "Especialidad inválida"
    }
    else {

      updateUser(
        correo,
        "especialidad",
        especialidad.trim().toLowerCase()
      );

    }
    setErrors(newErrors);
  }

  return (

    <div className={`min-w-[35rem] justify-center ${rol === 'especialista' ? '' : 'w-[35vw]'}`}>
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 " >EDITAR USUARIO</h3>
      <img className="m-auto mb-3" src={imagenUsuario} alt="imagen del usuario" />

      <form className="w-full">
        <div className={` ${rol === 'especialista' ? 'lg:w-[70vw] w-full flex flex-col lg:flex-row' : ''}`}>
          <div className={` ${rol === 'especialista' ? 'lg:w-1/2 p-3' : ''}`}>

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="nombre">
              Nombre
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<input
                id="nombre"
                name="nombre"
                className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="text"
                placeholder="Nombres..."
                value={nombre}
                onChange={(e) => handleChangeNombre(e.target.value)}
              />)}

            </label>

            {errors.nombre && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.nombre}</span>
                <br />
              </div>
            )}

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="apellido">
              Apellidos
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<input
                id="apellido"
                name="apellido"
                className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="text"
                placeholder="Apellidos..."
                value={apellido}
                onChange={(e) => handleChangeApellido(e.target.value)}
              />)}
            </label>

            {errors.apellido && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.apellido}</span>
                <br />
              </div>
            )}

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="correo">
              Correo
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<input
                required
                disabled
                id="correo"
                name="correo"
                className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="email"
                placeholder="ejemplo@dominio.com"
                defaultValue={correo}
              />)}
            </label>

            <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="rol">
              Rol
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<select
                required
                className="block w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
                id="rol"
                name="rol"
                value={rol}
                onChange={(e) => handleChangeRol(e.target.value)}


              >
                <option key="0" value="especialista">Especialista</option>
                <option key="1" value="coordinador">Coordinador</option>
                <option key="2" value="administrador">Administrador</option>
              </select>)}
            </label>
          </div>


          {isLoading ? ('') : (<div className={` ${rol === 'especialista' ? 'lg:w-1/2 p-3 lg:border-l lg:border-grey' : 'hidden'}`}>

            <div className="flex items-center">
              {Array.isArray(tarjetas) && tarjetas.length > 0 && (
                <div className='w-full'>
                  <CarouselSe servicios={tarjetas} onBorrar={handleEliminarServicio}/>
                </div>
              )}
            </div>

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="especialidad">
              Especialidad
              <input
                id="especialidad"
                name="especialidad"
                className={`w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 ${rol === 'especialista' ? '' : 'hidden'}`}
                type="text"
                placeholder="Especialidad..."
                value={especialidad}
                onChange={(e) => handleChangeEspecialidad(e.target.value)}
              />

            </label>

            {errors.especialidad && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.especialidad}</span>
                <br />
              </div>
            )}

            <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="nombreServicio">Selecciona un servicio:
              <select id="servicioId"
                name="servicioId"
                onChange={handleChangeTarjeta}
                value={tarjetaServicio.servicioId}
                className="block w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "                            >
                <option key="123456" value="0" disabled>Selecciona una opción</option>
                {Array.isArray(servicios) && servicios.length > 0 && servicios.map((servicio) => {
                  const estaEnTarjetas = tarjetas.some((tarjeta) => tarjeta.nombre === servicio.nombre);
                  return (
                    <option
                      key={servicio.uid}
                      value={servicio.uid}
                      disabled={estaEnTarjetas}
                    >
                      {servicio.nombre}
                    </option>
                  );
                })}
              </select>
            </label>

            {tarjetaServicio.servicioId !== "0" && (<div className="flex w-full justify-around my-4"><label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
              <input
                name="tipoSeleccionado"
                type="radio"
                value="porcentaje"
                className="mr-4"
                onChange={handleTipoChange}
              />
              Porcentaje
            </label>

              <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
                <input
                  className="mr-4"
                  name="tipoSeleccionado"
                  type="radio"
                  value="monto"
                  onChange={handleTipoChange}
                />
                Monto Fijo
              </label>
            </div>)}

            {tipoSeleccionado === 'porcentaje' && (
              <label htmlFor="porcentaje" className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"> Porcentaje
                <div className="relative" >
                  <BsPercent className=" absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                  <input
                    id="porcentaje"
                    name="porcentaje"
                    required={tipoSeleccionado === 'porcentaje'}
                    value={tarjetaServicio.porcentaje}
                    type="text"
                    onChange={handleChangeTarjeta}
                    className="block pl-10  w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  ></input>
                </div></label>)}
            {errorsTarjeta.porcentaje && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errorsTarjeta.porcentaje}</span>
                <br />
              </div>
            )}

            {tipoSeleccionado === 'monto' && (

              <label htmlFor="monto" className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">Monto Fijo
                <div className="relative" >
                  <BsCurrencyDollar className=" absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                  <input
                    id="monto"
                    name="monto"
                    value={tarjetaServicio.monto}
                    type="text"
                    onChange={handleChangeTarjeta}
                    className="block pl-10  w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  ></input>
                </div></label>
            )}
            {errorsTarjeta.monto && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errorsTarjeta.monto}</span>
                <br />
              </div>
            )}


            {tipoSeleccionado !== '' && (<div className="flex justify-end">
              <button type="button"
                onClick={handleAddServicioTarjeta}
                className="min-w-fit sm:text-xs mt-4 lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white">crear</button>
            </div>)}


          </div>)}
          {/* fin container derecha */}
        </div>
      </form>
    </div>
  );
};

export default UpdateUsuario;