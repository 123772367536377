import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import { nanoid } from 'nanoid';
import { useHookReportes } from '../../../libs/hookReportes';
import CardEstadisticas from './CardEstadisticas';
import GaleryCards from './GaleryCards';
import FiltroSideBar from './FiltroSideBar';
import { useHookAuth } from '../../../libs/firebase';

export default function index() {
	const { usuario } = useHookAuth();
	const [loading, setLoading] = useState(false);
	const {
		getReport,
		getReportPacientes,
		handlerDateFin,
		handlerDateInicio,
		filtros1,
		filtros2,
		filtros3,
		filtros5,
		filtros6,
		filtros7,
		filtros8,
		handlerFiltros1,
		handlerFiltros2,
		handlerFiltros3,
		handlerFiltros5,
		handlerFiltros6,
		handlerFiltros7,
		handlerFiltros8,
		handlerFiltroDate1,
		handlerFiltroDate2,
		handlerFiltroDate3,
		handlerFiltroDate5,
		handlerFiltroDate6,
		handlerFiltroDate7,
		handlerFiltroDate8,
		handlerAcumulado,
	} = useHookReportes();
	const [showFilter, setShowFilter] = useState(false);
	const [filtroSelected, setFiltroSelected] = useState('filtro');
	const isFirstRender = useRef(true);
	const [lengthIngresos, setLengthIngresos] = useState(0);
	const [loadingCentros, setLoadingCentros] = useState(false);
	const [loadingServicios, setLoadingServicios] = useState(false);
	const [loadingEspecialistas, setLoadingEspecialistas] = useState(false);
	const [loadingIngresos, setLoadingIngresos] = useState(false);
	const [loadingDeudas, setLoadingDeudas] = useState(false);
	const [loadingUsuariosNuevos, setLoadingUsuariosNuevos] = useState(false);
	const [loadingSesionesAtendidas, setLoadingSesionesAtendidas] = useState(false);
	const [grafCitas, setGrafCitas] = useState([]);
	const [ingresos, setIngresos] = useState(0);
	const [cartera, setCartera] = useState(0);
	const [totalCitas, setTotalCitas] = useState(0);
	const [pacientesNuevos, setPacientesNuevos] = useState(0);
	const [ingresosEspecialistas, setIngresosEspecialistas] = useState([]);
	const [costosEspecialistas, setCostosEspecialistas] = useState([]);
	const [pagosEspecialistas, setPagosEspecialistas] = useState([]);
	const [series, setSeries] = useState([]);
	const [dataSet, setDataSet] = useState([]);
	
	//se llama a get report cada vez que cambia el filtro
	const getReports = async(filter, dateInicio, dateFin) => {
				await setearFiltros(filter, dateInicio, dateFin);
				if (filtroSelected === 'centros') {
					setLoadingCentros(true)
					handlerGetReportCentros(dateInicio, dateFin, filter);
				} else if (filtroSelected === 'servicios') {
					setLoadingServicios(true)
					handlerGetReportServicios(dateInicio, dateFin, filter);
				} else if (filtroSelected === 'citas') {
					setLoadingEspecialistas(true)
					handlerGetReportEspecialistas(dateInicio, dateFin, filter);
				}  else if (filtroSelected === 'ingresos') {
					setLoadingIngresos(true)
					handlerGetReportIngresos(dateInicio, dateFin, filter);
				} else if (filtroSelected === 'deudas') {
					setLoadingDeudas(true)
					handlerGetReportDeudas(dateInicio, dateFin, filter);
				} else if (filtroSelected === 'usuariosNuevos') {
					setLoadingUsuariosNuevos(true)
					handlerGetUsuariosNuevos(dateInicio, dateFin, filter);
				} else if (filtroSelected === 'sesionesAtendidas') {
					setLoadingSesionesAtendidas(true)
					handlerGetSesionesAtendidas(dateInicio, dateFin, filter);
				} 
		}

	const crearObjeto = objeto => {
	
		const dataAxis = Object.keys(objeto).map(valor => {
			const number = Number(valor);
			if (isNaN(number)) {
				return { dataKey: valor, label: valor };
			}
		});
		return dataAxis;
	};

	const objectFormater = objeto => {
		const data = Object.keys(objeto).map((valor, index) => {
			if (valor !== 'total') {
				return {
					id: index,
					value: objeto[valor],
					label: valor,
				};
			}
		});
		const filteredArr = data.filter(element => element !== undefined);
		return filteredArr;
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//reporte card SesionesAtendidas
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handlerGetSesionesAtendidas = async (inicio, fin, filter) => {
		if(showFilter) { setLoading(true)}
		const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);
		const res2 = await getReport(dateInicio, dateFin, 2, false, filter);
		if (typeof res2 === "object") {
		setTotalCitas(res2.summary.total);
		} else {
		setTotalCitas(0);
		}
		setLoadingSesionesAtendidas(false);
		setLoading(false);
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//reporte card usuarios nuevos
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handlerGetUsuariosNuevos = async (inicio, fin, filter) => {
		if(showFilter) { setLoading(true)}
		const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);
	const resPacientes = await getReportPacientes(
			dateInicio,
			dateFin,
			1,
			false,
			filter
		);
		if (typeof resPacientes === "object" ){
			setPacientesNuevos(resPacientes.summary.total);
		} else {
			setPacientesNuevos(0);
		}
		setLoadingUsuariosNuevos(false);
		setLoading(false);
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//reporte card deuda
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handlerGetReportDeudas = async (inicio, fin, filter) => {
		if(showFilter) { setLoading(true)}
		const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);	
		let res5;
		try {
			if(checkedAcumulado === "acumulado"){
				res5 = await getReport(0, 0, 6, false );
			} else {
				res5 = await getReport(dateInicio, dateFin, 6, false, filter );
			}
		} catch (error) {
			console.log("res5 error", error)
		}
		if(typeof res5 === "object") {
			setCartera(res5.total_deuda);
		} else {
			setCartera(0);
		}
		setLoadingDeudas(false);
		setLoading(false);
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//reporte card Ingresos
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handlerGetReportIngresos = async (inicio, fin, filter) => {
		if(showFilter) { setLoading(true)}
		const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);	
		let res5;
		try {
			res5 = await getReport(dateInicio, dateFin, 5, false, filter );
		if(typeof res5 === "object" && typeof res5.total_ingresos === "number"){
			setIngresos(res5.total_ingresos);
		} else {
			setIngresos(0);
		}
		} catch (error) {
			console.log("res5 error", error)
		}
		setLoadingIngresos(false);
		setLoading(false);
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//reporte card especialistas
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const handlerGetReportEspecialistas = async (inicio, fin, filter) => {
		if(showFilter) { setLoading(true)}
		setPagosEspecialistas([]);
		const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);
		let res3;
		let res4;

		try {
			res3 = await getReport(dateInicio, dateFin, 3, false, filter );
		} catch (error) {
			console.log("res3 error", error)
		}
		try {
			res4 = await getReport(dateInicio, dateFin, 4, false, filter );	
		} catch (error) {
			console.log("res4 error", error)
		}

		let filteredData;
		let filteredData2;


		if (typeof res3 === "object") {
			filteredData = Object.entries(res3?.costos_especialistas);
			filteredData2 = Object.entries(res3?.ingresos_especialistas);
			const convertedData = filteredData.map(item => ({
				[item[0]]: item[1],
			}));
			const convertedData2 = filteredData2.map(item => ({
				[item[0]]: item[1],
			}));

			setIngresosEspecialistas(convertedData);
			setCostosEspecialistas(convertedData2);
			if(convertedData.length < 3){
				setLengthIngresos(convertedData.length * 80);
			} else {
				setLengthIngresos(convertedData.length * 20);
			}
			
		} else {
			filteredData = []
			filteredData2 = []
			setIngresosEspecialistas( [{ correo: 'Sin datos' , pago: 0 }]);
			setCostosEspecialistas( [{ correo: 'Sin datos' , pago: 0 }]);
			setLengthIngresos(80);
		}

		let filteredData3;
		if (typeof res4 === "object") {
			filteredData3 = Object.entries(res4?.pago_por_especialista);
			if(res4 && filteredData3.length > 0) {
				const convertedData3 = filteredData3.map(item => ({
					correo: [item[0]], pago: item[1]
				}))
				setPagosEspecialistas(convertedData3);
			} else {
				setPagosEspecialistas( [{correo: 'Sin datos' , pago: 0 }]);
			}
		}else{
			setPagosEspecialistas( [{correo: 'Sin datos' , pago: 0 }]);
		}
		setLoadingEspecialistas(false);
		setLoading(false);
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//reporte servicios
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handlerGetReportServicios = async (inicio, fin, filter) => {
		if(showFilter) { setLoading(true)}
		setDataSet([]);
		const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);
		const res1 = await getReport(dateInicio, dateFin, 1, false, filter );
		if(typeof res1 === "object") {
		setSeries(crearObjeto(res1.summary));
		setDataSet([res1.summary]);
		} else {
			setSeries([{ dataKey: 'sin datos', label: 'sin datos' }]);
			setDataSet([{'sin datos': 0}]);
		}
		setLoadingServicios(false);
		setLoading(false);
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	//reporte centros
	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	const handlerGetReportCentros = async (inicio, fin, filter) => {
		if(showFilter) { setLoading(true)}
		const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);	
		const res2 = await getReport(dateInicio, dateFin, 2, false, filter );
		if (typeof res2 === "object") {
		setGrafCitas(objectFormater(res2.summary));
		} else {
			setGrafCitas([{ id: 0, value: 10, label: 'Sin datos' }]);
		}
		setLoadingCentros(false);
		setLoading(false);
	};

	const handleLoading = (data) => {
		setLoadingCentros(data);
		setLoadingServicios(data);
		setLoadingEspecialistas(data);
		setLoadingIngresos(data);
		setLoadingDeudas(data);
		setLoadingUsuariosNuevos(data);
		setLoadingSesionesAtendidas(data);
	}

	useEffect(() => {
		let arrayEspecialistasId = [];
		let filters;
		if (isFirstRender.current) {
			if (usuario.rol === 'especialista') {
				arrayEspecialistasId = [usuario.correo];
			} 

			filters = [
				{ filtro: 'centros', centros: [] },
				{ filtro: 'pacientes', pacientes: [] },
				{ filtro: 'especialistas', especialistas: arrayEspecialistasId },
				{ filtro: 'servicios', servicios: [] },
			];

			isFirstRender.current = false;
			const today = new Date();
			const dayOfWeek = today.getDay();
			const monday = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() - dayOfWeek + 1
			);
			const sunday = new Date(
				monday.getFullYear(),
				monday.getMonth(),
				monday.getDate() + 6
			);
			const dateInicio = Math.floor(monday.getTime() / 1000);
			const dateFin = Math.floor(sunday.getTime() / 1000);
			handlerDateInicio(dateInicio);
			handlerDateFin(dateFin);
			if(filtros1.length > 0) {
				handlerGetReportCentros(monday, sunday, filtros1);
			} else {
				handlerGetReportCentros(monday, sunday);
			}
			if (filtros2.length > 0) {
				handlerGetReportServicios(monday, sunday, filtros2);
			} else {
				handlerGetReportServicios(monday, sunday);
			}
			if (usuario.rol === 'especialista') {
				if(filtros3.length > 0) {
					handlerGetReportEspecialistas(monday, sunday, filtros3);
				} else {
				handlerGetReportEspecialistas(monday, sunday, filters);
				handlerFiltros3(filters);
				}
			} else {
				if(filtros3.length > 0) {
					handlerGetReportEspecialistas(monday, sunday, filtros3);
				} else {
					handlerGetReportEspecialistas(monday, sunday);
				}
			}
			if (filtros5.length > 0) {
				handlerGetReportIngresos(monday, sunday, filtros5);
			} else {
				handlerGetReportIngresos(monday, sunday);
			}
			if (filtros6.length > 0) {
				handlerGetReportDeudas(monday, sunday, filtros6);
			} else {
				handlerGetReportDeudas(monday, sunday);
			}
			if (filtros7.length > 0) {
				handlerGetUsuariosNuevos(monday, sunday, filtros7);
			} else {
				handlerGetUsuariosNuevos(monday, sunday);
			}
			if (filtros8.length > 0) {
				handlerGetSesionesAtendidas(monday, sunday, filtros8);
			} else {
				handlerGetSesionesAtendidas(monday, sunday);
			}
			handleLoading(true);
		}
	});

	const setearFiltros = (filtros, fecha1, fecha2) => {
		if (filtroSelected === "centros") {
			handlerFiltros1(filtros);
			handlerFiltroDate1([fecha1, fecha2]);
		} else if (filtroSelected === "servicios") {
			handlerFiltros2(filtros);
			handlerFiltroDate2([fecha1, fecha2]);
		} else if (filtroSelected === "citas") {
			handlerFiltros3(filtros);
			handlerFiltroDate3([fecha1, fecha2]);
		} else if (filtroSelected === "ingresos") {
			handlerFiltros5(filtros);
			handlerFiltroDate5([fecha1, fecha2]);
		} else if (filtroSelected === "deudas") {
			handlerFiltros6(filtros);
			handlerFiltroDate6([fecha1, fecha2]);
		} else if (filtroSelected === "usuariosNuevos") {
			handlerFiltros7(filtros);
			handlerFiltroDate7([fecha1, fecha2]);
		} else if (filtroSelected === "sesionesAtendidas") {
			handlerFiltros8(filtros);
			handlerFiltroDate8([fecha1, fecha2]);
		}
	}

	const handlerShowFilter = (data, fecha1, fecha2) => {
		setFiltroSelected(data);
		if(showFilter) {
			setearFiltros(data, fecha1, fecha2);
		} 
		setShowFilter(!showFilter);
	}

	
	const [checked, setChecked] = useState("pagos");
	const [checkedAcumulado, setCheckedAcumulado] = useState("acumulado");

	const handleChange = id => {
		setChecked(id);
	};

	const handleChangeAcumulado = id => {
		setCheckedAcumulado(id);
		if(id === "acumulado") {
			handlerAcumulado(true);
		} else {
			handlerAcumulado(false);
		}
	};

	return (
		<div
			className="w-[100%] h-full bg-azure  overflow-y-auto relative"
			key={nanoid()}
		>
			{showFilter && <FiltroSideBar getReports={getReports} handlerShowFilter={handlerShowFilter} filtroSelected={filtroSelected} 
			checked={checked} 
			checkedAcumulado={checkedAcumulado} 
			handleChange={handleChange} 
			handleChangeAcumulado={handleChangeAcumulado}
			loading={loading}
			/>}

			{/* finfiltro */}

			<CardEstadisticas
				ingresos={ingresos}
				cartera={cartera}
				totalCitas={totalCitas}
				pacientesNuevos={pacientesNuevos}
				handlerShowFilter={handlerShowFilter}
				loadingIngresos={loadingIngresos}
				loadingDeudas={loadingDeudas}
				loadingUsuariosNuevos={loadingUsuariosNuevos}
				loadingSesionesAtendidas={loadingSesionesAtendidas}
				checkedAcumulado={checkedAcumulado}
			/>

			{/* inicio graficos */}

			<GaleryCards
				grafCitas={grafCitas}
				series={series}
				dataSet={dataSet}
				ingresosEspecialistas={ingresosEspecialistas}
				costosEspecialistas={costosEspecialistas}
				lengthIngresos={lengthIngresos}
				//usuarios={usuarios}
				//summaryIngresos={summaryIngresos}
				handlerShowFilter={handlerShowFilter}
				pagosEspecialistas={pagosEspecialistas}
				checked={checked} 
				loadingCentros={loadingCentros}
				loadingServicios={loadingServicios}
				loadingEspecialistas={loadingEspecialistas}
			/>

			{/* fin graficos */}

		
		</div>
	);
}