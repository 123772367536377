import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHookReportes } from '../../../libs/hookReportes';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { BiCalendar } from 'react-icons/bi';
import { BsCurrencyDollar } from 'react-icons/bs';
import { BarChart } from '@mui/x-charts/BarChart';
import { useHookAuth } from '../../../libs/firebase';

const ReporteIngresos = () => {
	const isFirstRender = useRef(true);
	const [isLoading, setIsLoading] = useState(true);
	const [totalIngresos, setTotalIngresos] = useState(0);
	const [totalSaldo, setTotalSaldo] = useState(0);
	const [dataSet2, setDataSet2] = useState([]);

	const columns = [
		{ field: 'col1', headerName: 'Id', width: 50 },
		{ field: 'col2', headerName: 'Centro', width: 130 },
		{ field: 'col3', headerName: 'Fecha', width: 120 },
		{ field: 'col4', headerName: 'Paciente', width: 200 },
		{ field: 'col5', headerName: 'Especialista', width: 280 },
		{ field: 'col6', headerName: 'Servicio', width: 200 },
		{ field: 'col7', headerName: 'Precio', width: 70 },
		{ field: 'col8', headerName: 'Pagos', width: 70 },
		{ field: 'col9', headerName: 'Saldo', width: 70 },
		{ field: 'col10', headerName: 'Porcentaje', width: 100 },
		{ field: 'col11', headerName: 'Monto fijo', width: 100 },
		{ field: 'col12', headerName: 'Pago Especialista', width: 140 },
		{ field: 'col13', headerName: 'Utilidad', width: 100 },
	];

	const { getReport, filtroDate3, filtros3 } = useHookReportes();
	const {menuFull} = useHookAuth();
	const [rows, setRows] = useState([]);
	//const [totalCitas, setTotalCitas] = useState(0);

	function capitalizeString(str) {
		if (typeof str === 'string' && str.length > 0) {
			const words = str.split(' ');
			if (words.length > 0) {
				const palabras = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				return palabras.join(' ');
			} else {
				return str;
			}
		} else {
			return str;
		}
	}

	const chartSetting2 = {
		xAxis: [
			{
				label: 'Ingresos',
			},
		],
		width: 600,
		height: 480,
	};

	const valueFormatter = value => `S/. ${value}`;


	const crearObjetoSeries = async (data, data2) => {
		let series = [];
		for (const item of data) {
			const [email, value] = Object.entries(item)[0];
			for (const item2 of data2) {
				const [email2, value2] = Object.entries(item2)[0];
				if(email === email2) {
					series.push({ cita:value2, especialista: value, correo: email });
				}
			}
		}
		setDataSet2(series);
	};

	function formatDate(fecha) {
		// Obtenemos el día, el mes y el año de la fecha
		if(fecha !== undefined)
		{
		  const dia = fecha.getDate();
		const mes = fecha.getMonth() + 1;
		const anyo = fecha.getFullYear();
			// Formateamos la fecha
			return `${dia}/${mes}/${anyo}`;
		} else {
		  return "";
		}
	}

	  const fechaFormateada = unixTimestamp => {
		const fecha = new Date(unixTimestamp * 1000);
		const fechaFormateada = fecha.toLocaleDateString();
		return fechaFormateada;
	};

	function generarObjeto(data) {
		const objeto = [];
		let index = 0;
		const sortedData = data.sort((a, b) => a.inicio - b.inicio, {
			reverse: true,
		});
		for (const cita of sortedData) {

			index++;
			objeto.push({
				id: index,
				col1: index,
				col2: capitalizeString(cita.nombreCentro),
				col3: fechaFormateada(cita.inicio),
				col4: cita.pacientes,
				col5: cita.especialistas,
				col6: capitalizeString(cita.servicios),
				col7: cita.precio,
				col8: parseInt(cita.precio) - parseInt(cita.saldo),
				col9: cita.saldo,
				col10: `${cita.porcentaje}%`,
				col11: cita.monto_fijo,
				col12: cita.pago_especialista,
				col13: parseInt(cita.precio) - parseInt(cita.pago_especialista),
			});
		}
		return objeto;
	}

	const handlerGetData = async () => {
		let arrayCitas;
		let usuarios;
		const dateInicio = Math.floor(filtroDate3[0].getTime() / 1000);
		const dateFin = Math.floor(filtroDate3[1].getTime() / 1000);	
		await getReport(dateInicio, dateFin, 3, true, filtros3)
			.then(res => {
				if(typeof res === 'object'){
				setTotalIngresos(res.summary.total_ingresos);
				setTotalSaldo(res.summary.total_saldo);
				//(res.data.summary.total);
				arrayCitas = res.data;
				const filteredData = Object.entries(res.costos_especialistas);
				const filteredData2 = Object.entries(res.ingresos_especialistas);
				const convertedData = filteredData.map(item => ({
					[item[0]]: item[1],
				}));
				const convertedData2 = filteredData2.map(item => ({
					[item[0]]: item[1],
				}));
				// setIngresosEspecialistas(convertedData);
				setRows(generarObjeto(arrayCitas, usuarios));
				crearObjetoSeries(convertedData, convertedData2);
				} else {
					setTotalIngresos(0);
					setTotalSaldo(0);
					setDataSet2( [{ correo: 'Sin datos' , pago: 0 }]);
					setRows([]);
				}
				
			})
			.catch(error => {
				console.log(error);
			});
		
		setIsLoading(false);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handlerGetData();
		}
	}, [handlerGetData]);

	return (
		<div
			className={`h-full bg-azure  overflow-y-auto relative mt-3 w-[100%] `}
			key={nanoid()}
		>
			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-50 z-40">
					<div className="absolute inset-0 flex items-center justify-center z-50">
						<div className="animate-spin rounded-full h-16 w-16 border-t-[6px] border-b-[6px] border-[#29CEBE]"></div>
					</div>
				</div>
			)}
		<div className={`mx-auto ${menuFull? 'w-[80vw]': 'w-[90vw]'}`}>
		<div className="flex mx-auto rounded-2xl h-20 w-[93%] my-5 bg-darkblue ">
				<p className="text-2xl font-semibold text-center mx-auto my-auto text-white">
					{`Reportes centro Skinner ${formatDate(
						filtroDate3[0]
					)} hasta ${formatDate(filtroDate3[1])}`}
				</p>
			</div>
			{/* inicio graficos */}
			{rows.length > 0 && (
				<section className="text-gray-600 body-font w-full mb-8 mx-auto">
					<div className="container px-5 pt-3 mx-auto w-full">
						<div className="flex -m-4 text-center mx-auto w-full justify-center">
							<div className="p-4 md:w-4/5 sm:w-2/2 w-full">
								<div className="flex-wrap  px-3 py-4 bg-lightgrey rounded-xl  text-darkblue mx-auto h-[100%]">
									<p className="text-2xl font-medium text-center mx-auto mb-1 ">
									Pago por especialista
									</p>
									<div className="flex justify-center items-center h-fit">
										{!isLoading && dataSet2.length > 0 && (
											<BarChart
												margin={{ top: 20, left: 350, right: 50, bottom: 20 }}
												dataset={dataSet2}
												yAxis={[
													{
														scaleType: 'band',
														dataKey: 'correo',
														categoryGapRatio: 0.1,
														barGapRatio: 0.01,
													},
												]}
												series={[{ dataKey: 'cita' , label: 'Valor Cita',  valueFormatter }, 
												{ dataKey: 'especialista', label: 'Pago Especialista',  valueFormatter }]}
												layout="horizontal"
												{...chartSetting2}
											/>
										)}
									</div>
								</div>
							</div>

							{/* card 2 */}
							<div className="hidden p-4 md:w-2/5 sm:w-1/1 w-full  ">
								<div className="flex-col bg-lightgrey rounded-xl  text-darkblue justify-center items-center  h-[100%]">
									{/* card  */}
									<div className="p-2 w-full h-auto flex">
										<div className=" flex-col w-[100%] border-2 border-gray-200 px-3  bg-[#38b000] rounded-xl  text-white h-[90%] justify-between">
											<div className="my-1">
												<div className="mt-2 flex justify-center items-center align-middle">
													<BiCalendar className="text-2xl" />
													<h2 className=" title-font font-medium text-xl text-gray-900 px-2 ">{`${formatDate(
														filtroDate3[0]
													)}`}</h2>
												</div>
												<p className="leading-relaxed">Hasta</p>
												<div className="flex justify-center items-center align-middle">
													<BiCalendar className="text-2xl" />
													<h2 className=" title-font font-medium text-xl text-gray-900 px-2 ">{`${formatDate(
														filtroDate3[1]
													)}`}</h2>
												</div>
											</div>
										</div>
									</div>
									{/* fin car  */}
									{/* inicio  */}
									<div className="p-2 w-full h-fit  ">
										<div className="flex-wrap border-2 border-gray-200 p-3 bg-[#4cc9f0] rounded-xl  text-white h-[90%] my-auto">
											<div className="flex justify-center my-2">
												<BsCurrencyDollar className="text-4xl " />
												<h2 className=" title-font font-medium text-3xl text-gray-900 px-2 ">{`${totalIngresos}`}</h2>
											</div>
											<p className="leading-relaxed">Ingresos</p>
											<div className="flex justify-center my-2">
												<BsCurrencyDollar className="text-4xl" />
												<h2 className=" title-font font-medium text-3xl text-gray-900 px-2 ">{`${totalSaldo}`}</h2>
											</div>
											<p className="leading-relaxed">Cartera</p>
										</div>
									</div>
									{/* fin car  */}
								</div>
							</div>
							{/* fin car 2 */}
						</div>
					</div>
				</section>
			)}
			{/* fin graficos */}
			<div className=" mx-auto bg-lightgrey  rounded-2xl p-5 w-[95%]">
				<Box sx={{ height: 600, width: 1 }} className="overflow-x-auto">
					<DataGrid
						showCellVerticalBorder={true}
						showColumnVerticalBorder={true}
						// unstable_ignoreValueFormatterDuringExport={true}
						rows={rows}
						// disableColumnFilter
						// disableColumnSelector
						// disableDensitySelector
						columns={columns}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
					/>
				</Box>
			</div>
		</div>
		</div>
	);
};

export default ReporteIngresos;