import React, { useState, useEffect, useRef } from "react";
import imagenUsuario from "../../../img/user-circle1.svg";
import { useHookUsuarios } from "../../../libs/hookUsuarios";
import { useHookServicios } from "../../../libs/hookServicios";
import { BsCurrencyDollar, BsPercent } from "react-icons/bs"

import CarouselSe from "../../../components/CarouselSe";



const CrearUsuario = (props) => {
  const isFirstRender = useRef(true);
  const { createUser, createUserEspecialista } = useHookUsuarios();
  const { getServicios } = useHookServicios();
  const [servicios, setServicios] = useState(null)
  const [tarjetas, setTarjetas] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorsTarjeta, setErrorsTarjeta] = useState({});
  const [tipoSeleccionado, setTipoSeleccionado] = useState('');
  const [user, setUser] = useState({
    nombre: "",
    apellido: "",
    correo: "",
    password: "",
    proveedor: "google",
    repetir: "",
    rol: "administrador",
    especialidad: "",
    activo: true,
    servicios: [],
  });


  const handleGetServicios = async () => {

    await getServicios().then((data) => {
      setServicios(data)
    })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleGetServicios();
    }

  }, [handleGetServicios])

  const [tarjetaServicio, setTarjetaServicio] = useState({
    nombre: "",
    porcentaje: "",
    monto: "",
    servicioId: "0",
  });

  const handleEliminarServicio = (nombre) => {
    const updatedTarjetas = tarjetas.filter(servicio => servicio.nombre !== nombre);
    setTarjetas(updatedTarjetas)
  }

  const handleAddServicioTarjeta = (e) => {
    e.preventDefault();
    let errors = {};

    const montoValido = /^[0-9]+(?:\.[0-9]+)?$/;
    const porcentajeValido = /^\d{1,2}$/;

    if (tipoSeleccionado === 'porcentaje') {
      if (!porcentajeValido.test(tarjetaServicio.porcentaje)) {
        errors.porcentaje = "Porcentaje inválido"
      }
    }

    if (tipoSeleccionado === 'monto') {
      if (!montoValido.test(tarjetaServicio.monto)) {
        errors.monto = "Monto inválido"
      }
    }
    setErrorsTarjeta(errors);

    if (porcentajeValido.test(tarjetaServicio.porcentaje) || montoValido.test(tarjetaServicio.monto)) {
      const servicio = servicios.find(item => item.uid === tarjetaServicio.servicioId);
      if (servicio) {
        const nuevaTarjeta = {
          servicioId: servicio.uid,
          nombre: servicio.nombre,
          porcentaje: tarjetaServicio.porcentaje.trim(),
          monto: tarjetaServicio.monto.trim(),
        };

        setTarjetas([nuevaTarjeta, ...tarjetas]);
        setTarjetaServicio({ nombre: "", porcentaje: "", monto: "", servicioId: "0" });
        setTipoSeleccionado("");
      }
    }
  };

  const handleChangeTarjeta = ({ target: { value, name } }) => {
    setTarjetaServicio({ ...tarjetaServicio, [name]: value });
  };

  const handleTipoChange = (event) => {

    setTipoSeleccionado(event.target.value);
    setTarjetaServicio(prevState => ({
      ...prevState,
      porcentaje: "",
      monto: "",
    }));

  };

  const validarContra = (user) => {
    let errors = {};
    if (user.repetir !== user.password) {
      errors.contraseña = "La contraseña no coincide.";
    }
    return errors;
  };

  const handleChange = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validarContra(user);
    setErrors(newErrors);
    const trimUser = {};
    const excluir = ['password', 'repetir']

    if (Object.keys(newErrors).length === 0) {


      for (const propiedad in user) {

        if (typeof user[propiedad] === 'string') {

          if (excluir.includes(propiedad)) {

            trimUser[propiedad] = user[propiedad].trim();

          } else {
            trimUser[propiedad] = user[propiedad].trim().toLowerCase();
          }
        } else {
          trimUser[propiedad] = user[propiedad];
        }
      }
      if (trimUser.rol === 'especialista') {
        const modifiedArray = tarjetas.map(({ nombre, servicioId, porcentaje, monto }) => ({ nombre, servicioId, porcentaje, monto }));
        trimUser.servicios = [...modifiedArray];
        props.setShowModal(false);
        props.setIsLoading(true);
        await createUserEspecialista(
          trimUser.password,
          trimUser.nombre,
          trimUser.apellido,
          trimUser.correo,
          trimUser.proveedor,
          trimUser.rol,
          trimUser.especialidad,
          trimUser.activo,
          trimUser.servicios)
        props.getUsuarios();


      } else {
        props.setShowModal(false);
        props.setIsLoading(true);
        await createUser(
          trimUser.password,
          trimUser.nombre,
          trimUser.apellido,
          trimUser.correo,
          trimUser.proveedor,
          trimUser.rol,
          trimUser.activo,
        )
        props.getUsuarios();
      }

    }
  };

  return (

    <div className={`min-w-[35rem] justify-center ${user.rol === 'especialista' ? '' : 'w-[35vw]'}`}>
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 " >CREAR USUARIO</h3>
      <img className="m-auto mb-3" src={imagenUsuario} alt="imagen del usuario" />

      <form className="w-full" onSubmit={handleSubmit}>
        <div className={` ${user.rol === 'especialista' ? 'lg:w-[70vw] w-full flex flex-col lg:flex-row' : ''}`}>
          <div className={` ${user.rol === 'especialista' ? 'lg:w-1/2 p-3' : ''}`}>
            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="nombre">
              Nombre
              <input
                required
                pattern="^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$"
                id="nombre"
                name="nombre"
                minLength="3"
                maxLength="20"
                className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="text"
                placeholder="Nombres..."
                value={user.nombre}
                onChange={handleChange}
              />

            </label>

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="apellido">
              {" "}
              Apellidos
              <input
                required
                pattern="^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$"
                id="apellido"
                name="apellido"
                minLength="3"
                maxLength="25"
                className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="text"
                placeholder="Apellidos..."
                value={user.apellido}
                onChange={handleChange}
              />
            </label>

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="correo">
              Correo
              <input
                required
                id="correo"
                name="correo"
                className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="email"
                placeholder="ejemplo@dominio.com"
                value={user.correo}
                onChange={handleChange}
              />
            </label>

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="password">
              Contraseña
              <input
                required
                id="password"
                name="password"
                minLength="6"
                maxLength="15"
                className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="password"
                placeholder="Ingresa tu contraseña..."
                onChange={handleChange}
                value={user.contraseña}
              />
            </label>

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="repetir">
              Repetir contraseña
              <input
                required
                id="repetir"
                name="repetir"
                minLength="6"
                maxLength="15"
                className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                type="password"
                placeholder="Repite tu contraseña..."
                onChange={handleChange}
                value={user.repetir}
              />
            </label>

            {errors.contraseña && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.contraseña}</span>
                <br />
              </div>
            )}

            <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="rol">
              Rol
              <select
                required
                className="block w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
                id="rol"
                name="rol"
                onChange={handleChange}
                value={user.rol}
              >
                <option value="especialista">Especialista</option>
                <option value="coordinador">Coordinador</option>
                <option value="administrador">Administrador</option>
              </select>
            </label>
          </div>

          <div className={` ${user.rol === 'especialista' ? 'lg:w-1/2 p-3 lg:border-l lg:border-grey' : 'hidden'}`}>

            <div className="flex items-center">
              {Array.isArray(tarjetas) && tarjetas.length > 0 && (
                <div className='w-full'>
                  <CarouselSe servicios={tarjetas} onBorrar={handleEliminarServicio} />
                </div>
              )}
            </div>

            <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="especialidad">
              Especialidad
              <input

                pattern="^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$"
                id="especialidad"
                name="especialidad"
                minLength="3"
                maxLength="20"
                className={`w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 ${user.rol === 'especialista' ? '' : 'hidden'}`}
                type="text"
                placeholder="Especialidad..."
                value={user.especialidad}
                onChange={handleChange}
              />

            </label>

            <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="servicioId">Selecciona un servicio:
              <select id="servicioId" name="servicioId" required={user.rol === 'especialista'} 
              onChange={handleChangeTarjeta} value={tarjetaServicio.servicioId} 
              className="block w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2 ">
                <option value="0" disabled>Selecciona una opción</option>
                {Array.isArray(servicios) && servicios.length > 0 && servicios.map((servicio) => {
                  const estaEnTarjetas = tarjetas.some((tarjeta) => tarjeta.nombre === servicio.nombre);
                  return (
                    <option
                      key={servicio.uid}
                      value={servicio.uid}
                      disabled={estaEnTarjetas}
                    >
                      {servicio.nombre}
                    </option>
                  );
                })}
              </select>
            </label>

            {tarjetaServicio.servicioId !== "0" && (<div className="flex w-full justify-around my-4"><label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
              <input
                name="tipoSeleccionado"
                type="radio"
                value="porcentaje"
                className="mr-4"
                onChange={handleTipoChange}
              />
              Porcentaje
            </label>

              <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
                <input
                  className="mr-4"
                  name="tipoSeleccionado"
                  type="radio"
                  value="monto"
                  onChange={handleTipoChange}
                />
                Monto Fijo
              </label>
            </div>)}

            {tipoSeleccionado === 'porcentaje' && (
              <label htmlFor="porcentaje" className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"> Porcentaje
                <div className="relative" >
                  <BsPercent className=" absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                  <input
                    id="porcentaje"
                    name="porcentaje"
                    required={tipoSeleccionado === 'porcentaje'}
                    value={tarjetaServicio.porcentaje}
                    type="text"
                    onChange={handleChangeTarjeta}
                    className="block pl-10  w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  ></input>
                </div></label>)}
            {errorsTarjeta.porcentaje && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errorsTarjeta.porcentaje}</span>
                <br />
              </div>
            )}

            {tipoSeleccionado === 'monto' && (

              <label htmlFor="monto" className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">Monto Fijo
                <div className="relative" >
                  <BsCurrencyDollar className=" absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
                  <input
                    id="monto"
                    name="monto"
                    value={tarjetaServicio.monto}
                    type="text"
                    onChange={handleChangeTarjeta}
                    className="block pl-10  w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  ></input>
                </div></label>
            )}
            {errorsTarjeta.monto && (
              <div>
                <span className="text-end text-[#FF0000] mt-1 text-sm">{errorsTarjeta.monto}</span>
                <br />
              </div>
            )}


            {tipoSeleccionado !== '' && (<div className="flex justify-end">
              <button type="submit"
                onClick={handleAddServicioTarjeta}
                className="min-w-fit sm:text-xs mt-4 lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white">crear</button>
            </div>)}

          </div>
        </div>
        <div className='flex justify-around w-3/4 m-auto mt-8 mb-4'>
          <input type="submit" value={"Guardar"} className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"></input>
          <button type="button" onClick={() => props.setShowModal(false)} className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white" >
            Cancelar
          </button>
        </div>
      </form >
    </div >
  );
};

export default CrearUsuario;