import { useEffect, useRef, useState } from "react";
import { BiSearchAlt2 } from "react-icons/bi";

const Searchable = ({
  options,
  label,
  id,
  selectedVal,
  handleChange, 
  
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [optionsRender, setOptionsRender] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  useEffect(() => {
    setOptionsRender(options);
  },[])

  // useEffect(() => {
  //   setOptionsRender(options);
  // }, [arrayCentros])

  useEffect(() => {
    if(query === "") {
      setOptionsRender(options);
    }
    if (query.length > 2) {
      const centrosFiltrados = options.filter((option) => {
        return option.nombre.includes(query);
      });
      setOptionsRender(centrosFiltrados);
    }
    return () => {};
  }, [query]);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    e.preventDefault();
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  return (
    <div className="dropdown">
      <div className="control ">
        <div className="relative">
        <BiSearchAlt2 className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
          <input
            className="" 
            autoComplete="off"
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onClick={toggle}
            style={{ background: "#DDE8ED", border: "none", color: '#000', borderRadius: '0.375rem',
            paddingLeft: '40px', paddingBottom: '0.75rem', paddingRight: '1rem', paddingTop: '0.75rem',
            fontSize: '1.1rem', textTransform: 'capitalize',
            outline: 'none', width:'90%',
            transition: 'box-shadow 0.2s ease-in-out',
           }}
           onFocus={(e) => {
            e.target.style.boxShadow = '0 0 0 2px #00cecb'; 
          }}
          onBlur={(e) => {
            e.target.style.boxShadow = '0 0 0 0 '; 
          }}
          />
        </div>
        <div className={`arrow  bg-[#ffff] ${isOpen ? "open" : ""}`}
        style={{right: '50px'}}></div>
      </div>

      <div className={` options ${isOpen ? "open" : ""}`}>
        {optionsRender !== undefined &&
          optionsRender !== null &&
          optionsRender.map((option, index) => {
            return (
              <div
                onClick={() => selectOption(option)}
                className={`  option ${
                  option[label] === selectedVal ? "selected " : ""
                }`}
                key={`${id}-${index}`}
              >
                {`${option.nombre}`}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Searchable;