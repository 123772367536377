import { useState} from "react";
import Styles from "../styles/home.module.css";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
// imagenes
import Iconos from "./iconos";
import logo from "../../img/logo-skinner.svg";
import logoMini from "../../img/logo-miniatura.svg";
import usuarioImg from "../../img/user-circle.svg";
import { FaSignOutAlt } from "react-icons/fa";

import { useHookAuth } from "../../libs/firebase";
import { useHookUsuarios } from "../../libs/hookUsuarios";


const MenuDesktop = () => {
  const navigate = useNavigate();
  const { usuario, logout, handleMenuFull } = useHookAuth();
  const { handleSetUserSelect } = useHookUsuarios();
  handleSetUserSelect(usuario.correo);
  const [hideText, setHideText] = useState(true);
  const [smallSidebar, setSmallSidebar] = useState(true );
  const [submenuVisible, setSubmenuVisible] = useState(null);
  const menu = [
        {
            titulo: 'Calendario',
            imagen: 'calendario',
        },
        {
          titulo: 'Administración',
          imagen: 'tools',
          submenu: [
              {titulo: 'Disponibilidad', imagen: 'disponibilidad',funcion: ''},
              {titulo: 'Reportes', imagen: 'reportes',funcion: ''},
          ],
      },    
    ]
  /**
   * Funcion setea className del texto e imagen para que se pueda ocultar.
   * @author Laura Navarro
   */
  const toggleText = () => {
    setHideText(!hideText);
    setSmallSidebar(!smallSidebar);
    handleMenuFull();
  };

  /**
   * Funcion setea si el submenu es visible.
   * @author Laura Navarro
   */

  const handleToggleSubmenu = (index) => {
    if (submenuVisible === index) {
      setSubmenuVisible(null);
    } else {
      setSubmenuVisible(index);
    }
  };

  /**
   * Funciona que llama a la funcioLogOut y redirecciona a /login.
   * @author Victor Muñoz
   */

  const handleLogout = async () => {
    // getSesiones();
    try {
      await logout();
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * Funciona para navegar a una ruta.
   * @author Victor Muñoz
   */

  const handlerNavigate = (ruta) => {
      navigate(ruta)
  };

  return (
    <div className={`${Styles.sidebar} ${smallSidebar ? Styles.small : ""}`}>
      <div className={Styles.contenedor_superior_sidebar}>
        {/*logo y datos usuario imagen, nombre */}
        <div className={Styles.contenedor_logo_y_usuario}>
          <img
            onClick={toggleText}
            className={`${smallSidebar ? Styles.small_logo : Styles.logo}`}
            src={smallSidebar ? logoMini : logo}
            alt="Logo-Skinner"
          />
        </div>

        <div className={Styles.contenedor_logo_y_usuario}>
          <img
            className={Styles.usuario}
            src={usuarioImg}
            alt="imagen del usuario"
          />
          <span className={smallSidebar ? Styles.hidden : Styles.text}>
            {`${usuario.nombre} ${usuario.apellido}`}
          </span>
        </div>

              {/* inicio menu home */}
      <div className={Styles.menu}>
      {menu !== undefined && menu.map((item, index) => (
        <div className={Styles.menu_item} key={nanoid()}>
          {item.submenu ? (
            <button
              className={`${Styles.nav_btn} ${hideText ? Styles.hide_text : ""}`}
              onClick={() => handleToggleSubmenu(index)}
            >
              <Iconos opcion={item.imagen} />
              <span className={Styles.btn_text}>{item.titulo}</span>
            </button>
          ) : (
            <button
              className={`${Styles.nav_btn} ${hideText ? Styles.hide_text : ""}`}
              onClick={() => handlerNavigate(item.titulo) }
            >
              <Iconos opcion={item.imagen} />
              <span className={Styles.btn_text}>{item.titulo}</span>
            </button>
          )}

          {submenuVisible === index && item.submenu && (
            <div className={Styles.submenu}>
              {item.submenu.map((subItem, subIndex) => (
                <button
                  className={`${Styles.submenu_btn} ${Styles.nav_btn} ${hideText ? Styles.hide_text : ""}`}
                  onClick={() => handlerNavigate(subItem.titulo)}
                  key={subIndex}
                >
                  <Iconos opcion={subItem.imagen} />
                  <span className={Styles.btn_text}>{subItem.titulo}</span>
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
     
      {/* fin menu home */}
      </div>
      {/*fin logo y datos usuario imagen, nombre */}

      {/* log out */}
      <div className={Styles.contenedor_salir}>
        <button
          className={`${Styles.nav_btn} ${hideText ? Styles.hide_text : ""}`}
          onClick={handleLogout}
        >
          <FaSignOutAlt className={Styles.iconos} />
          <span className={Styles.btn_text}>Cerrar Sesión</span>
        </button>
      </div>
      {/*fin log out */}
    </div>
  );
};

export default MenuDesktop;

