import config from '../config';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { deleteObject } from 'firebase/storage';
import { getStorage, ref } from 'firebase/storage';
//atoms de recoil
import {
	userDataState,
	sesionesCitasState,
	dateRangeState,
	citaSelectedState,
	sesionSelectedState,
	dateInicioState,
	dateFinState,
	showFilterState,
	filtrosState,
	toogleState,
	filtrosDateState,
} from '../atoms/utilsAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { getDataFromApi } from './functions';

//firebase
//firebase
const storage = getStorage();
const fb = initializeApp(config.firebaseConfig);
const auth = getAuth(fb);


//////////////////////////////////////////HOOK CALENDARIO////////////////////////////////////////

const useHookCalendario = () => {
	const [sesionesCitas, setSesionesCitas] = useRecoilState(sesionesCitasState);
	const [citaSelected, setCitaSelected] = useRecoilState(citaSelectedState);
	const [sesionSelected, setSesionSelected] = useRecoilState(sesionSelectedState);
	const usuario = useRecoilValue(userDataState);
	const [showFilter, setShowFilter] = useRecoilState(showFilterState);
	const [dateRange, setDateRange] = useRecoilState(dateRangeState);
	const [dateInicio, setDateInicio] = useRecoilState(dateInicioState);
	const [dateFin, setDateFin] = useRecoilState(dateFinState);
	const [filtrosDate, setFiltrosDate] = useRecoilState(filtrosDateState);
	const [filtros, setFiltros] = useRecoilState(filtrosState);
	const [toogle, setToogle] = useRecoilState(toogleState);

	const handlerFiltros = (data) => {
		setFiltros(data);
	}

	const handlerFiltrosDate = (data) => {
		setFiltrosDate(data);
	}

	const handlerToogle = (data) => {
		setToogle(data);
	}
	
	const handlerDateInicio = (data) => {
		setDateInicio(data);
	}

	const handlerShowFilter = () => {
		setShowFilter(!showFilter);
	}

	const handlerDateFin = (data) => {
		setDateFin(data);
	}

	const handleSetSesionesCita = data => {
		
		setSesionesCitas(data);
	};

	const handleSetSesionSelected = data => {
		setSesionSelected(data);
	}

	const handleSetCitaSelected = data => {
		setCitaSelected(data);
	};

	const handleSetDateRange = data => {
		
		setDateRange(data);
		
	};

	const createCita = async datos => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'createCita';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			citaData: datos,
		};

		const res = await getDataFromApi(endPoint, token, data);

		if(res && res.status === 200) {
			return res.data
		} else {
			console.log("create cita error: ",res)
			return false
		}	
	};

	const editCita = async (citaId,parametro,value) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'editCita';

		const data = {
			clienteId: usuario.clienteId,
			uid: usuario.uid,
			citaId: citaId,
			parametro: parametro,
			valor: value
		};

		const res = await getDataFromApi(endPoint, token, data);
			
		if (res &&  res.status === 200 ) {
			return res.data;
		} else {
			console.log("edit cita error: ",res)
			return false;
		}
	};

	const getCita = async citaId => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'getCita';

		const data = {
			clienteId: usuario.clienteId,
			uid: usuario.uid,
			citaId: citaId,
		};

		const res = await getDataFromApi(endPoint, token, data);
		
		if (res && res.status === 200 ) {
			// console.log(res)
			return res.data;
		} else {
			console.log("get cita error: ",res)
		}
	};

	const findSesiones = async citaId => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'getSesiones';

		const data = {
			clienteId: usuario.clienteId,
			uid: usuario.uid,
			citaId: citaId,
		};
		const res = await getDataFromApi(endPoint, token, data);
	
		if (res && res.status  === 200 ) {
			return res.data
		} else {
			console.error('Error al obtener sesiones :', res);
			return false;
		}
	};

	const getSesiones = async (inicio, fin, filter) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'findSesiones';

		const data = {
			clienteId: usuario.clienteId,
			uid: usuario.uid,
			inicio: parseInt(inicio),
			fin: parseInt(fin),
			filtros: filter,
			campos: [
				'citaId',
				'nombre',
				'duracion',
				'centroId',
				'nombreCentro',
				'sala',
				'inicio',
				'fin',
				'especialista',
				'paciente',
				'servicio',
				'sesionId',
				'oldId',
			],
		};

		const res = await getDataFromApi(endPoint, token, data);
	
		if (res && res.status === 200 ) {
			if (Array.isArray(res.data)) {
				const respuesta = updateObjects(res.data);
				return respuesta;
			} else {
				return false;
			}
		} else {
			console.error('Error al Buscar sesiones:', res);
			return false;
		}

		function updateObjects(array) {
				//console.log(array)
			return array.map(item => {
				
				if(item.oldId){
				item.inicio = item.inicio-18000
				item.fin = item.fin-18000
				const paciente = item.paciente[0];
				item.nombrePaciente = paciente.nombre;
				item.apellidoPaciente = paciente.apellido;
				item.telefonoPaciente = paciente.telefono;
				item.pacienteIdPaciente = paciente.pacienteId;
				delete item.paciente;
				const servicio = item.servicio[0];
				item.nombreServicio = servicio?.nombre;
				item.servicioIdServicio = servicio?.servicioId;
				delete item.servicio;
				const especialista = item.especialista[0];
				item.nombreEspecialista = especialista.nombre;
				item.apellidoEspecialista = especialista.apellido;
				item.correoEspecialista = especialista.correo;
				delete item.especialista;
				return item;
				} else {
					const paciente = item.paciente[0];
				item.nombrePaciente = paciente.nombre;
				item.apellidoPaciente = paciente.apellido;
				item.telefonoPaciente = paciente.telefono;
				item.pacienteIdPaciente = paciente.pacienteId;
				delete item.paciente;
				const servicio = item.servicio[0];
				item.nombreServicio = servicio.nombre;
				item.servicioIdServicio = servicio.servicioId;
				delete item.servicio;
				const especialista = item.especialista[0];
				item.nombreEspecialista = especialista.nombre;
				item.apellidoEspecialista = especialista.apellido;
				item.correoEspecialista = especialista.correo;
				delete item.especialista;
				return item;
				}
				
			});
		}
	};

	const updateSesion = async (sesionId, parametro, valor) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'editSesion';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			sesionId: sesionId,
			parametro: parametro,
			valor: valor,
		};

		const res = await getDataFromApi(endPoint, token, data);
		
		if (res && res.status === 200) {
			return res.data;
		} else {
			console.error('Error EditSesion : ', res);
			return false;
		}
	};

	const getCountSesiones = async (inicio, fin, filter) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'countSesiones';

		const data = {
			clienteId: usuario.clienteId,
			uid: usuario.uid,
			inicio: parseInt(inicio),
			fin: parseInt(fin),
			filtros: filter,
		};
	
		const res = await getDataFromApi(endPoint, token, data);
		if (res && res.status === 200 ) {
			return res.data;
		} else {
			console.error('Error al Buscar sesiones:', res);
			return false;
		}

		
				
			
		
	};

	
	const updateCita = async (citaId, parametro, valor) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'editCita';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			citaId: citaId,
			parametro: parametro,
			valor: valor,
		};

		const res = await getDataFromApi(endPoint, token, data);
	
		if (res && res.status === 200) {
			return res.data;
		} else {
			console.error('Error EditCita : ', res);
			return false;
		}

	};

	const deleteCita = async (citaId) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'delCita';

		const data = {
			uid: usuario.uid,
			clienteId: usuario.clienteId,
			citaId: citaId,
		};
		const res = await getDataFromApi(endPoint, token, data);
		
		if (res && res.status === 200) {
			
			return res.data;
		} else {
			console.error('Error al eliminar cita :', res);
			return false;
		}
	};

	const handlerDeleteImages = async nameComplete => {
		
	
		// Create a reference to the file to delete
		const imageRef = ref(storage, `pagos/${nameComplete}`);

		// Delete the file
		deleteObject(imageRef)
			.then((res) => {
				// File deleted successfully
				return res
			})
			.catch(error => {
				console.log(error);
			});
	};

	return {
		createCita,
		editCita,
		getCita,
		updateSesion,
		getSesiones,
		findSesiones,
		sesionesCitas,
		dateRange,
		handleSetSesionesCita,
		handleSetDateRange,
		handleSetCitaSelected,
		handleSetSesionSelected,
		getCountSesiones,
		sesionSelected,
		citaSelected,
		updateCita,
		deleteCita,
		handlerDeleteImages,
		handlerDateFin,
		dateFin,
		handlerDateInicio,
		dateInicio,
		handlerShowFilter,
		showFilter,
		filtros,
		handlerFiltros,
		filtrosDate,
		handlerFiltrosDate,
		handlerToogle,
		toogle
	};
};

//////////////////////////////////////////////////////////////

export { useHookCalendario };