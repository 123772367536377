import config from '../config';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getDataFromApi } from './functions';
import  { toast } from 'react-toastify';

//atoms de recoil
import {
	userDataState,
	dateInicioState,
	dateFinState,
	filtrosState,
	filtrosState1,
	filtrosState2,
	filtrosState3,
	filtrosState4,
	filtrosState5,
	filtrosState6,
	filtrosState7,
	filtrosState8,
	filtrosDate1,
	filtrosDate2,
	filtrosDate3,
	filtrosDate4,
	filtrosDate5,
	filtrosDate6,
	filtrosDate7,
	filtrosDate8,
	acumuladoState,
} from '../atoms/utilsAtom';
import { useRecoilState, useRecoilValue } from 'recoil';

//firebase
const fb = initializeApp(config.firebaseConfig);
const auth = getAuth(fb);

//////////////////////////////////////////HOOK CALENDARIO////////////////////////////////////////

const useHookReportes = () => {
	const usuario = useRecoilValue(userDataState);
	const [acumulado, setAcumulado] = useRecoilState(acumuladoState);
	const [dateInicio, setDateInicio] = useRecoilState(dateInicioState);
	const [dateFin, setDateFin] = useRecoilState(dateFinState);
	const [filtros, setFiltros] = useRecoilState(filtrosState);
	const [filtros1, setFiltros1] = useRecoilState(filtrosState1);
	const [filtros2, setFiltros2] = useRecoilState(filtrosState2);
	const [filtros3, setFiltros3] = useRecoilState(filtrosState3);
	const [filtros4, setFiltros4] = useRecoilState(filtrosState4);
	const [filtros5, setFiltros5] = useRecoilState(filtrosState5);
	const [filtros6, setFiltros6] = useRecoilState(filtrosState6);
	const [filtros7, setFiltros7] = useRecoilState(filtrosState7);
	const [filtros8, setFiltros8] = useRecoilState(filtrosState8);
	const [filtroDate1, setFiltroDate1] = useRecoilState(filtrosDate1);
	const [filtroDate2, setFiltroDate2] = useRecoilState(filtrosDate2);
	const [filtroDate3, setFiltroDate3] = useRecoilState(filtrosDate3);
	const [filtroDate4, setFiltroDate4] = useRecoilState(filtrosDate4);
	const [filtroDate5, setFiltroDate5] = useRecoilState(filtrosDate5);
	const [filtroDate6, setFiltroDate6] = useRecoilState(filtrosDate6);
	const [filtroDate7, setFiltroDate7] = useRecoilState(filtrosDate7);
	const [filtroDate8, setFiltroDate8] = useRecoilState(filtrosDate8);

	const handlerAcumulado = (data) => {
		setAcumulado(data);
	}

	const handlerFiltroDate1 = (data) => {
		setFiltroDate1(data);
	}

	const handlerFiltroDate2 = (data) => {
		setFiltroDate2(data);
	}

	const handlerFiltroDate3 = (data) => {
		setFiltroDate3(data);
	}

	const handlerFiltroDate4 = (data) => {
		setFiltroDate4(data);
	}

	const handlerFiltroDate5 = (data) => {
		setFiltroDate5(data);
	}

	const handlerFiltroDate6 = (data) => {
		setFiltroDate6(data);
	}

	const handlerFiltroDate7 = (data) => {
		setFiltroDate7(data);
	}

	const handlerFiltroDate8 = (data) => {
		setFiltroDate8(data);
	}
	
	const handlerFiltros = (data) => {
		setFiltros(data);
	}

	const handlerFiltros1 = (data) => {
		setFiltros1(data);
	}
	
	const handlerFiltros2 = (data) => {
		setFiltros2(data);
	}

	const handlerFiltros3 = (data) => {
		setFiltros3(data);
	}

	const handlerFiltros4 = (data) => {
		setFiltros4(data);
	}

	const handlerFiltros5 = (data) => {
		setFiltros5(data);
	}

	const handlerFiltros6 = (data) => {
		setFiltros6(data);
	}

	const handlerFiltros7 = (data) => {
		setFiltros7(data);
	}

	const handlerFiltros8 = (data) => {
		setFiltros8(data);
	}

	const handlerDateInicio = (data) => {
		setDateInicio(data);
	}

	const handlerDateFin = (data) => {
		setDateFin(data);
	}


	const getReport = async (inicio, fin, reporte, bool, filtros ) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'reportSesiones';

		const data = {
			clienteId: usuario.clienteId,
            uid: usuario.uid,
            inicio: parseInt(inicio),
            fin: parseInt(fin),
            reporte: parseInt(reporte),
            descargar: bool,
            filtros:filtros
		};

		const res = await getDataFromApi(endPoint, token, data);

		if (res && res.status === 200) {
			return res.data
		} else {
			console.log("Error getReport : ",res.data)
			toast.error('ha ocurrido un error', {
				position: 'top-center',
				theme: 'colored',
			});
			return false
		}
	};

	const getReportPacientes = async (inicio, fin, reporte, bool, filtros ) => {
		const token = await auth.currentUser.getIdToken();
		const endPoint = 'reportPacientes';

		const data = {
			clienteId: usuario.clienteId,
            uid: usuario.uid,
            inicio: parseInt(inicio),
            fin: parseInt(fin),
            reporte: parseInt(reporte),
            descargar: bool,
			filtros:filtros
		};
		
		const res = await getDataFromApi(endPoint, token, data);
		
		if (res && res.status === 200) {
			return res.data
		} else {
			console.log("Error reportPacientes : ",res.data)
			toast.error('ha ocurrido un error', {
				position: 'top-center',
				theme: 'colored',
			});
			return false
		}
	};



	return {
		getReport,
		getReportPacientes,
		dateInicio,
		dateFin,
		handlerDateFin,
		handlerDateInicio,
		filtros,
		handlerFiltros,
		filtros1,
		handlerFiltros1,
		filtros2,
		handlerFiltros2,
		filtros3,
		handlerFiltros3,
		filtros4,
		handlerFiltros4,
		filtros5,
		handlerFiltros5,
		filtros6,
		handlerFiltros6,
		filtros7,
		handlerFiltros7,
		filtros8,
		handlerFiltros8,
		filtroDate1,
		handlerFiltroDate1,
		filtroDate2,
		handlerFiltroDate2,
		filtroDate3,
		handlerFiltroDate3,
		filtroDate4,
		handlerFiltroDate4,
		filtroDate5,
		handlerFiltroDate5,
		filtroDate6,
		handlerFiltroDate6,
		filtroDate7,
		handlerFiltroDate7,
		filtroDate8,
		handlerFiltroDate8,
		acumulado,
		handlerAcumulado,
	};
};

//////////////////////////////////////////////////////////////

export {useHookReportes };