import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHookUsuarios } from "../../../libs/hookUsuarios";
import { nanoid } from "nanoid";
import { FaUserPlus } from "react-icons/fa";
import { FaTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import UpdateUsuario from './UpdateUsuario'
import EliminarUsuario from './EliminarUsuario'
import CrearUsuario from './CrearUsuario'
import VerUsuario from './VerUsuario';
import {BiCalendar} from "react-icons/bi";
import Paginacion from "../../../components/paginacion";
import PopUp from '../../../components/PopUp';

let Usuarios = () => {
  const navigate = useNavigate();
  const { getUsuarios, updateUser, handleSetUserSelect} = useHookUsuarios();
  const [usuarios, setUsuarios] = useState(null);
  const usuarioSelectedCorreo = useRef(null)
  const isFirstRender = useRef(true);
  const [nombreUsuarioEliminar, setNombreUsuarioEliminar] = useState('');

  const [showModalEditar, setShowModalEditar] = useState(false)
  const [showModalCrear, setShowModalCrear] = useState(false)
  const [showModalEliminar, setShowModalEliminar] = useState(false)
  const [showModalVer, setShowModalVer] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  const handleGetUsuarios = async () => {

    await getUsuarios().then((data) => {
      let array = data.sort((a, b) => a.nombre.localeCompare(b.nombre));
      setUsuarios(array)
      setIsLoading(false)
    })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {

    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleGetUsuarios();
    }

  }, [handleGetUsuarios])

  const handleOnEditar = (usuarioCorreo) => {
    usuarioSelectedCorreo.current = usuarioCorreo;
    setShowModalEditar(true)
  }

  const handleOnDisponiblidad = (usuarioCorreo) => {
    usuarioSelectedCorreo.current = usuarioCorreo;
    handleSetUserSelect(usuarioSelectedCorreo.current);
    navigate('Disponibilidad')
    //setShowModalEditar(true)
  }

  const handleOnEliminar = (usuarioCorreo, nombre, apellido) => {
    setNombreUsuarioEliminar(`${nombre} ${apellido}`)
    usuarioSelectedCorreo.current = usuarioCorreo;
    setShowModalEliminar(true)
  }

  const handleOnVer = (usuarioCorreo) => {
    usuarioSelectedCorreo.current = usuarioCorreo;
    setShowModalVer(true)
  }

  const handleUpdateActivo = async (activo, correo) => {
    await updateUser(correo, "activo", !activo) 
    handleGetUsuarios();
  }

  //PAGINACION:
  const [paginaActual, setPaginaActual] = useState(1);
  const usuariosPorPagina = 10;
  const numeroTotalPaginas = Array.isArray(usuarios) ? Math.ceil(usuarios?.length / usuariosPorPagina) : 1;

  const handlePaginaCambio = (numeroPagina) => {
    setPaginaActual(numeroPagina);
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < numeroTotalPaginas) {
      setPaginaActual(paginaActual + 1);
    }
  };

  const closeModalEditar = () => {
    setShowModalEditar(false)
    setIsLoading(true)
    handleGetUsuarios();
  }

  const closeModalVer = () => {
    setShowModalVer(false)
  }

  const closeModalCrear = () => {
    setShowModalCrear(false)
  }

  const closeModalEliminar = () => {
    setShowModalEliminar(false)
  }

  return (
    <div className="w-full h-full bg-azure overflow-x-auto" key={nanoid()}>

      {showModalEditar && (<PopUp closeFunction={closeModalEditar}>
              <UpdateUsuario setShowModal={setShowModalEditar} usuarioCorreo={usuarioSelectedCorreo.current} getUsuarios={handleGetUsuarios} />
              </PopUp>)}

      {showModalVer && (<PopUp closeFunction={closeModalVer}>
              <VerUsuario usuarioCorreo={usuarioSelectedCorreo.current} />
              </PopUp>)}

      {showModalEliminar && (<PopUp closeFunction={closeModalEliminar}>
              <EliminarUsuario nombre={nombreUsuarioEliminar} usuarioCorreo={usuarioSelectedCorreo.current} setShowModal={setShowModalEliminar} getUsuarios={handleGetUsuarios} setIsLoading={setIsLoading} />
            </PopUp>)}

      {showModalCrear && (<PopUp closeFunction={closeModalCrear}>
              <CrearUsuario setShowModal={setShowModalCrear} getUsuarios={handleGetUsuarios} setIsLoading={setIsLoading} />
            </PopUp>)}
        {/* inicio lista de usuarios */}
      <div id="last-users" className="w[98%] m-4  bg-lightgrey rounded-md  p-4" key={nanoid()}>
        <div className="flex justify-between">
          <h1 className="text-3xl  text-center font-semibold text-gray-700 m-4">
            Lista de usuarios
          </h1>

          <button
            onClick={() => setShowModalCrear(true)}
            className="min-w-fit items-center m-4 flex sm:text-xs lg:text-xl md:text-md px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
          >
            <FaUserPlus className="mr-2 w-8" /> <span> Añadir Usuario</span>
          </button>
        </div>

        <div className="overflow-x-auto">
          <table className="m-auto whitespace-nowrap w-[98%] rounded-md">
            <thead className=" ">
              <tr>
                <th className="text-left py-3 px-2">Nombres y Apellidos</th>
                <th className="text-left py-3 px-2">Rol</th>
                <th className="text-left py-3 px-2">Correo Electrónico</th>
                <th className="text-center py-3 px-2">Activo</th>
                <th className="text-left py-3 px-2"></th>
              </tr>
            </thead>

            <tbody>
              {!Array.isArray(usuarios) || isLoading ? (

                Array.from({ length: (usuariosPorPagina / 2) }, (_, index) => (
                  <tr key={index} className="bg-azure border-lightgrey border-b-8">
                    <td colSpan={5} className="py-3 px-2">
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                      </div>
                    </td>
                  </tr>
                ))

              ) : (

                Array.isArray(usuarios) && !isLoading &&
                usuarios.slice((paginaActual - 1) * usuariosPorPagina, paginaActual * usuariosPorPagina).map((usuario, index) => (

                  <tr key={index} className="bg-azure border-lightgrey border-b-8 hover:bg-aqua hover:text-white cursor-pointer"  >

                    <td className="py-3 px-2  capitalize rounded-l-[10px]" onClick={() => handleOnVer(usuario.correo)}
                    >{`${usuario.nombre} ${usuario.apellido}`} </td>
                    <td className="py-3 px-2 capitalize" onClick={() => handleOnVer(usuario.correo)}>
                      {usuario.rol}</td>
                    <td className="py-3 px-2" onClick={() => handleOnVer(usuario.correo)}>
                      {usuario.correo}</td>
                    <td className="py-5 px-2 flex justify-center items-center rounded-r-[10px]">
                      <input
                        className=''
                        type="checkbox"
                        checked={usuario.activo}
                        value={usuario.activo ? 'true' : 'false'}
                        onChange={() => handleUpdateActivo(usuario.activo, usuario.correo)}
                      /></td>
                    <td className="py-3 px-2 rounded-r-md">
                      <div className='flex justify-around space-x-2' key={nanoid()}>
                        
                        {usuario.rol !== 'especialista' ? (<div> </div>): (<button type="button" title="Disponibilidad" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => handleOnDisponiblidad(usuario.correo)}><BiCalendar /></button>)}
                        <button type="button" title="Edit" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => handleOnEditar(usuario.correo)}><FiEdit /></button>
                        <button type="button" className="text-red hover:text-darkred" onClick={() => handleOnEliminar(usuario.correo, usuario.nombre, usuario.apellido)}><FaTrashCan /></button>
                      </div>
                    </td>
                  </tr>

                )))}

            </tbody>
          </table>
        </div>
        <Paginacion
          currentPage={paginaActual}
          totalPages={numeroTotalPaginas}
          onPageChange={handlePaginaCambio}
          onPrevPage={handlePaginaAnterior}
          onNextPage={handlePaginaSiguiente}
        />
      </div>
    </div>
  );
};

export default Usuarios;
