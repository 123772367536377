import React, { useState, useRef, useEffect } from "react";
import { useHookServicios } from "../../../libs/hookServicios";
import { nanoid } from "nanoid";
import CarouselSesion from "../../../components/CarouselSesion";
import solesImg from '../../../img/simbolo-de-moneda-nuevo-sol-de-peru.png';

const VerServicio = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { getServicio } = useHookServicios();
  const [servicioSelected, setServicioSelected] = useState(null)
  const isFirstRender = useRef(true);

  const handleGetServicio = async (servicioId) => {

    try {
      const servicioData = await getServicio(servicioId);
      setServicioSelected(servicioData)
      setIsLoading(false)
    }
    catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleGetServicio(props.servicioId)
    };
  }, [handleGetServicio, props.servicioId]);

  return (
    <div key={nanoid()}>
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">
        DATOS SERVICIO
      </h3>

      <form className="w-full">
        <div className="flex flex-col lg:flex-row w-[70vw]">
          <div className="lg:w-1/2 p-3 w-full">
            <label
              className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
              htmlFor="nombre"
            >
              Nombre
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<input
                disabled
                id="nombre"
                name="nombre"
                className="capitalize w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                value={servicioSelected.nombre}
              />)}
            </label>

            <label
              className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
              htmlFor="descripcion"
            >
              Descripción
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<input
                disabled
                id="descripcion"
                name="descripcion"
                className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                placeholder="Descripción del servicio..."
                value={servicioSelected.descripcion}
              />)}
            </label>

            <label
              className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
              htmlFor="precio"
            >
              Precio
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<div className="relative">
                <img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />
                <input
                  disabled
                  id="precio"
                  name="precio"
                  className="block pl-10 w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  value={servicioSelected.precio}
                />
              </div>)}
            </label>

            <label
              className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
              htmlFor="precioMinimo"
            >Precio mínimo
              {isLoading ? (<div role="status" className="w-full animate-pulse">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
              </div>) : (<div className="relative">
                <img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />
                <input
                  disabled
                  id="precioMinimo"
                  name="precioMinimo"
                  className="block pl-10  w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  value={servicioSelected.precioMinimo}
                />
              </div>)}
            </label>
          </div>

          <div className="lg:w-1/2 w-full p-3 lg:border-l lg:border-grey sm:w-full ">
            <h2 className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2">
              Sesiones: {servicioSelected && Array.isArray(servicioSelected.sesiones) ? servicioSelected.sesiones.length : '0'}
            </h2>
            {isLoading ? (<div role="status" className="w-full animate-pulse">
              <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
            </div>) :
              Array.isArray(servicioSelected.sesiones) ? (
   
                    <div className="flex items-center">
                      {Array.isArray(servicioSelected.sesiones) && servicioSelected.sesiones.length > 0 && (
                        <div className='w-full'>
                          <CarouselSesion sesiones={servicioSelected.sesiones} edicion={true} />
                        </div>
                      )}
                    </div>

              ) : (
                ""
              )}

          </div>

        </div>

      </form>
    </div>
  );
};

export default VerServicio;
