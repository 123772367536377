import React from 'react'
import { useHookUsuarios } from "../../../libs/hookUsuarios";


const EliminarUsuario = (props) => {
  const { deleteUser } = useHookUsuarios();

  let handleEliminar = async () => {
    props.setIsLoading(true);
    props.setShowModal(false);
    await deleteUser(props.usuarioCorreo);
    props.getUsuarios();
  }

  return (

    <div className="w-[35vw] justify-center">
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">ELIMINAR USUARIO</h3>

      <div className='justify-center py-10 text-center'>
        <span className='inline-block' >¿Desea eliminar el usuario: <p className='inline-block capitalize'>{props.nombre}</p>?</span>
      </div>

      <div className='flex justify-around w-3/4 m-auto mt-8 mb-4'>
        <button type="submit" onClick={handleEliminar} className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white">Eliminar</button>
      </div>



    </div>
  );
}

export default EliminarUsuario;