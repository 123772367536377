import config from "../config";
import { initializeApp } from "firebase/app";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import { getDataFromApi } from "./functions";

//atoms de recoil
import { userDataState } from "../atoms/utilsAtom";
import { useRecoilState} from "recoil";

//firebase
const fb = initializeApp(config.firebaseConfig);
const auth = getAuth(fb);

//////////////////////////////////////////HOOK SERVICIOS////////////////////////////////////////

const useHookServicios = () => {

  const [usuario, setUsuario] = useRecoilState(userDataState);

  const handleSetUsuario = (data) => {
    setUsuario(data)
  }

  const createServicio = async (nombre, descripcion, precio, precioMinimo,
    sesiones,
  ) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "createServicio";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      servicioData: {
        clienteId: usuario.clienteId,
        nombre: nombre,
        descripcion: descripcion,
        precio: precio,
        precioMinimo: precioMinimo,
        sesiones: sesiones
      },
    };

    const res = await getDataFromApi(endPoint, token, data);
    if (res && res.status === 200) {
      if (res && res.data === "servicio creado correctamente") {
        toast.success(`${res.data}`, {
          position: "top-center",
          theme: "colored",
        })
        return res.data;
      } else {
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
        return false
      }
    } else {
      console.log("error creando servicio", res);
      return false
    }
  };

  const deleteServicio = async (servicioId) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "delServicio";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      servicioId : servicioId,
    };
    const res = await getDataFromApi(endPoint, token, data);
    if(res && res.status === 200){
      if (res && res.data === "servicio eliminado correctamente") {
        toast.success(`${res.data}`, {
          position: "top-center",
          theme: "colored",
        })
        return res.data
      } else {
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
        return false
      }
    } else {
      console.log("error eliminando servicio", res)
      return false
    }
  };

  const getServicio = async (servicioId) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getServicio";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      servicioId: servicioId,
    };

    const res = await getDataFromApi(endPoint, token, data);
    if(res && res.status === 200){
      if (typeof res === "object") {
        return res.data;
      } else {
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
        return false;
      }
    } else {
      console.log("error al obtener servicio", res)
      return false
    }
  };

  const updateServicio = async (
    parametro,
    valor,
    uid,
  ) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "editServicio";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      servicioId: uid,
      parametro: parametro,
      valor: valor,
    };

    const res = await getDataFromApi(endPoint, token, data);
    if (res && res.status === 200) {
      if (res && res.data === "servicio actualizado correctamente") {
        return res.data
      } else {
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
        return false
      }
    } else {
      console.log("error editando servicio", res)
      return false
    }
      
    
  };

  const getServicios = async () => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getServicios";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
    };

    const res = await getDataFromApi(endPoint, token, data);
    if (res && res.status === 200) {
      if (Array.isArray(res.data)) {
        return res.data
      } else {
        return []
      }
    } else {
      console.error('Error al obtener servicios:', res);
			return false;
    }
  };

  const getServicioEspecialistas = async (uid) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getEspecialistasPorServicio";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      servicioId: uid,
    };
    const res = await getDataFromApi(endPoint, token, data);
    
    if (res && res.status === 200) {
      if (Array.isArray(res.data)) {
        return res.data
      } else {
        return []
      }
    } else {
      console.error('Error al obtener especialista por servicios:', res);
      return false;
    }
    
      
  };

  return {
    createServicio,
    getServicios,
    getServicio,
    deleteServicio,
    updateServicio,
    handleSetUsuario,
    getServicioEspecialistas,
  };
};

export { useHookServicios };
