import React from "react";
import { FaTrashCan } from "react-icons/fa6";

const CardServicio = ({ servicio, onBorrar, edicion = false, seleccionado }) => {
    function handleClickInsideCard(e) {
        e.stopPropagation();
    }

    return (
        <>
            <h1 className="uppercase mb-2" onClick={(e) => handleClickInsideCard(e)}>{`${servicio.nombre}`} </h1>
            <span className="mb-2 flex" onClick={(e) => handleClickInsideCard(e)}>
                {`${servicio.porcentaje !== "" ? 'Porcentaje:' : 'Monto fijo:'}`}
                <p className="ml-2" onClick={(e) => handleClickInsideCard(e)}>
                    {`${servicio.porcentaje !== "" ? ("%" + servicio.porcentaje) : ("$" + servicio.monto)}`}
                    </p></span>
            
            {!edicion && (servicio.servicioId === seleccionado) && <button type="button" className="text-red hover:text-darkred absolute bottom-4 right-4" onClick={(e)=>{
                onBorrar()
                handleClickInsideCard(e)}}><FaTrashCan /></button>}
        </>

    )
}

export default CardServicio;