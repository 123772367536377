import React, { useEffect, useRef, useState } from "react";
import CardSesion from '../pages/home/adminServicios/CardSesion'
import './estiloSe.css'

const CarouselSesion = ({ sesiones, seleccionarSesion, edicion = false }) => {
  
  const ulRef = useRef(null);

  const asignarPosiciones = (array) => {

    const length = array.length;
    const positions = [];

    if (length === 1) {
      positions.push(0);
    } else if (length === 2) {
      positions.push(0, 1);
    } else if (length === 3) {
      positions.push(0, 1, -1);
    } else if (length === 4) {
      positions.push(0, 1, -1, 'oculto');
    } else if (length === 5) {
      positions.push(0, 1, 2, -2, -1);
    } else if (length > 5) {
      positions.push(0, 1, 2);
      for (let i = 3; i < length - 2; i++) {
        positions.push('oculto');
      }
      positions.push(-2, -1);
    }

    for (let i = 0; i < length; i++) {
      array[i].pos = positions[i];
    }
    
    return array;
  }

  const [arrayCarrusel, setArrayCarrusel] = useState(asignarPosiciones(sesiones))
  const [seleccionado, setSeleccionado] = useState(arrayCarrusel[0].nombre)

  const reasignarPosicion = (itemIndex, index) => {
    let newPos;

    switch (arrayCarrusel.length) {
      case 1:
        if (itemIndex === index) {
          newPos = 0
        }
        break;
      case 2:
        if (itemIndex === index) {
          newPos = 0
        } else {
          newPos = 1
        }
        break;
      case 3:
        if (index === 0) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === 1) {
            newPos = 1
          } else if (itemIndex === 2) {
            newPos = -1
          }
        } else if (index === 1) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === 2) {
            newPos = 1
          } else if (itemIndex === 0) {
            newPos = -1
          }
        } else if (index === 2) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === 1) {
            newPos = -1
          } else if (itemIndex === 0) {
            newPos = 1
          }
        }
        break;
      case 4:
        if (index === 0) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === index + 1) {
            newPos = 1
          } else if (itemIndex === arrayCarrusel.length - 1) {
            newPos = -1
          }
          else {
            newPos = "oculto";
          }
        } else if (index === 3) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === arrayCarrusel.length - arrayCarrusel.length) {
            newPos = 1
          } else if (itemIndex === index - 1) {
            newPos = -1
          } else {
            newPos = "oculto";
          }
        } else {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === index + 1) {
            newPos = 1
          } else if (itemIndex === index - 1) {
            newPos = -1
          } else {
            newPos = "oculto";
          }
        }
        break;
      default:
        if (index === 0) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === index + 1) {
            newPos = 1
          } else if (itemIndex === index + 2) {
            newPos = 2
          } else if (itemIndex === arrayCarrusel.length - 1) {
            newPos = -1
          } else if (itemIndex === arrayCarrusel.length - 2) {
            newPos = -2
          } else {
            newPos = "oculto";
          }
        } else if (index === 1) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === index + 1) {
            newPos = 1
          } else if (itemIndex === index + 2) {
            newPos = 2
          } else if (itemIndex === index - 1) {
            newPos = -1
          } else if (itemIndex === arrayCarrusel.length - 1) {
            newPos = -2
          } else {
            newPos = "oculto";
          }
        } else if (index === arrayCarrusel.length - 1) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === 0) {
            newPos = 1
          } else if (itemIndex === 1) {
            newPos = 2
          } else if (itemIndex === arrayCarrusel.length - 2) {
            newPos = -1
          } else if (itemIndex === arrayCarrusel.length - 3) {
            newPos = -2
          } else {
            newPos = "oculto";
          }
        } else if (index === arrayCarrusel.length - 2) {
          if (itemIndex === index) {
            newPos = 0
          } else if (itemIndex === arrayCarrusel.length - 1) {
            newPos = 1
          } else if (itemIndex === 0) {
            newPos = 2
          } else if (itemIndex === arrayCarrusel.length - 3) {
            newPos = -1
          } else if (itemIndex === arrayCarrusel.length - 4) {
            newPos = -2
          } else {
            newPos = "oculto";
          }
        } else {
          if (itemIndex === index) {
            newPos = 0;
          } else if (itemIndex === (index + 1)) {
            newPos = 1;
          } else if (itemIndex === (index + 2)) {
            newPos = 2;
          } else if (itemIndex === (index - 1)) {
            newPos = -1;
          } else if (itemIndex === (index - 2)) {
            newPos = -2;
          } else {
            newPos = "oculto";
          }
        }
    }
    return newPos;
  }

  const handleLista = (newActive, nombre) => {
    setSeleccionado(nombre)
    const cards = Array.from(ulRef.current.children)
    const newActiveIndex = cards.indexOf(newActive);

    cards.forEach(item => {
      let itemIndex = cards.indexOf(item);
      item.dataset.pos = reasignarPosicion(itemIndex, newActiveIndex)
    });
   
    
  };

  useEffect(() => {
    const nuevoSeleccionado = sesiones[0].nombre;
    setSeleccionado(nuevoSeleccionado)
    const newArrayCarrusel = asignarPosiciones(sesiones);
    setArrayCarrusel(newArrayCarrusel);
  }, [sesiones]);


  return (
    <div>
      <div className="carousel">
        <ul className="carousel__list" ref={ulRef}>
          {arrayCarrusel.map((value, index) => (
            <li key={index} className="carousel__se" data-pos={value.pos}
              onClick={(e) => handleLista(e.target, value.nombre)}>
              <CardSesion
                sesion={value}
                edicion={edicion}
                onBorrar={()=> seleccionarSesion(value, "borrar")} 
                onEditar={()=> seleccionarSesion(value, "editar")} 
                seleccionado={seleccionado}
                />
            </li>))}
        </ul>
      </div>
      <div>
      </div>
    </div>

  )
}
export default CarouselSesion;
