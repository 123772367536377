import React, { useRef, useCallback, useEffect, useState } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts';
import { useNavigate } from 'react-router-dom';
import { useHookAuth } from '../../../libs/firebase';
import { useHookReportes } from '../../../libs/hookReportes';
import GearConfig from '../../../components/GearConfig';

const GaleryCards = ({
	grafCitas,
	series,
	dataSet,
	ingresosEspecialistas,
	costosEspecialistas,
	lengthIngresos,
	handlerShowFilter,
	pagosEspecialistas,
	checked,
	loadingCentros,
	loadingServicios,
	loadingEspecialistas,
}) => {
	const navigate = useNavigate();
	const { usuario } = useHookAuth();
	const { filtroDate1, filtroDate2, filtroDate3 } = useHookReportes();
	const [dataSet2, setDataSet2] = useState([]);
	let heightValue = usuario.rol === 'especialista' ? 80 : lengthIngresos;
	let heightValue2 = lengthIngresos === 80 ? 80 : lengthIngresos * 2;
	// function capitalizeString(str) {
	// 	const words = str.split(' ');
	// 	const palabras = words.map(word => {
	// 		return word[0].toUpperCase() + word.slice(1);
	// 	});
	// 	return palabras.join(' ');
	// }
	const crearObjetoSeries = async (data, data2) => {
		let series = [];
		for (const item of data) {
			const [email, value] = Object.entries(item)[0];
			for (const item2 of data2) {
				const [email2, value2] = Object.entries(item2)[0];
				if (email === email2) {
					series.push({ cita: value2, especialista: value, correo: email });
				}
			}
		}
		setDataSet2(series);
	};

	const handleReportesClick = ruta => {
		if (usuario.rol !== 'coordinador') {
			handlerNavigate(ruta);
		} else {
			handlerNavigate('Citashoy');
		}
	};

	const handlerNavigate = useCallback(
		ruta => {
			navigate(ruta);
		},
		[navigate]
	);

	const isFirstRender = useRef(true);
	const size = {
		width: 340,
		height: 160,
	};

	const chartSetting = {
		yAxis: [
			{
				label: 'Citas',
			},
		],
		width: 600,
		height: 280,
		sx: {
			[`.${axisClasses.left} .${axisClasses.label}`]: {
				transform: 'rotate(-90deg) translate(0px, -30px)',
			},
		},
	};

	const chartSetting2 = {
		xAxis: [
			{
				label: 'Ingresos',
			},
		],
		width: 1000,
		height: heightValue,
	};

	const chartSetting3 = {
		xAxis: [
			{
				label: 'Ingresos',
			},
		],
		width: 1000,
		height:  heightValue2,
	};

	const valueFormatter = value => `S/. ${value}`;

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			crearObjetoSeries(ingresosEspecialistas, costosEspecialistas);
			
		}
	}, [costosEspecialistas]);

	function formatDate(fecha) {
		// Obtenemos el día, el mes y el año de la fecha
		if(fecha !== undefined)
		{
		  const dia = fecha.getDate();
		const mes = fecha.getMonth() + 1;
		const anyo = fecha.getFullYear();
			// Formateamos la fecha
			return `${dia}/${mes}/${anyo}`;
		} else {
		  return "";
		}
	}

	return (
		<div className="py-1 sm:py-8 lg:py-12">
			<div className="mx-auto max-w-screen-2xl px-4 md:px-8">
				{/* <div className="mb-4 flex items-center justify-between gap-8 sm:mb-8 md:mb-12">
					<div className="flex items-center gap-12 mx-auto">
						<h2 className="text-2xl font-bold text-gray-800 lg:text-3xl">
							Estadisticas
						</h2>
					</div>
				</div> */}

				<div className="grid grid-cols-5 gap-4 md:gap-6 xl:gap-8 max-sm:grid-cols-1 max-md:grid-cols-1 max-lg:grid-cols-1">
					{/* image - start */}

					{loadingCentros ? (
						<div className="justify-center group relative flex h-[350px] items-center overflow-hidden rounded-lg bg-gray-100 shadow-lg  bg-white col-span-2 py-4  ">
							<div className="flex-col items-center">
								<p className="text-2xl font-medium text-center mx-auto mb-12 mt-0">
									Citas por centro
								</p>
								<div className="flex justify-center items-center">
									<div className=" rounded-full flex justify-center items-center h-[180px] w-[180px] bg-greymedium  animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
									<div className="m-10 flex justify-center items-center h-[120px] w-[120px] bg-greymedium rounded-sm  animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
								</div>
							</div>
						</div>
					) : (
						<div className="justify-center group relative flex h-auto items-center overflow-hidden rounded-lg bg-gray-100 shadow-lg  bg-white col-span-2 py-4 hover:scale-[1.02]">
							<GearConfig
								handlerShowFilter={handlerShowFilter}
								data={'centros'}
							/>
							<div
								className="flex-col items-center mb-4 hover:cursor-hand"
								onClick={() => handleReportesClick('ReporteCitas')}
							>
								<p className="text-2xl font-medium text-center mx-auto mb-7 ">
									Citas por centro
								</p>
								<div className="flex justify-center items-center h-[280px] ">
									<PieChart
										margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
										series={[
											{
												data: grafCitas,
												innerRadius: 40,
												cx: 85,
											},
										]}
										sx={{
											'--ChartsLegend-rootOffsetX': ' -160px',
											'--ChartsLegend-rootOffsetY': ' 0px',
										}}
										{...size}
									/>
								</div>
							</div>
							{filtroDate1 && <div className='text-white absolute bottom-0 left-0 right-0 flex justify-center bg-darkblue rounded-b-lg text-base'>{`${formatDate(filtroDate1[0])} - ${formatDate(filtroDate1[1])}`}</div>}
						</div>
					)}
					{/* image - end */}
					{/* image - start */}
					{loadingServicios ? (
						<div className="justify-center group relative flex h-[350px] items-center overflow-hidden rounded-lg bg-gray-100 shadow-lg  bg-white col-span-3  py-4">
							<div className="flex-col items-center">
								<p className="text-2xl font-medium text-center mx-auto mb-12 mt-0">
									Servicios agendados
								</p>
								<div className="flex justify-center items-center">
									<div className=" rounded-sm flex justify-center items-center h-[180px] w-[450px] bg-greymedium  animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
								</div>
							</div>
						</div>
					) : (
						<div className="group relative flex h-auto items-center justify-center overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-3  bg-white sm:col-span-5 py-4  transition-all hover:scale-[1.02]">
							<GearConfig
								handlerShowFilter={handlerShowFilter}
								data={'servicios'}
							/>
							<div
								className="flex-col items-center mb-4 hover:cursor-hand"
								onClick={() => handleReportesClick('ReporteServicios')}
							>
								<p className="text-2xl font-medium text-center mx-auto mb-7 ">
									Servicios agendados
								</p>
								<div className="flex justify-center items-center h-[280px] mb-5">
									<BarChart
										dataset={dataSet}
										margin={{ top: 20, left: 50, right: 250, bottom: 20 }}
										series={series}
										xAxis={[
											{
												scaleType: 'band',
												data: ['Servicios'],
												categoryGapRatio: 0.1,
												barGapRatio: 0.1,
											},
										]}
										{...chartSetting}
										legend={{
											direction: 'column',
											position: {
												vertical: 'top',
												horizontal: 'right',
											},
										}}
										sx={{
											'--ChartsLegend-rootOffsetX': '10px',
											'--ChartsLegend-rootOffsetY': '220px',
											'--ChartsLegend-itemMarkSize': '16px',
											'--ChartsLegend-labelSpacing': '4px',
											'--ChartsLegend-rootSpacing': '3px',
										}}
									/>
								</div>
							</div>
							{filtroDate2 && <div className='text-white absolute bottom-0 left-0 right-0 flex justify-center bg-darkblue rounded-b-lg text-base'>{`${formatDate(filtroDate2[0])} - ${formatDate(filtroDate2[1])}`}</div>}
						</div>
					)}
					{/* image - end */}
					{/* image 3a/3 - start */}
					{checked === 'citas' && (
						<div className="group relative flex h-auto items-center justify-center overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-5  bg-white sm:col-span-5 py-4  transition-all hover:scale-[1.02]">
							{!loadingEspecialistas && dataSet2.length > 0 ? (
								<div>
									<GearConfig
										handlerShowFilter={handlerShowFilter}
										data={'citas'} />
									<div
										className="flex-col items-center hover:cursor-hand mb-3"
										onClick={() => handleReportesClick('ReporteIngresos')}
									>
										<p className="text-2xl font-medium text-center mx-auto mb-7 ">
											Pago por especialista
										</p>
										<div className="flex justify-center items-center h-fit mb-5">
												<BarChart
													margin={{ top: 20, left: 350, right: 50, bottom: 20 }}
													dataset={dataSet2}
													yAxis={[{scaleType: 'band',
															dataKey: 'correo',
															categoryGapRatio: 0.1,
															barGapRatio: 0.01,},]}
													series={[
														{
															dataKey: 'cita',
															label: 'Valor Cita',
															valueFormatter,
														},
														{
															dataKey: 'especialista',
															label: 'Pago Especialista',
															valueFormatter,
														},
													]}
													layout="horizontal"
													{...chartSetting3}
												/>
										</div>
									</div>
									{filtroDate3 && <div className='text-white absolute bottom-0 left-0 right-0 flex justify-center bg-darkblue rounded-b-lg text-base'>{`${formatDate(filtroDate3[0])} - ${formatDate(filtroDate3[1])}`}</div>}
								</div>
							) : (
								<div className="flex-col items-center">
									<p className="text-2xl font-medium text-center mx-auto mb-12 mt-0">
										Pago por citas especialistas
									</p>
									<div className="flex justify-center items-center">
										<div className=" rounded-sm flex justify-center items-center h-[180px] w-[450px] bg-greymedium  animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
									</div>
								</div>
							)}
						</div>
					)}

					{/* image - end */}
					{/* image 3b/3 - start */}
					{checked === 'pagos' && (
						<div className="group relative flex h-auto items-center justify-center overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-5  bg-white sm:col-span-5 py-4 transition-all hover:scale-[1.02]">
							{!loadingEspecialistas && pagosEspecialistas.length > 0 ? (
								<div>
									<GearConfig
										handlerShowFilter={handlerShowFilter}
										data={'citas'}
									/>
									<div
										className="flex-col items-center hover:cursor-hand mb-3"
										onClick={() => handleReportesClick('ReporteIngresosPagos')}
									>
										<p className="text-2xl font-medium text-center mx-auto mb-7 ">
											Pago por especialista
										</p>
										<div className="flex justify-center items-center h-fit">
											<BarChart
												margin={{ top: 20, left: 350, right: 50, bottom: 20 }}
												dataset={pagosEspecialistas}
												yAxis={[
													{
														scaleType: 'band',
														dataKey: 'correo',
														categoryGapRatio: 0.1,
														barGapRatio: 0.01,
													},
												]}
												series={[
													{
														dataKey: 'pago',
														label: 'Pago Especialista',
														valueFormatter,
													},
												]}
												layout="horizontal"
												{...chartSetting2}
											/>
										</div>
									</div>
									{filtroDate3 && <div className='text-white absolute bottom-0 left-0 right-0 flex justify-center bg-darkblue rounded-b-lg text-base'>{`${formatDate(filtroDate3[0])} - ${formatDate(filtroDate3[1])}`}</div>}
								</div>
							) : (
								<div className="flex-col items-center">
									<p className="text-2xl font-medium text-center mx-auto mb-12 mt-0">
										Pago por especialista
									</p>
									<div className="flex justify-center items-center">
										<div className=" rounded-sm flex justify-center items-center h-[180px] w-[450px] bg-greymedium  animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
									</div>
								</div>
							)}
						</div>
					)}
					{/* image - end */}
				</div>
			</div>
		</div>
	);
};

export default GaleryCards;