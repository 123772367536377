import axios from "axios";
import { toast } from "react-toastify";
import config from "../config";


/**
 * Retrieves data from the API.
 * @param {string} endPoint - The endpoint to retrieve data from.
 * @param {string} token - The authorization token.
 * @param {object} data - The data to be sent in the request body.
 * @return {Promise} A promise that resolves with the response data or false if an error occurs.
 */

export const getDataFromApi = async(endPoint, token, data) => {
    const url = config.endPoint;
    const localhostendpoint = url+endPoint;
    const configAxios = {
      headers: {
        'Content-Type': 'application/json',
        authorization: 'Bearer ' + token,
      }
    }
    //console.log(data)

    try {
           const response = await axios.post(localhostendpoint, { data: {body: data }}, configAxios);
           //console.log(response)
           if(response.status === 200) {
            return response;
            
           } else {
              toast.error(`${response.data}`, {
                position: 'top-center',
                theme: 'colored',
            });
              return false;
            }
    
           }
            
           catch (error) {
              console.log("repuesta de error  ",error)
              console.log("code error ",error.code)
              console.log("reponse request error ",error.request.response)
              console.log("data enviada ",data)
              toast.error('ha ocurrido un error', {
                  position: 'top-center',
                  theme: 'colored',
              });
              return false;
            }
  };
  
