import React from "react";
import Editar from "../../../components/Editar";

const CardSesion = ({sesion, onBorrar, onEditar, edicion = false, seleccionado }) =>{

    function handleClickInsideCard(e) {
        e.stopPropagation();
    }

    return (
        <>
            <p onClick={(e) => handleClickInsideCard(e)} className="font-semibold">Nombre:</p>
            <p onClick={(e) => handleClickInsideCard(e)}>{sesion.nombre}</p>
            <p onClick={(e) => handleClickInsideCard(e)} className="font-semibold">Duración:</p>
            <p onClick={(e) => handleClickInsideCard(e)} >{sesion.duracion} minutos</p>
            <div onClick={(e) => handleClickInsideCard(e)} className="absolute bottom-4 right-4">
            {!edicion && (sesion.nombre === seleccionado) && (<Editar  onBorrar={(e)=>{
                onBorrar()
                handleClickInsideCard(e)}} onEditar={(e)=>{
                    onEditar()
                    handleClickInsideCard(e)}} />)}
            </div>
        </>
    )
}

export default CardSesion;