import React from "react";
import imagenAzul from "../../../img/user-circle1.svg";
import imagenBlanca from "../../../img/user-circle.svg";
import { PiIdentificationCardBold,PiPhoneCallBold } from "react-icons/pi";
import { useHookMenu } from "../../../libs/firebase";


const Card = ({ paciente, seleccionado, largo, index}) => {
  const { handlerRuta, handlerModal, } = useHookMenu();
  const handleButtonClick = () => {
    handlerRuta("agendar_cita");
    handlerModal();
  };

  function handleClickInsideCard(e) {
    e.stopPropagation();
  }

  return (
    <>
    <h1 className={`absolute top-2 right-2 text-lg ${paciente.uid === seleccionado ? "bg-white text-darkblue" : "bg-darkblue text-white"}   rounded-full p-1`} onClick={(e) => handleClickInsideCard(e)}> {`${index}/${largo}`}</h1>
      <img
        className="m-auto mt-0 mb-2 w-auto h-auto"
        src={paciente.uid === seleccionado ? imagenBlanca : imagenAzul}
        alt="imagen del usuario"
        onClick={(e) => handleClickInsideCard(e)}
      />
      <h3 className="uppercase text-lg font-semibold mb-2" onClick={(e) => handleClickInsideCard(e)}>{`${paciente.nombre} ${paciente.apellido}`}</h3>
      <h1 className="flex items-center uppercase text-lg font-semibold mb-2" onClick={(e) => handleClickInsideCard(e)}> <PiIdentificationCardBold className="mr-2" size={25}/>{`${paciente.documento}`}</h1>
      <h1 className="flex items-center uppercase text-lg font-semibold mb-2" onClick={(e) => handleClickInsideCard(e)}> <PiPhoneCallBold className="mr-2" size={25}/>{`${paciente.telefono}`}</h1>
      <button
        className={`absolute bottom-3 sm:text-xs lg:text-xl md:text-md w-auto px-4 py-1 tracking-wide transition-colors duration-200 transform border-2 border-white hover:text-darkblue  rounded-md hover:bg-white hover:border-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white" type="button first-letter:first-line:
      ${paciente.uid === seleccionado ? "" : "hidden"}`}
        onClick={(e)=>{
          handleClickInsideCard(e)
          handleButtonClick()}}
      >
        Agendar Cita
      </button>

    </>
  );
};

export default Card;
