import React, { useState, useEffect, useRef } from "react";
import { FaTrashCan } from "react-icons/fa6";
import { useHookCentros } from "../../../libs/hookCentros";

const UpdateCentro = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const { updateCentro, getCentro } = useHookCentros();
  const [nombreSala, setNombreSala] = useState("");
  const [errors, setErrors] = useState({});
  const isFirstRender = useRef(true);
  
  const [nombre, setNombre] = useState(null);
  const [direccion, setDireccion] = useState(null);
  const [telefono, setTelefono] = useState(null);
  const [salasCentro, setSalasCentro] = useState([]);

  /**
 * Renders a form to update a centro.
 *
 * @param {string} props - The props object containing the centroId.
 * @return {object} The object with the info of center.
 */
  const handleGetCentro = async (centroId) => {

    try {
      const centroData = await getCentro(centroId);
      setNombre(centroData.nombre)
      setDireccion(centroData.direccion)
      setTelefono(centroData.telefono)
      if (Array.isArray(centroData.salas)) {
        setSalasCentro(centroData.salas)
      }
      setIsLoading(false)
    }
    catch (error) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleGetCentro(props.centroId)
    };
  }, [handleGetCentro, props.centroId]);

    /**
   * Updates the value of the 'nombreSala' state variable based on the input
   * event.
   *
   * @param {Event} event - The input event that triggered the change.
   * @return {void} This function does not return anything.
   */
  const handleNombreSalaChange = (event) => {
    setNombreSala(event.target.value);
  };

    /**
  * Handles the addition of a new sala.
  *
  * @param {Event} e - The event object.
  * @return {void} No return value.
  */
  const handleAddSala = (e) => {
    e.preventDefault();
    let newErrors = {};

    const nombreSalaValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9\s]{3,}$/;

    if (!nombreSalaValido.test(nombreSala.trim())) {
      newErrors.nombreSala = "Nombre inválido"
    } else {
      setSalasCentro([...salasCentro, { nombre: nombreSala.trim() }]);
      setNombreSala("");

      updateCentro(
        "salas",
        [...salasCentro, { nombre: nombreSala.trim() }],
        props.centroId
      );
    }

    setErrors(newErrors);
  };

    /**
   * Removes a sala from the list of salasCentro at the specified index.
   *
   * @param {number} index - The index of the sala to remove.
   * @return {void} This function does not return a value.
   */
  const handleRemoveSala = (index) => {
    const updatedSalas = [...salasCentro];
    updatedSalas.splice(index, 1);
    setSalasCentro(updatedSalas);
    updateCentro("salas", updatedSalas, props.centroId);
  };

    /**
   * Updates the nombre and validates it.
   *
   * @param {string} nombre - The new nombre value.
   * @return {void} No return value.
   */
  const handleChangeNombre = (nombre) => {
    setNombre(nombre)
    let newErrors = {};

    const nombreValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9\s]{3,}$/;

    if (!nombreValido.test(nombre.trim())) {
      newErrors.nombre = "Nombre inválido"
    } else {
      updateCentro(
        "nombre",
        nombre.trim().toLowerCase(),
        props.centroId
      );
    }
    setErrors(newErrors);
  };

    /**
   * Updates the direccion state and performs validation.
   *
   * @param {string} direccion - The new direccion value.
   * @return {void} This function does not return anything.
   */
  const handleChangeDireccion = (direccion) => {
    setDireccion(direccion)
    let newErrors = {};

    const direccionValida = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9# -]{3,}$/;

    if (!direccionValida.test(direccion.trim())) {
      newErrors.direccion = "Dirección inválida"
    } else {
      updateCentro(
        "direccion",
        direccion.trim().toLowerCase(),
        props.centroId
      );
    }
    setErrors(newErrors);
  };

    /**
   * Updates the telefono state and checks if it is a valid phone number.
   *
   * @param {string} telefono - The new telefono value.
   * @return {void} This function does not return anything.
   */
  const handleChangeTelefono = (telefono) => {
    setTelefono(telefono)
    let newErrors = {};

    const telefonoValido = /^\+?\d+$/;

    if (!telefonoValido.test(telefono.trim())) {
      newErrors.telefono = "Telefono inválido"
    } else {
      updateCentro(
        "telefono",
        telefono.trim().toLowerCase(),
        props.centroId
      );
    }
    setErrors(newErrors);
  };


  return (
    <div className="w-[35vw] min-w-[20rem] justify-center">
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">EDITAR CENTRO</h3>
      {/* incio form */}
      <form>

        <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
          htmlFor='nombre'>Nombre
          {isLoading ? (<div role="status" className="w-full animate-pulse">
            <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
          </div>) : (<input
            required
            id='nombre'
            name='nombre'
            className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
            type="text"
            placeholder="Nombres..."
            value={nombre}
            onChange={(e) => handleChangeNombre(e.target.value)}
          />)}
        </label>

        {errors.nombre && (
          <div>
            <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.nombre}</span>
            <br />
          </div>
        )}

        <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
          htmlFor='direccion'>Dirección
          {isLoading ? (<div role="status" className="w-full animate-pulse">
            <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
          </div>) : (<input
            required
            id='direccion'
            name='direccion'
            className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
            type="text"
            placeholder="Dirección..."
            value={direccion}
            onChange={(e) => handleChangeDireccion(e.target.value)}
          />)}
        </label>

        {errors.direccion && (
          <div>
            <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.direccion}</span>
            <br />
          </div>
        )}

        <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
          htmlFor='telefono'>Teléfono
          {isLoading ? (<div role="status" className="w-full animate-pulse">
            <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
          </div>) : (<input
            id='telefono'
            name='telefono'
            className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
            type="phone"
            placeholder="+51 9## ### ###"
            value={telefono}
            onChange={(e) => handleChangeTelefono(e.target.value)}
          />)}
        </label>

        {errors.telefono && (
          <div>
            <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.telefono}</span>
            <br />
          </div>
        )}
        {/* inicio  input salas */}
        <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
          htmlFor='salasCentro' >Salas

          {isLoading ? (<div role="status" className="w-full animate-pulse">
            <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
          </div>) : (<div className="flex justify-between">
            <input
              className="w-[75%] px-4 py-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
              type="text"
              name="salasCentro"
              id="salasCentro"
              placeholder="Nombre sala..."
              value={nombreSala}
              onChange={handleNombreSalaChange}
            />


            <button onClick={handleAddSala} className="sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white">Agregar</button>
          </div>)}
        </label>

        {errors.nombreSala && (
          <div>
            <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.nombreSala}</span>
            <br />
          </div>
        )}
        {/*fin  input salas */}
        {/*inicio cards salas */}
        <div className="mt-4">
          <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" >Salas creadas: </label>
          {isLoading ? (<div role="status" className="w-full animate-pulse">
            <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
          </div>) : (<ul className="flex flex-wrap">
            {Array.isArray(salasCentro) && salasCentro.map((sala, index) => (
              <li className="ring-2 ring-aqua px-2 py-2 w-auto m-4 tracking-wide  rounded-md flex justify-center" key={index}>{sala.nombre}
                <button type="button" onClick={() => handleRemoveSala(index)} className="text-red hover:text-darkred ml-4">
                  <FaTrashCan /></button>
              </li>
            ))}
          </ul>)}
        </div>
        {/*fin cards salas */}
      </form>
        {/* fin form */}
    </div>
  )
};

export default UpdateCentro;