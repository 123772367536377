import React from "react";

const Paginacion = ({ currentPage, totalPages, onPageChange, onPrevPage, onNextPage }) => {
    const mostrarNumerosPagina = () => {
        const numerosPagina = [];
        const maximoNumerosMostrados = 5;
        const mitadMaximaNumeros = Math.floor(maximoNumerosMostrados / 2);

        let inicio = Math.max(1, currentPage - mitadMaximaNumeros);
        let fin = Math.min(totalPages, inicio + maximoNumerosMostrados - 1);

        if (fin - inicio + 1 < maximoNumerosMostrados) {
            inicio = Math.max(1, fin - maximoNumerosMostrados + 1);
        }

        for (let i = inicio; i <= fin; i++) {
            numerosPagina.push(
                <li key={i}>
                    <button
                        className={`w-9 h-9 flex items-center justify-center mx-1 rounded-md border border-aqua  text-base hover:bg-primary hover:border-primary hover:text-black ${currentPage === i ? 'bg-aqua text-white border-darkaqua' : 'text-[#626262]'}`}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </button>
                </li>
            );
        }

        return numerosPagina;
    };

    return (
        <div className="flex justify-center m-4">
            <button
                onClick={onPrevPage}
                disabled={currentPage === 1}
                className="mr-4 text-gray-700 rounded-md cursor-pointer disabled:opacity-50 hover:underline hover:text-darkaqua"
            >
                Anterior
            </button>
            <ul className="flex">
                {mostrarNumerosPagina()}
            </ul>
            <button
                onClick={onNextPage}
                disabled={currentPage === totalPages}
                className="ml-4 text-gray-700 rounded-md cursor-pointer disabled:opacity-50 hover:underline hover:text-darkaqua"
            >
                Siguiente
            </button>
        </div>
    );
};

export default Paginacion;
