import { Routes, Route } from "react-router-dom";
import Usuarios from "./adminUsuarios";
import Centros from "./adminCentros/index";
import Servicios from "./adminServicios";
import Calendario from "./calendario";
import Pacientes from "./pacientes";
import Reportes from "./adminReportes";
import NucleoFam from "./pacientes/nucleoFam";
import UpdatePaciente from "./pacientes/UpdatePaciente";
import CitasHoy from "./calendario/CitasReporte";
import Disponibilidad from "./adminDisponibilidad";
import ReporteIngresos from "./adminReportes/reportesIngresos";
import ReporteCitas from "./adminReportes/reportesCitas";
import ReporteServicios from "./adminReportes/reportesServicios";
import ReporteIngresosPagos from "./adminReportes/reportesIngresosPagos";
import ReporteEstadisticaIngresos from "./adminReportes/reportesEstadisticaIngresos";

import ReporteEstadisticasDeuda from "./adminReportes/reportesEstadisticaDeuda";
import InfinityLoader from "./pacientes/InfinityLoader";

const RutasContainer = () => {
  return (
    <Routes>

      <Route index element={<Calendario />} />

      <Route index path="/Calendario" element={<Calendario />} />

      <Route index path="/Pacientes" element={<Pacientes />} />

      <Route index path="/Centros" element={<Centros />} />
      
      <Route index path="/Servicios" element={<Servicios />} />

      <Route index path="/Usuarios" element={<Usuarios />} />

      <Route index path="/Reportes" element={<Reportes />} />

      <Route index path="/Disponibilidad" element={<Disponibilidad />} />

      <Route path="/Pacientes/Nucleo" element={<NucleoFam />} />

      <Route path="/Usuarios/Disponibilidad" element={<Disponibilidad />} />

      <Route path="/Pacientes/UpdatePaciente" element={<UpdatePaciente />} />

      <Route path="/Pacientes/HistorialPaciente" element={<InfinityLoader />} />

      <Route path="/Citashoy" element={<CitasHoy />} />

      <Route path="/Calendario/Citashoy" element={<CitasHoy />} />

      <Route path="/Reportes/Citashoy" element={<CitasHoy />} />

      <Route path="/Reportes/ReporteCitas" element={<ReporteCitas />} />

      <Route path="/Reportes/ReporteServicios" element={<ReporteServicios />} />

      <Route path="/Reportes/ReporteIngresos" element={<ReporteIngresos />} />

      <Route path="/Reportes/ReporteEstadisticoIngresos" element={<ReporteEstadisticaIngresos />} />

      <Route path="/Reportes/ReporteEstadisticoDeudas" element={<ReporteEstadisticasDeuda />} />

      <Route path="/Reportes/ReporteIngresosPagos" element={<ReporteIngresosPagos />} />

      <Route path="*" element={<Calendario />} />
   
    </Routes>
  );
};

export default RutasContainer;
