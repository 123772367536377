import { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHookReportes } from '../../../libs/hookReportes';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

const ReporteEstadisticasDeuda = () => {
	const isFirstRender = useRef(true);
	const [isLoading, setIsLoading] = useState(true);
	let height;
    if(window.innerHeight > 600){
      height = 720;
    } else {
      height = 300;
    }

	const columns = [
		{ field: 'col1', headerName: 'Id', width: 40 },
		{ field: 'col2', headerName: 'Creación', width: 120 },
		{ field: 'col3', headerName: 'Paciente', width: 250 },
		{ field: 'col4', headerName: 'Servicio', width: 240 },
		{ field: 'col5', headerName: 'Especialista', width: 250 },
		{ field: 'col6', headerName: 'Centro', width: 100 },
		{ field: 'col7', headerName: 'Saldo', width: 120 },
		{ field: 'col8', headerName: 'Valor Cita', width: 120 },
	];

	const { getReport,filtroDate6, filtros6, acumulado } = useHookReportes();
	const [rows, setRows] = useState([]);

	function capitalizeString(str) {
		if (typeof str === 'string' && str.length > 0) {
			const words = str.split(' ');
			const palabras = words.map(word => {
				if(word.length > 0 && word !== ' ' && word !== ',') {
					return word[0].toUpperCase() + word.slice(1);
				}
			});
			return palabras.join(' ');
		} else {
			return str;
		}
	}

	function formatDate(fecha) {
		// Obtenemos el día, el mes y el año de la fecha
		if(fecha !== undefined)
		{
		  const dia = fecha.getDate();
		const mes = fecha.getMonth() + 1;
		const anyo = fecha.getFullYear();
			// Formateamos la fecha
			return `${dia}/${mes}/${anyo}`;
		} else {
		  return "";
		}
	}

	const fechaFormateada = unixTimestamp => {
		const fecha = new Date(unixTimestamp * 1000);
		const fechaFormateada = fecha.toLocaleDateString();
		return fechaFormateada;
	};

	function generarObjeto(data) {
		const objeto = [];
		let index = 0;
		const sortedData = data.sort((a, b) => a.inicio - b.inicio, {
			reverse: true,
		});

		for (const cita of sortedData) {
			index++;
			objeto.push({
				id: index,
				col1: index,
				col2: fechaFormateada(cita.creadoTs),
				col3: capitalizeString(cita.paciente),
				col4: capitalizeString(cita.servicio),
				col5: capitalizeString(cita.especialistas),
				col6: capitalizeString(cita.nombresCentros),
				col7: cita.saldo,
				col8: cita.precio,
			});
		}
		return objeto;
	}


	const handlerGetData = async () => {
		let arrayCitas;
		const dateInicio = Math.floor(filtroDate6[0].getTime() / 1000);
		const dateFin = Math.floor(filtroDate6[1].getTime() / 1000);
		let res;
		try {
			if(acumulado){
				res = await getReport(0, 0, 6, true );
			} else {
				res = await getReport(dateInicio, dateFin, 6, true, filtros6 );
			}
		} catch (error) {
			console.log("res error reporte deudas", error)
		}
				if (typeof res === 'object') {
					 arrayCitas = res.data;
					setRows(generarObjeto(arrayCitas));

				} else {
					arrayCitas = [];
				}

			setIsLoading(false);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handlerGetData();
		}
	}, [handlerGetData]);

	return (
		<div
			className="w-full h-full bg-azure  overflow-y-auto relative"
			key={nanoid()}
		>
			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-50 z-40">
					<div className="absolute inset-0 flex items-center justify-center z-50">
						<div className="animate-spin rounded-full h-16 w-16 border-t-[6px] border-b-[6px] border-[#29CEBE]"></div>
					</div>
				</div>
			)}
			<div className='w-[90vw]'>
			<div className="flex mx-auto rounded-2xl h-20 my-5 bg-darkblue w-[95%]">
				<p className="text-2xl font-semibold text-center mx-auto my-auto text-white">
					{`Reportes Deudas por centro Skinner ${formatDate(
						filtroDate6[0]
					)} hasta ${formatDate(filtroDate6[1])}`}
				</p>
			</div>
			
			<div className=" mx-auto bg-lightgrey  rounded-2xl p-5 w-[95%]">
				<Box sx={{ height: {height}, width: 1 }} className="">
					<DataGrid
						showCellVerticalBorder={true}
						showColumnVerticalBorder={true}
						// unstable_ignoreValueFormatterDuringExport={true}
						rows={rows}
						// disableColumnFilter
						// disableColumnSelector
						// disableDensitySelector
						columns={columns}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
					/>
				</Box>
			</div>
			</div>
		</div>
	);
};

export default ReporteEstadisticasDeuda;
