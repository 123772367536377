import React from "react";
import { nanoid } from "nanoid";

import { useHookMenu } from "../libs/firebase";
import { useHookServicios } from "../libs/hookServicios";
import AgendarCita from "../pages/home/calendario/AgendarCita";
import RegistrarPago from "../pages/home/calendario/RegistrarPago";
import VerSesion from "../pages/home/calendario/VerSesion";



const Modal = () => {
  const {sesionSelected } = useHookServicios();

  const {
    ruta,
    handlerModal,
  } = useHookMenu();

  let contenidoRederizar;

  //citas

  if (ruta === "agendar_cita"){
    contenidoRederizar = <AgendarCita />
  }
  else if (ruta === "registrar_pago"){
    contenidoRederizar = <RegistrarPago/>
  }
  else if (ruta === "ver_sesion"){
    contenidoRederizar = <VerSesion sesion={sesionSelected}/>
  } 

  return (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-90 z-50"
          key={nanoid()}
        >
          <div className="relative flex flex-col justify-center">
            <div className="w-auto p-6 m-auto bg-white rounded-3xl shadow-md max-h-[90vh] overflow-auto">
              <div className="flex items-start justify-between rounded-t">
                <button
                  onClick={handlerModal}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {contenidoRederizar}
            </div>
          </div>
        </div>
  );
};

export default Modal;
