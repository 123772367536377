import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHookReportes } from '../../../libs/hookReportes';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { BarChart } from '@mui/x-charts/BarChart';
import { useHookAuth } from '../../../libs/firebase';

const ReporteIngresosPagos = () => {
	const { usuario } = useHookAuth();
	const isFirstRender = useRef(true);
	const [isLoading, setIsLoading] = useState(true);
	const [dataSet2, setDataSet2] = useState([]);
	let columns;
	if ( usuario.rol === "especialista" ) {
		columns = [
			{ field: 'col1', headerName: 'Id', width: 50 },
			{ field: 'col2', headerName: 'Sede', width: 120 },
			{ field: 'col3', headerName: 'Fecha de Pago', width: 120 },
			{ field: 'col4', headerName: 'Recibo', width: 120 },
			{ field: 'col5', headerName: 'tipo', width: 120 },
			{ field: 'col6', headerName: 'Paciente', width: 200 },
			{ field: 'col7', headerName: 'Especialista', width: 280 },
			{ field: 'col8', headerName: 'Servicio', width: 200 },
			{ field: 'col9', headerName: 'Pago Especialista', width: 140 },
			
		];
	} else {
		columns = [
			{ field: 'col1', headerName: 'Id', width: 50 },
			{ field: 'col2', headerName: 'Sede', width: 120 },
			{ field: 'col3', headerName: 'Fecha de Pago', width: 120 },
			{ field: 'col4', headerName: 'Recibo', width: 120 },
			{ field: 'col5', headerName: 'Tipo', width: 120 },
			{ field: 'col6', headerName: 'Paciente', width: 200 },
			{ field: 'col7', headerName: 'Especialista', width: 280 },
			{ field: 'col8', headerName: 'Servicio', width: 200 },
			{ field: 'col9', headerName: 'Precio', width: 70 },
			{ field: 'col10', headerName: 'Pagos', width: 70 },
			{ field: 'col11', headerName: 'Saldo', width: 70 },
			{ field: 'col12', headerName: 'Porcentaje', width: 100 },
			{ field: 'col13', headerName: 'Monto fijo', width: 100 },
			{ field: 'col14', headerName: 'Pago Especialista', width: 140 },
			{ field: 'col15', headerName: 'Utilidad', width: 100 },
		];
	}


	const { getReport, filtroDate3, filtros3 } = useHookReportes();
	const {menuFull} = useHookAuth();
	const [rows, setRows] = useState([]);
	//const [totalCitas, setTotalCitas] = useState(0);

	function capitalizeString(str) {
		if (typeof str === 'string' && str.length > 0) {
			const words = str.split(' ');
			if (words.length > 0) {
				const palabras = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				return palabras.join(' ');
			} else {
				return str;
			}
		} else {
			return str;
		}
	}

	const chartSetting2 = {
		xAxis: [
			{
				label: 'Ingresos',
			},
		],
		width: 600,
		height: 480,
	};

	const valueFormatter = value => `S/. ${value}`;


	// const crearObjetoSeries = async (data, data2) => {
	// 	let series = [];
	// 	for (const item of data) {
	// 		const [email, value] = Object.entries(item)[0];
	// 		for (const item2 of data2) {
	// 			const [email2, value2] = Object.entries(item2)[0];
	// 			if(email === email2) {
	// 				series.push({ cita:value2, especialista: value, correo: email });
	// 			}
	// 		}
	// 	}
	// 	setDataSet2(series);
	// };

	function formatDate(fecha) {
		// Obtenemos el día, el mes y el año de la fecha
		if(fecha !== undefined)
		{
		  const dia = fecha.getDate();
		const mes = fecha.getMonth() + 1;
		const anyo = fecha.getFullYear();
			// Formateamos la fecha
			return `${dia}/${mes}/${anyo}`;
		} else {
		  return "";
		}
	}

	  const fechaFormateada = unixTimestamp => {
		const fecha = new Date(unixTimestamp * 1000);
		const fechaFormateada = fecha.toLocaleDateString();
		return fechaFormateada;
	};

	function generarObjeto (data) {
		const objeto = [];
		let index = 0;
		
		if (usuario.rol === "especialista"){
			for (const cita of data) {

				index++;
				objeto.push({
					id: index,
					col1: index,
					col2:cita.nombresCentros,
					col3: fechaFormateada(cita.fecha),
					col4: cita.recibo,
					col5: cita.tipo,
					col6: cita.pacientes,
					col7: cita.especialistas,
					col8: capitalizeString(cita.servicios),
					col9: cita.pago_especialista,
				});
			}
		} else {
			for (const cita of data) {

				index++;
				objeto.push({
					id: index,
					col1: index,
					col2:cita.nombresCentros,
					col3: fechaFormateada(cita.fecha),
					col4: cita.recibo,
					col5: cita.tipo,
					col6: cita.pacientes,
					col7: cita.especialistas,
					col8: capitalizeString(cita.servicios),
					col9: cita.precio,
					col10: parseInt(cita.precio) - parseInt(cita.saldo),
					col11: cita.saldo,
					col12: `${cita.porcentaje}%`,
					col13: cita.monto_fijo,
					col14: cita.pago_especialista,
					col15: parseInt(cita.precio) - parseInt(cita.pago_especialista),
				});
			}
		}

		return objeto;
	}

	const handlerGetData = async () => {
		let arrayCitas;
		const dateInicio = Math.floor(filtroDate3[0].getTime() / 1000);
		const dateFin = Math.floor(filtroDate3[1].getTime() / 1000);
		const res = await getReport(dateInicio, dateFin, 4, true, filtros3);
		arrayCitas = res.data;
		if(res.pago_por_especialista && usuario.rol === "administrador" && typeof arrayCitas === "object") {
		const filteredData3 = Object.entries(res.pago_por_especialista);
		if(filteredData3.length > 0) {
			const convertedData3 = filteredData3.map(item => ({
				correo: [item[0]], pago: item[1]
			}))
			setDataSet2(convertedData3);
		} else {
			setDataSet2( [{ correo: [ 'Sin datos' ], pago: 0 }]);
		}
		
		} else {
			setDataSet2( [{ correo: [ 'Sin datos' ], pago: 0 }]);
		}
		
		// setIngresosEspecialistas(convertedData);
		//crearObjetoSeries(convertedData, convertedData2);
		
		if(arrayCitas && arrayCitas.length > 0) {
		setRows(generarObjeto(arrayCitas));
		} else {
			setRows([]);
		}
		
		setIsLoading(false);
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handlerGetData();
		}
	}, [handlerGetData]);

	return (
		<div
			className={`h-full bg-azure  overflow-y-auto relative mt-3 w-[100%] `}
			key={nanoid()}
		>
			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-50 z-40">
					<div className="absolute inset-0 flex items-center justify-center z-50">
						<div className="animate-spin rounded-full h-16 w-16 border-t-[6px] border-b-[6px] border-[#29CEBE]"></div>
					</div>
				</div>
			)}
		<div className={`mx-auto ${menuFull? 'w-[80vw]': 'w-[90vw]'}`}>
		<div className="flex mx-auto rounded-2xl h-20 w-[93%] my-5 bg-darkblue ">
				<p className="text-2xl font-semibold text-center mx-auto my-auto text-white">
					{`Reportes centro Skinner ${formatDate(
						filtroDate3[0]
					)} hasta ${formatDate(filtroDate3[1])}`}
				</p>
			</div>
			{/* inicio graficos */}
			
			{usuario.rol !== "especialista" && (<section className="text-gray-600 body-font w-full mb-8 mx-auto">
					<div className="container px-5 pt-3 mx-auto w-full">
						<div className="flex -m-4 text-center mx-auto w-full justify-center">
							<div className="p-4 md:w-4/5 sm:w-2/2 w-full">
								<div className="flex-wrap  px-3 py-4 bg-lightgrey rounded-xl  text-darkblue mx-auto h-[100%]">
									<p className="text-2xl font-medium text-center mx-auto mb-1 ">
									Pago por especialistas
									</p>
									<div className="flex justify-center items-center h-fit">
										{!isLoading && dataSet2.length > 0&& (
											<BarChart
												margin={{ top: 20, left: 350, right: 50, bottom: 20 }}
												dataset={dataSet2}
												yAxis={[
													{
														scaleType: 'band',
														dataKey: 'correo',
														categoryGapRatio: 0.1,
														barGapRatio: 0.01,
													},
												]}
												series={[{ dataKey: 'pago',
												label: 'Pago Especialista',
												valueFormatter } 
												]}
												layout="horizontal"
												{...chartSetting2}
											/>
										)}
									</div>
								</div>
							</div>				
						</div>
					</div>
				</section>)}
			
			{/* fin graficos */}
			<div className=" mx-auto bg-lightgrey  rounded-2xl p-5 w-[95%]">
				<Box sx={{ height: 600, width: 1 }} className="overflow-x-auto">
					<DataGrid
						showCellVerticalBorder={true}
						showColumnVerticalBorder={true}
						// unstable_ignoreValueFormatterDuringExport={true}
						rows={rows}
						// disableColumnFilter
						// disableColumnSelector
						// disableDensitySelector
						columns={columns}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
					/>
				</Box>
			</div>
		</div>
		</div>
	);
};

export default ReporteIngresosPagos;