import React, { useState, useRef, useEffect } from "react";
import { useHookCentros } from "../../../libs/hookCentros";


const VerCentro = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const { getCentro } = useHookCentros();
    const [centroSelected, setCentroSelected] = useState(null)
    const isFirstRender = useRef(true);

        /**
     * Handles the retrieval of a centro based on the provided centroId.
     *
     * @param {string} centroId - The ID of the centro to retrieve.
     * @return {Promise<void>} - A promise that resolves when the centro is retrieved successfully.
     */
    const handleGetCentro = async (centroId) => {
        try {
            const centroData = await getCentro(centroId);
            setCentroSelected(centroData)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            handleGetCentro(props.centroId)
        };
    }, [handleGetCentro, props.centroId]);


    return (
        <div className="w-[35vw] min-w-[20rem] justify-center">
            <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">DATOS CENTRO</h3>
            {/* incio form */}
            <form >

                <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                    htmlFor='nombre'>Nombre
                    {isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) : (<input
                        id='nombre'
                        name='nombre'
                        className="capitalize w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                        value={centroSelected.nombre}
                        disabled
                    />)}
                </label>

                <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                    htmlFor='direccion'>Dirección
                    {isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) : (<input
                        id='direccion'
                        name='direccion'
                        className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                        value={centroSelected.direccion}
                        disabled
                    />)}
                </label>

                <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                    htmlFor='telefono'>Teléfono
                    {isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) : (<input
                        id='telefono'
                        name='telefono'
                        className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                        value={centroSelected.telefono}
                        disabled
                    />)}
                </label>
                {/* inicio cards centros */}
                <div className="mt-4">
                    <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" >Salas creadas: {centroSelected && Array.isArray(centroSelected.salas) ? centroSelected.salas.length : '0' }</label>
                    {isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) : (<ul className="flex flex-wrap">
                        {Array.isArray(centroSelected.salas) && centroSelected.salas.map((sala, index) => (
                            <li className="ring-2 ring-aqua px-2 py-2 w-auto m-4 tracking-wide  rounded-md flex justify-center" key={index}>
                                {sala.nombre}
                            </li>
                        ))}
                    </ul>)}
                </div>
                {/* fin cards centros */}
            </form>
            {/* fin form */}
        </div>
    )
}

export default VerCentro;
