import { useEffect, useRef, useState, useCallback } from "react";
import { useHookPacientes } from "../libs/hookPacientes";
import { FaUser} from "react-icons/fa";

const SearchableDropdown = ({
  options,
  label,
  id,
  selectedVal,
  handleChange, 
  handlerSetPacientes
}) => {
  const [query, setQuery] = useState("");
  const { searchPaciente } = useHookPacientes();
  const [isOpen, setIsOpen] = useState(true);
  const [isSearching, setIsSearching] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);


  const handlerSearchPaciente = useCallback(async (paciente) => {
    if (!isSearching) {
      setIsSearching(true)
      await searchPaciente(paciente.toLowerCase())
        .then((res) => {
          handlerSetPacientes(res)
          setIsSearching(false)
        })
        .catch((err) => {
          setIsSearching(false)
          console.log(err);
        });
    }
    

  }, [searchPaciente]);


  useEffect(() => {
    if (query.length > 1) {    
      setTimeout(() => {
        handlerSearchPaciente(query);
        }, 500);   
    }
  }, [query, handlerSearchPaciente]);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    e.preventDefault();
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;
    return "";
  };



  return (
    <div className="dropdown relative w-full">
      <div className="control ">
        <div className="relative selected-value text-black">
        <FaUser className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
          <input
            className=""
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
            }}
            onClick={toggle}
            style={{ background: "#DDE8ED", border: "none", color: '#000', borderRadius: '0.375rem',
            paddingLeft: '40px', paddingBottom: '0.75rem', paddingRight: '1rem', paddingTop: '0.75rem',
            fontSize: '1.1rem', textTransform: 'capitalize',
            outline: 'none',
            transition: 'box-shadow 0.2s ease-in-out',
           }}
           onFocus={(e) => {
            e.target.style.boxShadow = '0 0 0 2px #00cecb'; 
          }}
          onBlur={(e) => {
            e.target.style.boxShadow = '0 0 0 2px #fff'; 
          }}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={` options ${isOpen ? "open" : ""}`}>
        {options !== undefined &&
          options !== null &&
          options.map((option, index) => {
            return (
              <div
                onClick={() => selectOption(option)}
                className={` option ${
                  option[label] === selectedVal ? "selected" : ""
                }`}
                key={`${id}-${index}`}
              >
                {`${option.nombre} ${option.apellido}`}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SearchableDropdown;