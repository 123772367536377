import React from "react";
import {FaTrashCan} from "react-icons/fa6";
import { nanoid } from 'nanoid';

const Editar = ({onBorrar}) => {
   return(
        <div className='flex justify-around space-x-2' key={nanoid()}>
            {/* <button type="button" title="Edit" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={onEditar}><FiEdit/></button> */}
            <button type="button" className="text-red hover:text-darkred" onClick={onBorrar}><FaTrashCan/></button>
        </div>
   ) 
}

export default Editar;
