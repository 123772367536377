import React from "react";
import { useState } from "react";
import "../../styles/calendarioCita.css";
import DatosSesion from "./DatosSesion";
import EditCita from "./EditCita";
import RegistrarPago from "./RegistrarPago";
import { useEffect, useRef } from "react";
import { useHookCalendario } from "../../../libs/hookCalendario";
import { useHookCentros } from "../../../libs/hookCentros";


const VerSesion = ({handleShowVerCitas, sesionSelect}) => {
    const [toggleState, setToggleState] = useState(1);
    const [citaDatos, setCitaDatos] = useState(null);
    const [sesionesCita, setSesionesCita] = useState(null);
    const [centros, setCentros] = useState(null);
    const isFirstRender = useRef(true);
    

    const toggleTab = (idx) => {
      setToggleState(idx);
    };

    const { sesionSelected, getCita, findSesiones, toogle } = useHookCalendario();
    const { getCentros } = useHookCentros();
    const [vistaCitas, setVistaCitas] = useState(false);

    const handlerGetDatos = async citaSelected => {
        setToggleState(toogle);
        if(toogle === 2) {
            setVistaCitas(true);
        }
        const promesas = [getCita(citaSelected.citaId), findSesiones(citaSelected.citaId), getCentros()];
        const resultados = await Promise.all(promesas);
        
        setCitaDatos(resultados[0]);
        setSesionesCita(resultados[1]);
        setCentros(resultados[2]);
    }
    
    useEffect(() => {
      if (sesionSelect) {
        if(isFirstRender.current) {
          isFirstRender.current = false;
          setVistaCitas(true);
          handlerGetDatos(sesionSelect);
      }}  else if (sesionSelected) {
        if(isFirstRender.current) {
            isFirstRender.current = false;
            handlerGetDatos(sesionSelected);
        }        
     } 
    },[handlerGetDatos, sesionSelected])

    return (
      <div className="flex-col relative h-fit my-0 mx-auto max-w-7xl">

{/* inicio botones tabs */}
<div className="flex w-full">
        
{!vistaCitas && (<button
  className={toggleState === 1 ? "mx-auto m-4 p-4 text-align-center w-1/3 bg-darkblue text-white border-gray-300 rounded-xl relative box-content outline-none sm:text-xs lg:text-xl md:text-md tracking-wide transition-colors duration-200 transform " : "m-4 p-4 text-align-center w-1/3  border-gray-300 rounded-xl relative box-content outline-none sm:text-xs lg:text-xl md:text-md tracking-wide transition-colors duration-200 transform text-[#000000] border-none bg-lightgrey" }
  onClick={() => toggleTab(1)}
>
  Sesion
</button>)}

<button
  className={toggleState === 2 ? "mx-auto m-4 p-4 text-align-center w-1/3 bg-darkblue text-white border-gray-300 rounded-xl relative box-content outline-none sm:text-xs lg:text-xl md:text-md tracking-wide transition-colors duration-200 transform " : "m-4 p-4 text-align-center w-1/3  border-gray-300 rounded-xl relative box-content outline-none sm:text-xs lg:text-xl md:text-md tracking-wide transition-colors duration-200 transform text-[#000000] border-none bg-lightgrey" }
  onClick={() => toggleTab(2)}
>
  Citas
</button>
<button
  className={toggleState === 3 ? "mx-auto m-4 p-4 text-align-center w-1/3 bg-darkblue text-white border-gray-300 rounded-xl relative box-content outline-none sm:text-xs lg:text-xl md:text-md tracking-wide transition-colors duration-200 transform " : "m-4 p-4 text-align-center w-1/3  border-gray-300 rounded-xl relative box-content outline-none sm:text-xs lg:text-xl md:text-md tracking-wide transition-colors duration-200 transform text-[#000000] border-none bg-lightgrey" }
  onClick={() => toggleTab(3)}
>
  Pagos
</button>
</div>
{/* fin botones tabs */}
{/* inicio containers */}
<div className="flex-grow-1">
<div
  className={toggleState === 1 ? "w-full h-full py-2 block" : "w-full h-full py-2 hidden"}
>
  {citaDatos !== null && !vistaCitas && (<DatosSesion handleShowVerCitas={handleShowVerCitas} />)}

  
</div>

<div
  className={toggleState === 2 ? "w-full h-full py-2 block" : "w-full h-full py-2 hidden"}
>
 
 {citaDatos !== null && <EditCita handlerSetViewEditarCita={handleShowVerCitas} citaDatos={citaDatos} sesionesCita={sesionesCita} centros={centros} />}
 
</div>

<div
  className={toggleState === 3 ? "w-full h-full py-2 block" : "w-full h-full py-2 hidden"}
>

  {citaDatos !== null && <RegistrarPago handleShowVerCitas={handleShowVerCitas} cita={citaDatos} />}

</div>

</div>
{/* fin containers */}

      </div>
    );
};

export default VerSesion;

