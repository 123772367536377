import AppRoutes from "./appRoutes";
import React from 'react';
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';



function App() {
 

  return (
    <>
   <AppRoutes />
   <ToastContainer />
   </>
  );
}

export default App;
