import React from 'react';
import { DataGrid} from '@mui/x-data-grid';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import { useHookReportes } from '../../../libs/hookReportes';
import { GridToolbar } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { useHookAuth } from '../../../libs/firebase';
import { FaFilter } from 'react-icons/fa';
import { useHookCalendario } from '../../../libs/hookCalendario';
import PopUp from '../../../components/PopUp';
import VerSesion from './VerSesion';
import FiltroSideBarReporte from './FiltroSideBarReporte';

const CitasReporte = () => {
	const { 
		handleSetSesionSelected, 
		handlerToogle, 
		filtros, 
		handlerFiltros,
		handlerFiltrosDate, } =
		useHookCalendario();

	const [showModalVerSesion, setShowModalVerSesion] = useState(false);

	const [loading, setLoading] = useState(false);
	

	const showVerSesion = () => {
		setShowModalVerSesion(!showModalVerSesion);
	}

    const handleRowClick = (params) => {
		handlerToogle(2);
		const citaSelected = {citaId: params.row.col11, inicio : 1700750551, fin : 1700750551 };
		handleSetSesionSelected(citaSelected);
		showVerSesion();
      };

   
    const {
		getReport,
	} = useHookReportes();

	const [isLoading, setIsLoading] = useState(false);
	const [showFilterAside, SetShowFilterAside] = useState(false);



	const columns = [
		{ field: 'col1', headerName: 'Id', width: 40 },
		{ field: 'col2', headerName: 'Centro', width: 80 },
		{ field: 'col3', headerName: 'Fecha', width: 100 },
		{ field: 'col4', headerName: 'Paciente', width: 220 },
		{ field: 'col5', headerName: 'Especialista', width: 280 },
		{ field: 'col6', headerName: 'Servicio', width: 200 },
		{ field: 'col7', headerName: 'Precio', width: 70 },
		{ field: 'col8', headerName: 'Pagos', width: 70 },
		{ field: 'col9', headerName: 'Saldo', width: 70 },
		{ field: 'col10', headerName: 'Estado', width: 100 },
	];

	const {menuFull} = useHookAuth();
	const [rows, setRows] = useState([]);
	//const [totalCitas, setTotalCitas] = useState(0);

	function capitalizeString(str) {
		if (str !== undefined && str !== null && str !== '') {
			const words = str.split(' ');
			if (words.length > 0) {
				const palabras = words.map(word => {
					return word[0].toUpperCase() + word.slice(1);
				});
				return palabras.join(' ');
			} else {
				return str;
			}
		} else {
			return str;
		}
	}



	const fechaFormateada = unixTimestamp => {
		const fecha = new Date(unixTimestamp * 1000);
		const fechaFormateada = fecha.toLocaleDateString();
		return fechaFormateada;
	};

	function generarObjeto(data) {
		const objeto = [];
		let index = 0;
		const sortedData = data.sort((a, b) => a.inicio - b.inicio, {
			reverse: true,
		});
		for (const cita of sortedData) {
			index++;
			objeto.push({
				id: index,
				col1: index,
				col2: capitalizeString(cita.nombreCentro),
				col3: fechaFormateada(cita.inicio),
				col4: cita.pacientes,
				col5: cita.especialistas,
				col6: capitalizeString(cita.servicios),
				col7: cita.precio,
				col8: parseInt(cita.precio) - parseInt(cita.saldo),
				col9: cita.saldo,
				col10: cita.saldo === 0 ? 'Pagada' : 'Saldo',
				col11: cita.citaId,
			});
		}
		return objeto;
	}

	const handlerGetData = async (inicio, fin) => {
        const dateInicio = Math.floor(inicio.getTime() / 1000);
		const dateFin = Math.floor(fin.getTime() / 1000);
       
		setIsLoading(true);
		setLoading(true);
		
		let arrayCitas;
		let usuarios;
		
		await getReport(dateInicio, dateFin, 3, true, filtros)
			.then(res => {
				arrayCitas = res.data;
                setRows(generarObjeto(arrayCitas, usuarios));
		        setIsLoading(false); 
				setLoading(false); 
			})
			.catch(error => {
				console.log(error);
                setIsLoading(false);
				setLoading(false);
			});
	};

    const [showFilter, setShowFilter] = useState(false);

	const handlerShowFilter = (data, valueFecha1, valueFecha2) => {
		handlerFiltros(data);
		handlerFiltrosDate([valueFecha1, valueFecha2]);
		handlerGetData(valueFecha1, valueFecha2);
	}

  

	const ShowFilter = async() => {
		SetShowFilterAside(!showFilterAside);
		setShowFilter(!showFilter);
	}

	const cancelFilter = () => {
		setShowFilter(!showFilter);
		SetShowFilterAside(!showFilterAside);
	}


	return (
		<div
			className={`h-full bg-azure  overflow-y-auto relative mt-3 w-[100%] `}
			key={nanoid()}
		>
			
            {!showFilterAside && (
				<div className="fixed top-[50%] boton-[50%] right-0 p-4 z-50 animate-fade-up animate-once animate-duration-500 animate-delay-0 animate-ease-in-out ">
					<button
						className="bg-darkblue text-white rounded-full w-16 h-16 flex items-center justify-center hover:bg-darkaqua hover:animate-jump animate-once animate-duration-1000 animate-delay-0 animate-ease-in-out"
						onClick={() => ShowFilter()}
					>
						<FaFilter className="w-8 h-8" />
					</button>
				</div>
			)}
            {/* inicio filtro */}
			{showFilter && (
				<FiltroSideBarReporte 
			handlerShowFilter={handlerShowFilter} 
			loading={loading} 
			cancelFilter={cancelFilter}
			/>
				)}
			{/* fin filtro */}


			{showModalVerSesion && <PopUp closeFunction={showVerSesion} > 
			<VerSesion  handleShowVerCitas={showVerSesion}		
			/></PopUp>}

			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-50 z-40">
					<div className="absolute inset-0 flex items-center justify-center z-50">
						<div className="animate-spin rounded-full h-16 w-16 border-t-[6px] border-b-[6px] border-[#29CEBE]"></div>
					</div>
				</div>
			)}
		<div className={`mx-auto ${menuFull? 'w-[80vw]': 'w-[90vw]'}`}>
		<div className="flex mx-auto rounded-2xl h-20 w-[93%] my-5 bg-darkblue ">
				<p className="text-2xl font-semibold text-center mx-auto my-auto text-white">
					{`Reportes centro Skinner`}
				</p>
			</div>
			
			<div className=" mx-auto bg-lightgrey  rounded-2xl p-5 w-[95%]">
				<Box sx={{ height: 600, width: 1 }} className="overflow-x-auto">
					<DataGrid
						showCellVerticalBorder={true}
						showColumnVerticalBorder={true}
                        onRowClick={handleRowClick}
						// unstable_ignoreValueFormatterDuringExport={true}
						rows={rows}
						// disableColumnFilter
						// disableColumnSelector
						// disableDensitySelector
						columns={columns}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
					/>
				</Box>
			</div>
		</div>
		</div>
	);
};

export default CitasReporte;