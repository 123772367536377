import React, { useEffect,  useState, useRef } from 'react'
import { nanoid } from 'nanoid'
import { useHookCalendario } from '../../../libs/hookCalendario'

const Dia = ({number, showDay, filtros}) => {
  const [dia, setDia] = useState(number)
    const isFirstRender = useRef(true)
    const {getCountSesiones} = useHookCalendario()
    let date = new Date(number.year, number.mes-1, number.dia);
    let dateFin = new Date(number.year, number.mes-1, number.dia);
    date.setHours(0);
    date.setMinutes(0);
    dateFin.setHours(21);
    dateFin.setMinutes(59);
    const dateUnix = Math.floor(date.getTime() / 1000);
    const dateFinUnix = Math.floor(dateFin.getTime() / 1000);

    const getCount = async () => {
     
      
        const count = await getCountSesiones(dateUnix, dateFinUnix, filtros);    
          setDia({...dia, citas: count})
  
       
    }
    
    useEffect(() => {
        if(isFirstRender.current) {
            isFirstRender.current = false
            getCount()
            return
        }
        
    }, [])


  return (
    <div className={`w-1/7 h-[13vh] text-center border-solid border-1 m-1 rounded-lg flex flex-col cursor-pointer hover:bg-aqua  ${dia.mesSelecionado === true ? 'bg-white': 'bg-darkgrey  '}`} key={nanoid() } 
    onClick={() => showDay(dia.dia, dia.mes, dia.year)}>
							<p className='my-auto'>{dia.dia}</p>
							<div className='my-auto sm:text-sm md:text-md lg:text-lg text-base'>
                {dia.citas === "" ? <div className='w-[10vw] rounded-lg h-[40px] mx-auto bg-grey animate-pulse animate-infinite animate-duration-1000'></div> : <div className='w-[10vw] rounded-lg h-[30px] mx-auto bg-[#DDE8ED] text-center'>{`Citas: ${dia.citas}`}</div>}
              </div>
	</div>
  )
}

export default Dia