import React, { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHookUsuarios } from '../../../libs/hookUsuarios';
import { FaBuilding } from 'react-icons/fa';
import { nanoid } from 'nanoid';

const UpdateFecha = ({ closeModalEditar, agendaSelectedId, centros }) => {

    const [checked, setChecked] = useState('fecha');
    const [valueHoras, setValueHoras] = useState(new Date());
    const [fechas, setFechas] = useState([{inicio:null, fin:null}]);
    const { getAgendaEspecialista, updateAgendaEspecialista } = useHookUsuarios();
    const isFirstRender = useRef(true);
    const [skeleton, setSkeleton] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    

    useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false;
            getAgendaId(agendaSelectedId)
        }
        
    })

    function convertirUnixADate(unix) {
        const fecha = new Date(unix * 1000);
      
        return fecha;
      }

    const getAgendaId = async (agendaId) => {

        try {
            const res = await getAgendaEspecialista(agendaId)
            setFechas([{inicio:convertirUnixADate(res.inicio), fin:convertirUnixADate(res.fin), centro:res.nombreCentro}])
            setValueHoras(convertirUnixADate(res.inicio))
            setSkeleton(false)
        }
        catch (error) {
            console.log(error)
         }
    }

	/**
	 * Handles the form submission.
	 *
	 * @param {Event} e - The event object.
	 * @return {Promise} A promise that resolves when the form submission is complete.
	 */
	const handleSubmit = async e => {
		e.preventDefault();
	};

    const handleChange = id => {
		setChecked(id);
	};

    const handleDateChange = (date) => {
        setValueHoras(date);
    }

    const handleDateChangeInicio = (date, index) => {
        const resFilter = fechas.filter((fecha, i) => i === index);
        const newRegistro = {inicio: date, fin: resFilter[0].fin, centro: resFilter[0].centro}
        const fechasRemplazadas = fechas.map((fecha, i) => {
            if (i === index) {
                return newRegistro
            } else {
                return fecha
            }
        })
        setFechas(fechasRemplazadas)
    }

    const handleDateChangeFin = (date, index) => {
        const resFilter = fechas.filter((fecha, i) => i === index);
        const newRegistro = {inicio: resFilter[0].inicio, fin: date, centro: resFilter[0].centro}
        const fechasRemplazadas = fechas.map((fecha, i) => {
            if (i === index) {
                return newRegistro
            } else {
                return fecha
            }
        })
        setFechas(fechasRemplazadas)
    }

    const handleDateChangeCentro = (uid, index) => {
        const resFilter = fechas.filter((fecha, i) => i === index);
        const newRegistro = {inicio: resFilter[0].inicio, fin: resFilter[0].fin, centro: uid}
        const fechasRemplazadas = fechas.map((fecha, i) => {
            if (i === index) {
                return newRegistro
            } else {
                return fecha
            }
        })
        setFechas(fechasRemplazadas)
    }


    const handlerCreate = async (fechas) => {
        try {
            setIsLoading(true)
            if (checked === 'fecha'){
                fechas.forEach((fecha) => {
                    enviarFecha(fecha);
                })
                setTimeout(() => {
                    closeModalEditar();
                }, 2000);
            }

        } catch (error) {
            console.log(error)
        }
    }

    const enviarFecha = async (fecha) => {  
        
        const horaInicio = fecha.inicio.getHours();
        const minutesInicio = fecha.inicio.getMinutes();
        const diaInicio = valueHoras
        const fechaInicio = diaInicio.setHours(horaInicio, minutesInicio)
      
        const inicio = fechaInicio / 1000
        const horaFin = fecha.fin.getHours();
        const minutesFin = fecha.fin.getMinutes();
        const diaFin = valueHoras
        const fechaFin = diaFin.setHours(horaFin, minutesFin)
   
        const fin = fechaFin / 1000
        
        update(agendaSelectedId, "inicio", inicio).then(() => {
            
            update(agendaSelectedId, "fin", fin).then(() => {
                
               update(agendaSelectedId, "nombreCentro", fecha.centro).then(() => {
                setTimeout(() => {
                    closeModalEditar();
                }, 1000);
               })
            })
        }).catch((err) => {
            console.log(err)
        })
      }

      const update = async (agendaSelectedId, parametro, valor) => {
        const res = await  updateAgendaEspecialista(agendaSelectedId, parametro, valor)
        return res
        }

        const seleccionarCentro = (event, index) => {
            event.preventDefault();
            handleDateChangeCentro(event.target.value, index);
        };


	return (
		<div className="w-[30vw] min-w-[30rem] justify-center min-h-[40vh]">
			<h3 className="text-2xl text-center font-semibold text-gray-700 mb-5 ">
				Agregar disponibilidad
			</h3>
			{/* inicio form */}
			<form onSubmit={handleSubmit} >
                <div className='flex flex-col min-h-[30vh]'>
				<div className="flex justify-around mb-4">
				
					<label htmlFor="fecha" id="fecha" onClick={event => handleChange(event.target.id)} >Fecha especifica
                    <input
						type="checkbox"
						id="fecha"
						checked={checked === 'fecha'}
						onChange={event => handleChange(event.target.id)}
                        className="ml-2"
					/>
                    </label>
				</div>

               
                    <label
                    className={`my-3 sm:text-md lg:text-xl md:text-lg text-gray-800 w-full`}
                    htmlFor="Dia y Hora"
                >
                    Selecciona una fecha:
                    <br/>
                    {skeleton? 
                       (<div role="status" className="w-full animate-pulse">
                       <div className="h-5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                     </div>)
                    : (
                        <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={es}
                        key={nanoid()}
                    >
                        <DatePicker
                            value={valueHoras}
                            key={nanoid()}
                            className="block pl-10 w-full px-4 py-3 mt-4 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                            onChange={newValue => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                    )
                    }
                </label>
        
                <div>
                {fechas.map ((fecha, index) => (
                    <div className='flex flex-col' key={nanoid()}> 
                    <div className='mt-4 flex justify-items-start' key={index}>
                         <span className='pr-4 py-3'>Desde</span>
						{skeleton?(
                            <div role="status" className="w-full animate-pulse my-auto">
                            <div className=" h-5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                          </div>
                        ):(
                            <MobileTimePicker
							value={fecha.inicio}
                            key={index}
							className="block pl-10 w-[100px] px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							onChange={newValue => handleDateChangeInicio(newValue, index)}
						/>
                        )}
                        <span className='px-4 py-3'>Hasta</span>
                        {skeleton?(
                             <div role="status" className="w-full animate-pulse my-auto">
                             <div className="h-5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                           </div>
                        ):(
                            <MobileTimePicker
							value={fecha.fin}
                            key={index}
							className="block pl-10 w-[100px] px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							onChange={newValue => handleDateChangeFin(newValue, index)}
						/>
                        )}
                    </div>
                    {skeleton?(
                             <div role="status" className="w-full animate-pulse my-auto">
                             <div className="h-5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                           </div>
                        ):(
                    <div className='flex justify-items-start'>
                    <span className='pr-4 justify-center align-middle py-4'>Centro</span>
                    <div className="relative w-full">
                                <FaBuilding className="absolute top-1/2 translate-y-[-35%] left-3 text-2xl" />
                                <select
                                    id="centro"
                                    name="centro"
                                    value={fecha.centro}
                                    onChange={event => seleccionarCentro(event, index)}
                                    className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                                >
                                    <option value="0">Selecciona una opción</option>
                                    {centros !== undefined &&
                                        centros !== null &&
                                        centros.length > 0 &&
                                        centros.map(centro => (
                                            <option key={nanoid()} value={centro.uid}>
                                                {centro.nombre}
                                            </option>
                                        ))}
                                </select>
                        </div>
                    </div>
                    )}
                    </div>
                ))}
                </div>
                </div>

				{/* inicio botones guardar y cancelar */}
				<div className="flex justify-around w-3/5 m-auto mt-8 mb-4">
					<input
						type="submit"
						value={'Guardar'}
                        onClick={() => handlerCreate(fechas)}
                        disabled={isLoading}
						className="sm:text-md lg:text-xl md:text-lg min-w-fit w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white"
					></input>

					<button
						className="sm:text-md lg:text-xl md:text-lg min-w-fit w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white"
						type="button"
						onClick={() => closeModalEditar(false)}
					>
						Cancelar
					</button>
				</div>
				{/* fin botones guardar y cancelar */}
			</form>
			{/* fin form */}
		</div>
	);
};

export default UpdateFecha;
