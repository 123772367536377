import React, { useEffect,  useState, useRef } from 'react'
import { nanoid } from 'nanoid'
import { useHookCalendario } from '../../../libs/hookCalendario'
import { useHookUsuarios } from '../../../libs/hookUsuarios';
import 'moment/locale/es';
import moment from 'moment';
moment.locale('es');

const Agenda = ({hora, centros, showSesiones, filtros}) => {
  const [diasCalendar, setDiasCalendar] = useState([]);
  const [loading, setLoading] = useState(true);
  const isFirstRender = useRef(true);
  const { getAgendaCompletaEspecialista } = useHookUsuarios();
  let disponible = false;
  
  const {getSesiones} = useHookCalendario()

  function obtenerIdPorNombre(nombre, listaCentros) {
    const centroEncontrado = listaCentros.find((centro) => centro.nombre === nombre);
    if (centroEncontrado) {
      return centroEncontrado.id;
    }
    return "null";
  }

  const agendaUsuario = async (userSelect, inicio, fin) => {
    //setDisponibilidad(null)
  
    try {
        if(filtros[0].centros && filtros[0].centros.length === 1 ) {
          const nombre = filtros[0].centros[0];
          const id = obtenerIdPorNombre(nombre, centros);
          const agenda = await getAgendaCompletaEspecialista(userSelect, inicio, fin, id);      
          return agenda
        } 
    } catch (error) {
        console.error(error);
        return []
    }
}

  function filterByInicio(array, hora) {
    const filteredArray = [];
  
    for (const object of array) {
      const date = new Date(object.inicio * 1000);
      const hours = date.getHours();
      if (hours === hora) {
        filteredArray.push(object);
      }
    }
  
    return filteredArray;
  }

  const getCount = async () => {

    let start = moment(hora).startOf('day');
		let end = moment(hora).endOf('day');
    const dateUnix = Math.floor(start.unix());
    const dateFinUnix = Math.floor(end.unix());
    const correo = filtros.find((item) => item.filtro === 'especialistas').especialistas[0];
    let disponibilidad = [];
    if(correo !== undefined){
      disponibilidad = await agendaUsuario(correo, dateUnix, dateFinUnix)
    } 
    const count = await getSesiones(dateUnix, dateFinUnix, filtros);
    let dias = [];
    let  dia = moment(hora);
    let horaFin =moment(hora)
    if (true) {
      for (let hora = 6; hora <= 22; hora++) {
        dia.hour(hora);
        dia.minute(0);
        horaFin.hour(hora+1);
        horaFin.minute(0);
        //dentro de rango?
        disponible = false;


        { disponibilidad !== undefined && disponibilidad.length > 0 && disponibilidad.forEach((item) => {
            const inicioDate= new Date(item.inicio * 1000);
            const finDate = new Date(item.fin * 1000);
            const horaInicio = inicioDate.getHours();
            const horaFin = finDate.getHours();
            if (hora >= horaInicio && hora <= horaFin) {
              disponible = true;
              if(hora === horaFin){
                let minutoFin = finDate.getMinutes();
                if(minutoFin === 0){
                  disponible = false;
                } 
              }
              
              
            }
          })
        }
        
        dias.push({hora: hora.toString().padStart(2, '0'), dia: dia.clone().format('DD'), mes: dia.clone().format('MM'), year: dia.clone().format('YYYY'), array: filterByInicio(count,  hora), disponible:disponible});
      }
      setDiasCalendar([...dias]);
    }
        setLoading(false);
    }


    const formatearHora = (fecha) => {
      let formattedTime;
      let timestamp;
      let date;
      let hours;
      let minutes;
    timestamp = fecha;
    date = new Date(timestamp * 1000);
    hours = date.getHours().toString().padStart(2, '0');
    minutes = date.getMinutes().toString().padStart(2, '0');
    formattedTime = `${hours}:${minutes}`;
 

    return formattedTime
    }


    useEffect(() => {
      if(isFirstRender.current) {
          isFirstRender.current = false
          getCount()
          return
      }  
  }, [])

  




  return (
    <>
   {loading ? (<div className=' rounded-lg h-[40px] mx-auto bg-grey animate-pulse animate-infinite animate-duration-1000 col-span-7 w-full'></div>) : 
   <div className='col-span-7 mx-2'>   
      {diasCalendar.length > 0 && diasCalendar.map((number) => (
        <div className='grid grid-cols-8 key ' key={nanoid()}>
        <div className='text-left bg-white rounded-lg  m-2 p-2'>{`${number.hora}:00`}</div> 
        <div className={`col-span-7  rounded-lg  m-2 p-2 flex flex-wrap justify-start ${number.disponible === true ? ' bg-[#84d1fb]' : ' bg-white'}`}>
          {number.array.length > 0 && number.array.map((cita) => (
            <div key={nanoid()} className={`shadow-lg w-[22vw]  rounded-lg my-2 mx-2 p-2 capitalize  
            ${cita.centroId === centros[0].id ? 'bg-[#b2ff9e] ' : ''} 
            ${cita.centroId === centros[1].id ? 'bg-[#ffd670] ' : ''} 
            ${cita.centroId === centros[2].id ? 'bg-[#e9ff70] ' : ''} 
            ${cita.centroId === centros[3].id ? 'bg-[#ff7070] ' : ''} 
            ${cita.centroId === centros[4].id ? 'bg-[#ff99c8] ' : ''} 
            ${cita.centroId === centros[5].id ? 'bg-[#947bd3] ' : ''}
            ${cita.centroId === null ? 'bg-[#8ea8c3] ' : ''}
             cursor-pointer hover:bg-aqua  hover:text-white`}
            onClick={() => showSesiones(cita)}
            >
             <div className='flex justify-between sm:text-[1rem] lg:text-[1rem] md:text-[1rem] font-normal'>
              <div>{`${formatearHora(cita.inicio)} - ${formatearHora(cita.fin)}`}</div>
              <div>{`${cita.duracion} min`}</div>
              </div> 
            <div key={nanoid()} className='font-medium truncate ...' >
              {`${cita.nombrePaciente} ${cita.apellidoPaciente}`}
            </div>
            <div key={nanoid()} className='sm:text-[1rem] lg:text-[1rem] md:text-[1rem] font-light truncate ...' >
              {`${cita.nombreServicio} `}
            </div>
            <div key={nanoid()} className='sm:text-[1rem] lg:text-[1rem] md:text-[1rem] font-light truncate ...' >
              {`${cita.nombreEspecialista} ${cita.apellidoEspecialista}`}
            </div>
            </div>
          ))}
        </div>
        </div>
      )) }
    </div>
      }
   </>
  )
}

export default Agenda

{/* <div className='col-span-7'>
      <div className='grid grid-cols-8 border'>
      <div className='text-left border'>{`${number.hora}:00`}</div>
      <div className='col-span-2 sm:text-xs lg:text-lg md:text-md' >Nombre</div>
      <div className='col-span-2 sm:text-xs lg:text-lg md:text-md' >Especialista</div>
      <div className='col-span-1 sm:text-xs lg:text-lg md:text-md' >Centro</div>
      <div className='col-span-2 sm:text-xs lg:text-lg md:text-md' >Servicio</div>
      </div>
    </div> */}