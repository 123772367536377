import React, { useEffect, useRef, useState } from 'react';
import { FaTrashCan } from 'react-icons/fa6';
import {BiCalendar} from "react-icons/bi";
import { FaBuilding, FaPlus } from "react-icons/fa";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import es from 'date-fns/locale/es';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useHookUsuarios } from '../../../libs/hookUsuarios';
import { nanoid } from 'nanoid';

const CrearFecha = ({ closeModalCrear, userSelect,  valueHora,  centros}) => {
   const mes = valueHora.getMonth() + 1;
    const anio = valueHora.getFullYear();
	const [checked, setChecked] = useState('dia');
    const [valueHoras, setValueHoras] = useState(new Date());
    const [fechas, setFechas] = useState([{inicio:null, fin:null, centro:null}]);
    const { createAgendaEspecialista } = useHookUsuarios();
    const [diasMes, setDiasMes] = useState([]);
    const isFirstRender = useRef(true);
    const [disabled, setDisabled] = useState(false);
    

    useEffect(() => {
        if(isFirstRender.current){
            isFirstRender.current = false;
            findDiasDeSemana(anio, mes, 1);
        }
        
    })

    function findDiasDeSemana(anio, mes, diaSemana) {
        let diasSem;
        if(typeof diaSemana === 'string') {
            diasSem = parseInt(diaSemana)
        } else {
            diasSem = diaSemana;
        }
        const fechaInicial = new Date(`${anio}-${mes}-01`);
        const dias = [];
      
        for (let i = 0; i < 31; i++) {
          const fecha = new Date(fechaInicial.getTime() + (i * 86400000));
          const dia = fecha.getDay();
      
          if (dia === diasSem) {
            dias.push(fecha);
          }
        }
        setDiasMes(dias);
      }

	/**
	 * Handles the form submission.
	 *
	 * @param {Event} e - The event object.
	 * @return {Promise} A promise that resolves when the form submission is complete.
	 */
	const handleSubmit = async e => {
		e.preventDefault();
	};

    const handleChange = id => {
		setChecked(id);
	};

    const handleDateChange = (date) => {
        setValueHoras(date);
    }

    const handleDateChangeInicio = (date, index) => {
        const resFilter = fechas.filter((fecha, i) => i === index);
        const newRegistro = {inicio: date, fin: resFilter[0].fin, centro: resFilter[0].centro}
        const fechasRemplazadas = fechas.map((fecha, i) => {
            if (i === index) {
                return newRegistro
            } else {
                return fecha
            }
        })
        setFechas(fechasRemplazadas)
    }

    const handleDateChangeFin = (date, index) => {
        const resFilter = fechas.filter((fecha, i) => i === index);
        const newRegistro = {inicio: resFilter[0].inicio, fin: date, centro: resFilter[0].centro}
        const fechasRemplazadas = fechas.map((fecha, i) => {
            if (i === index) {
                return newRegistro
            } else {
                return fecha
            }
        })
        setFechas(fechasRemplazadas)
    }

    const handleDateChangeCentro = (uid, index) => {
        const resFilter = fechas.filter((fecha, i) => i === index);
        const newRegistro = {inicio: resFilter[0].inicio, fin: resFilter[0].fin, centro: uid}
        const fechasRemplazadas = fechas.map((fecha, i) => {
            if (i === index) {
                return newRegistro
            } else {
                return fecha
            }
        })
        setFechas(fechasRemplazadas)
    }


    const handlerCreate = async (fechas) => {
        setDisabled(true)
        try {
            if(checked === 'dia'){
                fechas.forEach((fecha) => {
                    enviarDia(userSelect, fecha);
                  });
                  setTimeout(() => {
                    closeModalCrear();
                }, 4000);
            } else if (checked === 'fecha'){
                fechas.forEach((fecha) => {

                    enviarFecha(userSelect, fecha);
                })
                setTimeout(() => {
                    closeModalCrear();
                }, 4000);
            }
            
        } catch (error) {
            console.log(error)
        }
    }

    const enviarFecha = async (userSelect, fecha) => {
        const horaInicio = fecha.inicio.getHours();
        const minutesInicio = fecha.inicio.getMinutes();
        const diaInicio = valueHoras
        const fechaInicio = diaInicio.setHours(horaInicio, minutesInicio)
        const inicio = fechaInicio / 1000
        const horaFin = fecha.fin.getHours();
        const minutesFin = fecha.fin.getMinutes();
        const diaFin = valueHoras
        const fechaFin = diaFin.setHours(horaFin, minutesFin)
   
        const fin = fechaFin / 1000
        crear(userSelect, inicio, fin, fecha.centro).then(() => {
           
        }).catch((err) => {
            console.log(err)
        })
      }

      const crear = async (userSelect, inicio, fin, centro) => {
        const response = await  createAgendaEspecialista(userSelect, inicio, fin, centro)
        return response
        }

      const enviarDia = async (userSelect, fecha) => {
        diasMes.forEach((dia) => {
            const horaInicio = fecha.inicio.getHours();
            const minutesInicio = fecha.inicio.getMinutes();
            const diaInicio = dia
            const fechaInicio = diaInicio.setHours(horaInicio, minutesInicio)
           
            const inicio = fechaInicio / 1000
            const horaFin = fecha.fin.getHours();
            const minutesFin = fecha.fin.getMinutes();
            const diaFin = dia
            const fechaFin = diaFin.setHours(horaFin, minutesFin)
           
            const fin = fechaFin / 1000

         
            crear(userSelect, inicio, fin, fecha.centro).then(() => {
              
            }).catch((err) => {
                console.log(err)
            })
            
        }   
        )
       
      }

      const seleccionarCentro = (event, index) => {
		event.preventDefault();
        handleDateChangeCentro(event.target.value, index);
	};



	return (
		<div className="w-[30vw] min-w-[30rem] justify-center min-h-[40vh]">
			<h3 className="text-2xl text-center font-semibold text-gray-700 mb-5 ">
				Agregar disponibilidad
			</h3>
			{/* inicio form */}
			<form onSubmit={handleSubmit} >
                <div className='flex flex-col min-h-[30vh]'>
				<div className="flex justify-around mb-4">
					
					<label className="mr-6 " id="dia" htmlFor="dia" onClick={event => handleChange(event.target.id)}>
						Dia de la semana
                        <input
						type="checkbox"
						id="dia"
						checked={checked === 'dia'}
						onChange={event => handleChange(event.target.id)}
						className="ml-2"
					/>
					</label>
				
					<label htmlFor="fecha" id="fecha" onClick={event => handleChange(event.target.id)} >Fecha especifica
                    <input
						type="checkbox"
						id="fecha"
						checked={checked === 'fecha'}
						onChange={event => handleChange(event.target.id)}
                        className="ml-2"
					/>
                    </label>
				</div>

                {checked === 'dia' ? (
                   <label
                   className={`my-3 sm:text-md lg:text-xl md:text-lg text-gray-800 w-full`}
                   htmlFor="selectorDia"
               >
                Seleccione un dia de la semana:
                <br/>
                        <BiCalendar className="ml-5 translate-y-[180%] left-5 text-2xl" />
                        <select className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 " 
                        onChange={e => findDiasDeSemana(anio, mes, e.target.value)}
                        >
                            <option value={1}  className='flex'>Lunes</option>
                            <option value={2} >Martes</option>
                            <option value={3} >Miercoles</option>
                            <option value={4} >Jueves</option>
                            <option value={5} >Viernes</option>
                            <option value={6} >Sabado</option>
                            <option value={7} >Domingo</option>
                        </select>
                        
                    </label>
                    
                ): (
                    <label
                    className={`my-3 sm:text-md lg:text-xl md:text-lg text-gray-800 w-full`}
                    htmlFor="Dia y Hora"
                >
                    Selecciona una fecha:
                    <br/>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={es}
                    >
                        <DatePicker
                            value={valueHoras}
                           
                            className="block pl-10 w-full px-4 py-3 mt-4 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                            onChange={newValue => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                </label>
                )}
                <div>
                {fechas.map ((fecha, index) => (
                    <div className='flex flex-col' key={nanoid()}>
                    <div className='mt-4 flex justify-items-start' key={nanoid()}>
                         <span className='pr-4 py-3'>Desde</span>
						<MobileTimePicker
							value={fecha.inicio}
                            key={index}
							className="block pl-10 w-[100px] px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							onChange={newValue => handleDateChangeInicio(newValue, index)}
						/>
                        <span className='px-4 py-3'>Hasta</span>
                        <MobileTimePicker
							value={fecha.fin}
                            key={index}
							className="block pl-10 w-[100px] px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							onChange={newValue => handleDateChangeFin(newValue, index)}
						/>
                        <FaTrashCan className='ml-6 my-auto text-darkred' 
                        onClick={() => setFechas(fechas.filter((_, i) => i !== index))} />
                    </div>
                    <div className='flex justify-items-start'>
                        <span className='pr-4 justify-center align-middle py-4'>Centro</span>
                        <div className="relative w-full">
									<FaBuilding className="absolute top-1/2 translate-y-[-35%] left-3 text-2xl" />
									<select
										id="centro"
										name="centro"
										value={fecha.centro}
										onChange={event => seleccionarCentro(event, index)}
										className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									>
										<option value="0">Selecciona una opción</option>
										{centros !== undefined &&
											centros !== null &&
											centros.length > 0 &&
											centros.map(centro => (
												<option key={centro.uid} value={centro.uid}>
													{centro.nombre}
												</option>
											))}
									</select>
							</div>
                        </div>
                    </div>
                ))}
                </div>
                <button type="button" className="w-fit my-4 sm:text-md lg:text-xl md:text-lg px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white" 
                onClick={() => setFechas([...fechas, {inicio: null, fin:null}])}>
                        <FaPlus />
                        </button>
                </div>

				{/* inicio botones guardar y cancelar */}
				<div className="flex justify-around w-3/5 m-auto mt-8 mb-4">
					<input
						type="submit"
						value={'Guardar'}
                        disabled={disabled}
                        onClick={() => handlerCreate(fechas)}
						className="sm:text-md lg:text-xl md:text-lg min-w-fit w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white"
					></input>

					<button
						className="sm:text-md lg:text-xl md:text-lg min-w-fit w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white"
						type="button"
						onClick={() => closeModalCrear()}
					>
						Cancelar
					</button>
				</div>
				{/* fin botones guardar y cancelar */}
			</form>
			{/* fin form */}
		</div>
	);
};

export default CrearFecha;
