import RutasContainer from "./RutasContainer";
import MenuDesktop from "./MenuDesktopCoordinador";
import Modal from "../../components/Modal";
import Styles from "../styles/home.module.css";
import { useHookMenu } from "../../libs/firebase";
import { useHookAuth } from "../../libs/firebase";
import { useEffect, useRef } from "react";


const HomeCoordinador = () => {
  const { showModal} = useHookMenu();
  const { validateUserLogin } = useHookAuth();
  const isFirstRender = useRef(true);

useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    validateUserLogin();
  }
})

  return (
    <div className={Styles.contenedor_principal} id="home">
      {/* modal para el crud y notificaciones*/}
      {showModal && <Modal />}
      {/* menu izquierdo */}
      <MenuDesktop className="sidebar" />
      {/* div donde se renderiza las rutas de los componentes */}
      <div className={Styles.contenedor_derecho}>
            <RutasContainer />  
      </div>
    </div>
  );
};

export default HomeCoordinador;