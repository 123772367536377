import React, { useEffect,  useState, useRef } from 'react'
import { nanoid } from 'nanoid'
import { useHookCalendario } from '../../../libs/hookCalendario'

const DiaSemana = ({number, centros, showSesiones, showDay, filtros}) => {
    const [dia, setDia] = useState(null);
    const [loading, setLoading] = useState(true);
    const isFirstRender = useRef(true);
    const {getSesiones} = useHookCalendario()
    let date = new Date(number.year, number.mes-1, number.dia);
    let dateFin = new Date(number.year, number.mes-1, number.dia);
    date.setHours(5);
    date.setMinutes(0);
    dateFin.setHours(5);
    dateFin.setMinutes(0);
    dateFin.setDate(dateFin.getDate() + 1);
    const dateUnix = Math.floor(date.getTime() / 1000);
    const dateFinUnix = Math.floor(dateFin.getTime() / 1000);

    const getCount = async () => {
          const count = await getSesiones(dateUnix, dateFinUnix, filtros);
          
          const sortedData = count.sort((a, b) => a.inicio - b.inicio, {
          });
          
          const dataSort = sortedData.sort(compareInicio);

    
            setDia(dataSort)
            setLoading(false);

      }

      function compareInicio(cita1, cita2) {
        const inicio1 = new Date(cita1.inicio);
        const inicio2 = new Date(cita2.inicio);
        return inicio1 - inicio2;
      }


      const formatearHora = (fecha) => {
        let formattedTime;
        let timestamp;
        let date;
        let hours;
        let minutes;
        // let formattedDate;
        // let dia;
        // let mes;
        // let year;
      timestamp = fecha;
		  date = new Date(timestamp * 1000);
		  hours = date.getHours().toString().padStart(2, '0');
		  minutes = date.getMinutes().toString().padStart(2, '0');
      // dia = date.getDate().toString().padStart(2, '0');
      // mes = (date.getMonth() + 1).toString().padStart(2, '0');
      // year = date.getFullYear();
      formattedTime = `${hours}:${minutes}`;
      //formattedDate = `${dia}-${mes}-${year}`;

      return formattedTime
      }


      useEffect(() => {
        if(isFirstRender.current) {
            isFirstRender.current = false
            getCount()
            return
        }  
    }, [])




  return (
    <div className='w-full justify-center text-center cursor-pointer '  >
      <div className='w-full justify-center text-center cursor-pointer ' onClick={() => showDay(number.dia, number.mes, number.year)}>
      {number.dia}
      </div>
      
      {loading ? (<div className='w-[10vw] rounded-lg h-[40px] mx-auto bg-grey animate-pulse animate-infinite animate-duration-1000'></div>) : 
      dia !== null && dia.length > 0 && dia.map((e) => 
      <div key={nanoid()} className={`w-[95%] rounded-lg mx-auto my-2 capitalize sm:text-xs lg:text-lg md:text-md 
      ${e.centroId === centros[0].id ? 'bg-[#b2ff9e] ' : ''} 
      ${e.centroId === centros[1].id ? 'bg-[#ffd670] ' : ''} 
      ${e.centroId === centros[2].id ? 'bg-[#e9ff70] ' : ''} 
      ${e.centroId === centros[3].id ? 'bg-[#ff7070] ' : ''} 
      ${e.centroId === centros[4].id ? 'bg-[#ff99c8] ' : ''} 
      ${e.centroId === centros[5].id ? 'bg-[#947bd3] ' : ''}
      ${e.centroId === null ? 'bg-[#8ea8c3] ' : ''}
       cursor-pointer hover:bg-aqua  hover:text-white`}
      onClick={() => showSesiones(e)}
      >
        <div className='flex justify-center sm:text-[1rem] lg:text-[1rem] md:text-[1rem] font-light'>
          {`${formatearHora(e.inicio)} - ${formatearHora(e.fin)}`}
        </div>
        
      <div key={nanoid()} className='sm:text-xs lg:text-lg md:text-md' >
        {`${e.nombrePaciente} ${e.apellidoPaciente}`}
      </div>
      </div>
      
      )
      }
      
      </div>
  )
}

export default DiaSemana