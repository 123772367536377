import imagenUsuario from "../../../img/user-circle1.svg";
import React, { useState, useEffect, useRef } from "react";
import { useHookUsuarios } from "../../../libs/hookUsuarios";
import CarouselSe from "../../../components/CarouselSe";


const VerUsuario = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { getUser } = useHookUsuarios();
  const [usuarioSelected, setUsuarioSelected] = useState(null);
  const isFirstRender = useRef(true);

  const handleGetUser = async (usuarioCorreo) => {

      try {
          const usuarioData = await getUser(usuarioCorreo);
          setUsuarioSelected(usuarioData)
          setIsLoading(false)
      }
      catch (error) {
          console.log(error)
      }
      
  };

  useEffect(() => {
      if (isFirstRender.current) {
          isFirstRender.current = false;
          handleGetUser(props.usuarioCorreo)
      };
  }, [handleGetUser, props.usuarioCorreo]);

  return (

    <div className="w-[35vw] justify-center">
      <img className="m-auto mb-3" src={imagenUsuario} alt="imagen del usuario" />
      <h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">DATOS USUARIO</h3>

      <form className="w-full">
        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="nombre">
          Nombre
          { isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) : (<input
            required
            id="nombre"
            name="nombre"
            className="capitalize block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
            type="text"
            placeholder="Nombres..."
            value={usuarioSelected.nombre}
            disabled
          />)}
        </label>

        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="apellido">

          Apellidos
         {isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) :( <input
            required
            pattern="^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$"
            id="apellido"
            name="apellido"
            minLength="3"
            maxLength="25"
            className="capitalize block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
            type="text"
            placeholder="Apellidos..."
            value={usuarioSelected.apellido}
            disabled
          />)}
        </label>

        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="correo">
          Correo
          {isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) :(<input
            required
            id="correo"
            name="correo"
            className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
            type="email"
            placeholder="ejemplo@dominio.com"
            value={usuarioSelected.correo}
            disabled
          />)}
        </label>

        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="rol">
          Rol
          {isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) :(<select
            required
            className="block w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
            id="rol"
            name="rol"
            value={usuarioSelected.rol}
            disabled
          >
            <option value="especialista">Especialista</option>
            <option value="coordinador">Coordinador</option>
            <option value="administrador">Administrador</option>
          </select>)}
        </label>

        <label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800" htmlFor="estado">
          Activo
          { isLoading ? (<div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>) :(<input
            id="estado"
            name="estado"
            className="w-5 m-4 mr-[35%]"
            type="checkbox"
            checked={usuarioSelected.activo}
            disabled
          />)}
        </label>

        {isLoading ? ('') : usuarioSelected.rol === 'especialista' && <div>
          <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" htmlFor="especialidad">
            Especialidad
            <input
              id="especialidad"
              name="especialidad"
              disabled
              className="capitalize block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
              type="text"
              value={usuarioSelected.especialidad}
            /> </label>
            
            <div className="flex items-center">
              {Array.isArray(usuarioSelected.servicios) && usuarioSelected.servicios.length > 0 && (
                <div className='w-full'>
                  <CarouselSe servicios={usuarioSelected.servicios} edicion={true}/>
                </div>
              )}
            </div>

            </div>}

      </form>
    </div>
  );
};

export default VerUsuario;
