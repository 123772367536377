import React, { useEffect, useState, useRef } from "react";
import { AiFillEyeInvisible, AiOutlineGoogle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import pcLogin from "../../img/pc-login.png";
import { useHookAuth } from "../../libs/firebase";
import { toast } from "react-toastify";

let Login = () => {
  const { logued, handleUserLogued, login, rol, signInWithGoogle } = useHookAuth();
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate();
  const isFirstRender = useRef(true);
  const [inputType, setInputType] = useState("password");
  const [user, setUser] = useState({
    email: "",
    password: "",
    displayName: "",
    photoURL: "",
    uid: "",
  });

  // Primer render ve si usuario esta logueado
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleUserLogued();
    }
  }, [handleUserLogued]);

  //Escucha si el estado logued cambia
  useEffect(() => {
    if (logued) {
      setShowModal(false)
      if (rol === "administrador") {
        navigate("/home/admin");
      }
      if (rol === "especialista") {
        navigate("/home/especialista");
      }
      if (rol === "coordinador") {
        navigate("/home/coordinador");
      }
    }
  }, [logued, navigate]);

  /**
   * Esta función recibe los input y setea user.
   * @author Victor Muñoz
   */
  const handleChange = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
  };

  /**
   * Esta función controla la visibilidad del input password.
   * @author Laura Navarro Zapata
   */
  const handleInput = () =>
    setInputType(inputType === "text" ? "password" : "text");

  /**
* Funcion Login desde firebase.
* @author Victor Muñoz
*/

  const handleSignIn = async (e) => {
    setShowModal(true)
    e.preventDefault();
    await login(user.email, user.password).then((data) => {
      if (data) {
        handleUserLogued();
      } 
    }).catch((error) => {
     
      if(error.code === "auth/wrong-password"){
        toast.error("Contraseña incorrecta"				 , {
          position: 'top-center',
          theme: 'colored',
        }
      );
        setShowModal(false)
      } if (error.code === "auth/user-not-found") {
        toast.error("Usuario no encontrado"				 , {
          position: 'top-center',
          theme: 'colored',
        }
      );
        setShowModal(false)
      } if (error.code === "auth/too-many-requests") {
        toast.error("Numero maximo de intentos alcanzado"		 , {
          position: 'top-center',
          theme: 'colored',
        }
        );
        setShowModal(false)
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        
      } if (error.code === "auth/network-request-failed") {
        toast.error("Error de red"				 , {
          position: 'top-center',
          theme: 'colored',
        }
        );
        setShowModal(false)
      } 
  
      if (error.code === "auth/invalid-email") {
        toast.error("Error de red"				 , {
          position: 'top-center',
          theme: 'colored',
        }
        );
        setShowModal(false)
        
      }

      if (error.code === "auth/invalid-login-credentials") {
        toast.error("Error credenciales no validas"				 , {
          position: 'top-center',
          theme: 'colored',
        }
        );
        setShowModal(false)
        
      }
      
      console.log(error.code);
      setShowModal(false)
      
    })

  };

  return (

    <div className="h-screen overflow-hidden flex items-center justify-center bg-lightgrey ">
      {showModal && (

        <div className="fixed inset-0 bg-white bg-opacity-50 z-40">
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="animate-spin rounded-full h-16 w-16 border-t-[6px] border-b-[6px] border-[#29CEBE]"></div>
          </div>
        </div>

      )}

      <div className="bg-darkblue h-screen w-screen">
        <div className="flex flex-col items-center flex-1 h-full justify-center px-4 sm:px-0">
          {/* container login */}
          {/* columna imagen */}
          <div className="rounded-2xl flex justify-center flex-wrap items-center px-6 py-12 max-w-6xl mx-auto w-full p-6 m-auto bg-white ">
            <div className="hidden md:flex md:w-1/2 lg:border-e-2 border-grey py-20  px-30 h-max md lg:w-[45%] mb-0 md:mb-3 sm:mb-0 sm:w-[30%] lg:flex md:justify-center lg:justify-center">
              <img src={pcLogin} alt="pc-login" className="w-64 rounded-2xl" />
            </div>
            {/* fin columna imagen */}
            {/* columna formulario */}
            <div className="md:w-[67%] lg:w-[45%] lg:ml-20 relative flex flex-col justify-center min-h-fit overflow-hidden">
              <div className="flex flex-col flex-1 justify-center mb-8">
                <h1 className="text-3xl  text-center font-semibold text-gray-700 ">
                  Iniciar sesión
                </h1>
                <div className="w-full mt-4">
                  {/* inicio formulario */}
                  <form className="form-horizontal w-3/4 mx-auto">
                    {/* inicio input correo */}
                    <div className="mb-2 relative">
                      <label
                        htmlFor="email"
                        className="block sm:text-xs lg:text-lg md:text-md  text-gray-800"
                      >
                        Correo
                      </label>
                      <input
                        id="email"
                        type="text"
                        className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                        name="email"
                        onChange={handleChange}
                        value={user.email}
                        placeholder="tucorreo@company.com"
                      />
                    </div>
                    {/* inicio input correo */}
                    {/* inicio input password */}
                    <div className="mb-2 relative">
                      <label
                        htmlFor="password"
                        className="sm:text-xs lg:text-lg md:text-md block  text-gray-800"
                      >
                        Contraseña
                      </label>

                      <input
                        type={inputType}
                        className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
                        id="password"
                        name="password"
                        value={user.password}
                        onChange={handleChange}
                        placeholder="*************"
                        required
                      />
                      <AiFillEyeInvisible
                        onClick={handleInput}
                        className={`absolute right-3 lg:top-12 md:top-9 sm:top-9 max-sm:top-11  text-xl cursor-pointer ${inputType === "text"
                          ? "hover:text-red"
                          : "hover:text-aqua"
                          }`}
                      />
                    </div>
                     {/* fin input password */}
                     {/* inicio botones ingresar */}
                    <div className="flex items-center mt-4"></div>
                    <div className="flex flex-col mt-8">
                      <button
                        className="sm:text-xs lg:text-xl md:text-md w-full px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
                        onClick={handleSignIn}
                      >
                        Ingresar
                      </button>
                      <br />
                    </div>
                  </form>
                    {/* fin formulario */}
                  <div className="flex items-center my-4 before:border-t before:flex-1 before:border-gray-300 after:border-t after:flex-1 after:border-gray-300">
                    <p className="mx-4 sm:text-s lg:text-xl md:text-lg font-semibold text-black ">
                      o inicia sesión
                    </p>
                  </div>
                  <div className="form-horizontal w-3/4 mx-auto">
                    <button
                      className="sm:text-xs lg:text-xl md:text-md w-full px-4 py-2 tracking-wide transition-colors duration-200 transform bg-red rounded-md hover:bg-darkred hover:text-white active:outline-none active:shadow-lg active:text-white"
                     onClick={signInWithGoogle}
                    >
                      <AiOutlineGoogle className="my-1 inline mx-4" />
                      Cuenta Google
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* fin columna formulario */}
          </div>
          {/* fin container login */}
        </div>
      </div>
    </div>
  );
};

export default Login;
