import React, { useEffect, useState, useRef } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import es from 'date-fns/locale/es';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import {
	FaHandHoldingMedical,
	FaConciergeBell,
	FaBuilding,
	FaUser,
} from 'react-icons/fa';
import '../../styles/calendarioCita.css';
import { useHookCalendario } from '../../../libs/hookCalendario';
import { useHookServicios } from '../../../libs/hookServicios';
import solesImg from '../../../img/simbolo-de-moneda-nuevo-sol-de-peru.png';


const EditCita = ({
	handlerSetViewEditarCita,
	citaDatos,
	sesionesCita,
	centros,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [valueHora, setValueHora] = useState(new Date());
	const { updateSesion, updateCita, deleteCita, handlerDeleteImages } = useHookCalendario();
	const {getServicioEspecialistas} = useHookServicios();
	const [salaSeleccionada, setSalaSeleccionada] = useState(null);
	const [sesionSelected, setSesionSelected] = useState(null);
	const [centro, setCentro] = useState(null);
	const [centroValue, setCentroValue] = useState(null);
	const [especialistaFilter, setEspecialistaFilter] = useState(null);

	const [sesiones, setSesiones] = useState(null);
	const [disabled, setDisabled] = useState(false);
	const isFirstRender = useRef(true);

	const [cita, setCita] = useState({
		especialista: null,
		comentario: '',
		precio: '',
		saldo: '',
		pago: false,
		paciente: null,
		sesiones: null,
		pagos: null,
	});

	const [paciente, setPaciente] = useState({
		pacienteId: '',
		nombre: '',
		apellido: '',
		documento: '',
		correo: '',
		telefono: '',
	});

	const [especialistaCita, setEspecialistaCita] = useState({
		especialistaId: '',
		nombre: '',
		apellido: '',
		correo: '',
		telefono: '',
	});

	const [servicioCita, setServicioCita] = useState({
		servicioId: '',
		nombre: '',
		descripcion: '',
	});

	const handlerSearchEspecialistas = async (data) => {
		await getServicioEspecialistas(data).then((res) => {
			setEspecialistaFilter(res);
			setEspecialistaCita({
				...especialistaCita,
				especialistaId: citaDatos.especialista[0].especialistaId,
				nombre: citaDatos.especialista[0].nombre,
				apellido: citaDatos.especialista[0].apellido,
				correo: citaDatos.especialista[0].correo,
				telefono: citaDatos.especialista[0].telefono,
			});
			setIsLoading(false);
		})
	}

	useEffect(() => {
		if (Array.isArray(sesionesCita) && sesionesCita.length > 0) {
			setPaciente({
				...paciente,
				pacienteId: citaDatos.paciente[0].pacienteId,
				nombre: citaDatos.paciente[0].nombre,
				apellido: citaDatos.paciente[0].apellido,
				documento: citaDatos.paciente[0].documento,
				correo: citaDatos.paciente[0].correo,
				telefono: citaDatos.paciente[0].telefono,
			});
			setServicioCita({
				...servicioCita,
				servicioId: citaDatos.servicio[0].servicioId,
				nombre: citaDatos.servicio[0].nombre,
				descripcion: citaDatos.servicio[0].descripcion,
			});
			setCita({
				...cita,
				especialista: citaDatos.especialista[0].especialistaId,
				precio: citaDatos.precio,
				comentario: citaDatos.comentario,
			});
			setEspecialistaCita({
				...especialistaCita,
				especialistaId: citaDatos.especialista[0].especialistaId,
				nombre: citaDatos.especialista[0].nombre,
				apellido: citaDatos.especialista[0].apellido,
				correo: citaDatos.especialista[0].correo,
				telefono: citaDatos.especialista[0].telefono,
			});
			setSesiones(filterArrayByStartTime(sesionesCita));
		}
	}, [sesionesCita]);

	useEffect(() => {
		if (isFirstRender.current) {
			
			if (servicioCita.servicioId !== '') {
				isFirstRender.current = false;
				handlerSearchEspecialistas(servicioCita.servicioId);
			}
		}
		
	}, [servicioCita , handlerSearchEspecialistas]);


	const seleccionarEspecialista = async event => {
		event.preventDefault();
		if (event.target.value !== '0') {
			setCita({ ...cita, especialista: event.target.value });

			const especialistaSeleccionado = especialistaFilter.find(
				especialista => especialista.especialistaId === event.target.value
			);

			setEspecialistaCita({
				...especialistaCita,
				especialistaId: especialistaSeleccionado.especialistaId,
				nombre: especialistaSeleccionado.nombre,
				apellido: especialistaSeleccionado.apellido,
				correo: especialistaSeleccionado.especialistaId,
				telefono: especialistaSeleccionado.telefono,
			});
		}
	};

	function compareInicio(cita1, cita2) {
		return cita1.inicio - cita2.inicio;
	  }

	const filterArrayByStartTime = array => {
		const datos = array.filter((item) => item.inicio !== "").reverse();
		const citasOrdenadas = datos.sort(compareInicio);
		const sindatos = array.filter((item) => item.inicio === "").reverse();
		return citasOrdenadas.concat(sindatos);
	};



	const seleccionarSesion = (data, index) => {
		setSesionSelected(data, index);
		const sesion = sesiones[index];

		if (
			sesion.nombreCentro !== '' &&
			sesion.nombreCentro !== 'strapi_migracion'
		) {
		
			const centroSel = centros.find(centro => sesion.nombreCentro.toLowerCase() === centro.nombre.toLowerCase() );
			sesion.centroId = centroSel?.uid;
			sesion.nombreCentro = centroSel?.nombre;
			setCentro(centroSel);
			setCentroValue(centroSel.uid);
		} else {
			setCentro(null);
			setSalaSeleccionada(null);
			setCentroValue('0');
		}

		if (sesion.sala !== '' && sesion.sala !== 'strapi_migration') {
			const sala = { nombre: sesion.sala };
			setSalaSeleccionada(sala);
			sesion.sala = data.sala;
		} else {
			setSalaSeleccionada(null);
		}

		if (sesion.inicio !== '' && sesion.oldId) {
			const fecha = new Date((sesion.inicio-18000) * 1000);
			handleDateChange(fecha, sesion);
		} else {
			const fecha = new Date((sesion.inicio) * 1000);
			handleDateChange(fecha, sesion);
		}
		sesion.nombresesion = data.nombre;
		sesion.nombre = data.nombre;
		// Reemplazamos el objeto original con el objeto modificado
		setSesiones([
			...sesiones.slice(0, index),
			sesion,
			...sesiones.slice(index + 1),
		]);
	};

	const seleccionarCentro = event => {
		event.preventDefault();
		if (event.target.value !== '0') {
			const centroSel = centros.find(
				centro => centro.uid === event.target.value
			);
			setCentro(centroSel);
			setCentroValue(event.target.value);
			const newData = sesiones.map(objeto => {
				if (objeto.nombre === sesionSelected.nombre) {
					return {
						...objeto,
						centroId: event.target.value,
						nombreCentro: centroSel.nombre,
					};
				}
				return objeto;
			});
			setSesiones(newData);
		}
	};

	const handlerSala = data => {
		setSalaSeleccionada(data);
		const newData = sesiones.map(objeto => {
			if (objeto.nombre === sesionSelected.nombre) {
				return {
					...objeto,
					sala: data.nombre,
				};
			}
			return objeto;
		});
		setSesiones(newData);
	};

	const handleDateChange = (date, data) => {
		
		setValueHora(date);
		const inicio = new Date(date).getTime() / 1000;
		let duracion;
		if (data === undefined) {
			duracion = parseInt(sesionSelected.duracion);
		} else {
			duracion = parseInt(data.duracion);
		}

		let fechafin = new Date(date);
		fechafin.setMinutes(fechafin.getMinutes() + duracion);
		const fin = new Date(fechafin).getTime() / 1000;
		let newData;

		if (data === undefined) {
			newData = sesiones.map(objeto => {
				if (objeto.nombre === sesionSelected.nombre) {
					return {
						...objeto,
						inicio: inicio,
						fin: fin,
					};
				}
				return objeto;
			});
		} else {
			newData = sesiones.map(objeto => {
				if (objeto.nombre === data.nombre) {
					return {
						...objeto,
						inicio: inicio,
						fin: fin,
					};
				}
				return objeto;
			});
		}

		setSesiones(newData);
	};

	const handleComentarioChange = event => {
		setCita({ ...cita, comentario: event });
	};

	const handlerGuardar = event => {
		event.preventDefault();
		const filteredSessions = sesiones.map(session => {
			delete session.nombresesion;
			return session;
		});
		const sesionFiltered = filteredSessions.filter(
			item => item.inicio !== '' && item.fin !== ''  && item.sala !== '' && item.centroId !== ''
		);
		if (
			cita.precio !== '' &&
			paciente.pacienteId !== '' &&
			servicioCita.servicioId !== '' &&
			especialistaCita.especialistaId !== '' &&
			sesionFiltered.length > 0
		) {
			handlerCreateCita(filteredSessions);
		} else {
			toast.error('Debe completar todos los campos y una sesion', {
				position: 'top-center',
				theme: 'colored',
			});
		}
	};

	const handlerCreateCita = async data => {
		let error = null;
		function closeModal() {
			setTimeout(() => {
				handlerSetViewEditarCita(undefined, undefined, true);
			}, 2000);
		}

		setDisabled(true);
		if (cita.precio !== citaDatos.precio) {
			await updateCita(citaDatos.uid, 'precio', cita.precio)
				.then(() => {})
				.catch(err => {
					error = err;
				});
		}

		if (citaDatos.especialista[0].especialistaId !== especialistaCita.especialistaId) {
			const especialista = [
				{
					especialistaId: especialistaCita.especialistaId,
					nombre: especialistaCita.nombre,
					apellido: especialistaCita.apellido,
					correo: especialistaCita.correo,
					telefono: '',
					documento: '',
				},
			]
			await updateCita(citaDatos.uid, 'especialista', especialista)
				.then(() => {})
				.catch(err => {
					error = err;
				});
		}

		if (cita.comentario !== citaDatos.comentario) {
			await updateCita(citaDatos.uid, 'comentario', cita.comentario)
				.then(() => {})
				.catch(err => {
					error = err;
				});
		}
		for (const sesion of data) {
			const citaFiltrada = sesionesCita.filter(cita => cita.uid === sesion.uid);
			for (const campo in sesion) {
				// Accede al valor del campo
				if (sesion[campo] !== citaFiltrada[0][campo]) {
					const valor = sesion[campo];
					await updateSesion(sesion.uid, campo, valor)
						.then(() => {})
						.catch(err => {
							error = err;
						});
					if(sesion.oldId && campo === "inicio") {
						await updateSesion(sesion.uid, "oldId", "")
						.then(() => {})
						.catch(err => {
							error = err;
						});
					}
				}
			}
		}

		if (error === null) {
			toast.success('Cita actualizada correctamente ', {
				position: 'top-center',
				theme: 'colored',
			});

			closeModal();
		} else {
			toast.error('Error actualizando sesion', {
				position: 'top-center',
				theme: 'colored',
			});

			closeModal();
		}
	};

	let handleDeleteCita = async () => {
		setDisabled(true);
		function closeModal() {
			setTimeout(() => {
				handlerSetViewEditarCita(undefined, undefined, true);
			}, 1000);
		}
		setDisabled(true);
		
		if (citaDatos.pagos !== undefined){
			for (const pago of citaDatos.pagos) {
				for (const adjunto of pago.adjuntos){
					await handlerDeleteImages(adjunto).then(() => {
					}).catch(() => {
					});
				}
			}
		}
		

		await deleteCita(citaDatos.uid)
			.then(data => {
				if (data === 'cita eliminada correctamente') {
					toast.success('Cita eliminado correctamente', {
						position: 'top-center',
						theme: 'colored',
					});
					closeModal();
				} else {
					toast.error(
						{ data },
						{
							position: 'top-center',
							theme: 'colored',
						}
					);
					closeModal;
				}
			})
			.catch(error => {
				toast.error(
					{ error },
					{
						position: 'top-center',
						theme: 'colored',
					}
				);
				closeModal();
			});
	};

	return (
		<div className="flex-col relative h-fit my-12 mx-auto max-w-7xl">
			<h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">
				EDITAR CITA
			</h3>
			
			<form className="w-full">
				<div className="flex flex-col lg:flex-row w-[50vw]">
					<div className="lg:w-1/2 p-3 w-full ">
						{/* buscador de paciente */}
						<label
							className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800 "
							htmlFor="paciente"
						>
							{' '}
							Paciente:
							{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
								<FaUser className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
								<input
									type="text"
									value={`${paciente.nombre} ${paciente.apellido}`}
									name="paciente"
									id="paciente"
									placeholder="Nombre del paciente"
									disabled
									className="capitalize w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
								/>
							</div>)}
						</label>
						{/* fin buscador paciente */}
						{/* selector servicio */}
						{sesiones !== null && (
							<label
								className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
								htmlFor="nombreServicio"
							>
								{' '}
								Servicio:
								{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
									<FaConciergeBell className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
									<input
										type="text"
										value={servicioCita.nombre}
										name="nombreServicio"
										id="nombreServicio"
										placeholder="Servicio..."
										disabled
										className="capitalize w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									/>
								</div>)}
							</label>
						)}
						{/* fin selector servicio */}

							{/* selector especialistas */}
						{sesiones !== null && (
							<label
								className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
								htmlFor="especialista"
							>
								Selecciona un especialista:
								{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
									<FaHandHoldingMedical className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />

									<select
										required
										id="especialista"
										name="especialista"
										value={especialistaCita.especialistaId}
										onChange={event => seleccionarEspecialista(event)}
										className="capitalize block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
									>
										<option value="0">Selecciona una opción</option>
										{especialistaFilter !== null &&
											especialistaFilter.length > 0 &&
											especialistaFilter.map((especialista, index) => (
												<option key={index} value={especialista.especialistaId} className='capitalize'>
													{`${especialista.nombre} ${especialista.apellido}`}
												</option>
											))}
									</select>
								</div>)}
							</label>
						)}
						{/* fin selector especialistas */}

						{/* input precio */}
						{especialistaCita.especialistaId !== '' && (
							<label
								className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
								htmlFor="precio"
							>
								Precio:
								{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<div className="relative">
								<img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />
									<input
										required
										pattern="[0-9]+(?:\.[0-9]+)?"
										id="precio"
										name="precio"
										value={cita.precio}
										onChange={event =>
											setCita({ ...cita, precio: event.target.value })
										}
										className="block pl-10 w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										type="number"
										placeholder="0.0"
									/>
								</div>)}
							</label>
						)}

						{/* nota */}
						
							<div>
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="nota"
								>
									Nota
									{cita.precio !== null && cita.precio !== '' && (<textarea
										id="nota"
										name="nota"
										placeholder="Descripción adicional para la cita"
										value={cita.comentario}
										onChange={event =>
											handleComentarioChange(event.target.value)
										}
										rows={4}
										className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									/>
						)}
						</label>
							</div>
						{/* fin nota */}

						{/* fin labels */}
					</div>
					{/* container derecha */}
					<div className="lg:w-1/2 w-full p-3 lg:border-l lg:border-grey sm:w-full ">
						{/* selector de sesiones */}
						{cita.precio !== '' && (
							<div className="relative w-full">
								<label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
									Selecciona una sesion:
								</label>
								{isLoading? (<div role="status" className="w-full animate-pulse ">
                <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-3"></div>
              </div>) : (<ul className="flex flex-wrap">
									{Array.isArray(sesiones) &&
										sesiones.map((sesion, index) => (
											<li
												onClick={() => seleccionarSesion(sesion, index)}
												className={`${
													sesionSelected &&
													sesionSelected.nombre === sesion.nombre
														? 'bg-aqua text-white'
														: ''
												} cursor-pointer ring-1 ring-aqua px-2 py-2 w-auto m-2 tracking-wide rounded-md flex justify-center`}
												key={index}
											>
												{sesion.nombre}
											</li>
										))}
								</ul>)}
								{/* fin selector de sesiones     */}
							</div>
						)}

						{/* selector de centro */}
						{sesionSelected !== null && (
							<div className="relative">
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="centro"
								>
									Selecciona un centro:
									<FaBuilding className="absolute top-1/2 translate-y-[25%] left-3 text-2xl" />
									<select
										id="centro"
										name="centro"
										value={centroValue}
										onChange={event => seleccionarCentro(event)}
										className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									>
										<option value="0">Selecciona una opción</option>
										{centros !== undefined &&
											centros !== null &&
											centros.length > 0 &&
											centros.map(centro => (
												<option key={centro.uid} value={centro.uid}>
													{centro.nombre}
												</option>
											))}
									</select>
								</label>
							</div>
						)}
						{/* fin input centro */}

						{centro !== null && (
							<div className="relative">
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="centro"
								>
									Selecciona un dia:
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
										adapterLocale={es}
									>
										<DatePicker
											value={valueHora}
											className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
											onChange={newValue => handleDateChange(newValue)}
										/>
									</LocalizationProvider>
								</label>
							</div>
						)}

						{/* selector hora */}

						{centro !== null && (
							<div className="relative">
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="centro"
								>
									Selecciona la hora:
									<MobileTimePicker
										value={valueHora}
										className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										onChange={newValue => handleDateChange(newValue)}
									/>
								</label>
							</div>
						)}

						{/* fin selecor hora */}

						{/* selector sala */}
						{centro !== null && (
							<div>
								<label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
									Selecciona una sala:
								</label>
								<ul className="flex flex-wrap">
									{centro.salas.map((sala, index) => (
										<li
											onClick={() => handlerSala(sala, index)}
											className={`${
												salaSeleccionada &&
												salaSeleccionada.nombre === sala.nombre
													? 'bg-aqua text-white'
													: ''
											} cursor-pointer ring-1 ring-aqua px-2 py-2 w-auto m-4 tracking-wide rounded-md flex justify-center`}
											key={index}
										>
											{sala.nombre}
										</li>
									))}
								</ul>
							</div>
						)}

						{/* fin selecto sala */}
					</div>
					{/* fin container derecha */}
				</div>

				<div className="flex justify-around w-3/4 m-auto mt-8 mb-4">
					<button
						onClick={handlerGuardar}
						disabled={disabled}
						className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white "
					>
						Guardar
					</button>
					<button
						type="button"
						onClick={handleDeleteCita}
						disabled={disabled}
						className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-darkred text-white rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white"
					>
						Eliminar
					</button>
				</div>
			</form>
		</div>
	);
};

export default EditCita;


						// {/* selector especialistas */}
						// {sesiones !== null && (
						// 	<label
						// 		className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
						// 		htmlFor="nombreServicio"
						// 	>
						// 		{' '}
						// 		Servicio:
						// 		<div className="relative">
						// 			<FaConciergeBell className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
						// 			<input
						// 				type="text"
						// 				value={servicioCita.nombre}
						// 				name="nombreServicio"
						// 				id="nombreServicio"
						// 				placeholder="Servicio..."
						// 				disabled
						// 				className=" w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
						// 			/>
						// 		</div>
						// 	</label>
						// )}
						// {/* fin selector especialistas */}


							// {/* selector de servicio */}
							// {paciente.nombre !== '' && (
							// 	<label
							// 		className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
							// 		htmlFor="especialista"
							// 	>
							// 		{' '}
							// 		Especialista:
							// 		<div className="relative">
							// 			<FaHandHoldingMedical className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
							// 			<input
							// 				type="text"
							// 				name="especialista"
							// 				value={`${especialistaCita.nombre} ${especialistaCita.apellido}`}
							// 				id="especialista"
							// 				placeholder="Especialista..."
							// 				disabled
							// 				className="capitalize w-full px-4 py-2 pl-12  text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							// 			/>
							// 		</div>
							// 	</label>
							// )}
							// {/* fin selector de servicio */}