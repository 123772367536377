import React, { useState, useEffect, useRef } from 'react';
import { useHookServicios } from '../../../libs/hookServicios';
import { nanoid } from "nanoid";
import CrearServicio from "./crearServicio";
import UpdateServicio from './UpdateServicio'
import EliminarServicio from './EliminarServicio'
import VerServico from './VerServicio';
import Paginacion from "../../../components/paginacion";
import PopUp from "../../../components/PopUp";

import { FaConciergeBell } from "react-icons/fa";
import { FaTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";

const Servicios = () => {
  const { getServicios } = useHookServicios();
  const [servicios, setServicios] = useState(null);
  const servicioSelectedId = useRef(null)
  const isFirstRender = useRef(true);
  const [nombreServicioEliminar, setNombreServicioEliminar] = useState('');

  const [showModalEditar, setShowModalEditar] = useState(false)
  const [showModalCrear, setShowModalCrear] = useState(false)
  const [showModalEliminar, setShowModalEliminar] = useState(false)
  const [showModalVer, setShowModalVer] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

    /**
  * Handles the retrieval of servicios data and sorts it by name.
  *
  * @return {Promise<void>} - Promise that resolves when the data is retrieved and sorted.
  */
  const handleGetServicios = async () => {
    try {
      const serviciosData = await getServicios();
      let array = serviciosData.sort((a, b) => a.nombre.localeCompare(b.nombre));
      setServicios(array);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      handleGetServicios();
    }
  }, [handleGetServicios])

  /**
 * Handles the event when the "Editar" button is clicked and show Popup.
 *
 * @param {string} servicioId - The ID of the service being edited.
 * @return {void} This function does not return anything.
 */
  const handleOnEditar = (servicioId) => {
    servicioSelectedId.current = servicioId;
    setShowModalEditar(true)
  }

    /**
 * Handles the event when the "Eliminar" button is clicked and show Popup.
 *
 * @param {string} servicioId - The ID of the service being edited.
 * @param {string} nombre - The name of the service being edited.
 * @return {void} This function does not return anything.
 */
  const handleOnEliminar = (servicioId, nombre) => {
    setNombreServicioEliminar(nombre)
    servicioSelectedId.current = servicioId;
    setShowModalEliminar(true)
  }

    /**
   * Handles the "onVer" event for a given service ID and shows Popup.
   *
   * @param {string} servicioId - The ID of the service to handle.
 * @return {void} This function does not return anything.
   */
  const handleOnVer = (servicioId) => {
    servicioSelectedId.current = servicioId;
    setShowModalVer(true)
  }

  //PAGINACION:
  const [paginaActual, setPaginaActual] = useState(1);
  const serviciosPorPagina = 10;
  const numeroTotalPaginas = Array.isArray(servicios) ? Math.ceil(servicios.length / serviciosPorPagina) : 1;


  const handlePaginaCambio = (numeroPagina) => {
    setPaginaActual(numeroPagina);
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < numeroTotalPaginas) {
      setPaginaActual(paginaActual + 1);
    }
  };

    /**
   * Closes the modal for editing and triggers a reload of the services.
    * @return {void} This function does not return anything.
   */
  const closeModalEditar = () => {
    setShowModalEditar(false)
    setIsLoading(true)
    handleGetServicios();
  }

  /**
   * Closes the modal for editing and triggers.
    * @return {void} This function does not return anything.
   */
  const closeModalVer = () => {
    setShowModalVer(false)
  }

  /**
   * Closes the modal for editing and triggers.
    * @return {void} This function does not return anything.
   */
  const closeModalEliminar = () => {
    setShowModalEliminar(false)
  }

  /**
   * Closes the modal for editing and triggers.
    * @return {void} This function does not return anything.
   */
  const closeModalCrear = () => {
    setShowModalCrear(false)
  }

  return (
    <div className="w-full h-full bg-azure overflow-x-auto" key={nanoid()}>

      {showModalEditar && ( <PopUp closeFunction={closeModalEditar}>
              <UpdateServicio servicioId={servicioSelectedId.current} getServicios={handleGetServicios} />
              </PopUp>)}

      {showModalVer && (<PopUp closeFunction={closeModalVer}>
              <VerServico servicioId={servicioSelectedId.current} />
              </PopUp>)}

      {showModalEliminar && (<PopUp closeFunction={closeModalEliminar}>
              <EliminarServicio nombre={nombreServicioEliminar} servicioId={servicioSelectedId.current} setShowModal={setShowModalEliminar} getServicios={handleGetServicios} setIsLoading={setIsLoading} />
              </PopUp>)}

      {showModalCrear && (<PopUp closeFunction={closeModalCrear}>
              <CrearServicio setShowModal={setShowModalCrear} getServicios={handleGetServicios} setIsLoading={setIsLoading} />
              </PopUp>)}
        {/* inicio lista de servicios */}
      <div id="last-users" className="w[98%] m-4  bg-lightgrey rounded-md  p-4" key={nanoid()}>
        <div className="flex justify-between">
          <h1 className="text-3xl  text-center font-semibold text-gray-700 m-4">
            Lista de servicios
          </h1>

          <button
            onClick={() => setShowModalCrear(true)}
            className=" max-w-full items-center lg:m-4 flex  sm:text-xs lg:text-xl md:text-md lg:px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
          >
            <FaConciergeBell className="lg:mr-2 w-8" /> <span> Añadir servicio</span>
          </button>
        </div>
        {/* table - table head */}
        <div className="overflow-x-auto">
          <table className="m-auto whitespace-nowrap w-[98%] rounded-md">
            <thead className=" ">
              <tr>
                <th className="text-left py-3 px-2">Nombre</th>
                <th className="text-left py-3 px-2">Nº Sesiones</th>
                <th className="text-left py-3 px-2">Precio</th>
                <th className="text-left py-3 px-2">Precio mínimo</th>
                <th className="text-left py-3 px-2"></th>
              </tr>
            </thead>
        {/* incion tablebody */}
            <tbody>
              {!Array.isArray(servicios) || isLoading ? (

                Array.from({ length: (serviciosPorPagina / 2) }, (_, index) => (
                  <tr key={index} className="bg-azure border-lightgrey border-b-8">
                    <td colSpan={5} className="py-3 px-2">
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                      </div>
                    </td>
                  </tr>
                ))

              ) : (
                Array.isArray(servicios) && !isLoading && servicios.length > 0 &&
                servicios.slice((paginaActual - 1) * serviciosPorPagina, paginaActual * serviciosPorPagina).map((servicio, index) => (
                  <tr key={index} className="bg-azure border-lightgrey border-b-8 hover:bg-aqua hover:text-white cursor-pointer">

                    <td onClick={() => handleOnVer(servicio.uid)} className="py-3 px-2 capitalize rounded-l-[10px]"
                    >{servicio.nombre}
                    </td>
                    <td onClick={() => handleOnVer(servicio.uid)} className="py-3 px-2 text-center" >
                      {servicio.sesiones.length}
                    </td>
                    <td onClick={() => handleOnVer(servicio.uid)} className="py-3 px-2" >
                      S/. {servicio.precio}
                    </td>
                    <td onClick={() => handleOnVer(servicio.uid)} className="py-3 px-2 text-center" >
                      S/. {servicio.precioMinimo}
                    </td>
                    <td className="py-3 px-2 rounded-r-md">
                      <div className='flex justify-around space-x-2' key={nanoid()}>
                        <button type="button" title="Edit" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => handleOnEditar(servicio.uid)}><FiEdit /></button>
                        <button type="button" className="text-red hover:text-darkred" onClick={() => handleOnEliminar(servicio.uid, servicio.nombre)}><FaTrashCan /></button>
                      </div>
                    </td>
                  </tr>
                )))}

            </tbody>
          </table>
          {/* fin table */}
        </div>
        <Paginacion
          currentPage={paginaActual}
          totalPages={numeroTotalPaginas}
          onPageChange={handlePaginaCambio}
          onPrevPage={handlePaginaAnterior}
          onNextPage={handlePaginaSiguiente}
        />
      </div>
    </div>
  );
}

export default Servicios;
