import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaMoneyCheckAlt } from "react-icons/fa";
import { BiSolidUser, BiCalendar } from "react-icons/bi";
import { useHookReportes } from '../../../libs/hookReportes';
import { useHookAuth } from '../../../libs/firebase';
import { FaGear } from 'react-icons/fa6';

const CardEstadisticas = ({ingresos, cartera, totalCitas, pacientesNuevos, handlerShowFilter, loadingIngresos, loadingDeudas, loadingUsuariosNuevos, loadingSesionesAtendidas, checkedAcumulado}) => {
	const {
    filtroDate5,
    filtroDate6,
    filtroDate7,
    filtroDate8,
	} = useHookReportes();
  const navigate = useNavigate();
  const { usuario } = useHookAuth();
  const sizeConfig = 30;

  const handlerNavigate = useCallback(
		ruta => {
			navigate(ruta);
		},
		[navigate]
	);

  const handleReportesClick = ruta => {
		if (usuario.rol === 'administrador') {
			handlerNavigate(ruta);
		} 
	};

  function formatDate(fecha) {
    // Obtenemos el día, el mes y el año de la fecha
    if(fecha !== undefined)
    {
      const dia = fecha.getDate();
    const mes = fecha.getMonth() + 1;
    const anyo = fecha.getFullYear();
        // Formateamos la fecha
        return `${dia}/${mes}/${anyo}`;
    } else {
      return "";
    }
    


  }
  
  return (
    <section className=" text-gray-600 body-font">
  <div className="container px-5 pt-3 mx-auto">
    <div className="flex flex-col text-center w-full mb-5 rounded-2xl h-20 bg-darkblue">
      <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 text-white my-auto">{`Reportes`}</h1>
    </div>
    <div className="py-4 m-2 text-center group relative flex h-auto items-center justify-center overflow-hidden rounded-lg bg-gray-100 shadow-lg md:col-span-5  bg-white sm:col-span-5 ">

    {/* card 1 */}
    {usuario.rol === "administrador" &&      
      <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
       
        {loadingIngresos ? (
          <div className="flex-wrap  px-3 py-4 bg-greymedium rounded-xl h-[95px] animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
          ) : (<div>
          
            <div className="relative flex-wrap border-2 border-gray-200 px-3 py-4 bg-[#38b000] rounded-xl  text-white">
            <div className="absolute top-1 right-1 rounded-lg bg-transparent p-2 hover:text-darkblue text-white">
            <FaGear 
            size={sizeConfig} 
            onClick={() => handlerShowFilter('ingresos')} />
            </div>
            <div className='flex justify-center cursor-pointer hover:cursor-hand'onClick={() => handleReportesClick('ReporteEstadisticoIngresos')} >
            
            <FaMoneyCheckAlt className='text-4xl' />
            <h2 className=" title-font font-medium text-3xl text-gray-900 px-2 "> {` S/. ${ingresos}`}</h2>  
            </div>
            <p className="leading-relaxed cursor-pointer hover:cursor-hand mb-4">Ingresos</p>
            {filtroDate6 && <div className='text-white bg-darkblue absolute bottom-0 left-0 right-0 flex justify-center rounded-b-lg text-base ' >{`${formatDate(filtroDate5[0])} - ${formatDate(filtroDate5[1])}`}</div>}
            </div>
            </div>) }
      
    </div>
    }
    {/* fin car 1 */}

    {/* card 2 */}
    {usuario.rol === "administrador" &&      
      <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
          {!loadingDeudas ? (<div>
        <div className="relative flex-wrap border-2 border-gray-200 px-3 py-4 bg-[#ff595e] rounded-xl  text-white">
        <div className="absolute top-1 right-1 rounded-lg bg-transparent p-2 hover:text-darkblue text-white">
	        <FaGear 
          size={sizeConfig} 
          onClick={() => handlerShowFilter('deudas')} />
	        </div>
        <div className='flex justify-center cursor-pointer hover:cursor-hand'onClick={() => handleReportesClick('ReporteEstadisticoDeudas')}  >
          <FaMoneyCheckAlt className='text-4xl' />
          <h2 className=" title-font font-medium text-3xl text-gray-900 px-2">{` S/. ${cartera}`}</h2>  
        </div>
        <p className="leading-relaxed mb-4">Deuda</p>
        {filtroDate6 && <div className='absolute bottom-0 left-0 right-0 flex justify-center bg-darkblue rounded-b-lg text-base'>{checkedAcumulado !== 'acumulado' ? `${formatDate(filtroDate6[0])} - ${formatDate(filtroDate6[1])}` : ``}</div>}
        </div> </div>):(
          <div className="flex-wrap  px-3 py-4 bg-greymedium rounded-xl  h-[95px] animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
        )}</div>
        }
      {/* fin car 2 */}
      {/* inicio 3 */}
      <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
          {!loadingSesionesAtendidas ? (<div>
        <div className="relative flex-wrap border-2 border-gray-200 px-3 py-4 bg-[#4cc9f0] rounded-xl  text-white">
        <div className="absolute top-1 right-1 rounded-lg bg-transparent p-2 hover:text-darkblue text-white">
	        <FaGear 
          size={sizeConfig}
          onClick={() => handlerShowFilter('sesionesAtendidas')} />
	        </div>
        <div className='flex justify-center'>
        <BiCalendar className='text-4xl' />
          <h2 className=" title-font font-medium text-3xl text-gray-900 px-2 ">{`${totalCitas}`}</h2>  
        </div>
        <p className="leading-relaxed mb-4">Sesiones atendidas</p>
        {filtroDate7 && <div className='absolute bottom-0 left-0 right-0 flex justify-center bg-darkblue rounded-b-lg text-base'>{`${formatDate(filtroDate7[0])} - ${formatDate(filtroDate7[1])}`}</div>}
        </div></div>):(
          <div className="flex-wrap  px-3 py-4 bg-greymedium rounded-xl  h-[95px] animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
        )}
      </div>
            {/* fin car 3 */}
      {/* inicio card 4 */}
      <div className="p-2 md:w-1/4 sm:w-1/2 w-full">
          {!loadingUsuariosNuevos ? (<div>
        <div className="relative flex-wrap border-2 border-gray-200 px-3 py-4 bg-[#ffd500] rounded-xl  text-white">
        <div className="absolute top-1 right-1 rounded-lg bg-transparent p-2 hover:text-darkblue text-white">
	        <FaGear 
          size={sizeConfig}
          onClick={() => handlerShowFilter('usuariosNuevos')} />
	        </div>
        <div className='flex justify-center'>
        <BiSolidUser className='text-4xl' />
          <h2 className=" title-font font-medium text-3xl text-gray-900 px-2 ">{`${pacientesNuevos}`}</h2>  
        </div>
        <p className="leading-relaxed mb-4">Pacientes Nuevos</p>
        {filtroDate8 && <div className='absolute bottom-0 left-0 right-0 flex justify-center bg-darkblue rounded-b-lg text-base'>{`${formatDate(filtroDate8[0])} - ${formatDate(filtroDate8[1])}`}</div>}
        </div> </div>):(
          <div className="flex-wrap  px-3 py-4 bg-greymedium rounded-xl  h-[95px] animate-pulse animate-infinite animate-duration-1000 animate-ease-in"></div>
        )}
      </div>
      {/* fin card 4 */}
    
    </div>
  </div>
</section>
  )
}

export default CardEstadisticas