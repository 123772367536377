import { atom } from "recoil";

const today = new Date();
			const dayOfWeek = today.getDay();
			const monday = new Date(
				today.getFullYear(),
				today.getMonth(),
				today.getDate() - dayOfWeek + 1
			);
			const sunday = new Date(
				monday.getFullYear(),
				monday.getMonth(),
				monday.getDate() + 6
			);
//Estados de la apliacion

export let rutaState = atom({
  key: "rutaState",
  default: "",
});

export let acumuladoState = atom({
  key: "acumuladoState",
  default: true,
});
export let filtrosDateState = atom({
  key: "filtrosDateState",
  default: [monday, sunday],
})

export let toogleState = atom({
  key: "toogleState",
  default: 1,
});

export let showModalState = atom({
  key: "showModalState",
  default: false,
});

export let showMenuFullState = atom({
  key: "showMenuFullState",
  default: false,
});

// calendario

export let sesionesCitasState = atom({
  key: "sesionesCitasState",
  default: [],
});

export let dateInicioState = atom({
  key: "dateInicioState",
  default: null,
});

export let uidPacienteState = atom({
  key: "uidPacienteState",
  default: null,
});

export let dateFinState = atom({
  key: "dateFinState",
  default: null,
});

export let dateRangeState = atom({
  key: "dateRangeState",
  default: null,
})

export let citaSelectedState = atom({
  key: "citaSelectedState",
  default: null,
})

export let sesionSelectedState = atom({
  key: "sesionSelectedState",
  default: null,
})

export let showFilterState = atom({
  key: "showFilterState",
  default: null,
})

export let filtrosDate1 = atom({
  key: "filtrosDate1",
  default: [monday, sunday],
})

export let filtrosDate2 = atom({
  key: "filtrosDate2",
  default: [monday, sunday],
})

export let filtrosDate3 = atom({
  key: "filtrosDate3",
  default: [monday, sunday],
})

export let filtrosDate4 = atom({
  key: "filtrosDate4",
  default: [monday, sunday],
})

export let filtrosDate5 = atom({
  key: "filtrosDate5",
  default: [monday, sunday],
})

export let filtrosDate6 = atom({
  key: "filtrosDate6",
  default: [monday, sunday],
})

export let filtrosDate7 = atom({
  key: "filtrosDate7",
  default: [monday, sunday],
})

export let filtrosDate8 = atom({
  key: "filtrosDate8",
  default: [monday, sunday],
})



export let filtrosState = atom({
  key: "filtrosState",
  default: [
    {
        filtro: "centros",
        centros: []
    },
    {
        filtro: "pacientes",
        pacientes: []
    },
    {
        filtro: "especialistas",
        especialistas: [] 
    },
    {
        filtro: "servicios",
        servicios: []
    },
],
})

export let filtrosState1 = atom({
  key: "filtrosState1",
  default: [],
})

export let filtrosState2 = atom({
  key: "filtrosState2",
  default: [],
})

export let filtrosState3 = atom({
  key: "filtrosState3",
  default: [],
})

export let filtrosState4 = atom({
  key: "filtrosState4",
  default: [],
})

export let filtrosState5 = atom({
  key: "filtrosState5",
  default: [],
})

export let filtrosState6 = atom({
  key: "filtrosState6",
  default: [],
})

export let filtrosState7 = atom({
  key: "filtrosState7",
  default: [],
})

export let filtrosState8 = atom({
  key: "filtrosState8",
  default: [],
})

//Estados de Usuario

export let clienteIdState = atom({
  key: "clienteIdState",
  default: "centroSkinner",
});

export let loguedState = atom({
  key: "loguedState",
  default: false,
});

export let rolState = atom({
  key: "rolState",
  default: null,
});

export let userDataState = atom({
  key: "userDataState",
  default: {
    activo: false,
    apellido: "",
    clienteId: "",
    correo: "",
    nombre: "",
    proveedor: "",
    rol: "",
    uid: "",
  },
});

//Estados de usuarios
export const usuariosState = atom({
  key: 'usuariosState',
  default: null
});

export let userSelectState = atom({
  key: "userSelectState",
  default: {
    activo: false,
    apellido: "",
    clienteId: "",
    correo: "",
    nombre: "",
    proveedor: "",
    rol: "",
    uid: "",
    especialidad:"",
    servicios: "",
  },
});

export const especialistaSelectedState = atom({
  key: 'especialistaSelectedState',
  default: null
});

//Estados de pacientes

export const pacienteSelectedUidState = atom({
  key: 'pacienteSelectedUidState',
  default: null
});


//////Estados citas
