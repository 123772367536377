import React, { useState } from 'react';
import { useHookServicios } from '../../../libs/hookServicios';
import { nanoid } from 'nanoid';
import CarouselSesion from '../../../components/CarouselSesion';
import solesImg from '../../../img/simbolo-de-moneda-nuevo-sol-de-peru.png';

const CrearServicio = props => {
	const { createServicio } = useHookServicios();

	//Sesiones
	const [sesionSelected, setSesionSelected] = useState(null);
	const [sesiones, setSesiones] = useState([]);
	const [sesion, setSesion] = useState({
		nombreSesion: '',
		duracion: '',
		key: '',
	});
	const nombreSesionValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9\s]{3,}$/;
	const duracionValido = /^\d{1,2}$/;
	const [boton, setBoton] = useState('');
	const [errors, setErrors] = useState({});

	const [servicio, setServicio] = useState({
		nombre: '',
		descripcion: '',
		precio: '',
		precioMinimo: '',
		sesiones: '',
	});

	const handleChange = ({ target: { value, name } }) => {
		setServicio({ ...servicio, [name]: value });
	};

	const handleChangeSesion = ({ target: { value, name } }) => {
		setSesion({ ...sesion, [name]: value });
	};

	/**
	 * Handles the addition of a new session.
	 *
	 * @param {object} e - The event object.
	 * @return {void} This function does not return a value.
	 */
	const handleAddSesion = e => {
		e.preventDefault();
		let newErrors = {};

		if (!nombreSesionValido.test(sesion.nombreSesion.trim())) {
			newErrors.nombreSesion = 'Nombre inválido';
		}
		if (!duracionValido.test(sesion.duracion.trim())) {
			newErrors.tiempo = 'Tiempo inválido, ingresa los minutos';
		}

		setErrors(newErrors);

		if (
			nombreSesionValido.test(sesion.nombreSesion.trim()) &&
			duracionValido.test(sesion.duracion.trim())
		) {
			setSesiones([
				{
					nombre: sesion.nombreSesion,
					duracion: sesion.duracion,
					key: nanoid(),
				},
				...sesiones,
			]);
			setSesion({ nombreSesion: '', duracion: '', key: '' });
		}
	};

	/**
	 * Handles the deletion of a session.
	 * @param {Object} sesion - The session to be deleted.
	 */
	const handleBorrarSesion = sesion => {
		const updatedSesiones = sesiones.filter(item => item.key !== sesion.key);
		setSesiones(updatedSesiones);
		setSesionSelected(null);
	};

	/**
	 * Handles the edit session event.
	 *
	 * @param {Event} e - The event object.
	 * @return {void} - This function does not return anything.
	 */
	const handleEditSesion = e => {
		e.preventDefault();

		let newErrors = {};

		if (!nombreSesionValido.test(sesion.nombreSesion.trim())) {
			newErrors.nombreSesion = 'Nombre inválido';
		}
		if (!duracionValido.test(sesion.duracion.trim())) {
			newErrors.tiempo = 'Tiempo inválido, ingresa los minutos';
		}
		setErrors(newErrors);

		if (
			sesion.nombreSesion.trim() !== '' &&
			sesion.duracion.trim() !== '' &&
			nombreSesionValido.test(sesion.nombreSesion.trim()) &&
			duracionValido.test(sesion.duracion.trim())
		) {
			const updatedSesiones = sesiones.map(sesionItem =>
				sesionItem.key === sesionSelected.key
					? {
							...sesionItem,
							nombre: sesion.nombreSesion,
							duracion: sesion.duracion,
							key: sesionItem.key,
					  }
					: sesionItem
			);

			setSesiones(updatedSesiones);
			setSesion({ nombreSesion: '', duracion: '', key: '' });
			setBoton('');
			setSesionSelected(null);
		}
	};

	/**
	 * Handles the "Cancelar" action.
	 */
	const handleCancelar = () => {
		setBoton('');
		setSesion({ nombreSesion: '', duracion: '', key: '' });
		setSesionSelected(null);
	};

	/**
	 * Updates the selected session and performs an action based on the button clicked.
	 *
	 * @param {Object} s - The selected session object.
	 * @param {string} boton - The button clicked ('borrar' or 'editar').
	 */
	const seleccionarSesion = (s, boton) => {
		setBoton(boton);
		setSesionSelected(s);

		if (boton === 'borrar') {
			handleBorrarSesion(s);
		}
		if (boton === 'editar') {
			setSesion({ nombreSesion: s.nombre, duracion: s.duracion });
		}
	};

        /**
     * Handles the form submission.
     *
     * @param {Event} e - The event object.
     * @return {void} This function does not return anything.
     */
	const handleSubmit = async e => {
		e.preventDefault();

		const trimServicio = {};

		if (Object.keys(errors).length === 0) {
			for (const propiedad in servicio) {
				if (typeof servicio[propiedad] === 'string') {
					trimServicio[propiedad] = servicio[propiedad].trim().toLowerCase();
				} else {
					trimServicio[propiedad] = servicio[propiedad];
				}
			}

			trimServicio.sesiones = sesiones.reverse().map(sesion => ({
				nombre: sesion.nombre,
				duracion: sesion.duracion,
			}));
			props.setShowModal(false);
			props.setIsLoading(true);
			await createServicio(
				trimServicio.nombre,
				trimServicio.descripcion,
				trimServicio.precio,
				trimServicio.precioMinimo,
				trimServicio.sesiones
			);
			props.getServicios();
		}
	};

	return (
		<div>
			<h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">
				CREAR SERVICIO
			</h3>
      {/* incio form */}
			<form className="w-full" onSubmit={handleSubmit}>
				<div className="flex flex-col lg:flex-row w-[70vw]">
					<div className="lg:w-1/2 p-3 w-full">
						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="nombre"
						>
							Nombre
							<input
								required
								pattern="^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9\s\(\)]{3,}$"
								id="nombre"
								name="nombre"
								minLength="3"
								maxLength="65"
								className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
								type="text"
								placeholder="Nombres..."
								value={servicio.nombre}
								onChange={handleChange}
							/>
						</label>

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="descripcion"
						>
							Descripción
							<input
								id="descripcion"
								name="descripcion"
								maxLength="150"
								className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
								type="text"
								placeholder="Descripción del servicio..."
								value={servicio.descripcion}
								onChange={handleChange}
							/>
						</label>

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="precio"
						>
							Precio
							<div className="relative">
							<img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />

								<input
									required
									pattern="[0-9]+(?:\.[0-9]+)?"
									minLength={3}
									id="precio"
									name="precio"
									className="block pl-10 w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									type="text"
									placeholder="0.0"
									value={servicio.precio}
									onChange={handleChange}
								/>
							</div>
						</label>

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="precioMinimo"
						>
							Precio mínimo
							<div className="relative">
							<img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />

								<input
									required
									pattern="[0-9]+(?:\.[0-9]+)?"
									minLength={3}
									id="precioMinimo"
									name="precioMinimo"
									className="block pl-10  w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									type="text"
									placeholder="0.0"
									value={servicio.precioMinimo}
									onChange={handleChange}
								/>
							</div>
						</label>
					</div>
          {/* incio input sesiones */}
					<div className="lg:w-1/2 w-full p-3 lg:border-l lg:border-grey sm:w-full ">
						<h2 className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2">
							Sesiones Creadas: {sesiones.length}
						</h2>

						<div className="flex justify-end">
							<button
								type="button"
								onClick={handleEditSesion}
								className={` ${
									boton !== 'editar' ? 'hidden' : ''
								} min-w-fit ml-4 h-fit sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white`}
							>
								Actualizar
							</button>

							<button
								type="button"
								onClick={handleCancelar}
								className={` ${
									boton !== 'editar' ? 'hidden' : ''
								} min-w-fit ml-4 h-fit sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white`}
							>
								Cancelar
							</button>
						</div>
            {/* incio cards sesiones */}
						<div className="flex items-center">
							{Array.isArray(sesiones) && sesiones.length > 0 && (
								<div className="w-full">
									<CarouselSesion
										sesiones={sesiones}
										seleccionarSesion={seleccionarSesion}
									/>
								</div>
							)}
						</div>

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="salasCentro"
						>
							Sesiones:
						</label>

						<input
							className=" w-full px-4 py-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							type="text"
							name="nombreSesion"
							id="nombreSesion"
							placeholder="Nombre sesion..."
							value={sesion.nombreSesion}
							onChange={handleChangeSesion}
						/>

						{errors.nombreSesion && (
							<div>
								<span className="text-end text-[#FF0000] mt-1 text-sm">
									{errors.nombreSesion}
								</span>
								<br />
							</div>
						)}

						<input
							className="mt-4 w-full px-4 py-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							type="text"
							name="duracion"
							id="duracion"
							placeholder="Duracion sesion..."
							value={sesion.duracion}
							onChange={handleChangeSesion}
						/>

						{errors.tiempo && (
							<div>
								<span className="text-end text-[#FF0000] mt-1 text-sm">
									{errors.tiempo}
								</span>
								<br />
							</div>
						)}
						<div className="w-full flex justify-end mt-4">
							<button
								onClick={handleAddSesion}
								className={` ${
									boton !== 'editar' ? '' : 'hidden'
								} min-w-fit h-fit sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white`}
							>
								Agregar sesión
							</button>
						</div>
					</div>
				</div>
         {/* fin input sesiones */}
				<div className="flex justify-around w-3/4 m-auto mt-8 mb-4">
					<input
						type="submit"
						value={'Guardar'}
						className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
					></input>

					<button
						type="button"
						onClick={() => props.setShowModal(false)}
						className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white"
					>
						Cancelar
					</button>
				</div>
			</form>
      {/* fin form */}
		</div>
	);
};

export default CrearServicio;
