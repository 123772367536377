import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const Sesion = ({sesion, closeModalEditar}) => {
    function SetCitaSelected(citaId) {
        closeModalEditar(citaId);
    }

    function unixToTime(unix) {
        const date = new Date(unix * 1000);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        if(minutes < 10){
            return `${hours}:0${minutes}`;
        }
        if(hours < 10){
            return `0${hours}:${minutes}`;
        }
        return `${hours}:${minutes}`;
      }

      function formatDay(day) {
        if(day < 10){
            return `0${day}`;
        }
        return `${day}`;
      }

    let sesionesArray = [];
    if(typeof sesion === "object"){
        sesionesArray = Object.values(sesion);
    }
    
  return (
    <>
        {sesionesArray.map((sesion, index) => (
            <TableRow key={index} className='hover:bg-darkaqua hover:text-white hover:cursor-pointer' 
            onClick={() => SetCitaSelected(sesion)}>
                <TableCell align="left" sx={{ fontSize: 'body1.fontSize'}}>{formatDay(sesion.dia)}</TableCell>
                <TableCell align="left" sx={{ fontSize: 'body1.fontSize'}}>{unixToTime(sesion.inicio)}</TableCell>
                <TableCell align="left" sx={{ fontSize: 'body1.fontSize'}}>{sesion.nombreCentro}</TableCell>
                <TableCell align="left" sx={{ fontSize: 'body1.fontSize'}}>{sesion.servicios}</TableCell>
                <TableCell align="left" sx={{ fontSize: 'body1.fontSize'}}>{sesion.especialistas[0]}</TableCell>
            </TableRow>
        ))}
    </>
  )
}

export default Sesion