import React, { useState, useEffect, useRef } from 'react';
import { useHookServicios } from '../../../libs/hookServicios';
import { nanoid } from 'nanoid';
import { BsCurrencyDollar } from 'react-icons/bs';
import CarouselSesion from '../../../components/CarouselSesion';

const UpdateServicio = props => {
	const [isLoading, setIsLoading] = useState(true);
	const { updateServicio, getServicio } = useHookServicios();
	const isFirstRender = useRef(true);
	const [errors, setErrors] = useState({});

	const [sesionSelected, setSesionSelected] = useState(null);
	const [sesion, setSesion] = useState({
		nombreSesion: '',
		duracion: '',
		key: '',
	});
	const [boton, setBoton] = useState('');

	const nombreSesionValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9\s]{3,}$/;
	const duracionValido = /^\d{1,2}$/;

	const [nombre, setNombre] = useState(null);
	const [descripcion, setDescripcion] = useState(null);
	const [precio, setPrecio] = useState(null);
	const [precioMinimo, setPrecioMinimo] = useState(null);
	const [sesiones, setSesiones] = useState([]);
	const [sesionesEnviar, setSesionesEnviar] = useState([]);

	/**
	 * Handles the retrieval of a service by its ID.
	 *
	 * @param {string} servicioId - The ID of the service to retrieve.
	 * @return {Promise<void>} A promise that resolves when the service data is retrieved and processed.
	 */
	const handleGetServicio = async servicioId => {
		try {
			const servicioData = await getServicio(servicioId);
			setNombre(servicioData.nombre);
			setDescripcion(servicioData.descripcion);
			setPrecio(servicioData.precio);
			setPrecioMinimo(servicioData.precioMinimo);

			if (Array.isArray(servicioData.sesiones)) {
				setSesiones(
					servicioData.sesiones.map(sesion => ({
						nombre: sesion.nombre,
						duracion: sesion.duracion,
						key: nanoid(),
					}))
				);
				setSesionesEnviar(servicioData.sesiones);
			}
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handleGetServicio(props.servicioId);
		}
	}, [handleGetServicio, props.servicioId]);

	const handleChangeSesion = ({ target: { value, name } }) => {
		setSesion({ ...sesion, [name]: value });
	};

	const handleAddSesion = e => {
		e.preventDefault();
		let errors = {};

		const isValidName = nombreSesionValido.test(sesion.nombreSesion.trim());
		const isValidDuration = duracionValido.test(sesion.duracion.trim());

		if (!isValidName) {
			errors.nombreSesion = 'Invalid name';
		}

		if (!isValidDuration) {
			errors.tiempo = 'Invalid duration, enter minutes';
		}

		setErrors(errors);

		if (isValidName && isValidDuration) {
			const newSession = {
				nombre: sesion.nombreSesion,
				duracion: sesion.duracion,
				key: nanoid(),
			};

			setSesiones([newSession, ...sesiones]);
			setSesionesEnviar([newSession, ...sesionesEnviar]);
			setSesion({ nombreSesion: '', duracion: '' });

			updateServicio(
				'sesiones',
				[newSession, ...sesionesEnviar],
				props.servicioId
			);
		}
	};

	const handleBorrarSesion = sesion => {
		const updatedSesiones = sesiones.filter(item => item.key !== sesion.key);
		setSesiones(updatedSesiones);
		setSesionSelected(null);
		setSesionesEnviar(
			updatedSesiones.map(sesion => ({
				nombre: sesion.nombre,
				duracion: sesion.duracion,
			}))
		);
		updateServicio(
			'sesiones',
			updatedSesiones.map(sesion => ({
				nombre: sesion.nombre,
				duracion: sesion.duracion,
			})),
			props.servicioId
		);
	};

	const handleEditSesion = e => {
		e.preventDefault();

		let newErrors = {};

		if (!nombreSesionValido.test(sesion.nombreSesion.trim())) {
			newErrors.nombreSesion = 'Nombre inválido';
		}
		if (!duracionValido.test(sesion.duracion.trim())) {
			newErrors.tiempo = 'Tiempo inválido, ingresa los minutos';
		}
		setErrors(newErrors);

		if (
			sesion.nombreSesion.trim() !== '' &&
			sesion.duracion.trim() !== '' &&
			nombreSesionValido.test(sesion.nombreSesion.trim()) &&
			duracionValido.test(sesion.duracion.trim())
		) {
			const updatedSesiones = sesiones.map(sesionItem =>
				sesionItem.key === sesionSelected.key
					? {
							...sesionItem,
							nombre: sesion.nombreSesion,
							duracion: sesion.duracion,
					  }
					: sesionItem
			);

			setSesiones(updatedSesiones);
			setSesion({ nombreSesion: '', duracion: '' });
			setBoton('');
			setSesionSelected(null);
			setSesionesEnviar(
				updatedSesiones.map(sesion => ({
					nombre: sesion.nombre,
					duracion: sesion.duracion,
				}))
			);
			updateServicio(
				'sesiones',
				updatedSesiones.map(sesion => ({
					nombre: sesion.nombre,
					duracion: sesion.duracion,
				})),
				props.servicioId
			);
		}
	};

	const handleCancelar = () => {
		setBoton('');
		setSesion({ nombreSesion: '', duracion: '' });
		setSesionSelected(null);
	};

	const seleccionarSesion = (s, boton) => {
		setBoton(boton);
		setSesionSelected(s);

		if (boton === 'borrar') {
			handleBorrarSesion(s);
		}

		if (boton === 'editar') {
			setSesion({ nombreSesion: s.nombre, duracion: s.duracion });
		}
	};

	const handleChangeNombre = nombre => {
		setNombre(nombre);
		let newErrors = {};

		const nombreValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9\s()]{3,}$/;

		if (!nombreValido.test(nombre)) {
			newErrors.nombre = 'Nombre inválido';
		} else {
			updateServicio('nombre', nombre.trim().toLowerCase(), props.servicioId);
		}
		setErrors(newErrors);
	};

	const handleChangeDescripcion = descripcion => {
		setDescripcion(descripcion);
		updateServicio(
			'descripcion',
			descripcion.trim().toLowerCase(),
			props.servicioId
		);
	};

	const handleChangePrecio = precio => {
		setPrecio(precio);
		let newErrors = {};

		const precioValido = /[0-9]+(?:\.[0-9]+)?/;

		if (!precioValido.test(precio)) {
			newErrors.precio = 'Precio inválido';
		} else {
			updateServicio('precio', precio.trim(), props.servicioId);
		}
		setErrors(newErrors);
	};

	const handleChangePrecioMinimo = precioMinimo => {
		setPrecioMinimo(precioMinimo);
		let newErrors = {};

		const precioMinimoValido = /[0-9]+(?:\.[0-9]+)?/;

		if (!precioMinimoValido.test(precioMinimo)) {
			newErrors.precioMinimo = 'Precio minimo inválido';
		} else {
			updateServicio('precioMinimo', precioMinimo.trim(), props.servicioId);
		}
		setErrors(newErrors);
	};

	return (
		<div>
			<h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">
				EDITAR SERVICIO
			</h3>

			<form className="w-full">
				<div className="flex flex-col lg:flex-row w-[70vw]">
					<div className="lg:w-1/2 p-3 w-full">
						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="nombre"
						>
							Nombre
							{isLoading ? (
								<div role="status" className="w-full animate-pulse">
									<div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
								</div>
							) : (
								<input
									required
									id="nombre"
									name="nombre"
									className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									type="text"
									placeholder="Nombres..."
									value={nombre}
									onChange={e => handleChangeNombre(e.target.value)}
								/>
							)}
						</label>
						{errors.nombre && (
							<div>
								<span className="text-end text-[#FF0000] mt-1 text-sm">
									{errors.nombre}
								</span>
								<br />
							</div>
						)}

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="descripcion"
						>
							Descripción
							{isLoading ? (
								<div role="status" className="w-full animate-pulse">
									<div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
								</div>
							) : (
								<input
									pattern="^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$"
									id="descripcion"
									name="descripcion"
									className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									type="text"
									placeholder="Descripción del servicio..."
									value={descripcion}
									onChange={e => handleChangeDescripcion(e.target.value)}
								/>
							)}
						</label>

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="precio"
						>
							Precio
							{isLoading ? (
								<div role="status" className="w-full animate-pulse">
									<div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
								</div>
							) : (
								<div className="relative">
									<BsCurrencyDollar className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />

									<input
										required
										id="precio"
										name="precio"
										className="block pl-10 w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										type="number"
										placeholder="0.0"
										value={precio}
										onChange={e => handleChangePrecio(e.target.value)}
									/>
								</div>
							)}
						</label>
						{errors.precio && (
							<div>
								<span className="text-end text-[#FF0000] mt-1 text-sm">
									{errors.precio}
								</span>
								<br />
							</div>
						)}

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="precioMinimo"
						>
							Precio mínimo
							{isLoading ? (
								<div role="status" className="w-full animate-pulse">
									<div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
								</div>
							) : (
								<div className="relative">
									<BsCurrencyDollar className=" absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />

									<input
										required
										minLength={3}
										id="precioMinimo"
										name="precioMinimo"
										className="block pl-10  w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										type="number"
										placeholder="0.0"
										value={precioMinimo}
										onChange={e => handleChangePrecioMinimo(e.target.value)}
									/>
								</div>
							)}
						</label>

						{errors.precioMinimo && (
							<div>
								<span className="text-end text-[#FF0000] mt-1 text-sm">
									{errors.precioMinimo}
								</span>
								<br />
							</div>
						)}
					</div>

					<div className="lg:w-1/2 w-full p-3 lg:border-l lg:border-grey sm:w-full ">
						<h2 className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2">
							Sesiones Creadas: {sesiones.length}
						</h2>

						<div className="flex justify-end">
							<button
								type="button"
								onClick={handleEditSesion}
								className={` ${
									boton !== 'editar' ? 'hidden' : ''
								} min-w-fit ml-4 h-fit sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white`}
							>
								Actualizar
							</button>

							<button
								type="button"
								onClick={handleCancelar}
								className={` ${
									boton !== 'editar' ? 'hidden' : ''
								} min-w-fit ml-4 h-fit sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white`}
							>
								Cancelar
							</button>
						</div>

						{isLoading ? (
							<div role="status" className="w-full animate-pulse">
								<div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
							</div>
						) : sesiones.length > 0 ? (
							<div className="flex items-center">
								{Array.isArray(sesiones) && sesiones.length > 0 && (
									<div className="w-full">
										<CarouselSesion
											sesiones={sesiones}
											seleccionarSesion={seleccionarSesion}
										/>
									</div>
								)}
							</div>
						) : (
							''
						)}

						<label
							className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
							htmlFor="salasCentro"
						>
							Sesiones
						</label>

						{isLoading ? (
							<div role="status" className="w-full animate-pulse">
								<div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
							</div>
						) : (
							<input
								className=" w-full px-4 py-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
								type="text"
								name="nombreSesion"
								id="nombreSesion"
								placeholder="Nombre sesion..."
								value={sesion.nombreSesion}
								onChange={handleChangeSesion}
							/>
						)}

						{errors.nombreSesion && (
							<div>
								<span className="text-end text-[#FF0000] mt-1 text-sm">
									{errors.nombreSesion}
								</span>

								<br />
							</div>
						)}

						{isLoading ? (
							<div role="status" className="w-full animate-pulse">
								<div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
							</div>
						) : (
							<input
								className="mt-4 w-full px-4 py-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
								type="text"
								name="duracion"
								id="duracion"
								placeholder="Duracion sesion..."
								value={sesion.duracion}
								onChange={handleChangeSesion}
							/>
						)}

						{errors.tiempo && (
							<div>
								<span className="text-end text-[#FF0000] mt-1 text-sm">
									{errors.tiempo}
								</span>
								<br />
							</div>
						)}
						<div className="w-full flex justify-end mt-4">
							<button
								onClick={handleAddSesion}
								className={` ${
									boton !== 'editar' ? '' : 'hidden'
								} min-w-fit h-fit sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white`}
							>
								Agregar sesión
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default UpdateServicio;
