import { useState, useEffect } from 'react';
import { getStorage, ref } from 'firebase/storage';
import { getDownloadURL, uploadString, deleteObject } from 'firebase/storage';
import { nanoid } from 'nanoid';

//firebase
const storage = getStorage();

////////////////////////////////////////HOOK STORAGE///////////////////////////////////////

export const useHookStorage = file => {
	const [url, setUrl] = useState(null);
	const [name, setName] = useState(null);
	const [imagenes, setImagenes] = useState([]);

	// runs every time the file value changes
	useEffect(() => {
		if (file.file !== null) {
			// storage refs
			handlerUpload(file.file, file.extension);
		}
	}, [file]);

	const handlerUpload = async (selectedFile, extension) => {
		const nano = nanoid();
		
		setName(`${nano}.${extension}`);
		
		const imageRef = ref(storage, `pagos/${nano}.${extension}`);
		
		await uploadString(imageRef, selectedFile, 'data_url')
			.catch(err => {
		console.log(err)
			});
			const downloadURL = await getDownloadURL(imageRef);
				setImagenes([
					...imagenes,
					{ url: downloadURL, name: `${nano}.${extension}` },
				]);
		
				setUrl(downloadURL);
		
	};

	

	const handlerDelete = async index => {
		// Create a reference to the file to delete
		const imageRef = ref(storage, `pagos/${index}`);

		// Delete the file
		await deleteObject(imageRef)
			.then(() => {
				// File deleted successfully
				
				setUrl(null);
				setName(null);
				const newImages = imagenes.filter((_, i) => i !== index);
				setImagenes(newImages);		
			})
			.catch(error => {
				console.log(error);
			});
	};

    const handlerGetUrl = async archivo => {
        let array=[];
       
        for (const adjunto of archivo) {
		try {
			const imageRef = ref(storage, `pagos/${adjunto}`);
            const res = await getDownloadURL(imageRef);
        
            array =[...array, { url: res, name: `${adjunto}` }];     

		} catch (error) {
			console.log(error);
		}
                     
            
        }
        
        setImagenes(array);
    };

	const handleResetImagenes = () => {
        
		setImagenes([]);
	};

	

	return { url, name, handlerDelete, imagenes, handlerGetUrl, handleResetImagenes };
};
