import { useState, useEffect } from 'react'
import { useHookPacientes } from "../../../libs/hookPacientes";
import { useHookAuth } from "../../../libs/firebase";
import { format } from "date-fns";

import Calendar from "react-calendar";
import "../../styles/calendar.css";
import imagenUsuario from "../../../img/user-circle.svg";

import { AiOutlinePlusCircle } from "react-icons/ai";
import Carousel from './Carousel'

const NucleoFam = () => {
  const { usuario } = useHookAuth();
  const [disabled, setDisabled] = useState(false);
  const [nuevo, setNuevo] = useState(true);
  const [isLoading, setIsLoading] = useState(true)
  const [nucleoFamiliar, setNucleoFamiliar] = useState(null);
  const {
    pacienteSelectedUid,
    getPaciente,
    createPaciente,
    handlerSetPacienteSelectedUid
  } = useHookPacientes();
  const [errors, setErrors] = useState({});
  const [nucleoId, setNucleoId] = useState(null);
  const [beneficiarioSelected, setBeneficiarioSelected] = useState(null);
  const [user, setUser] = useState({
    documento: "",
    tipo: "dni",
    nombre: "",
    apellido: "",
    nacimiento: "",
    direccion: "",
    correo: "",
    telefono: "",
  });
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleNucleoFamiliar = async (pacienteUid) => {

    const pacienteData = await getPaciente(pacienteUid);
    if (pacienteData) {
      const cards = [pacienteData, ...pacienteData.nucleo];
      setNucleoFamiliar(cards)

      setBeneficiarioSelected(pacienteData.uid);
      setUser({
        documento: pacienteData.documento,
        tipo: pacienteData.tipo,
        nombre: pacienteData.nombre,
        apellido: pacienteData.apellido,
        direccion: pacienteData.direccion,
        correo: pacienteData.correo,
        telefono: pacienteData.telefono
      })
      setNucleoId(pacienteData.nucleoId)
      if (pacienteData.nacimiento && !isNaN(new Date(pacienteData.nacimiento))) {
        setSelectedDate(new Date(pacienteData.nacimiento));
      }
      setIsLoading(false);
      handlerSetPacienteSelectedUid(null)
    }
  
  };

  useEffect(() => {
    if (pacienteSelectedUid !== null) {
      handleNucleoFamiliar(pacienteSelectedUid);
      setNuevo(false);
    } else {
      setIsLoading(false);
    }
  }, [pacienteSelectedUid, handleNucleoFamiliar])

  const handleChange = ({ target: { value, name } }) => {
    setUser({ ...user, [name]: value });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const fechaActual = new Date();
    let newErrors = {};

    if (date > fechaActual) {
      newErrors.nacimiento = "Fecha inválida"
    }
    setErrors(newErrors);
  };

  const seleccionarPaciente = async (pacienteUid) => {
    if (pacienteUid === null) {
      if (beneficiarioSelected !== null){
        //ingresa cuando es usuario nuevo beneficiario
        setBeneficiarioSelected(null);
        setUser({
          id: "",
          documento: "",
          tipo: "dni",
          nombre: "",
          apellido: "",
          nacimiento: "",
          direccion: "",
          correo: "",
          telefono: "",
        })
        setSelectedDate(new Date());
        setNuevo(false);
      } else {
        //ingresa cuando es usuario nuevo titular
        setBeneficiarioSelected(null);
        setUser({
          id: "",
          documento: "",
          tipo: "dni",
          nombre: "",
          apellido: "",
          nacimiento: "",
          direccion: "",
          correo: "",
          telefono: "",
        })
        setNuevo(true);
      }
      
      setSelectedDate(new Date())

    } else if (pacienteUid !== beneficiarioSelected) {
      const pacienteData = await getPaciente(pacienteUid);
      setBeneficiarioSelected(pacienteData.uid);
      setUser({
        documento: pacienteData.documento,
        tipo: pacienteData.tipo,
        nombre: pacienteData.nombre,
        apellido: pacienteData.apellido,
        direccion: pacienteData.direccion,
        correo: pacienteData.correo,
        telefono: pacienteData.telefono
      })
      setNucleoId(pacienteData.nucleoId)
      if (pacienteData.nacimiento && !isNaN(new Date(pacienteData.nacimiento))) {
        setSelectedDate(new Date(pacienteData.nacimiento));
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const trimUser = {};
    const fecha = ["nacimiento"];

    if (Object.keys(errors).length === 0) {
      for (const propiedad in user) {
        if (fecha.includes(propiedad)) {
          trimUser[propiedad] = selectedDate.getTime();
        } else if (typeof user[propiedad] === "string") {
          trimUser[propiedad] = user[propiedad].trim().toLowerCase();
        } else {
          trimUser[propiedad] = user[propiedad];
        }
      }
      await handlerCreatePaciente(trimUser);
      handlerSetPacienteSelectedUid(null);
    }
  };

  const handlerCreatePaciente = async (trimUser) => {
    setDisabled(true);
    let pacienteData;
    if (nuevo) {
      pacienteData = {
        clienteId: usuario.clienteId,
        documento: trimUser.documento,
        tipo: trimUser.tipo,
        nombre: trimUser.nombre,
        apellido: trimUser.apellido,
        nacimiento: trimUser.nacimiento,
        direccion: trimUser.direccion,
        correo: trimUser.correo,
        telefono: trimUser.telefono,
      };
    } else {
      pacienteData = {
        clienteId: usuario.clienteId,
        documento: trimUser.documento,
        tipo: trimUser.tipo,
        nombre: trimUser.nombre,
        apellido: trimUser.apellido,
        nacimiento: trimUser.nacimiento,
        direccion: trimUser.direccion,
        correo: trimUser.correo,
        telefono: trimUser.telefono,
        nucleoId: nucleoId,
      };
    }
    await createPaciente(pacienteData)
      .then((data) => {
        if (data) {
          if (beneficiarioSelected) {
            getPacienteCreado(beneficiarioSelected);
           
          } else {
            getPacienteCreado(data);
           
          }
        } else {
          setBeneficiarioSelected(null)
          setDisabled(false);
        }
      })
  }

  const getPacienteCreado = async (data) => {
    await getPaciente(data).then((data) => {
      if (data) {
        const cards = [data, ...data.nucleo];
        setBeneficiarioSelected(data.uid);
        setNucleoFamiliar(cards);
        setNucleoId(data.nucleoId);
      }
      setDisabled(false);
    });
  };

  return (

    <div className='w-full h-full bg-azure overflow-x-auto'>

      <div className="relative h-[26rem] bg-lightgrey m-4 rounded-md py-[3rem] flex  mb-[3rem] ">
        {/* empieza carrusel */}

          {Array.isArray(nucleoFamiliar) && nucleoFamiliar.length > 0 && (
            <div className='w-full'>
              <Carousel seleccionarPaciente={seleccionarPaciente} pacientes={nucleoFamiliar} />
            </div>
            )}

          <button className="absolute flex left-[50%] transform translate-x-[-50%] bottom-[-2.5rem] transition-all duration-300 text-darkblue hover:text-aqua active:text-darkblue" 
          onClick={() => seleccionarPaciente(null)}>
            <AiOutlinePlusCircle className="w-[5rem] h-auto m-auto bg-white  rounded-full " />
          </button>


      </div>
      {/* fin carrusel */}

      {/* inicio persona */}

      <div className="bg-darkblue p-4 m-4 rounded-md text-white">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-row w-full">
            <img
              className="m-auto my-8 w-auto h-auto"
              src={imagenUsuario}
              alt="imagen del usuario"
            />

          </div>
          <div className="flex flex-row w-full rounded-md p-4 pb-0">
            <div className="w-1/2 p-3">
              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="nombre"
              >
                Nombres
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  required
                  pattern="^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ'´-.&/ ]+$"
                  id="nombre"
                  name="nombre"
                  minLength="3"
                  maxLength="100"
                  className="capitalize w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Nombres..."
                  value={user.nombre}
                  onChange={handleChange}
                  disabled={beneficiarioSelected !== null}
                />)}
              </label>

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="nacimiento"
              >
                Fecha de Nacimiento
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  disabled
                  id="nacimiento"
                  name="nacimiento"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  value={format(selectedDate, "dd/MM/yyyy")}
                />)}
              </label>

              {errors.nacimiento && (
                <div>
                  <span className="text-end text-red mt-1 text-sm">{errors.nacimiento}</span>
                  <br />
                </div>
              )}

              <div className="p-3 w-4/5 m-auto">
                <Calendar value={selectedDate} onChange={handleDateChange} />
              </div>
            </div>

            <div className="w-1/2 p-3 border-l border-grey">
              <label
                className=" block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="apellido"
              >
                Apellidos
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  required
                  pattern="^[a-zA-ZáéíóúüñÁÉÍÓÚÜÑ'´-.&/ ]+$"
                  id="apellido"
                  name="apellido"
                  minLength="3"
                  maxLength="100"
                  className="capitalize w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Apellidos..."
                  value={user.apellido}
                  onChange={handleChange}
                  disabled={beneficiarioSelected !== null}
                />)}
              </label>

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="tipo"
              >
                Tipo
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<select
                  required
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  id="tipo"
                  name="tipo"
                  onChange={handleChange}
                  value={user.tipo}
                  disabled={beneficiarioSelected !== null}
                >
                  <option value="dni">DNI</option>
                  <option value="ce">CE</option>
                  <option value="pasaporte">Pasaporte</option>
                  <option value="pasaporte">RUC</option>
                  <option value="otro">Otro</option>
                </select>)}
              </label>

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="documento"
              >
                Documento
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  required
                  pattern="^(?=.*[0-9a-zA-Z]).+$"
                  id="documento"
                  name="documento"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Numero documento..."
                  value={user.documento}
                  onChange={handleChange}
                  disabled={beneficiarioSelected !== null}
                />)}
              </label>

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="direccion"
              >
                Dirección
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  id="direccion"
                  name="direccion"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="text"
                  placeholder="Dirección..."
                  value={user.direccion}
                  onChange={handleChange}
                  disabled={beneficiarioSelected !== null}
                />)}
              </label>

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="correo"
              >
                Correo electrónico
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  required
                  id="correo"
                  name="correo"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="email"
                  placeholder="ejemplo@dominio.com"
                  value={user.correo}
                  onChange={handleChange}
                  disabled={beneficiarioSelected !== null}
                />)}
              </label>

              <label
                className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                htmlFor="telefono"
              >
                Teléfono
                {isLoading ? (<div role="status" className="w-full animate-pulse">
                  <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>) : (<input
                  required
                  pattern="[+]?[0-9]+"
                  id="telefono"
                  name="telefono"
                  className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                  type="phone"
                  placeholder="+51 9## ### ###"
                  value={user.telefono}
                  onChange={handleChange}
                  disabled={beneficiarioSelected !== null}
                />)}
              </label>
            </div>
          </div>

          <div className={`${beneficiarioSelected === null ? '' : 'hidden'} pt-4 w-full flex justify-end border-t border-grey`}>
                <input
                  type="submit"
                  value={"Guardar"}
                  disabled={disabled}
                  className='sm:text-xs lg:text-xl md:text-md w-[11rem] px-4 py-2 tracking-wide transition-colors duration-200 transform rounded-md hover:bg-darkaqua hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white'
                ></input>
          </div>
        </form>
      </div>

      {/* fin persona */}
    </div>
  );
}


export default NucleoFam;
