import { nanoid } from 'nanoid';
import SesionesList from './SesionesList';
import CardUser from './CardUser';


const InfinityLoader = () => {


  return (
    <div className="w-full h-full bg-azure overflow-x-auto" key={nanoid()}>

      <div id="last-users" className="w[98%] mx-4 my-4  bg-lightgrey rounded-md  p-4" key={nanoid()}>
        
      <CardUser />    
      <SesionesList />
        
      </div>
 
    </div>
  );
}

export default InfinityLoader