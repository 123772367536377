import config from "../config";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { toast } from "react-toastify";
import { getDataFromApi } from "./functions";

//atoms de recoil
import { pacienteSelectedUidState, userDataState, uidPacienteState} from "../atoms/utilsAtom";
import { useRecoilState, useRecoilValue } from "recoil";

//firebase
const fb = initializeApp(config.firebaseConfig);
const auth = getAuth(fb);


//////////////////////////////////////////HOOK PACIENTES////////////////////////////////////////

const useHookPacientes = () => {
  const [pacienteSelectedUid, setPacienteSelectedUid] = useRecoilState(pacienteSelectedUidState);
  const usuario = useRecoilValue(userDataState);
  const [uidPaciente, setUidPaciente] = useRecoilState(uidPacienteState);


  const handlerSetPacienteSelectedUid = (data) => {
    setPacienteSelectedUid(data);
  };

  const handlerUidPaciente = (data) => {
    setUidPaciente(data);
  }

  const searchPaciente = async (paciente) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "findPacientes";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      buscar: paciente,
      campos: ["nombre", "apellido", "telefono", "documento", "correo", "uid"]
    };
    
    const res = await getDataFromApi(endPoint, token, data);
    
    if(res && res.status === 200) {
      if (Array.isArray(res.data)) {
        return res.data
      } else {
        return []
      }
    } else {
      console.log("find paciente error: ",res)
      return false
    }
  }

  const deletePaciente = async (pacienteUid) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "delPaciente";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      pacienteUid: pacienteUid,
    };

    const res = await getDataFromApi(endPoint, token, data);
    
    if (res && res.status === 200) {
      if (res.data === "paciente eliminado correctamente") {
        toast.success(`${res.data}`, {
          position: "top-center",
          theme: "colored",
        })
      } else {
        console.log("delete paciente error: ",res)
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
      }
    } else {
      console.log("delete paciente error: ",res)
      toast.error("ha ocurrido un error", {
        position: "top-center",
        theme: "colored",
      })
    }
  }

  const createPaciente = async (pacienteData) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "createPaciente";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      pacienteData: pacienteData,
    };

    const res = await getDataFromApi(endPoint, token, data);
    
    if(res && res.status === 200){
        toast.success(`El Paciente se creo correctamente`, {
          position: "top-center",
          theme: "colored",
        });
        return res.data
    } else {
      console.log("create paciente error: ",res)
      return false
    }
  }

  const updatePaciente = async ( uid, parametro, valor ) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "editPaciente";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      pacienteUid: uid,
      parametro: parametro,
      valor: valor,
    };

    const res = await getDataFromApi(endPoint, token, data);
    if (res && res.status === 200) {
      if (res.data === "paciente actualizado correctamente") {
        return res.data
      } else {
        console.log("edit paciente error: ",res)
        toast.error("ha ocurrido un error", {
          position: "top-center",
          theme: "colored",
        })
        return false
      }
    } else {
      console.log("edit paciente error: ",res)
      return false
    }
  };

  const getPacientes = async () => {
    const token = await auth.currentUser.getIdToken();
   
      const endPoint = "getPacientes";

      const data = {
        uid: usuario.uid,
        clienteId: usuario.clienteId,
      };

      const res = await getDataFromApi(endPoint, token, data);
      if (res && res.data.estado !== "success") {
        return res.data.data
      } else {
        console.log("get paciente error: ",res)
        toast.error(`Error ${res.data}`, {
          position: "top-center",
          theme: "colored",
        })
        return false
      }
  };



  const getPaciente = async (pacienteUid) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getPaciente";

    const data = {
      uid: usuario.uid,
      clienteId: usuario.clienteId,
      pacienteUid: pacienteUid,
    };

    const res = await getDataFromApi(endPoint, token, data);
    
    if (res && res.status === 200) {
      return res.data
        } else {
      console.log("get paciente error: ",res.data)
      return false
    }
  };

  const getPacienteSesiones = async (pacienteUid, inicio, fin) => {
    const token = await auth.currentUser.getIdToken();
    const endPoint = "getSesionesPaciente";

    const data = {
      clienteId: usuario.clienteId,
      uid: usuario.uid,
      pacienteId: pacienteUid,
      inicio: parseInt(inicio),
      fin: parseInt(fin),
    };

    const res = await getDataFromApi(endPoint, token, data);
    if (res && res.status === 200) {
      return res.data
        } else {
      console.log("get paciente error: ",res)
      return false
    }
  };


  return {
    usuario,
    handlerSetPacienteSelectedUid,
    createPaciente,
    pacienteSelectedUid,
    getPacientes,
    getPacienteSesiones,
    deletePaciente,
    getPaciente,
    updatePaciente,
    searchPaciente,
    uidPaciente,
    handlerUidPaciente
  };
};

//////////////////////////////////////////////////////////////

export {
  useHookPacientes,
};

