import React, { useState, useCallback, useRef } from "react";
import { useHookPacientes } from "../../../libs/hookPacientes";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import { FaRegListAlt } from "react-icons/fa";

import Busqueda from "./busqueda";
import EliminarPaciente from './eliminarPaciente';

import { FaTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { FaCalendarPlus } from "react-icons/fa";
import { FaUserPlus } from "react-icons/fa";

import Paginacion from "../../../components/paginacion";
import { useHookMenu } from "../../../libs/firebase";

const Pacientes = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { handlerRuta, handlerModal, } = useHookMenu();

  const { searchPaciente, 
          handlerSetPacienteSelectedUid, 
          handlerUidPaciente 
        } = useHookPacientes();
  const navigate = useNavigate();
  const [pacientes, setPacientes] = useState(null)
  const handlerNavigate = useCallback((ruta) => {
    navigate(ruta);
  }, [navigate]);


  const handlerSearchPaciente = async (busqueda) => {
    setIsLoading(true)
    await searchPaciente(busqueda).then(
      (data) => {

        if (Array.isArray(data)) {
          setPacientes(data)
          setIsLoading(false)
        } 
      }
    )
      .catch((error) => {
        console.log(error)
      })
  };

  const handlerResetPacientes = () => {
    setPacientes(null);
    setIsLoading(false);
  }

  const getPacienteInfo = (pacienteUid) => {
    handlerUidPaciente(pacienteUid);
    handlerSetPacienteSelectedUid(pacienteUid);
    handlerNavigate("Nucleo");
  }

  const getAgendarPaciente = (pacienteUid) => {
    handlerUidPaciente(pacienteUid);
    handlerSetPacienteSelectedUid(pacienteUid);
    handlerRuta("agendar_cita");
    handlerModal();
  }

  const getPacienteInfoUpdate = (pacienteUid) => {

    handlerSetPacienteSelectedUid(pacienteUid);
    handlerNavigate("UpdatePaciente");
  }

  const getPacienteInfoHistorial = (pacienteUid) => {

    handlerSetPacienteSelectedUid(pacienteUid);
    handlerNavigate("HistorialPaciente");
  }

  const añadirPaciente = () => {

    handlerSetPacienteSelectedUid(null);
    handlerNavigate("Nucleo");
  };

  const [showModalEliminar, setShowModalEliminar] = useState(false)
  const [nombrePacienteEliminar, setNombrePacienteEliminar] = useState('');
  const [apellidoPacienteEliminar, setApellidoPacienteEliminar] = useState('');
  const selectedUid = useRef(null)

  const handleOnEliminar = (pacienteUid, nombre, apellido) => {
    setNombrePacienteEliminar(nombre)
    setApellidoPacienteEliminar(apellido)
    selectedUid.current = pacienteUid;
    setShowModalEliminar(true)
  }

  //PAGINACION:
  const [paginaActual, setPaginaActual] = useState(1);
  const pacientesPorPagina = 10;
  const numeroTotalPaginas = Array.isArray(pacientes) ? Math.ceil(pacientes.length / pacientesPorPagina) : 1;

  const handlePaginaCambio = (numeroPagina) => {
    setPaginaActual(numeroPagina);
  };

  const handlePaginaAnterior = () => {
    if (paginaActual > 1) {
      setPaginaActual(paginaActual - 1);
    }
  };

  const handlePaginaSiguiente = () => {
    if (paginaActual < numeroTotalPaginas) {
      setPaginaActual(paginaActual + 1);
    }
  };

  return (
    <div className="w-full h-full bg-azure  overflow-y-auto" key={nanoid()}>

      {showModalEliminar && (<div>
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-black bg-opacity-90 z-50"
          key={nanoid()}
        >
          <div className="relative flex flex-col justify-center">
            <div className="w-auto p-6 m-auto bg-white rounded-3xl shadow-md max-h-[90vh] overflow-auto">
              <div className="flex items-start justify-between rounded-t">
                <button
                  onClick={() => setShowModalEliminar(false)}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <EliminarPaciente nombre={nombrePacienteEliminar} 
              apellido={apellidoPacienteEliminar} 
              pacienteUid={selectedUid.current} 
              setShowModal={setShowModalEliminar} 
              busqueda={handlerSearchPaciente} 
              setIsLoading={setIsLoading} 
              handlerResetPacientes={handlerResetPacientes}
              />
            </div>
          </div>
        </div>
      </div>)}

      <Busqueda handlerSearchPaciente={handlerSearchPaciente}  />

      <div
        id="last-users"
        className="w-[98%] m-4  bg-lightgrey rounded-md  p-4"
        key={nanoid()}
      >
        <div className="flex justify-between">
          <h1 className="text-3xl  text-center font-semibold text-gray-700 m-4">
            Lista de pacientes
          </h1>

          <button
            onClick={añadirPaciente}
            className="items-center m-4 flex sm:text-xs lg:text-xl md:text-md px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"
          >
            <FaUserPlus className="m-4 lg:m-0 sm:m-0 md:m-0 lg:mr-2 w-8" />{" "}
            <span className="hidden sm:flex md:flex lg:flex">
              {" "}
              Añadir Paciente
            </span>

          </button>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="m-auto whitespace-nowrap w-[98%] ">
            <thead className=" ">
              <tr>
                <th className="text-left py-3 px-2">Nombres y Apellidos</th>
                <th className="text-left py-3 px-2">Teléfono</th>
                <th className="text-center py-3 px-2">DNI</th>
                <th className="text-left py-3 px-2">Correo Electrónico</th>
                <th className="text-left py-3 px-2"></th>
              </tr>
            </thead>

            <tbody>
              {isLoading ? (

                Array.from({ length: (pacientesPorPagina / 2) }, (_, index) => (
                  <tr key={index} className="bg-azure border-lightgrey border-b-8">
                    <td colSpan={5} className="py-3 px-2">
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-2.5 bg-darkgrey rounded-full dark:bg-gray-700 w-full mb-4"></div>
                      </div>
                    </td>
                  </tr>
                ))

              ) : (Array.isArray(pacientes) && pacientes.length > 0 && pacientes.slice(
                (paginaActual - 1) * pacientesPorPagina,
                paginaActual * pacientesPorPagina).map((paciente, index) => (

                  <tr key={index} className="bg-azure border-lightgrey border-b-8 hover:bg-aqua hover:text-white cursor-pointer">
                    <td className="py-3 px-2 rounded-l-[10px] capitalize" onClick={() => getPacienteInfo(paciente.uid)}>{`${paciente.nombre} ${paciente.apellido}`} </td>
                    <td className="py-3 px-2" onClick={() => getPacienteInfo(paciente.uid)}>{paciente.telefono}</td>
                    <td className="py-3 px-2" onClick={() => getPacienteInfo(paciente.uid)}>{paciente.documento}</td>
                    <td className="py-3 px-2" onClick={() => getPacienteInfo(paciente.uid)}>{paciente.correo}</td>
                    <td className="py-3 px-2  rounded-r-[10px]">
                      <div className='flex justify-around space-x-2' key={nanoid()}>
                      <button type="button" title="Historial" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => getAgendarPaciente(paciente.uid)}><FaCalendarPlus /></button>
                      <button type="button" title="Historial" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => getPacienteInfoHistorial(paciente.uid)}><FaRegListAlt /></button>
                      <button type="button" title="Editar" className=" text-[#9173f4] hover:text-[#4425A7]" onClick={() => getPacienteInfoUpdate(paciente.uid)}><FiEdit /></button>
                      <button type="button" title="Eliminar" className="text-red hover:text-darkred" onClick={() => handleOnEliminar(paciente.uid, paciente.nombre, paciente.apellido)}><FaTrashCan /></button>
                      </div>
                    </td>
                  </tr>
                )))}
            </tbody>
          </table>
        </div>
        <Paginacion
          currentPage={paginaActual}
          totalPages={numeroTotalPaginas}
          onPageChange={handlePaginaCambio}
          onPrevPage={handlePaginaAnterior}
          onNextPage={handlePaginaSiguiente}
        />
      </div>
     
    </div>
  );
};

export default Pacientes;