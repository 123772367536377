import React from 'react';
import { PieChart } from '@mui/x-charts';
import { useEffect, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { useHookReportes } from '../../../libs/hookReportes';
import { GridToolbar } from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { BiCalendar } from 'react-icons/bi';

const ReporteEstadisticasIngresos = () => {
	const isFirstRender = useRef(true);
	const [isLoading, setIsLoading] = useState(true);

	const columns = [
		{ field: 'col1', headerName: 'Id', width: 50 },
		{ field: 'col2', headerName: 'Fecha', width: 120 },
		{ field: 'col3', headerName: 'Centro', width: 120 },
		{ field: 'col4', headerName: 'Paciente', width: 280 },
		{ field: 'col5', headerName: 'Comporbante', width: 120 },
		{ field: 'col6', headerName: 'Recibo', width: 120 },
		{ field: 'col7', headerName: 'Tipo', width: 120 },
		{ field: 'col8', headerName: 'Precio', width: 100 },
		{ field: 'col9', headerName: 'Servicio', width: 250 },
		{ field: 'col10', headerName: 'Especialista', width: 250 },
	];

	const { getReport, filtroDate5, filtros5 } = useHookReportes();
	const [rows, setRows] = useState([]);
	const [grafCitas, setGrafCitas] = useState([]);
	const [totalCitas, setTotalCitas] = useState(0);

	function capitalizeString(str) {
		if (typeof str === 'string' && str.length > 0) {
			const words = str.split(' ');
			const palabras = words.map(word => {
				return word[0].toUpperCase() + word.slice(1);
			});
			return palabras.join(' ');
		} else {
			return str;
		}
	}

	function formatDate(fecha) {
		// Obtenemos el día, el mes y el año de la fecha
		if(fecha !== undefined)
		{
		  const dia = fecha.getDate();
		const mes = fecha.getMonth() + 1;
		const anyo = fecha.getFullYear();
			// Formateamos la fecha
			return `${dia}/${mes}/${anyo}`;
		} else {
		  return "";
		}
	}

	const fechaFormateada = unixTimestamp => {
		const fecha = new Date(unixTimestamp * 1000);
		const fechaFormateada = fecha.toLocaleDateString();
		return fechaFormateada;
	};

	function generarObjeto(data) {
		const objeto = [];
		let index = 0;
		const sortedData = data.sort((a, b) => a.inicio - b.inicio, {
			reverse: true,
		});
		for (const cita of sortedData) {
			
			index++;
			objeto.push({
				id: index,
				col1: index,
				col2: fechaFormateada(cita.fecha),
				col3: capitalizeString(cita.nombresCentros),
				col4: capitalizeString(cita.pacientes),
				col5: capitalizeString(cita.comprobante),
				col6: capitalizeString(cita.recibo),
				col7: capitalizeString(cita.tipo),
				col8: cita.precio,
				col9: capitalizeString(cita.servicio),
				col10: capitalizeString(cita.especialistas),
			});
		}
		return objeto;
	}


	function quitarComillas(key) {
		let keyRemplace = key.replace(/\'/g, ''); // eslint-disable-line
		return keyRemplace.replace(', ', '');
	}

	function sumAndRemoveDuplicates(arr) {
		// Creando un objeto para almacenar las keys y sus valores sumados
		const sumMap = {};

		// Recorriendo el array
		for (const item of arr) {
			const { key, value } = item;

			// Si la key ya existe en el sumMap, sumamos el nuevo value al existente
			if (sumMap.hasOwnProperty(key)) { // eslint-disable-line
				// eslint-disable-line
				sumMap[key] += value;
			} else {
				// Si la key no existe, la agregamos al sumMap con su value
				sumMap[key] = value;
			}
		}

		// Convirtiendo el sumMap de nuevo a un array
		const result = Object.keys(sumMap).map((key, index) => ({
			id: index,
			value: sumMap[key],
			label: key,
		}));

		return result;
	}

	const handlerGetData = async () => {
		const ingresos_por_centro = {
			"', 'nido brainy fun": 500,
			"', 'virtual": 700,
			'a domicilio': 1150,
			"a domicilio', '": 350,
			'la molina': 6850,
			miraflores: 7180,
			'nido brainy fun': 375,
			surco: 9860,
			virtual: 6720,
		};

		let arrayCitas;
		let usuarios;
		const dateInicio = Math.floor(filtroDate5[0].getTime() / 1000);
		const dateFin = Math.floor(filtroDate5[1].getTime() / 1000);	
		await getReport(dateInicio, dateFin, 5, true, filtros5)
			.then(res => {

				if (typeof res === 'object') {
					// setGrafCitas(objectFormater(res.summary));
					 setTotalCitas(res.total_ingresos);
					 arrayCitas = res.data;
					//setGrafCitas([]);
					// pie chart data
					const result = Object.keys(res.ingresos_por_centro);
					let array = [];
					for (const key of result) {
						if (key.includes("'")) {
							array.push({
								id: result.indexOf(key),
								key: quitarComillas(key),
								value: ingresos_por_centro[key],
							});
						} else {
							array.push({
								id: result.indexOf(key),
								key: key,
								value: ingresos_por_centro[key],
							});
						}
					}
					setGrafCitas(sumAndRemoveDuplicates(array));
				} else {
					setGrafCitas([]);
					setTotalCitas(0);
					arrayCitas = [];
				}
			})
			.catch(error => {
				console.log(error);
			});

		// await getUsuarios().then(res => {
		// 	const listaEspecialistas = res.filter(usuario => {
		// 		return usuario.rol === 'especialista';
		// 	});
		// 	const arrayDeObjetos = listaEspecialistas.map(objeto => ({
		// 		id: objeto.correo,
		// 		nombre: `${capitalizeString(objeto.nombre)} ${capitalizeString(
		// 			objeto.apellido
		// 		)}`,
		// 	}));

		// 	usuarios = arrayDeObjetos;
		// });
		if (arrayCitas.length > 0) {
			setRows(generarObjeto(arrayCitas, usuarios));
			setIsLoading(false);
		} else {
			setRows([]);
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handlerGetData();
		}
	}, [handlerGetData]);

	return (
		<div
			className="w-full h-full bg-azure  overflow-y-auto relative mt-3 "
			key={nanoid()}
		>
			{isLoading && (
				<div className="absolute inset-0 bg-white bg-opacity-50 z-40">
					<div className="absolute inset-0 flex items-center justify-center z-50">
						<div className="animate-spin rounded-full h-16 w-16 border-t-[6px] border-b-[6px] border-[#29CEBE]"></div>
					</div>
				</div>
			)}
			<div className='w-[90vw]'>
			<div className="flex mx-auto rounded-2xl h-20 my-5 bg-darkblue w-[95%]">
				<p className="text-2xl font-semibold text-center mx-auto my-auto text-white">
					{`Reportes ingresos por centro ${formatDate(
						filtroDate5[0]
					)} hasta ${formatDate(filtroDate5[1])}`}
				</p>
			</div>
			{/* inicio graficos */}
			{rows.length > 0 && (
				<section className="text-gray-600 body-font w-full mb-4 mx-auto">
					<div className="container px-5 pt-3 mx-auto w-full">
						<div className="flex -m-4 text-center mx-auto w-full justify-center">
							<div className="p-4 md:w-2/5 sm:w-1/2 w-full">
								<div className="flex-wrap  px-3 py-4 bg-lightgrey rounded-xl  text-darkblue mx-auto">
									<p className="text-2xl font-medium text-center mx-auto mb-1 ">
										Sesiones por centro
									</p>
									<div className="flex justify-center">
										{grafCitas.length > 0 && (
											<PieChart
												series={[
													{
														data: grafCitas,
														innerRadius: 50,
														cx: 95,
													},
												]}
												sx={{
													'--ChartsLegend-rootOffsetX': ' -80px',
													'--ChartsLegend-rootOffsetY': ' 0px',
												}}
												width={400}
												height={200}
											/>
										)}
									</div>
								</div>
							</div>

							{/* card 2 */}
							<div className="hidden p-4 md:w-2/5 sm:w-1/1 w-full  ">
								<div className="flex-col bg-lightgrey rounded-xl  text-darkblue justify-center items-center  h-[100%]">
									{/* card  */}
									<div className="p-2 w-full h-1/2  flex">
										<div className=" flex-col w-[100%] border-2 border-gray-200 px-3  bg-[#38b000] rounded-xl  text-white h-[90%] justify-between">
											<div className="my-1">
												<div className="mt-2 flex justify-center items-center align-middle">
													<BiCalendar className="text-2xl" />
													<h2 className=" title-font font-medium text-xl text-gray-900 px-2 ">{`${formatDate(
														filtroDate5[0]
													)}`}</h2>
												</div>
												<p className="leading-relaxed">Hasta</p>
												<div className="flex justify-center items-center align-middle">
													<BiCalendar className="text-2xl" />
													<h2 className=" title-font font-medium text-xl text-gray-900 px-2 ">{`${formatDate(
														filtroDate5[1]
													)}`}</h2>
												</div>
											</div>
										</div>
									</div>
									{/* fin car  */}
									{/* inicio  */}
									<div className="p-2 w-full h-1/2  ">
										<div className="flex-wrap border-2 border-gray-200 px-3 bg-[#4cc9f0] rounded-xl  text-white h-[90%] my-auto">
											<div className="flex justify-center mt-5 ">
												<BiCalendar className="text-4xl" />
												<h2 className=" title-font font-medium text-3xl text-gray-900 px-2 ">{`${totalCitas}`}</h2>
											</div>
											<p className="leading-relaxed">Citas</p>
										</div>
									</div>
									{/* fin car  */}
								</div>
							</div>
							{/* fin car 2 */}
						</div>
					</div>
				</section>
			)}
			{/* fin graficos */}
			<div className=" mx-auto bg-lightgrey  rounded-2xl p-5 w-[95%]">
				<Box sx={{ height: 400, width: 1 }} className="">
					<DataGrid
						showCellVerticalBorder={true}
						showColumnVerticalBorder={true}
						// unstable_ignoreValueFormatterDuringExport={true}
						rows={rows}
						// disableColumnFilter
						// disableColumnSelector
						// disableDensitySelector
						columns={columns}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
					/>
				</Box>
			</div>
			</div>
		</div>
	);
};

export default ReporteEstadisticasIngresos;
