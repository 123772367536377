import React from 'react';
import { useHookAuth } from "../libs/firebase";
import { Navigate } from 'react-router-dom';

export const ProtectedRouteRol = ({ children }) => {
  const { logued } = useHookAuth();
if (!logued) {
  return <Navigate to="/login" />
} 

  return <> {children} </>
}
