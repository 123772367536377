import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHookCalendario } from '../../../libs/hookCalendario';
import { FaFilter } from 'react-icons/fa';
import { useHookCentros } from '../../../libs/hookCentros';
import { useHookAuth } from '../../../libs/firebase';
import AgendarCita from './AgendarCita';
import { nanoid } from 'nanoid';

import {BsCalendar3Event, BsCalendar3, BsCalendar3Week, BsJournalPlus } from "react-icons/bs";
import { FaCalendarPlus } from "react-icons/fa";
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';

import 'moment/locale/es';
import moment from 'moment';
import Dia from './Dia';
moment.locale('es');

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import es from 'date-fns/locale/es';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DiaSemana from './DiaSemana';
import { useNavigate } from "react-router-dom";

import PopUp from '../../../components/PopUp';
import VerSesion from './VerSesion';
import Agenda from './Agenda';
import FiltroSideBar from './FiltroSideBar';
import { useHookPacientes } from '../../../libs/hookPacientes';

const Index = () => {
	const navigate = useNavigate();

	const {  
		handlerUidPaciente 
	  } = useHookPacientes();

	const [hora, setHora] = useState(new Date());
	const { usuario } = useHookAuth();
	const [valueHora, setValueHora] = useState(new Date());
	const [valueHoraSemana, setValueHoraSemana] = useState(new Date());
	const [view, setView] = useState('month');
	const isFirstRender = useRef(true);
	const {
		handleSetSesionSelected, handlerFiltros, filtros
	} = useHookCalendario();
	const { getCentros } = useHookCentros();
	const [showFilterAside, SetShowFilterAside] = useState(false);
	const [centros, setCentros] = useState(null);
	const [showModalAgendarCita, setShowModalAgendarCita] = useState(false);
	const [showModalVerSesion, setShowModalVerSesion] = useState(false);

	let disponibilidadTitle = "Sesiones";
	let correo = filtros.find((item) => item.filtro === 'especialistas').especialistas[0];
	if(correo !== undefined){
		disponibilidadTitle = "Disponibilidad especialista " + correo;
	}

	const handleCentros = async () => {
		await getCentros().then(res => {
			const arrayDeObjetos = res.map(objeto => ({
				id: objeto.uid,
				nombre: objeto.nombre,
			}));
			setCentros(arrayDeObjetos);
		});
	};

	const formatDate = (date) => {
		if (view === 'day' || view === 'agenda') {
		  return moment(date).format('dddd, D MMMM');
		} else if (view === 'week') {
		  const startOfWeek = moment(date).startOf('week').format('DD');
		  const endOfWeek = moment(date).endOf('week').format('DD');
		  return `${moment(date).format('MMMM')} ${startOfWeek}-${endOfWeek}`;
		} else if (view === 'month') {
		  return moment(date).format('MMMM YYYY');
		}
	};




	const handleShowAgendarCita = () => {
		setShowModalAgendarCita(!showModalAgendarCita);

	};

	const recargarCitas = () => {

	};



	const [diasCalendar, setDiasCalendar] = useState([]);


	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			 handleCentros();
			 if(usuario.rol === 'especialista'){
				const filters = [
					{ filtro: 'centros', centros: [] },
					{ filtro: 'pacientes', pacientes: [] },
					{ filtro: 'especialistas', especialistas: [usuario.correo] },
					{ filtro: 'servicios', servicios: [] },
				];
				handlerFiltros(filters);
			 }
		}
	}, [handleCentros]);

	const cargarDiasMes = async() => {
		const newDate = valueHora;
			let start, end;
			let dias = [];
			if (view === 'month') {
				start = moment(newDate).startOf('month').startOf('week');
				end = moment(newDate).endOf('month').endOf('week');
				// setStart(start);
				// setEnd(end);
				let mes = 1 + valueHora.getMonth();
				for (let dia = start; dia <= end; dia.add(1, 'day')) {
				
					const numeroConvertido = Number(dia.clone().format('MM'));
	
				if(numeroConvertido !== mes){
					dias.push({dia: dia.clone().format('DD'), mes: dia.clone().format('MM'), year: dia.clone().format('YYYY') , citas: "", mesSelecionado: false});
				} else {
					dias.push({dia: dia.clone().format('DD'), mes: dia.clone().format('MM'), year: dia.clone().format('YYYY') , citas: "", mesSelecionado: true});
				}
				  
				}
			}
			
			setDiasCalendar([...dias]);
	}

	const cargarHorasDias = async() => {
		const newDate = valueHora;
			let start ;
			let dias = [];
			if (view === 'day') {
				start = moment(newDate).startOf('day');
				
				const dia = start;
				for (let hora = 6; hora <= 22; hora++) {
					dias.push({hora: hora.toString().padStart(2, '0'), dia: dia.clone().format('DD'), mes: dia.clone().format('MM'), year: dia.clone().format('YYYY')});
				
			}
			setDiasCalendar([...dias]);
		}
	}

	const cargarDiasSemana = async() => {
		const newDate = valueHoraSemana;			
			let start, end;
			let dias = [];
			
			if (view === 'week') {
				
				start = moment(newDate).startOf('isoWeek');
				end = moment(newDate).endOf('isoWeek');

				for (let dia = start; dia <= end; dia.add(1, 'day')) {
					dias.push({dia: dia.clone().format('DD'), mes: dia.clone().format('MM'), year: dia.clone().format('YYYY') });
				}
			}
			
			setDiasCalendar([...dias]);
			
	}

	useEffect(() => {
		if(view === 'month'){
			cargarDiasMes();
		}	
	},[valueHora]);

	useEffect(() => {
		if(view === 'week'){
			cargarDiasSemana();
		}
	},[valueHoraSemana]);

	useEffect(() => {
		if(view === 'day'){
			cargarDiasSemana();
		}
	},[hora]);

	useEffect(() => {
		if(view === 'month'){
			cargarDiasMes();
		}
		if(view === 'week'){
			cargarDiasSemana();
		}
		if (view === 'day'){
			cargarHorasDias();	
		}
	},[view]);

	const avanzarSemana = () => {
		const dateAvanzar = moment(valueHoraSemana).add(1, 'week');
		setValueHora(dateAvanzar._d);
		setHora(dateAvanzar._d);
		setValueHoraSemana(dateAvanzar);
	}

	const retrocederSemana = () => {
		const dateRetroceder = moment(valueHoraSemana).subtract(1, 'week');
		setValueHora(dateRetroceder._d);
		setHora(dateRetroceder._d);
		setValueHoraSemana(dateRetroceder);
	}

	const changeFecha = (date) => {
		setValueHora(date);
		setValueHoraSemana(date);
		setHora(date);
	}

	const cambioVista = (vista) => {
		if(view === 'month'){
			setValueHora(new Date())
			setView(vista);
		} else if(view === 'week'){
			setView(vista);	
		} else if(view === 'day'){
			setView(vista);				
		}
	}

	const handlerNavigate = useCallback((ruta) => {
		navigate(ruta);
	  }, [navigate]);

	const handleCitasClick = () => {
		handlerNavigate('Citashoy');
	  };

	  const handleShowVerCitas = () => {
		setShowModalVerSesion(!showModalVerSesion);
	  };
  
	  const showSesiones = (sesionSeleccionada) => {
		if (usuario.rol !== 'especialista'){
			handleSetSesionSelected(sesionSeleccionada);
			setShowModalVerSesion(true)
		}
	  }

	  const showDay = (day, month, year) => {
		let fecha = new Date();
		fecha.setDate(parseInt(day));
  		fecha.setMonth(parseInt(month) - 1); 
  		fecha.setFullYear(parseInt(year));
		setHora(fecha);
		setView('day');
	  }

	  const [showFilter, setShowFilter] = useState(false);
	  const loading = false;


	  const handlerShowFilter = (data) => {
		handlerFiltros(data);
	}

	const ShowFilter = async() => {
		SetShowFilterAside(!showFilterAside);
		setShowFilter(!showFilter);
	}

	const cancelFilter = () => {
		setShowFilter(!showFilter);
		SetShowFilterAside(!showFilterAside);
	}

	const showAgendarCita = () => {
		setShowModalAgendarCita(true)
		handlerUidPaciente(null);
	}


	return (
		<div className="flex w-full h-full bg-azure overflow-x-auto relative">
			{!showFilterAside && (
				<div className="fixed top-[50%] boton-[50%] right-[0] p-2 z-50 animate-fade-up animate-once animate-duration-500 animate-delay-0 animate-ease-in-out ">
					<button
						className="bg-darkblue text-white rounded-full w-16 h-16 flex items-center justify-center hover:bg-darkaqua hover:animate-jump animate-once animate-duration-1000 animate-delay-0 animate-ease-in-out"
						onClick={() => ShowFilter()}
					>
						<FaFilter className="w-8 h-8" />
					</button>
				</div>
			)}

			{/* fin filtro */}
			{showFilter && (
				<FiltroSideBar 
			handlerShowFilter={handlerShowFilter} 
			loading={loading} 
			cancelFilter={cancelFilter}
			/>
			)}

			{showModalVerSesion && (<PopUp closeFunction={handleShowVerCitas}>
				<VerSesion handleShowVerCitas={handleShowVerCitas} showSesiones={showSesiones}/>
              </PopUp>)}			


			{/* inicio agendar cita */}
			{showModalAgendarCita && (<PopUp closeFunction={handleShowAgendarCita}>
				<AgendarCita 
				handleShowAgendarCita={handleShowAgendarCita}
				recargarCitas={recargarCitas}
				/>
              </PopUp>)}
			
			{/* fin agendar cita */}

			{/* container  */}

			<div
				className={` flex flex-col items-center w-full mt-4 
				}`}
			>
				<div className="relative w-[95%] flex items-center rounded-lg rounded-t-[12px] ">
					

					
					<div className="grid grid-cols-7 gap-0 w-[100%] bg-[#DDE8ED] shadow-lg ">
					<div className='w-1/7 text-center font-bold border-darkblue col-span-7 bg-[#202B37] rounded-t-lg text-white ' key={nanoid()}>
						
						{/* <div className="flex justify-center my-2">Calendario</div> */}

							<div className="flex  h-[10vh] items-center my-2 justify-between">

							{/* dia - semana - mes */}
							<div className="flex my-auto ml-3">
							<button className={`${view === 'day' ? 'bg-aqua ' : ''} text-white mx-2 items-center flex-col sm:text-md lg:text-xl md:text-lg p-2  tracking-wide transition-colors duration-200 transform  rounded-md hover:bg-aqua hover:text-white active:outline-none active:shadow-lg active:text-white`} onClick={() => cambioVista('day')}> <BsCalendar3Event className="mx-auto text-center" />Día</button>
							<button className={`${view === 'week' ? 'bg-aqua ' : ''} text-white mx-2 items-center flex-col sm:text-md lg:text-xl md:text-lg p-2  tracking-wide transition-colors duration-200 transform  rounded-md hover:bg-aqua hover:text-white active:outline-none active:shadow-lg active:text-white`} onClick={() => cambioVista('week')}><BsCalendar3Week className="mx-auto text-center" />Semana </button>
							<button className={`${view === 'month' ? 'bg-aqua ' : ''} text-white mx-2 items-center flex-col sm:text-md lg:text-xl md:text-lg p-2  tracking-wide transition-colors duration-200 transform  rounded-md hover:bg-aqua hover:text-white active:outline-none active:shadow-lg active:text-white`} onClick={() => cambioVista('month')}><BsCalendar3 className="mx-auto text-center" />Mes </button>
							{usuario.rol === 'coordinador' && <button className={`${view === 'agenda' ? 'bg-aqua ' : ''} text-white mx-2 items-center flex-col sm:text-md lg:text-xl md:text-lg p-2  tracking-wide transition-colors duration-200 transform  rounded-md hover:bg-aqua hover:text-white active:outline-none active:shadow-lg active:text-white`}onClick={() => handleCitasClick()}><BsJournalPlus className="mx-auto text-center" />Citas </button>}
							{usuario.rol === 'administrador' && <button className={`${view === 'agenda' ? 'bg-aqua ' : ''} text-white mx-2 items-center flex-col sm:text-md lg:text-xl md:text-lg p-2  tracking-wide transition-colors duration-200 transform  rounded-md hover:bg-aqua hover:text-white active:outline-none active:shadow-lg active:text-white`}onClick={() => handleCitasClick()}><BsJournalPlus className="mx-auto text-center" />Citas </button>}
							</div>
							{/* fin dia - semana - mes */}

							{/* data picker semana */}
							{view === 'week' && <div className='flex px-4'>
							<button  onClick={() => retrocederSemana()}>
        					<MdChevronLeft size={30} />
      						</button>
      						<div className="items-center flex sm:text-md lg:text-xl md:text-lg p-2  tracking-wide  rounded-md capitalize">
								{formatDate(valueHoraSemana)}</div>
      						<button  onClick={() => avanzarSemana()}>
        					<MdChevronRight size={30} />
      						</button>
							</div>}
	  						{/* fin data picker semana */}

							{/* data picker day */}
							{view === 'day' && <div className='flex px-4'>
							<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={es}
							className=" text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							>
							<DatePicker
							value={hora}
							views={['day', 'month', 'year']}
							className=" w-fit  text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2 sm:text-md lg:text-xl md:text-lg"
							onChange={newValue => changeFecha(newValue)}
							/>
							</LocalizationProvider>
							</div>}
							{/* fin data picker day*/}


							{/* data picker mes */}
							{view === 'month' && <div className='flex px-4'>
							<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={es}
							className=" text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
							>
							<DatePicker
							value={valueHora}
							views={[ 'month', 'year']}
							className=" w-fit  text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2 sm:text-md lg:text-xl md:text-lg"
							onChange={newValue => changeFecha(newValue)}
							/>
							</LocalizationProvider>
							</div>}
							{/* fin data picker mes */}

							{/* boton agendar */}
							{usuario.rol === 'administrador' && <div className="flex my-auto mr-3">
							<button className={` text-white mx-2 items-center flex-col sm:text-md lg:text-xl md:text-lg p-2  tracking-wide transition-colors duration-200 transform  rounded-md hover:bg-aqua hover:text-white active:outline-none active:shadow-lg active:text-white`} onClick={() => showAgendarCita()}> <FaCalendarPlus className="mx-auto text-center" />Añadir cita</button>
							</div>}

							{usuario.rol === 'coordinador' && <div className="flex my-auto mr-3">
							<button className={` text-white mx-2 items-center flex-col sm:text-md lg:text-xl md:text-lg p-2  tracking-wide transition-colors duration-200 transform  rounded-md hover:bg-aqua hover:text-white active:outline-none active:shadow-lg active:text-white`} onClick={() => showAgendarCita()}> <FaCalendarPlus className="mx-auto text-center" />Añadir cita</button>
							</div>}
							{/* fin boton agendar */}

								
							</div>

					</div>

					{view !== 'day' && <div className='w-1/7 h-[4vh] text-center font-bold truncate ...' key={nanoid()}>Lunes</div>}
					{view !== 'day' && <div className='w-1/7 h-[4vh] text-center font-bold truncate ...' key={nanoid()}>Martes</div>}
					{view !== 'day' && <div className='w-1/7 h-[4vh] text-center font-bold truncate ...' key={nanoid()}>Miercoles</div>}
					{view !== 'day' && <div className='w-1/7 h-[4vh] text-center font-bold truncate ...' key={nanoid()}>Jueves</div>}
					{view !== 'day' && <div className='w-1/7 h-[4vh] text-center font-bold truncate ...' key={nanoid()}>Viernes</div>}
					{view !== 'day' && <div className='w-1/7 h-[4vh] text-center font-bold truncate ...' key={nanoid()}>Sabado</div>}
					{view !== 'day' && <div className='w-1/7 h-[4vh] text-center font-bold truncate ...' key={nanoid()}>Domingo</div>}

					{view === 'day' && <div className='my-2 w-1/7 h-[4vh] text-center font-bold  truncate ...' key={nanoid()}>Hora</div>}
					{view === 'day' && <div className='my-2 w-1/7 h-[4vh] text-center font-bold col-span-6 truncate ...' key={nanoid()}>{disponibilidadTitle}</div>}
					{view === 'day' && disponibilidadTitle !== 'Sesiones' && <div className=' w-1/7 h-[4vh] text-center font-bold col-span-7 truncate ... flex justify-center ' key={nanoid()}>
						<div className='w-[40px] bg-[#84d1fb] h-[20px] rounded-md my-auto mr-2'></div>
						<span className='my-auto mr-5 font-normal' >Disponible</span>
						<div className='w-[40px] bg-white h-[20px] rounded-md my-auto mr-2 ml-5'></div>
						<span className='my-auto  font-normal' >No Disponible</span>
						</div>}

					{view === 'month'&& diasCalendar.map((number) => (
						<Dia number={number} key={nanoid()} showDay={showDay} filtros={filtros}  />
					))}

					{view === 'week'&& diasCalendar.length < 8 && diasCalendar.map((number) => (
						<DiaSemana number={number} key={nanoid()} centros={centros} showSesiones={showSesiones} showDay={showDay} filtros={filtros} />
					))}

					{view === 'day' &&
					<Agenda key={nanoid()} hora={hora} centros={centros} showSesiones={showSesiones} filtros={filtros} /> 
					}

					</div>
					
				</div>
			</div>
			{/* fin container */}
			
		</div>
	);
};


export default Index;