import React, { useState } from "react";
import { FaTrashCan } from "react-icons/fa6";
import { useHookCentros } from "../../../libs/hookCentros";

const CrearCentro = (props) => {
    const { createCentro } = useHookCentros();
    const [salasCentro, setSalasCentro] = useState([]);
    const [nombreSala, setNombreSala] = useState("");
    const [errors, setErrors] = useState({});
    const [centro, setCentro] = useState({
        nombre: "",
        direccion: "",
        salas: "",
        telefono: "",
    });

    /**
     * Adds a new sala to the center.
     *
     * @param {Object} e - The event object.
     * @return {void}
     */
    const addSala = (e) => {
        e.preventDefault();
        let newErrors = {};
        const nombreSalaValido = /^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú0-9\s]{3,}$/;

        if (!nombreSalaValido.test(nombreSala.trim())) {
            newErrors.nombreSala = "Nombre inválido"
            setErrors(newErrors);
        } else {
            setSalasCentro([...salasCentro, { nombre: nombreSala.trim() }]);
            setNombreSala("");
            setErrors({})
        }
    };

        /**
     * Removes a sala from the salasCentro array at the specified index.
     *
     * @param {number} index - The index of the sala to be removed.
     * @return {void} This function does not return anything.
     */
    const removeSala = (index) => {
        const updatedSalas = [...salasCentro];
        updatedSalas.splice(index, 1);
        setSalasCentro(updatedSalas);
    };

    /**
     * Updates the state of the "centro" object with the new value of the input field.
     *
     * @param {object} target - The target object containing the value and name properties.
     * @return {void} This function does not return anything.
     */   
    const handleChange = ({ target: { value, name } }) => {
        setCentro({ ...centro, [name]: value });
    };

        /**
     * Handles the form submission.
     *
     * @param {Event} e - The event object.
     * @return {Promise} A promise that resolves when the form submission is complete.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        const trimCentro = {};

        for (const propiedad in centro) {
            if (typeof centro[propiedad] === 'string') {
                trimCentro[propiedad] = centro[propiedad].trim().toLowerCase();
            } else {
                trimCentro[propiedad] = centro[propiedad];
            }
        }

        trimCentro.salas = [...salasCentro];
        props.setShowModal(false);
        props.setIsLoading(true);
        await createCentro(trimCentro.nombre, trimCentro.direccion, trimCentro.telefono, trimCentro.salas);
        props.getCentros();
    };

    return (
        <div className="w-[35vw] min-w-[20rem] justify-center">
            <h3 className="text-2xl text-center font-semibold text-gray-700 mb-3 ">CREAR CENTRO</h3>
            {/* inicio form */}
            <form onSubmit={handleSubmit}>

                <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                    htmlFor='nombre'>Nombre
                    <input
                        required
                        pattern="^(?!.*\s{2,})[A-Za-zÑñÁÉÍÓÚáéíóú\s]{3,}$"
                        id='nombre'
                        name='nombre'
                        minLength="3"
                        maxLength="50"
                        className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                        type="text"
                        placeholder="Nombres..."
                        value={centro.nombre}
                        onChange={handleChange}
                    />
                </label>

                <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                    htmlFor='direccion'>Dirección
                    <input
                        required
                        id='direccion'
                        name='direccion'
                        className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                        type="text"
                        placeholder="Dirección..."
                        value={centro.direccion}
                        onChange={handleChange}
                    />
                </label>

                <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                    htmlFor='telefono'>Teléfono
                    <input
                        pattern="[+]?[0-9]+"
                        id='telefono'
                        name='telefono'
                        className=" w-full px-4 py-2   text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                        type="phone"
                        placeholder="+51 9## ### ###"
                        value={centro.telefono}
                        onChange={handleChange}
                    />
                </label>

                <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2"
                    htmlFor='salasCentro' >Salas
                    <div className="flex justify-between">
                        <input
                            name="salasCentro"
                            id="salasCentro"
                            className="w-[65%] px-4 py-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
                            type="text"
                            placeholder="Nombre sala..."
                            value={nombreSala}
                            onChange={(e) => setNombreSala(e.target.value)}
                        />
                        <button type="button" onClick={addSala} className=" w-[30%] sm:text-xs lg:text-xl md:text-md px-2 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white">Agregar</button>
                    </div>
                </label>

                {errors.nombreSala && (
                    <div>
                        <span className="text-end text-[#FF0000] mt-1 text-sm">{errors.nombreSala}</span>
                        <br />
                    </div>
                )}
                {/* incio div salas creadas */}
                <div className="mt-4">
                    <label className="block sm:text-sm lg:text-lg md:text-md  text-gray-800 my-2" >Salas creadas: {salasCentro.length}</label>
                    <ul className="flex flex-wrap">
                        {salasCentro.map((sala, index) => (
                            <li className="ring-2 ring-aqua px-2 py-2 w-auto m-4 tracking-wide  rounded-md flex justify-center" key={index}>{sala.nombre}
                                <button type="button" onClick={() => removeSala(index)} className="text-red hover:text-darkred ml-4">
                                    <FaTrashCan /></button>
                            </li>
                        ))}
                    </ul>
                </div>
                {/* fin dv salas creadas */}
                {/* inicio botones guardar y cancelar */}
                <div className='flex justify-around w-3/5 m-auto mt-8 mb-4'>
                    <input type="submit" value={"Guardar"} className="sm:text-xs lg:text-xl md:text-md min-w-fit w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white"></input>
                    
                    <button className="sm:text-xs lg:text-xl md:text-md min-w-fit w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white" type="button"
                        onClick={() => props.setShowModal(false)} >Cancelar
                    </button>
                </div>
                {/* fin botones guardar y cancelar */}
            </form>
            {/* fin form */}
        </div>
    )
};

export default CrearCentro;