import { useState, useEffect, useRef } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { nanoid } from 'nanoid';
import { useHookPacientes } from '../../../libs/hookPacientes';
import Sesion from './Sesion';
import { TableBody } from '@mui/material';
import PopUp from '../../../components/PopUp';
import VerSesion from '../calendario/VerSesion';
import { useHookCalendario } from '../../../libs/hookCalendario';

const SesionesList = () => {
    let height;
    if(window.innerHeight > 600){
      height = 600;
    } else {
      height = 400;
    }
    const { handleSetSesionSelected, handlerToogle } = useHookCalendario();
    const [active, setActive] = useState(false);
    const [sesiones, setSesiones] = useState([]);
    const [fecha, setFecha] = useState(new Date());
    const isFirstRender = useRef(true);
    const [page, setPage] = useState(0);
    const { pacienteSelectedUid, getPacienteSesiones } = useHookPacientes();

    function getFechaMes(fecha, meses) {
        // Obtenemos la fecha del mes anterior
        const fechaAnterior = new Date(fecha.getFullYear(), fecha.getMonth() - meses, 1);

        // Obtenemos la fecha del primer día del mes anterior
        const fechaInicio = new Date(fechaAnterior.getFullYear(), fechaAnterior.getMonth(), 1, 0, 0);
        const inicio = Math.floor(fechaInicio.getTime() / 1000);

        // Obtenemos la fecha del último día del mes anterior
        const fechaFin = new Date(fechaAnterior.getFullYear(), fechaAnterior.getMonth() + 1, 0, 23, 59);
        const fin = Math.floor(fechaFin.getTime() / 1000);

        // Devolvemos un array con las fechas
        return [inicio, fin];
      }
    
      //console.log(getFechaMes(fecha, 1))
    
      function getNombreMesYAnyo(fecha, index) {
        // Obtenemos la fecha del mes anterior
        const fechaAnterior = new Date(fecha.getFullYear(), fecha.getMonth() - index, 1);
      
        // Obtenemos el nombre del mes
        const mes = new Intl.DateTimeFormat("es", { month: "long" }).format(fechaAnterior);
      
        // Obtenemos el año
        const anyo = fechaAnterior.getFullYear();
      
        // Devolvemos el string con el nombre del mes y el año
        return `${mes} ${anyo}`;
      }

      const getSesionesFunc = async(paciente, fechaInicio, fechaFin ) => {
        const sesiones = await getPacienteSesiones(paciente, fechaInicio, fechaFin);
        return sesiones;
      }

      const handleValueSesiones = async(data) => {
          const fechas = getFechaMes(fecha, page);
          const existe = sesiones.includes(data);
            if(page === 0) {
              const sesion = {
                mes: data,
                sesiones: await getSesionesFunc(pacienteSelectedUid, fechas[0], fechas[1]),
              }
              setSesiones([sesion]);
              setPage(page + 1);
            } else if(page === 1) {
              const sesion = {
                mes: data,
                sesiones: await getSesionesFunc(pacienteSelectedUid, fechas[0], fechas[1]),
              }
              setSesiones([...sesiones, sesion]);
              setPage(page + 1);
            } else if(page === 2) {
              const sesion = {
                mes: data,
                sesiones: await getSesionesFunc(pacienteSelectedUid, fechas[0], fechas[1]),
              }
              setSesiones([...sesiones, sesion]);
              setPage(page + 1);
            } else if (!existe) {
              const response = await getPacienteSesiones(pacienteSelectedUid, fechas[0], fechas[1]);
              const sesion = {
                mes: data,
                sesiones: response,
              }
              setSesiones([...sesiones, sesion]);
              //setPage(page + 1);
              }
      }

    
      useEffect(() => {
          const data = getNombreMesYAnyo(fecha, page);
          handleValueSesiones(data);
      }, [page]);
    
      useEffect(() => {
        if(isFirstRender.current) {
          isFirstRender.current = false;
          setFecha(new Date());
          return;
        }
      })

      const closeModalEditar = async (citaId) => {
        setActive(!active);
        handlerToogle(2);
        handleSetSesionSelected(citaId);
      }

  return (
    <>
          {active && (
              <PopUp closeFunction={closeModalEditar}>
              <VerSesion closeFunction={closeModalEditar}  />
              </PopUp>
              )}

    <InfiniteScroll
      //className='my-2'
      dataLength={sesiones.length}
      hasMore={true}
      next={() => setPage((prevPage) => prevPage + 1)}
      loader={<h4>Cargando registros...</h4>}
      height={height}
      //pullDownToRefreshThreshold={5}
      //pullDownToRefreshContent={<h3>Pull down to refresh</h3>}
      //refreshFunction={() => console.log("refresh")}
      //pullDownToRefresh={false}
    >
     
     {sesiones.map((registro) => (
      <div className="my-6 relative flex flex-col justify-center w-full" key={nanoid()}>
      <div className=" p-6 m-auto bg-white rounded-3xl shadow-md max-h-[90vh] overflow-auto w-[84vw]">
      <h2 className="text-xl font-bold text-darkblue md:text-2xl my-4 capitalize">
        {registro.mes}
        </h2>
        <div className="flex items-start justify-between rounded-t">
  <TableContainer component={Paper} elevation={3} sx={{ height: 'auto', overflow: 'auto' }} >

  {typeof registro.sesiones === 'object' ? (
    <Table sx={{ height: 'auto', overflow: 'auto' }}>
    <TableHead>
    <TableRow>
      <TableCell sx={{ fontSize: 'h6.fontSize', m: 1 }}>Dia</TableCell>
      <TableCell align="left" sx={{ fontSize: 'h6.fontSize', m: 1 }}>Hora</TableCell>
      <TableCell align="left" sx={{ fontSize: 'h6.fontSize', m: 1 }}>Centro</TableCell>
      <TableCell align="left" sx={{ fontSize: 'h6.fontSize', m: 1 }}>Servicio</TableCell>
      <TableCell align="left" sx={{ fontSize: 'h6.fontSize', m: 1 }}>Especialista</TableCell>
    </TableRow>
  </TableHead>
  
<TableBody sx={{ height: 'auto', overflow: 'auto' }} >
  {registro.sesiones.map((sesion) => (
  <Sesion key={sesion.Id} sesion={sesion} closeModalEditar={closeModalEditar} />
  ))}
</TableBody>

  </Table>
  ) : (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableBody>
        <TableRow>
          <TableCell align="left" sx={{ fontSize: 'h6.fontSize', m: 1 }}>No hay sesiones</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )}

   </TableContainer>
               </div>
               </div>
               </div>

      ))}
    
    </InfiniteScroll> 
    
    </>
  )
}

export default SesionesList