import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { useHookPacientes } from '../../../libs/hookPacientes';

const CardUser = () => {
    const [usuario, setUsuario] = useState(null);
    const isFirstRender = useRef(true);
    const {
      pacienteSelectedUid,
      getPaciente,
    } = useHookPacientes();

    const getDatosPaciente = async () => {
      const pacienteData = await getPaciente(pacienteSelectedUid);
      setUsuario(pacienteData);
    }

    useEffect(() => {
      if(isFirstRender.current){
        isFirstRender.current = false;
        getDatosPaciente();
      }
    })

  return (
    <div className="flex flex-col text-center w-full mb-1 rounded-2xl h-20 bg-darkblue">
          {usuario !== null ? (<h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 text-white my-auto capitalize">{`Historial de sesiones ${usuario.nombre} ${usuario.apellido}`}</h1>) : 
          (<h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 text-white my-auto">Cargando...</h1>)}
    </div>
  )
}

export default CardUser

