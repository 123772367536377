import React, { useState, useEffect, useRef} from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { toast } from 'react-toastify';
import es from 'date-fns/locale/es';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import solesImg from '../../../img/simbolo-de-moneda-nuevo-sol-de-peru.png';
import {
	FaHandHoldingMedical,
	FaConciergeBell,
	FaBuilding,
} from 'react-icons/fa';
import '../../styles/calendarioCita.css';
import { useHookPacientes } from '../../../libs/hookPacientes';
import { useHookServicios } from '../../../libs/hookServicios.jsx';
import { useHookAuth } from '../../../libs/firebase.js';
import { useHookCentros } from '../../../libs/hookCentros.jsx';
import { useHookCalendario } from '../../../libs/hookCalendario.jsx';
import SearchableDropdown from '../../../components/seachableDropdown.js';
import { useCallback } from 'react';
import { useHookMenu } from "../../../libs/firebase.js";

const AgendarCita = ({
	handleShowAgendarCita,
	
}) => {
	const {handlerModal} = useHookMenu();
	const [valueHora, setValueHora] = useState(new Date());
	const {uidPaciente, getPaciente} = useHookPacientes();
	const {usuario} = useHookAuth();
	const [servicios, setServicios] = useState(null);
	const [value, setValue] = useState('');
	const [error, setError] = useState({fecha: false, hora: false, sala: false, centro: false});
	const {getServicioEspecialistas, getServicios } = useHookServicios();
	const { getCentros } = useHookCentros();
	const [centros, setCentros] = useState(null);
	const { createCita } = useHookCalendario();
	const [salaSeleccionada, setSalaSeleccionada] = useState(null);
	const [sesionSelected, setSesionSelected] = useState(null);
	const [servicio, setServicio] = useState(null);
	const [centro, setCentro] = useState(null);
	const [especialistaFilter, setEspecialistaFilter] = useState(null);
	const [centroValue, setCentroValue] = useState(null);

	const [sesiones, setSesiones] = useState(null);
	const [disabled, setDisabled] = useState(false);

	const isFirstRender = useRef(true);

	const handlerGetServicios = useCallback(async() => {
		await getServicios().then((res)=>{
			if(usuario.servicios !== undefined){
				const misServicios =usuario.servicios;
				const especialistaServicios = [];
				for (const miServicio of misServicios) {
					const serviciosFiltrados = res.filter(servicio => servicio.uid === miServicio.servicioId );
					if(serviciosFiltrados !== undefined && serviciosFiltrados.length > 0){
					especialistaServicios.push(serviciosFiltrados[0])
					}}
				setServicios(especialistaServicios);
			} else {
				setServicios(res)
			}
		})
		await getCentros().then((res)=>{
			setCentros(res)})
		},[]);

	const handlerGetPaciente = useCallback(async(uidPaciente) => {
		let pacienteSel = null
		await getPaciente(uidPaciente).then((res)=>{
			pacienteSel = res;
			handlerSetPaciente(pacienteSel)
		}).catch((err)=>{console.log(err)})
	})


	useEffect(() => {
		if (isFirstRender.current) {
			isFirstRender.current = false;
			handlerGetServicios();
			if(uidPaciente !== null){
				handlerGetPaciente(uidPaciente);
			}
		}
	},[handlerGetServicios, handlerGetPaciente, uidPaciente])



	const handlerClose = () => {
		if (typeof handleShowAgendarCita === "function") {
			handleShowAgendarCita();
		  } else {
			handlerModal();
			// navigate('/home/calendario');
		  }
		
	};

	const [cita, setCita] = useState({
		especialista: null,
		comentario: '',
		precio: '',
		saldo: '',
		pago: false,
		paciente: null,
		sesiones: null,
		pagos: null,
	});

	const [paciente, setPaciente] = useState({
		pacienteId: '',
		nombre: '',
		apellido: '',
		documento: '',
		correo: '',
		telefono: '',
	});

	const [especialistaCita, setEspecialistaCita] = useState({
		especialistaId: '',
		nombre: '',
		apellido: '',
		correo: '',
		telefono: '',
	});

	const [servicioCita, setServicioCita] = useState({
		servicioId: '',
		nombre: '',
		descripcion: '',
	});

	const handlerSetPaciente = data => {
		setValue(`${data.nombre} ${data.apellido}`);
		setPaciente({
			...paciente,
			pacienteId: data.uid,
			nombre: data.nombre,
			apellido: data.apellido,
			documento: data.documento,
			correo: data.correo,
			telefono: data.telefono,
		});
	};

	const [pacientes, setPacientes] = useState(null);

	const handlerSetPacientes = data => {
		setPacientes(data);
	}

	const seleccionarServicio = async event => {
		event.preventDefault();

		//llamar especialistas x servicio
		if (event.target.value !== '0') {
			const servicioSeleccionado = servicios.find(
				servicio => servicio.uid === event.target.value
			);
			setServicio(servicioSeleccionado);
			setServicioCita({
				...servicioCita,
				servicioId: servicioSeleccionado.uid,
				nombre: servicioSeleccionado.nombre,
				descripcion: servicioSeleccionado.descripcion,
			});
			setSesiones(
				servicioSeleccionado.sesiones.map(objeto => ({
					...objeto,
					centroId: '',
					nombreCentro: '',
					sala: '',
					inicio: '',
					fin: '',
				}))
			);
			if(usuario.servicios !== undefined) {
				const especialista = {
					especialistaId: usuario.correo,
      				nombre: usuario.nombre,
      				apellido: usuario.apellido,
      				especialidad: usuario.especialidad
				}
				setEspecialistaFilter([especialista]);
			} else {
				await getServicioEspecialistas(event.target.value)
				.then(res => {
					if (res.length > 0) {
						setEspecialistaFilter(res);
					} else {
						setEspecialistaFilter([]);
					}
				})
				.catch(err => {
					console.log(err);
				});
			}

		}
			
	};

	const seleccionarEspecialista = async event => {
		event.preventDefault();
		if (event.target.value !== '0') {
			setCita({ ...cita, especialista: event.target.value });

			const especialistaSeleccionado = especialistaFilter.find(
				especialista => especialista.especialistaId === event.target.value
			);

			setEspecialistaCita({
				...especialistaCita,
				especialistaId: especialistaSeleccionado.especialistaId,
				nombre: especialistaSeleccionado.nombre,
				apellido: especialistaSeleccionado.apellido,
				correo: especialistaSeleccionado.especialistaId,
				telefono: especialistaSeleccionado.telefono,
			});
		}
	};

	const seleccionarSesion = (data, index) => {
		setSesionSelected(data);

		const sesion = sesiones[index];

		if (sesion.nombreCentro !== '') {
			const centroSel = centros.find(
				centro => sesion.nombreCentro === centro.nombre
			);
			sesion.centroId = centroSel.uid;
			sesion.nombreCentro = centroSel.nombre;
			setCentro(centroSel);
			setCentroValue(centroSel.uid);
		} else {
			setCentro(null);
			setSalaSeleccionada(null);
			setCentroValue('0');
		}

		if (sesion.sala !== '') {
			const sala = { nombre: sesion.sala };

			setSalaSeleccionada(sala);
			sesion.sala = data.sala;
		} else {
			setSalaSeleccionada(null);
		}

		if (sesion.inicio !== '') {
			const fecha = new Date(sesion.inicio * 1000);
			handleDateChange(fecha, sesion);
			setError({fecha:false, hora: false});
		} else {
			setError({fecha:true, hora: true});
		}
		sesion.nombresesion = data.nombre;
		sesion.nombre = data.nombre;

		setSesiones([
			...sesiones.slice(0, index),
			sesion,
			...sesiones.slice(index + 1),
		]);
	};

	const seleccionarCentro = event => {
		event.preventDefault();
		if (event.target.value !== '0') {
			const centroSel = centros.find(
				centro => centro.uid === event.target.value
			);
			setCentro(centroSel);
			setCentroValue(event.target.value);
			const newData = sesiones.map(objeto => {
				if (objeto.nombre === sesionSelected.nombre) {
					return {
						...objeto,
						centroId: event.target.value,
						nombreCentro: centroSel.nombre,
					};
				}
				return objeto;
			});
			setSesiones(newData);
			setError({
				...error,
				centro: false
			  });
		}
	};

	const handlerSala = data => {
		setSalaSeleccionada(data);
		const newData = sesiones.map(objeto => {
			if (objeto.nombre === sesionSelected.nombre) {
				return {
					...objeto,
					sala: data.nombre,
				};
			}
			return objeto;
		});
		setSesiones(newData);
		setError({
			...error,
			sala: false
		  });
	};

	const handleDateChange = (date, data) => {
		setError({...error, fecha:false, hora: false});
		setValueHora(date);
		const year = date.getFullYear();
		const month = date.getMonth();
		const day = date.getDate();
		const hora = date.getHours();
		const minuto = date.getMinutes();

		const nuevaFecha = new Date(year, month, day, hora, minuto, 0);
		const timestampUnixInicio = Math.floor(nuevaFecha.getTime() / 1000);
		const inicio = timestampUnixInicio;
		let duracion;
		if (data === undefined) {
			duracion = parseInt(sesionSelected.duracion);
		} else {
			duracion = parseInt(data.duracion);
		}

		const nuevaFecha2 = new Date(year, month, day, hora, minuto, 0);
		nuevaFecha2.setMinutes(nuevaFecha2.getMinutes() + duracion);
		const timestampUnixFin = Math.floor(nuevaFecha2.getTime() / 1000);
		const fin = timestampUnixFin;
		let newData;
		if (data === undefined) {
			newData = sesiones.map(objeto => {
				if (objeto.nombre === sesionSelected.nombre) {
					return {
						...objeto,
						inicio: inicio,
						fin: fin,
					};
				}
				return objeto;
			});
		} else {
			newData = sesiones.map(objeto => {
				if (objeto.nombre === data.nombre) {
					return {
						...objeto,
						inicio: inicio,
						fin: fin,
					};
				}
				return objeto;
			});
		}
		setSesiones(newData);
	};

	const handleComentarioChange = event => {
		setCita({ ...cita, comentario: event });
	};

	

	const handlerGuardar = event => {
		event.preventDefault();
		const filteredSessions = sesiones.map(session => {
			delete session.nombresesion;
			return session;
		});
		const sesionFiltered = filteredSessions.filter(
			item => item.inicio !== '' && item.fin !== '' && item.sala !== '' && item.centroId !== ''
		);
		
		if (
			cita.precio !== '' &&
			paciente.pacienteId !== '' &&
			servicioCita.servicioId !== '' &&
			especialistaCita.especialistaId !== '' &&
			sesionFiltered.length > 0
		) {
			const data = {
				clienteId: 'centroSkinner',
				comentario: cita.comentario.toLowerCase(),
				precio: parseInt(cita.precio),
				paciente: [
					{
						pacienteId: paciente.pacienteId,
						nombre: paciente.nombre,
						apellido: paciente.apellido,
						documento: paciente.documento,
						correo: paciente.correo,
						telefono: paciente.telefono,
					},
				],
				servicio: [
					{
						servicioId: servicioCita.servicioId,
						nombre: servicioCita.nombre,
						descripcion: servicioCita.descripcion,
					},
				],
				especialista: [
					{
						especialistaId: especialistaCita.especialistaId,
						nombre: especialistaCita.nombre,
						apellido: especialistaCita.apellido,
						correo: especialistaCita.correo,
						telefono: '',
						documento: '',
					},
				],
				sesiones: sesiones,
			};
			handlerCreateCita(data);
		} else {
			if(sesionFiltered.length === 0) {
				if (filteredSessions.filter(
					item => item.centroId !== ''
				).length === 0){
					toast.error('Debe completar todos los campos en la sesiones seleccione un centro', {
						position: 'top-center',
						theme: 'colored',
					});
					setError({
						...error,
						centro: true
					  });
				} else if(filteredSessions.filter(
					item => item.inicio !== '' 
				).length === 0){
					toast.error('Debe completar todos los campos en la sesiones  seleccione una fecha', {
						position: 'top-center',
						theme: 'colored',
					});
					setError({
						...error,
						fecha: true,
						hora: true
					  });
				} else if(filteredSessions.filter(
					item => item.fin !== '' 
				).length === 0){
					toast.error('Debe completar todos los campos en la sesiones  seleccione una fecha', {
						position: 'top-center',
						theme: 'colored',
					});
					setError({
						...error,
						fecha: true,
						hora: true
					  });
				} else if (filteredSessions.filter(
					item =>  item.sala !== '' 
				).length === 0){
					toast.error('Debe completar todos los campos en la sesiones seleccione una sala', {
						position: 'top-center',
						theme: 'colored',
					});
					setError({
						...error,
						sala: true
					  });
				}
			}
		}
	};

	const handlerCreateCita = async data => {
		function closeModal() {
			setTimeout(() => {
				handlerClose();
			}, 2000);
		}

		setDisabled(true);
		await createCita(data)
			.then(() => {
				toast.success('Cita creada correctamente ', {
					position: 'top-center',
					theme: 'colored',
				});
				closeModal();
			})
			.catch(err => {
				console.log(err);
				toast.error(
					{ err },
					{
						position: 'top-center',
						theme: 'colored',
					}
				);
				closeModal();
			});
	};

	return (
		<div className="scrollbar-none scrollbar-thumb-gray-900 scrollbar-track-gray-100">
			<h3 className="text-2xl  text-center font-semibold text-gray-700 mb-3 ">
				AGENDAR CITA
			</h3>

			<form className="w-full">
				<div className="flex flex-col lg:flex-row w-[70vw] min-h-[50vh]">
					<div className="lg:w-1/2 p-3 w-full">
						{/* buscador de paciente */}
						<label
							className="my-2 mb-4 sm:text-sm lg:text-lg md:text-md text-gray-800"
							htmlFor="nombrePaciente"
						>
							Selecciona un paciente:
							<div className="relative">
								<SearchableDropdown
									options={pacientes}
									label="nombre"
									id="id"
									selectedVal={value}
									handleChange={val => handlerSetPaciente(val)}
									disabled={false}
									handlerSetPacientes={handlerSetPacientes}
									className="relative"
								/>
							</div>
						</label>
						{/* fin buscador paciente */}
						{/* selector de servicio */}
						{paciente.nombre !== '' && (
							<label
								className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
								htmlFor="nombreServicio"
							>
								Selecciona un servicio:
								<div className="relative">
									<FaConciergeBell className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />
									<select
										id="nombreServicio"
										name="nombreServicio"
										required
										value={cita.servicioNombre}
										onChange={event => seleccionarServicio(event)}
										className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 "
									>
										<option value="0">Selecciona una opción</option>
										{servicios !== null &&
											servicios !== undefined &&
											servicios.length > 0 &&
											servicios.map(servicioItem => (
												<option key={servicioItem.uid} value={servicioItem.uid}>
													{servicioItem.nombre}
												</option>
											))}
									</select>
								</div>
							</label>
						)}
						{/* fin selector de servicio */}

						{/* selector especialistas */}
						{sesiones !== null && (
							<label
								className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
								htmlFor="especialista"
							>
								Selecciona un especialista:
								<div className="relative">
									<FaHandHoldingMedical className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl" />

									<select
										required
										id="especialista"
										name="especialista"
										value={especialistaCita.especialistaId}
										onChange={event => seleccionarEspecialista(event)}
										className="block pl-10  w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2 capitalize"
									>
										<option value="0">Selecciona una opción</option>
										{especialistaFilter !== null &&
											especialistaFilter.length > 0 &&
											especialistaFilter.map((especialista, index) => (
												<option key={index} value={especialista.especialistaId}>
													{`${especialista.nombre} ${especialista.apellido}`}
												</option>
											))}
									</select>
								</div>
							</label>
						)}
						{/* fin selector especialistas */}

						{/* input precio */}
						{especialistaCita.especialistaId !== '' && (
							<label
								className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
								htmlFor="precio"
							>
								{`Precio ${servicio.precioMinimo} - ${servicio.precio}`}
								{/* {cita.precio !== "" && cita.precio < servicio.precioMinimo || cita.precio > servicio.precio `Precio Fuera del rango`} */}
								<div className="relative">
									<img src={solesImg} className="absolute top-1/2 translate-y-[-50%] left-3 text-2xl w-6 h-6" />
									<input
										required
										pattern="[0-9]+(?:\.[0-9]+)?"
										id="precio"
										name="precio"
										value={cita.precio}
										onChange={event =>
											setCita({ ...cita, precio: event.target.value })
										}
										className="block pl-10 w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										type="number"
										min={parseInt(servicio.precioMinimo)}
										max={parseInt(servicio.precio)}
										placeholder="0.0"
									/>
								</div>
							</label>
						)}

						{/* nota */}
						{cita.precio !== null && cita.precio !== '' && (
							<div>
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="nota"
								>
									Nota
									<textarea
										id="nota"
										name="nota"
										placeholder="Descripción adicional para la cita"
										value={cita.comentario}
										onChange={event =>
											handleComentarioChange(event.target.value)
										}
										rows={4}
										className="block w-full px-4 py-2 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md  focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									/>
								</label>
							</div>
						)}
						{/* fin nota */}

						{/* fin labels */}
					</div>
					{/* container derecha */}
					<div className="lg:w-1/2 w-full p-3 lg:border-l lg:border-grey sm:w-full ">
						{/* selector de sesiones */}
						{cita.precio !== '' && (
							<div className="relative w-full">
								<label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
									Selecciona una sesion:
								</label>
								<ul className="flex flex-wrap">
									{sesiones !== null &&
										sesiones.map((sesion, index) => (
											<li
												onClick={() => seleccionarSesion(sesion, index)}
												className={`${
													sesionSelected &&
													sesionSelected.nombre === sesion.nombre
														? 'bg-aqua text-white'
														: ''
												} cursor-pointer ring-1 ring-aqua px-2 py-2 w-auto m-2 tracking-wide rounded-md flex justify-center`}
												key={index}
											>
												{sesion.nombre}
											</li>
										))}
								</ul>
								{/* fin selector de sesiones     */}
							</div>
						)}

						{/* selector de centro */}
						{sesionSelected !== null && (
							<div className="relative">
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="centro"
								>
									Selecciona un centro:
									<FaBuilding className="absolute top-1/2 translate-y-[25%] left-3 text-2xl" />
									<select
										id="centro"
										name="centro"
										value={centroValue}
										onChange={event => seleccionarCentro(event)}
										className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
									>
										<option value="0">Selecciona una opción</option>
										{centros !== undefined &&
											centros !== null &&
											centros.length > 0 &&
											centros.map(centro => (
												<option key={centro.uid} value={centro.uid}>
													{centro.nombre}
												</option>
											))}
									</select>
									{error.centro &&<p className='text-[red] italic mb-2'>Seleccione un centro </p>}
								</label>
							</div>
						)}
						{/* fin input centro */}

						{centro !== null && (
							<div className="relative">
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="centro"
								>
									Selecciona un dia:
									<LocalizationProvider
										dateAdapter={AdapterDateFns}
										adapterLocale={es}
									>
										<DatePicker
											value={valueHora}
											className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
											onChange={newValue => handleDateChange(newValue)}
										/>
									</LocalizationProvider>
								</label>
								{error.fecha &&<p className='text-[red] italic mb-2'>Seleccione una fecha </p>}
							</div>
						)}

						{/* selector hora */}

						{centro !== null && (
							<div className="relative">
								<label
									className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800"
									htmlFor="centro"
								>
									Selecciona la hora:
									<MobileTimePicker
										value={valueHora}
										className="block pl-10 w-full px-4 py-3 mt-2 text-[#000000] border-none bg-lightgrey border rounded-md focus:ring-[#00cecb] focus:outline-none focus:ring-2"
										onChange={newValue => handleDateChange(newValue)}
									/>
								</label>
								{error.hora && <p className='text-[red] italic mb-2'>Seleccione una hora </p>}
							</div>
						)}

						{/* fin selecor hora */}

						{/* selector sala */}
						{centro !== null && (
							<div>
								<label className="my-2 sm:text-sm lg:text-lg md:text-md text-gray-800">
									Selecciona una sala:
								</label>
								<ul className="flex flex-wrap">
									{centro.salas.map((sala, index) => (
										<li
											onClick={() => handlerSala(sala, index)}
											className={`${
												salaSeleccionada &&
												salaSeleccionada.nombre === sala.nombre
													? 'bg-aqua text-white'
													: ''
											} cursor-pointer ring-1 ring-aqua px-2 py-2 w-auto m-4 tracking-wide rounded-md flex justify-center`}
											key={index}
										>
											{sala.nombre}
										</li>
									))}
								</ul>
								{error.sala &&<p className='text-[red] italic mb-2'>Seleccione una sala</p>}
							</div>
						)}

						{/* fin selecto sala */}
					</div>
					{/* fin container derecha */}
				</div>

				<div className="flex justify-around w-3/4 m-auto mt-8 mb-4">
					<button
						onClick={handlerGuardar}
						disabled={disabled}
						className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-aqua rounded-md hover:bg-darkblue hover:text-white active:outline-none active:shadow-lg active:text-white disabled:bg-grey disabled:text-white "
					>
						Guardar
					</button>
					<button
						type="button"
						onClick={handlerClose}
						className="min-w-fit sm:text-xs lg:text-xl md:text-md w-2/5 px-4 py-2 tracking-wide transition-colors duration-200 transform bg-greymedium rounded-md hover:bg-grey hover:text-white active:bg-darkblue active:outline-none active:shadow-lg active:text-white"
					>
						Cancelar
					</button>
				</div>
			</form>
		</div>
	);
};

export default AgendarCita;
