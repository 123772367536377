import React from "react";
import { FaTools, FaBuilding, FaConciergeBell,FaUsers, FaChartPie } from "react-icons/fa";
import { BiSolidUser, BiCalendar } from "react-icons/bi";
import { FaRegCalendarCheck } from "react-icons/fa";
import Styles from "../styles/home.module.css";

const Iconos = ({ opcion }) => {
  switch (opcion) {
    case "calendario":
      return <BiCalendar className={Styles.iconos} />;
    case "user":
      return <BiSolidUser className={Styles.iconos} />;
    case "tools":
      return <FaTools className={Styles.iconos} />;
    case "centros":
      return <FaBuilding  className={Styles.iconos} />;
    case "servicios":
      return <FaConciergeBell  className={Styles.iconos} />;
    case "users":
      return <FaUsers  className={Styles.iconos} />;
    case "reportes":
      return <FaChartPie  className={Styles.iconos}  />;
      case "disponibilidad":
        return <FaRegCalendarCheck  className={Styles.iconos}  />;
    default: 
    break;
  }
};

export default Iconos;